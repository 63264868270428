/*body {
    font-family: "Libre Baskerville", serif;
    font-weight: normal;
    line-height: 1.7;
    color: #fff;
    background-color:#fff;
  }*/
  a {
    cursor: pointer;
}
  .only-mobile {
    display: none;
  }
  .only-desktop {
    display: block;
  }
  .boxes{
    background-color: #da3a50 !important;
    height: 313px;
    border: 10px ;
    margin-top: 4%;
  }
  .row{
      font-size:21px !important ;
      font-family: "Libre Baskerville", serif;
  }
  .column{
    position: relative;
    width: 50%;
    padding-right: 10px;
    padding-left: 75px;
    top: 80px;
    left:30px;
  }
  .column1{
    position: absolute;
    width: 50%;
    padding-right: 10px;
    padding-left: 75px;
    top: 135px;
    left:30px;
  }
  .column2{
    position: absolute;
    width: 50%;
    padding-right: 10px;
    padding-left: 175px;
    top: 135px;
    right:30px;
  }
  .outer_circle {
    position: relative;
    width: 42px;
    height: 42px;
    float: right;
    top: -220px;
    margin: 290px 20px;
    border-radius: 50%;
    background: #fff;
}
  
.inner_circle {
    position: relative;
    width: 37px;
    height: 37px;
    transform: translate(7.5% , 7.5%);
    border-radius: 50%;
    background-color:#da3a50;
}
  .cls{
    font-size: 24px;
    font-family: "Open-Sans", sans-serif;
    color:#fff;
    top:3.5%;
    float: right;
    right: 10px;
    position: fixed;
  } 
  .button {
    color: #da3a50;
    padding: 15px 50px;
    border-radius:4px;
    text-align: center;
    font-size: 18px;
    font-family:  "Open-Sans", sans-serif;
    margin: 22px 55px;
  }
  button:not(:disabled) {
    cursor: pointer;
}
  .button1 {
      background-color: #fff;
      margin-top:15%;
    }
    @media screen and (max-width: 768px)  {
      .only-mobile {
        display: block !important;
      }
      .only-desktop {
        display: none !important;
      }
      .boxes{
        background-color: #da3a50 !important;
        width: 100vw;
        height: 289px;
        margin-top: 12%;
        border: 10px ;
        padding: 40px;
      }
      .row{
        font-size:16px !important ;
        font-family: "Libre Baskerville", serif;
        color: #fff;
        line-height: 1.7;
    }
    .entire{
      margin-left: -2px;
      margin-right: 0px;
      width: 80vw;
    }
    .outer_circle {
      position: relative;
      width: 42px;
      height: 42px;
      float: right;
      top: -21px;
      margin: 290px 20px;
      border-radius: 50%;
      background: #da3a50;
  }
    
  .inner_circle {
      position: relative;
      width: 37px;
      height: 37px;
      transform: translate(7.5% , 7.5%);
      border-radius: 50%;
      background-color:#da3a50;
  }
    .cls{
    font-size: 20px;
    font-family: "Open-Sans", sans-serif;
    color:#fff;
    top:-500%;
    position: fixed;
    } 
    .button {
      color: #da3a50;
      padding: 2px 70px;
      border-radius:4px;
      text-align: center;
      font-size: 18px;
      font-family:  "Open-Sans", sans-serif;
      margin: 25px -4px;
      cursor: pointer;
    }
    .button1 {
        background-color: #fff;
      }
  }