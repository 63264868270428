body {
    margin: 0%;
    font-family: "Libre Baskerville", serif;
    font-weight: normal;
    line-height: 1.5;
    color: #444;
    background-color: #fff;
  }
  a {
    display: inline-block;
  }
  
  a:hover {
    text-decoration: none;
    color: inherit;
  }
 .image{
   display: initial;
   width:30px;
   height:32px;
  } 
  .design{
      margin-right: 20%;
      margin-left: 20%;
      margin-top: 8%;
      line-height: 2 !important;
  }
  .equip{
      font-size:24px;
  }
  u{
    color: #da3a50;;
    -webkit-text-decoration-line: underline; 
    text-decoration-line: underline;
  }
  .method{
    font-size:24px;
    text-align: center;
  }
  .val{
    font-size: 18px;
  }
  .txt{
      margin-left: 20%;
      margin-right: 23% ;
  }
  .value{
    font-size: 18px;
    margin-left: 2.5%;
    margin-right: -20% ;
  }
  .pop{
    margin-left: 15%;
      margin-right: 16% ;
      font-size:18px;
  }
  ul {
  list-style: none;
  }
  .drop li { 
    padding-left: 2.5em;
    position: relative; 
  }
  
  .drop li::before {
    content: '';
      height: .4em;
      width: .4em;
      background:#000; 
      position: absolute;
      transform: rotate(45deg);
      top: .75em;
      left: 5;
  }

  @media screen and (max-width: 768px) {
    .design{
      margin-right: 3%;
      margin-left: 6%;
      margin-top: 10%;
      line-height: 1.6 !important;
  }
  .image{
    width:20px;
    height:20px;
   }
  .equip{
      font-size:18px;
  }
  .method{
    font-size:20px;
    text-align: center;
  }
  .val{
    font-size: 14px;
  }
  .value{
    font-size: 14px;
    margin-left: 2.5%;
    margin-right: 1% ;
  }
  .txt{
      margin-left: 10%;
      margin-right:7% ;
  }
  .pop{
    margin-left: 0%;
      margin-right: 11% ;
      font-size: 13px;
  }
  ul {
    list-style: none;
    }
    .drop li { 
      padding-left: 2.5em;
      position: relative; 
    }
    
    .drop li::before {
      content: '';
        height: .4em;
        width: .4em;
        background:#000; 
        position: absolute;
        transform: rotate(45deg);
        top: .55em;
        left: 10;
    }

  }
