body {
  margin: 0;
  font-family: "Libre Baskerville", serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  color: #444;
  background-color: #fff;
    /* Set "my-sec-counter" to 0 */
    counter-reset: my-sec-counter;
}

a {
  display: inline-block;
}

a:hover {
  text-decoration: none;
  color: inherit;
}

img {

  

}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

input:focus,
button:focus,
.btn:focus,
.btn.focus,
.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: none;
  outline: none;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
}

/*** Start Checkboxes ***/

/* Customize the label (the shCheckCon) */
.shCheckCon {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.shCheckCon input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.shCheckCon:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.shCheckCon input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.shCheckCon input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.shCheckCon .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/*** Start Checkboxes ***/

.shHeader {
  width: 100%;
  height: auto;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 3;
  background-color: #fff;
}

.shTopHeader {
  padding: 15px 40px;
  height: 71px;
  border-bottom: 1px solid #e8e8e8;
  transition: all 0.5s ease-in-out;
}

.shHeader.smHeader .shTopHeader {
  padding: 0px 40px;
  height: 47px;
  transition: all 0.5s ease-in-out;
}

.shMenu {
  width: 28px;
  opacity: 1;
  -webkit-transition: opacity 1s ease-in;
  -moz-transition: opacity 1s ease-in;
  -o-transition: opacity 1s ease-in;
}

.shHeader.smHeader .shMenu {
  -webkit-transition: opacity 0.1s ease-in;
  -moz-transition: opacity 0.1s ease-in;
  -o-transition: opacity 0.1s ease-in;
  opacity: 0;
}
.sahaLogo {

    margin-left: 30px;
    width: 45px;
    transition: all 0.5s ease-in-out;
  }
  .sLogo {
    margin-left: 115px;
    margin-top: -45px;
    width: 110px;
  }


.shHeader.smHeader .shLogo {
  margin-left: -28px;
  margin-top: 2px;
  transition: all 0.5s ease-in-out;
}

.shSearch {
  margin-right: 60px;
  width: 24px;
  opacity: 1;
  -webkit-transition: opacity 1s ease-in;
  -moz-transition: opacity 1s ease-in;
  -o-transition: opacity 1s ease-in;
}

.shHeader.smHeader .shSearch {
  -webkit-transition: opacity 0.1s ease-in;
  -moz-transition: opacity 0.1s ease-in;
  -o-transition: opacity 0.1s ease-in;
  opacity: 0;
}

.topLinks {
  
  width: auto;
  -webkit-transition: opacity 0.1s ease-in;
  -moz-transition: opacity 0.1s ease-in;
  -o-transition: opacity 0.1s ease-in;
  opacity: 0;
  display: none;
}

.shHeader.smHeader .topLinks {
  opacity: 1;
  display: block;
  -webkit-transition: opacity 1s ease-in;
  -moz-transition: opacity 1s ease-in;
  -o-transition: opacity 1s ease-in;
}

.topLinks li {
  float: left;
  margin: 0 0 0px 70px;
}

.topLinks li a {
  font: 600 14px "Open Sans", Semi bold;
  color: #444;
  padding: 14px 15px;
  text-transform: uppercase;
  text-decoration: none;
  font-size:16px;
  margin-top:2%;
}

.topLinks li.active a,
.topLinks li a:hover {
  background: #fcecef;
  color: #da3a50;
  text-decoration: none;
}

.bookmarkIcon {
  float: right;
  width: 16px;
  -webkit-transition: opacity 0.1s ease-in;
  -moz-transition: opacity 0.1s ease-in;
  -o-transition: opacity 0.1s ease-in;
  opacity: 0;
  margin-top: 11px;
}

.shHeader.smHeader .bookmarkIcon {
  opacity: 1;
  -webkit-transition: opacity 1s ease-in;
  -moz-transition: opacity 1s ease-in;
  -o-transition: opacity 1s ease-in;
}

.shProfile {
  display: inline-block;
  font-family: "Open Sans", sans-serif;
  margin-right: -16px;
  opacity: 1;
  -webkit-transition: opacity 1s ease-in;
  -moz-transition: opacity 1s ease-in;
  -o-transition: opacity 1s ease-in;
}

.shHeader.smHeader .shProfile {
  -webkit-transition: opacity 0.1s ease-in;
  -moz-transition: opacity 0.1s ease-in;
  -o-transition: opacity 0.1s ease-in;
  opacity: 0;
}

.shProfile .dropdown-menu {
  border: 0;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  top: 14px !important;
}

.shProfile .dropdown-item {
  font-size: 14px;
  text-align: right;
  color: #444;
  padding: 5px 35px;
}

.shProfile .dropdown-item.dropdown-sign-out {
  color: #da3a50;
  padding: 20px 35px;
}

.shProfile .dropdown-toggle::after,
.shLang .dropdown-toggle::after {
  display: none;
}

.shProfile .btn-secondary {
  color: #fff !important;
  background-color: #da3a50 !important;
  border-color: #da3a50 !important;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  box-shadow: none !important;
}

.shBanner .container {
  position: relative;
}

.shBnImg > img.banner-image {
  display: block;
  height: 100%;
  /* margin: 0 auto; */
  width: auto;
}
.shBnImg > iframe.banner-image {
  display: block;
  height: 100%;
  border: none;
  /* margin: 0 auto; */
  width: 100%;
}
.shBnIfr > iframe.banner-image {
  display: block;
  height: 100%;
  border: none;
  /* margin: 0 auto; */
  width: 100%;
}

.shBnOverlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0.5) 0,
    rgba(0, 0, 0, 0.0001) 100%
  );
  background-image: -o-linear-gradient(
    left,
    rgba(0, 0, 0, 0.5) 0,
    rgba(0, 0, 0, 0.0001) 100%
  );
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(0, 0, 0, 0.5)),
    to(rgba(0, 0, 0, 0.0001))
  );
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.5) 0,
    rgba(0, 0, 0, 0.0001) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1);
  background-repeat: repeat-x;
}

.shBnImg {
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
}
.shBnIfr {
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
}

.shBnText {
  position: absolute;
  left: 60px;
  bottom: 40px;
  color: #fff;
}
.shBnTimeline {
  top: auto;
  width: 50%;
  margin-left: 130px;
  color: #000;
  z-index: 1;
  margin-top: 80px;
}
/* .sectioning{
    margin-top: 300px;
} */
.Timeline-Page{
    max-width: 100%;
      overflow-x: hidden;   
  }
.shBnTimeline p {
  font-weight: 100 !important;
}
.shBnTimeline #subDomain_p a {
  font-weight: 600 !important;
  color: #444444;
}
.shBnTimeline p {
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  color: #6b6b6b;
  margin: 0 0 12px;
}

.shBnText p {
  font-size: 16px;
  margin-bottom: 10px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  padding-left: 0px;
  color: whitesmoke;
}

.shBnText h2 {
  font-size: 36px;
  margin-bottom: 10px;
}

.shBnText .shDateSec {
  position: relative;
}

.shBnText .shDateSec h3 {
  font-size: 16px;
  /* margin-right: 60px; */
  margin: 7.5px 4px 0 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}

.shSocialList li {
  display: inline-block;
  margin-right: 15px;
}

.shSocialList li.shLoc {
  margin-right: -30px;
}
.shSocialList li.shTag {
  margin-left: 60px;
}

.balign {
  margin-left: 60px;
}

.shSocialList li a {
  width: 24px;
}

.shSocialSec {
  margin-top: 40px;
}

.shSocialSec .shSocialList {
  float: left;
}

.shSocialSec .shLang {
  float: left;
  margin-left: 80px;
  font-family: "Open Sans", sans-serif;
}

.shSocialSec .shLang .dropdown-menu {
  border: 0;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.shSocialSec .shLang .dropdown-item {
  color: #444;
  font-size: 14px;
}

.shLang .btn-secondary {
  color: #fff !important;
  background-color: transparent !important;
  border-color: transparent !important;
  font-size: 12px;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}

.shLang .btn-secondary img {
  width: 12px;
  margin-top: -2px;
  display: inline-block;
}

.articlePlaceMain,
.trailPlaceMain {
  margin-top: 70px;
}

.mainPlaceholder .articlePageTheme {
  padding-top: 50px!important;
}

/*.articlePage {
    width: 717px;
}*/
.mainPlaceholder .articleMainTxt p{
  padding-bottom: 40px;
  line-height: 36px;
  color: #444;
  font-size: 18px!important;
  /* letter-spacing: 1px; */
  margin-bottom: 0px;
}
.museumPageTheme .museumMainTxt p {
  padding-bottom: 0px;
  line-height: 2 !important;
  color: #444 !important;
  font-size: 18px !important;
  letter-spacing: 0px;
  margin: 0 0 0px;
}
/* .museumMainTxt div{
    padding-bottom: 0px;
    line-height: 2;
    color: #444;
    font-size: 18px;
    letter-spacing: 0px;
    margin:0 0 0px;
} */

.articleMainTxt .paraItalic p {
  font-style: italic;
  padding-bottom: 0px;
}

.pageImg {
  margin-bottom: 40px;
  position: relative;
}

.pageImg .pageImgItem {
  display: inline-block;
}

.pageImg .pageImgItem p {
  font-size: 12px;
  padding-bottom: 0;
  font-family: "Open Sans", sans-serif;
  text-align: left;
  padding-left: 40px;
}

.moreMainSec {
  float: right;
  width: 88%;
}

.shArtLogo {
  background: #fff;
  margin: 20px 0px;
  width: 100%;
  text-align: center;
}
.shArtLogo.shArtLogoSec {
  width: 75%;
  /* height: 200px; */
  display: inline-block;
}
.shArtLogo.shArtLogoSec img {
}
.moreTopicSec {
  background: #f5f5f5;
  padding: 35px 25px;
  margin-bottom: 40px;
  color: #444444;
}

.moreTopicSec h2 {
  font-family: 'Open sans';
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 20px;
  color: #444444;
}

.moreList li {
  border-bottom: 1px solid #d6d6d6;
  margin-bottom: 20px;
}

.moreList li:last-child {
  border-bottom: 0;
  margin-bottom: 0;
}

.moreList li a {
  color: #444444;
}

.moreList li a h3 {
  font-size: 18px;
  word-break: break-word;
  color: #444444;
  line-height: 1.5;
}

.moreList li a:hover h3 {
  text-decoration: underline;
  color: #444444;
}
.notTabMain a {
  cursor: auto;
}
.moreList li a p {
  font-size: 12px;
  font-family: sans-serif;
  font-weight: 600;
  color: #444444;
  line-height: 1.5;
}
.Timeline-Page .moreList li a p {
  font-size: 12px;
  font-family: sans-serif;
  font-weight: 700;
  color: #444444;
  line-height: 1.5;
}

.moreExtra .moreExtraItem {
  margin-bottom: 40px;
  min-height: 200px;
  position: relative;
}

.moreExtra .moreExtraItem .moreExtraItemTxt {
  padding: 40px 35px 20px 25px;
  position: absolute;
  z-index: 1;
  top: 0;
}

.overLay {
  background: rgb(0 0 0 / 0.3);
  height: 100%;
  width: 100%;
  position: absolute;
}

.moreExtra .moreExtraItem .moreExtraItemTxt h5 {
  padding-right: 50px;
  margin-bottom: 30px;
  color: #fff;
  font-size: 24px;
  font-family: "Open Sans", sans-serif;
}

.btn-con {
  font-size: 10px;
  border-radius: 0;
  padding: 5px 20px;
  font-family: "Open Sans", sans-serif;
  background-color: #fff;
}

.suppotedSec {
  font-size: 12px;
  color: #444;
  opacity: 0.7;
  font-style: italic;
  margin-top: -1rem;
}

/* .tantraSec {
    margin: 40px 0;
} */
div.banner-details div.right-des {
  letter-spacing: normal;
  height:52px;
  overflow-y: auto;
}
.tantraSec a {
  background: #f5f5f5;
  color: #9f9f9f;
  font-size: 12px;
  padding: 2px 18px;
  margin-bottom: 4%;
  border-radius: 4px;
  margin-right: 8px;
  font-family: "Open Sans", sans-serif;
}

.tantraSec a:hover{
  
  color: #dd4d61;
 background-color: #f8e2e5;
}

.articleNotesTab .nav-tabs {
  border-bottom: 0;
}

.articleNotesTab .nav-tabs .nav-item {
  width: 33.33%;
  border-right: 1px solid #fff;
}

.articleNotesTab .nav-tabs .nav-item:last-child {
  border-color: #e8e8e8;
  border-top-right-radius: 0.25rem;
}

.articleNotesTab .nav-tabs .nav-link {
  color: #444;
  background: #e8e8e8;
  font-size: 16px;
  padding: 5px 0;
  text-align: center;
  font-family: "Open Sans", sans-serif;
}

.articleNotesTab .nav-tabs .nav-link.active,
.articleNotesTab .nav-tabs .nav-item.show .nav-link {
  color: #444;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.articleNotesTab .tab-content {
  padding: 60px;
  background-color: #f5f5f5;
  height: auto;
}

.notTabMain p {
  font-size: 14px;
}

.artNotesTabSocial {
  padding: 20px 0;
}

.artNotesTabSocial .shSocialList {
  float: left;
}

.artNotesTabSocial .someRight {
  float: right;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  margin-top: 5px;
}

.someRight {
  color: #444;
}

/* .someRight.someRightdis {
    pointer-events: none;
} */

.someRight img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  display: inline-block;
}

.resize {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  margin-top: 3px;
  margin-bottom: 5px;
  margin-left: 190px;
}
.resize img {
  width: 12px;
  height: 12px;
  margin-right: 10px;
  margin-top: 6px;
}
.resize1 {
  float: right;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 5px;
}
.resize1 img {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.readMore {
  padding: 40px 0;
  font-family: sans-serif;
  font-weight: 600;
  border-top: 1px solid rgb(214 214 214 / 0.8);
}

.readMore h6 {
  color: #444;
  font-family: 'open sans' sans-serif;
  font-size: 16px;
  font-weight: 700;
}

.stFtThumb {
  padding-bottom: 80px;
}

.stThumbItem {
  color: #444;
  margin-bottom: 40px;
}

#navigate-menu {
  display: none;
}

#navigate-menu.active {
  display: block;
  margin-top: 30px;
}

.stThumbItem .stThumbItemImg {
  height: 227px;
  background-size: cover !important;
}

.stThumbItem .stThumbItemImg.visual_01 {
  background: url(../assets/img/visual_01.png) 0 0 no-repeat;
}

.stThumbItem .stThumbItemImg.visual_02 {
  background: url(../assets/img/visual_02.png) 0 0 no-repeat;
}

.stThumbItem .stThumbItemImg.visual_03 {
  background: url(../assets/img/visual_03.png) 0 0 no-repeat;
}

.stThumbItem .stThumbItemImg.visual_04 {
  background: url(../assets/img/visual_04.png) 0 0 no-repeat;
}

.stThumbItem .stThumbItemImg.visual_05 {
  background: url(../assets/img/visual_05.png) 0 0 no-repeat;
}

.stThumbItem .stThumbItemImg.visual_06 {
  background: url(../assets/img/visual_06.png) 0 0 no-repeat;
}

.stThumbItem p {
  font-size: 14px;
  margin-bottom: 10px;
}

.stThumbItem .stThumbCat {
  color: #da3a50;
  font-size: 12px;
  margin-top: 16px;
  font-family: "Open Sans", sans-serif;
}
.stThumbDesc {
  margin-top: 12px;
  margin-bottom: 12px !important;
}

.stThumbItem h2 {
  font-size: 24px;
  height:64px;
  line-height: 36px;
  margin-bottom: 0px;
}

.stThumbItem .stThumbOw {
  font-family: sans-serif;
  font-weight: 600;
  float: left;
}

.stThumbItem .stThumbSculp {
  color: #9f9f9f;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  margin-top: 4px;
}

.btn-load {
  background-color: #fff;
  border: 1px solid #3a3a3a;
  font-size: 16px;
  font-weight: 700;
  padding: 13px 33px;
  color: #444;
  font-family: "Open Sans", sans-serif;
  border-radius: 0;
}

.stFooter {
  background-color: #f5f5f5;
  padding-top: 40px;
}

.stFootLogo img {
  width: 264px;
  margin-left: 60px;
  margin-bottom: 20px;
}

.stFootBtm {
  padding-bottom: 23px;
  text-align: center;
}

.stFootBtm a {
  font-size: 12px;
  color: #444;
  margin: 0 20px;
  font-family: "Open Sans", sans-serif;
}

.stFtTopItem {
  font-family: "Open Sans", sans-serif;
  margin-bottom: 34px;
}

.stFtTopItem h5 {
  font-size: 16px;
  color: #444;
  font-weight: 700;
  margin-bottom: 12px;
}

.stFtTopList li {
  display: block;
  margin-bottom: 8px;
}

.stFtTopList li a {
  font-size: 14px;
  color: #444;
}

.stFtSubs h4,
.stFtSubs p {
  margin-bottom: 30px;
  font-family: "Open Sans", sans-serif;
}

.stFtSubs h4 {
  font-size: 24px;
}

.stFtSubs p {
  font-size: 14px;
}

.stFtSubsInput .form-control {
  float: left;
  width: calc(100% - 68px);
  height: 48px;
  background-color: #ebebeb;
  border-color: #ebebeb;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
}

.stFtSubsInput .btn-subs {
  float: right;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #da3a50;
}

.playItem {
  position: absolute;
  width: 100%;
  bottom: 80px;
  z-index: 1;
}

.playItem a {
  width: 96px;
}

.playItem h6 {
  color: #fff;
  font-size: 36px;
  font-weight: 400;
  margin-top: 40px;
  padding: 0 170px;
  line-height: 47px;
}

.playItem .btn-con {
  margin-top: 40px;
}

.searchPlaceMain,
.libraryPlaceMain {
  margin-top: 70px;
}

.shSearhTypeView {
  padding: 70px 0 20px;
}

.shSearchInputSec h2 {
  font-size: 18px;
  margin-bottom: 20px;
}

.shSearchInputSec h2.myLib {
  font-size: 24px;
}

.shSearchInputSec p {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.shSearchInputItem {
  width: 444px;
  height: 40px;
  border: 1px solid #d6d6d6;
  border-radius: 8px;
  position: relative;
  margin: 0 auto;
}

.shSearchInputItem input {
  width: 100%;
  height: 100%;
  padding: 0 50px 0 20px;
  border: 0;
  border-radius: 8px;
  font-family: "Open Sans", sans-serif;
}

.shSearchInputItem .btn-search {
  width: 50px;
  height: 40px;
  position: absolute;
  right: 0;
  padding: 3px 12px 7px;
  opacity: 0.6;
  background-color: transparent;
}

.shSearchResFoundBlk {
  padding: 20px 0;
  border-top: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
  font-family: "Open Sans", sans-serif;
}

.shResultCount {
  float: left;
  font-size: 12px;
  color: #da3a50;
  margin-top: 12px;
}

.shSearhResFoundFilter {
  float: left;
  margin-left: 100px;
}

.shSearhResFoundFilterItem {
  margin-right: 80px;
  float: left;
}

.shSearhResFoundFilterItem label {
  float: left;
  font-size: 12px;
  margin-top: 12px;
}

.shSearhResFoundFilterItem .shSearhResFoundFilterItemSel {
  float: left;
  margin-left: 30px;
}

.shSearhResFoundFilterItem .shSearhResFoundFilterItemSel .dropdown-toggle {
  width: 274px;
  height: 42px;
  background: #f5f5f5;
  border: 0;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 12px;
  text-align: left;
  color: #444;
  position: relative;
}

.shSearhResFoundFilterItem .shSearhResFoundFilterItemSel .dropdown-toggle img {
  width: 21px;
  position: absolute;
  right: 20px;
  display: inline-block;
}

.shSearhResFoundFilterItem
  .shSearhResFoundFilterItemSel
  .dropdown-toggle::after {
  display: none;
}

.shSearhResFoundFilterItem .shSearhResFoundFilterItemSel .dropdown-menu {
  width: 100%;
  background-color: #f5f5f5;
  font-size: 14px;
  margin-top: -5px;
  border: 0;
  border-top: 1px solid rgb(112 112 112 / 0.5);
  border-radius: 0;
}

.shSearchResListItem {
  width: 814px;
  margin: 0 auto;
}

.shSearchResListSec {
  padding: 60px 0;
}

.shSearchResList li {
  padding-bottom: 30px;
  border-bottom: 1px solid #d6d6d6;
  margin-bottom: 30px;
}

.shSearchResList li:last-child {
  border-bottom: 0;
}

.shSearchResList li .stThumbItem {
  margin-bottom: 0;
}

.shSearchResList li .stThumbItem .stThumbItemImg {
  float: right;
  width: 260px;
}

.shSearchResList li .stThumbItem .stThumbItemDesc {
  float: left;
  width: 460px;
}

.stThumbItemCtrl {
  text-align: right;
  margin-top: -28px;
}

.stThumbItemCtrl a {
  width: 18px;
  height: 18px;
  margin-left: 10px;
}

.shSearchResListSec.shSearchLibSec .stThumbItem h2 {
  margin-top: 20px;
}

#author_entity {
  cursor: pointer;
  margin-right: 64px;
}

#author_entity:hover {
  color: #da3a50;
}

h3[id^="author_entity_"] {
    cursor: pointer;
    margin-right: 15px;
    font-size: 16px;
    float: left;
}

h3[id^="author_entity_"]:hover {
    color: #da3a50;
}

.shAuthBio {
  padding: 20px;
  border-radius: 4px;
  background-color: #fff;
  width: 500px;
  position: absolute;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 30px;
  display: none;
}

.shAuthBio.active {
  display: block;
}

.shToolCl {
  /* width: 24px; */
  /* height: 24px; */
  position: absolute;
  top: 5px;
  right: 5px;
  text-align: center;
  z-index: 1000;
}

.shToolCl img {
  width: 12.5px;
}

.shAuthBio .shAuthImg {
  width: 100px;
  float: left;
}
.social-icon {
  background-color: #fff;
  border-radius: 5%;
}

.shAuthBio .shAuthDesc {
  padding-left: 120px;
  color: #444;
  line-height: 24px;
  font-size: 13px;
  font-family: "Libre Baskerville", serif;
  font-weight: 400;
}
.shAuthDesc2 {
  width: 500px;
  background-color: #fff;
  height: auto;
  margin-top: -10px;
  padding: 3px 10px 10px 10px;
  color: #444;
  line-height: 24px;
  font-size: 13px;
  font-family: "Libre Baskerville", serif;
  font-weight: 400;
}
/* .popup-content{background-color: inherit !important;border:none;box-shadow:initial !important;} */
.artCarouselSec {
  position: relative;
  margin-left: -23%;
}

.artCarouselSec .owl-carousel .owl-item .artCarouselImg {
  height: 376px;
  background-size: cover !important;
}

.artCarouselSec .owl-carousel .owl-item .artCarouselImg.artCarouselImg_01 {
  background: url(../assets/img/devarani.png) 0 0 no-repeat;
}

.artCarouselSec .owl-carousel .owl-item .artCarouselImg.artCarouselImg_02 {
  background: url(../assets/img/ananda.png) 0 0 no-repeat;
}

.artCarouselSec .owl-carousel .owl-item .artCarouselImg.artCarouselImg_03 {
  background: url(../assets/img/myanmar.png) 0 0 no-repeat;
}

.artCarouselSec .owl-carousel .owl-item .artCarouselImg.artCarouselImg_04 {
  background: url(../assets/img/padag.png) 0 0 no-repeat;
}

.artCarouselSec .owl-theme .owl-dots {
  display: none;
}

.artCarouselSec .owl-theme .owl-nav {
  position: absolute;
  width: 100%;
  top: 50%;
  margin-top: -70px;
  z-index: 1;
  padding: 0 20px;
}

.artCarouselSec .owl-theme .owl-nav button {
  position: relative;
  width: 40px;
  height: 40px;
  background: rgb(255 255 255 / 0.7) !important;
  border-radius: 50%;
  margin: 0;
}

.artCarouselSec .owl-theme .owl-nav button:focus {
  outline: none;
}

.artCarouselSec .owl-theme .owl-nav button.owl-prev {
  float: left;
}

.artCarouselSec .owl-theme .owl-nav button.owl-next {
  float: right;
}

.artCarouselSec .owl-theme .owl-nav button span {
  font-size: 48px;
  color: #000;
  position: relative;
  top: -20px;
}

.shSubHeader {
  height: 30px;
  padding-right: 40px;
  border-bottom: 1px solid #e8e8e8;
  font-family: "Open Sans", sans-serif;
}

.shAllMaps {
  width: 248px;
  color: #da3a50;
  display: block;
  line-height: 30px;
  float: left;
  padding-left: 40px;
}

.shAllMaps i {
  width: 12px;
  height: 12px;
  display: block;
  background: url(../assets/img/ic_all_map_def@2x.png) 0 0 no-repeat;
  background-size: cover;
  float: left;
  margin: 9px 10px 0 0;
}

.shAllMaps:hover {
  color: #fff;
  background-color: #da3a50;
}

.shAllMaps:hover i {
  width: 12px;
  height: 12px;
  display: block;
  background: url(../assets/img/ic_all_map_hov@2x.png) 0 0 no-repeat;
  background-size: cover;
  float: left;
  margin: 9px 10px 0 0;
}

.shSubHdRt {
  padding-left: 248px;
}

.shSubNav a {
  font-size: 12px;
  color: #444;
  line-height: 30px;
  padding: 0 33px;
  float: left;
}

.shSubNav a:hover {
  color: #da3a50;
  background-color: rgb(218 58 80 / 0.2);
}

.shSubNav a.shSubLoc {
  font-size: 14px;
  color: #da3a50;
  /* cursor: text; */
  text-transform: uppercase;
}

.shSubNav a.shSubLoc:hover {
  color: #da3a50;
  background-color: transparent;
}

.shBreadcrumb .breadcrumb {
  font-size: 12px;
  padding: 0;
  margin: 0;
  background-color: transparent;
  text-transform: uppercase;
}

.shBreadcrumb .breadcrumb .breadcrumb-item a {
  color: #da3a50;
  line-height: 30px;
  font-size: 14px;
}

.shBreadcrumb .breadcrumb .breadcrumb-item.active {
  color: rgb(104 104 104 / 0.5);
}

.shBreadcrumb .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  color: rgb(104 104 104 / 0.7);
  line-height: 30px;
}

.themePlaceMain {
  font-family: "Open Sans", sans-serif;
  height: calc(100% - 86px);
  position: fixed;
  top: 86px;
  margin-top: 15px;
}

.shMapSec {
  width: 100vw;
  height: 100%;
  background-color: #efefef;
}

.shSidemenu {
  width: 248px;
  height: 100%;
  padding-bottom: 10px;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #f7f7f7;
  z-index: 2;
  box-shadow: 0 4px 4px rgb(0 0 0 / 10%);
}

.shSideMenuTrig {
  width: 20px;
  height: 60px;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  right: -20px;
  background-color: #f7f7f7;
  border-radius: 0 10px 10px 0;
  text-align: center;
  padding-top: 17px;
  box-shadow: 6px 0px 4px 2px rgb(0 0 0 / 10%);
}

.shSideMenuTrig img {
  width: 16px;
  margin-left: -8px;
  display: none;
}

.shSideMenuTrig img.active {
  margin: 5px 0 0 0;
  display: block;
}

.shSidemenuScrl {
  padding-top: 30px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.shSidemenuList li {
  display: block;
  margin-bottom: 5px;
}

.shSidemenuList li a {
  color: #505050;
  font-size: 14px;
  display: block;
  padding: 7px 0 7px 20px;
  position: relative;
  line-height: 0.65rem;
  margin-right: 10px;
}

.shSidemenuList li a i.inbuilt {
  width: 22px;
  height: 22px;
  background: #505050;
  /* margin-right: 10px; */
  display: inline-block;
  border-radius: 50%;
  position: relative;
}

.shSidemenuList li a i.inbuilt.extra {
  margin-right: 10px;
}
.sub-theme-item,
.inbuilt.sub-theme-item {
  display: none !important;
}

.sub-theme-item.show {
  display: block !important;
}

a.theme-item {
  padding: 20px 0 0 !important;
  position: relative !important;
  left: -5px;
}

.shSidemenuList li a i.inbuilt img {
  position: absolute;
}

.bx {
  font-size: 26px !important;
}

a.all-theme-select i {
  display: none;
}

a.all-theme-select i.show {
  display: block;
}

.shSidemenuList li a span {
  position: relative;
  top: -5px;
  line-height: 1rem;
}

.shSidemenuList li a div {
  position: relative;
  top: -5px;
  line-height: 1rem;
}

.shSidemenuList li.active a .ic_acc,
.shMapSel .dropdown.show .btn-secondary .ic_acc {
  background: url(../assets/img/ic_arrow_up.svg) 0 0 no-repeat;
}

.shSidemenuList li a .ic_acc,
.shMapSel .btn-secondary .ic_acc {
  width: 12px;
  height: 12px;
  display: inline-block;
  background: url(../assets/img/ic_arrow_down.svg) 0 0 no-repeat;
  position: relative;
  top: -2px;
  margin-left: 10px;
}

.shSidemenuList li.active .shSidemenuList {
  display: block;
}

.shSidemenuList li .shSidemenuList {
  margin-left: 15px;
  display: none;
}

.shSidemenu.shSidemenuCol {
  width: 48px;
}

.shSidemenuCol .shSidemenuList li a span,
.shSidemenuCol .shSidemenuList li a .ic_acc {
  display: none;
}

.shSidemenuCol .shSidemenuList li a {
  padding: 7px 0 7px 13px;
}

.shSidemenuCol .shSidemenuList li .shSidemenuList {
  margin-left: 0;
}

.shMapItem {
  width: 100%;
  padding-left: 248px;
  position: relative;
}

.shMapSel {
  position: fixed;
  left: 278px;
  margin-top: 35px;
}

.shMapSearchSec {
  right: 0;
  position: fixed;
  z-index: 3;
}

.shMapSearchTrig {
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 50%;
  padding: 8px;
  margin-right: 46px;
  margin-top: 30px;
  box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 10%);
}

.shMapSearchBox {
  background: #fff;
  width: 277px;
  border-radius: 6px 0 0 6px;
  margin-top: 14px;
  padding: 20px 43px 14px 23px;
  box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 10%);
  display: none;
}

.shMapSearchBox .shSearchInputItem {
  border: 0;
  border-bottom: 1px solid #d6d6d6;
  border-radius: 0;
  width: 100%;
  height: 26px;
}

.shMapSearchBox .shSearchInputItem input {
  padding: 0 40px 0 0;
}
.shMapSearchBox .shSearchInputItem input {
  margin: 0px;
}

.shMapSearchBox .shSearchInputItem .btn-search {
  width: 32px;
  height: 32px;
  right: 8px;
  top: -8px;
  padding: 3px;
  opacity: 1;
}

.shMapSearchRes {
  height: calc(100vh - 200px);
  overflow-y: auto;
  margin-right: -43px;
  padding-right: 43px;
}

.shMapSearchResList li {
  margin-top: 24px;
}

.shMapSearchResList li a h2 {
  font-size: 18px;
  color: #444;
  margin-bottom: 4px;
  font-family: "EB Garamond", serif;
}

.shMapSearchResList li a label {
  font-size: 12px;
  color: #da3a50;
  margin-bottom: 4px;
  display: block;
}

.shMapSearchResList li a p {
  font-size: 12px;
  color: #444;
  font-family: "Libre Baskerville", serif;
}

.shMapSel .shSidemenuScrl {
  padding-top: 0;
}

.shMapSel .shSidemenuList li a {
  padding: 3px 0 3px 20px;
}

.shMapSel .shSidemenuList li a i {
  width: 12px;
  height: 12px;
  background: #9f9f9f;
  margin-right: 10px;
}

.shMapSel .shSidemenuList li a span {
  position: relative;
  top: -1px;
}

.shMapSel .shSidemenuList li a span:hover {
  color: #da4250;
}
.shMapSel .shSidemenuList li a div {
  position: relative;
  top: -1px;
}

.shMapSel .shSidemenuList li a div:hover {
  color: #da4250;
}

.shMapSel .btn-secondary,
.shMapSel .show > .btn-secondary.dropdown-toggle {
  color: #444;
  background-color: #fff;
  border-color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  padding: 6px 20px;
  box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 10%);
  height: auto;
}

.shMapSel .dropdown-toggle::after {
  margin-left: 5px;
  display: none;
}

.shMapSel .dropdown-menu {
  position: absolute;
  transform: translate3d(0px, 32px, 0px);
  top: 0px;
  left: 0px;
  will-change: transform;
  width: 220px;
  height: auto;
  max-height:300px !important;
  overflow-y: auto;
  border: 0;
  box-shadow: 0px 6px 4px 2px rgb(0 0 0 / 10%);
  margin-top: -5px;
}

.shAllMenu {
  font-family: "Open Sans", sans-serif;
  width: 100%;
  height: calc(100vh - 70px);
  max-height: 86%;
  position: fixed;
  top: 70px;
  z-index: 2;
  background-color: #f7f7f7;
  padding-top: 60px;
  overflow: scroll;
}

.shAllMenu .stFtTopItem {
  margin-top: 60px;
}

.shAllMenu .stFtTopItem h5 {
  color: #da3a50;
  margin-bottom: 20px;
}

.shPoiSec {
  width: 284px;
  position: fixed;
  top: 20%;
  left: 20%;
  z-index: 2;
  background: #fff;
  border-radius: 4px;
}

.shPoiHead {
  padding: 0px;
  position: relative;
}

.shPoiHead h2 {
  font-family: "Libre Baskerville", serif;
  font-size: 18px;
  margin-bottom: 5px;
  padding: 5px 70px 0px 10px;
  color: #444444;
}

.shPoiBody {
  padding: 8px;
  font-family: "Open Sans", sans-serif;
}

.shPoiBody p {
  font-size: 14px;
  /* padding-right: 50px; */
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: 0;
  line-height: 1.25rem;
}

.shPoiBody p .pop-summary {
  padding: 0;
}

.shPoiBreadcrumb {
  margin: 2px 0;
  font-size: 12px;
  padding: 0 8px;
}

.shPoiBreadcrumb .shBreadcrumb .breadcrumb {
  text-transform: initial;
}

.shPoiBreadcrumb .shBreadcrumb .breadcrumb .breadcrumb-item a {
  line-height: initial;
}

.shPoiBreadcrumb .shBreadcrumb .breadcrumb .breadcrumb-item.active {
  color: #da3a50;
}

.shPoiBreadcrumb .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  color: #444;
  line-height: initial;
}

.shPoiFoot {
  padding: 0 10px 15px 8px;
}

.shPoiFoot button {
  float: left;
}

.shPoiFoot .shPoiFtRt {
  float: right;
  margin-top: 4px;
}

.shPoiFoot .shPoiFtRt a {
  width: 24px;
  margin-left: 7px;
}
a.btn-more,
.btn-more {
  color: #fff;
  background-color: #da3a50;
  border-color: #da3a50;
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
  padding: 4px 25px;
}

.shMultiPoiSec {
  padding-top: 10px;
  height: 170px;
  overflow-y: auto;
  padding-right: 10px;
}

.shMultiPoiSec .shSidemenuList li {
  position: relative;
}

.shSidemenuList li .shPoiFtRt {
  position: absolute;
  right: 0;
  top: 10px;
}

.shSidemenuList li .shPoiFtRt a {
  width: 24px;
  display: inline-block;
  padding: 0;
  margin-left: 5px;
}

.aboutPlaceMain {
  margin-top: 100px;
}

.aboutNav {
  text-align: center;
  font-family: "Open Sans", sans-serif;
  padding: 70px 0 60px;
}

.shSubNav.aboutNav a {
  font-size: 14px;
  font-weight: 600;
  float: none;
}

.shSubNav.aboutNav a:hover {
  background-color: transparent;
}

.shSubNav.aboutNav a.active {
  color: #da3a50;
}

.shSubHdRt.shSubHdRtAbt {
  padding-left: 110px;
}

.aboutMain {
  padding-top: 60px;
  color: #444;
  width: 720px;
  margin: 0 auto;
}

.aboutMain p {
  font-size: 18px;
  line-height: 36px;
  margin-bottom: 60px;
}

.aboutMain h2 {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 36px;
  text-align: center;
}

.WorkList li {
  list-style: disc;
  margin-bottom: 60px;
  padding-left: 35px;
  margin-left: 20px;
  font-size: 18px;
}

.allTeamSec {
  font-family: "Open Sans", sans-serif;
}

.allTeamItem {
  margin-bottom: 70px;
}

.allTeamItem a {
  display: block;
  font-size: 16px;
  color: #444;
}

.allTeamItem h4 {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 4px;
}

.teamMemPic {
  height: 300px;
  background-size: cover !important;
  margin-bottom: 16px;
}

.teamMemPic.team_01 {
  background: url(../assets/img/team_01.png) 0 0 no-repeat;
}

.teamMemPic.team_02 {
  background: url(../assets/img/team_02.png) 0 0 no-repeat;
}

.teamMemPic.team_03 {
  background: url(../assets/img/team_03.png) 0 0 no-repeat;
}

.teamMemPic.team_04 {
  background: url(../assets/img/team_04.png) 0 0 no-repeat;
}

.contactSec {
  width: 720px;
  margin: 30px auto 0;
}

.contactItemWithHead {
  color: #444;
  margin-bottom: 70px;
  text-align: center;
}

.contactItemWithHead h3 {
  font-size: 36px;
  margin-bottom: 20px;
}

.contactItemWithHead p {
  font-size: 18px;
}

.contactList {
  font-family: "Open Sans", sans-serif;
  margin-bottom: 80px;
}

.contactList li {
  font-size: 16px;
  margin-bottom: 36px;
}

.contactList li a {
  color: #da3a50;
}

.teamMemDetail {
  position: fixed;
  z-index: 1;
  top: 100px;
}

.teamMemDetail .allTeamItem {
  margin-bottom: 40px;
  font-family: "Open Sans", sans-serif;
}

.overlayTeam {
  width: 300px;
  height: 100vh;
  background: rgb(0 0 0 / 20%);
  position: absolute;
}

.teamMemDetailMain {
  width: calc(100% - 300px);
  background: #fff;
  margin-left: 300px;
  padding: 50px 100px 80px 90px;
  position: relative;
  height: calc(100vh - 100px);
  overflow: auto;
}

.closeBox {
  width: 24px;
  position: absolute;
  right: 100px;
}

.teamMemDesc {
  font-size: 18px;
  line-height: 36px;
}

.shMapSel .btn-secondary .ic_acc,
.shMapSel .dropdown.show .btn-secondary .ic_acc {
  top: 2px;
  margin-left: 5px;
}

.shMapSel.contract {
  left: 68px;
}

.introPlaceMain .artCarouselSec {
  margin-left: auto;
}

.introBtnLogo {
  position: fixed;
  bottom: 40px;
  width: 100%;
  z-index: 2;
  text-align: center;
}

.introBtnLogo a {
  width: 240px;
  display: inline-block;
}

/* svg {
    display: none;
}

svg.show {
    display: block;
} */

.introBlk {
  display: block;
  position: relative;
  text-align: center;
}

.introBlk .introBgImg {
  width: 33.33vw;
  height: 100vh;
  background-size: cover !important;
}

.introBlk .introBgImg.intro_01 {
  background: url(../assets/img/intro_01.png) 0 0 no-repeat;
}

.introBlk .introBgImg.intro_02 {
  background: url(../assets/img/intro_02.png) 0 0 no-repeat;
}

.introBlk .introBgImg.intro_03 {
  background: url(../assets/img/intro_03.png) 0 0 no-repeat;
}

.introBlk .introBgImg.intro_04 {
  background: url(../assets/img/intro_04.png) 0 0 no-repeat;
}

.introBlk .introBgImg.intro_05 {
  background: url(../assets/img/intro_05.png) 0 0 no-repeat;
}

.introBlk p {
  position: absolute;
  color: #fff;
  top: 50%;
  margin-top: -40px;
  left: 50%;
  margin-left: -60px;
  margin-bottom: 0;
  width: 150px;
  text-align: center;
  font-weight: 700;
  color: #fff;
  font-size: 24px;
  font-family: "Open Sans", sans-serif;
}

.introPlaceMain .artCarouselSec .owl-carousel .owl-item img {
  height: 100vh;
}

.introPlaceMain .artCarouselSec .owl-theme .owl-nav {
  position: fixed;
  margin-top: -40px;
}

.introPlaceMain .artCarouselSec .owl-theme .owl-nav button {
  position: fixed;
  background: rgb(0 0 0 / 70%) !important;
}

.introPlaceMain .artCarouselSec .owl-theme .owl-nav button span {
  color: #fff;
}

.introPlaceMain .artCarouselSec .owl-theme .owl-nav button.owl-prev {
  left: 20px;
}

.introPlaceMain .artCarouselSec .owl-theme .owl-nav button.owl-next {
  right: 20px;
}

.trailPageTheme {
  padding-top: 45px;
}

.trailPlaceMain .shBnText {
  position: relative;
  left: auto;
  bottom: auto;
  color: #444;
  z-index: 1;
}

.trailPlaceMain .shBnText h2 {
  font-size: 30px;
  line-height: 36px;
}

.trailPlaceMain .shBnText .shTrailDesc {
  font-size: 18px;
  line-height: 36px;
  font-style: italic;
  font-family: "Libre Baskerville", serif;
}

.trailPlaceMain .shBnText .shBnCat {
  color: #da3a50;
}

.shTrailBnMap {
  position: relative;
}

.shExporeBtn {
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  color: #da3a50;
}

.shExporeBtn img {
  margin-left: 5px;
}

/* .shTrailBnMap .shBnImg {
  height: 480px;
} */

.shTrailBnText {
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    10%,
    rgba(255, 255, 255, 1)
  );
  position: absolute;
  width: 50%;
  top: 0;
  right: 0;
  height: 100%;
  padding: 110px 135px 0 150px;
}

.shBnCtrl {
  margin-top: 10px;
  font-family: "Open Sans", sans-serif;
}

.shBnCtrl a {
  color: #444;
  font-size: 14px;
  margin-right: 20px;
}

.shBnCtrl a img {
  margin-right: 10px;
}

.shBnOverview {
  position: absolute;
  top: 4px;
  left: 40px;
  z-index: 1;
}

.shBnOverview a {
  color: #da3a50;
  padding: 0 16px;
  font-size: 12px;
  margin-right: 20px;
  font-family: "Open Sans", sans-serif;
  background: #fff;
  border-radius: 4px;
}

.landingMapSec {
  width: 100%;
  height: 360px;
  background-color: #efefef;
  margin-top: -40px;
  margin-bottom: 60px;
}

.shSearchSec {
  position: relative;
}
.shSearchInputItem input {
  margin: 0px;
}
.shSearchSec .shSearchInputItem {
  border: 0;
  border-bottom: 1px solid #d6d6d6;
  border-radius: 0;
  width: 310px;
  position: absolute;
  right: 91px;
  margin-top: 1px;
}

.shSearchSec .shSearchInputItem input {
  padding-left: 0;
}

.shSearchSec .shSearchInputItem .btn-search {
  opacity: 1;
}

.locMapPopup,
.loginPopup {
  width: 100%;
  height: 100vh;
  background: rgb(0 0 0 / 30%);
  position: fixed;
  top: 0;
  z-index: 2;
}

.locMapPopup.hidden {
  display: none;
}

.locMapItem {
  width: 860px;
  height: 480px;
  background: #efefef;
  margin: 8% auto;
  border: 2px solid #fff;
  border-radius: 6px;
  position: relative;
}

.locMapItem > a {
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 50%;
  position: absolute;
  top: 20px;
  right: 20px;
  background: #fff;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 10%);
}

.locMapItem .locMapMain {
  width: 100%;
  height: 100%;
}

.expMapSec {
  width: 100%;
  height: calc(100vh - 70px);
  background: #efefef;
  position: fixed;
  top: 70px;
  z-index: 2;
}

.shSocialList li .shShare {
  font-family: "Open Sans", sans-serif;
}

.shSocialList li .shShare .dropdown > a {
  padding: 0;
  background: transparent;
  border: 0;
}

.shSocialList li .shShare .dropdown.show > a:focus {
  box-shadow: none;
}

.shSocialList li .shShare a img {
  width: 24px;
}

.shSocialList li .shShare .dropdown-toggle::after {
  display: none;
}

.shSocialList li .shShare .dropdown .dropdown-menu {
  margin-left: 36px;
  margin-top: -68px;
  border: 0;
  box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 10%);
}

.shSocialList li .shShare .dropdown .dropdown-menu:after {
  display: inline-block;
  width: 0;
  height: 0;
  content: "";
  border-top: 10px solid transparent;
  border-right: 10px solid #fff;
  border-bottom: 10px solid transparent;
  border-left: 0;
  position: absolute;
  left: -10px;
  top: 50%;
  margin-top: -10px;
}

.shSocialList li .shShare .dropdown .dropdown-menu h5 {
  color: #686868;
  font-size: 12px;
  padding: 0.25rem 1.5rem;
  margin-bottom: 0;
}

.shSocialList li .shShare .dropdown .dropdown-menu a {
  width: auto;
  font-size: 12px;
}

.shSocialList li .shShare .dropdown .dropdown-menu a > img {
  width: 12px;
  margin-right: 10px;
  margin-top: -1px;
}

.shChapterSec {
  margin-top: 40px;
  margin-bottom: 35px;
}

.shChapterItem {
  width: 100%;
}

.shChapterItem li {
  width: 20%;
  padding-left: 15px;
  padding-right: 15px;
  float: left;
}

.shChapterItem li a {
  display: block;
}

.shChapterItem li a .progress {
  height: 1px;
  background-color: #9f9f9f;
  margin-bottom: 10px;
}

.shChapterItem li a .progress .progress-bar {
  background-color: #da3a50;
}

.shChapterItem li a h5,
.shChapterItem li a h6 {
  font-size: 12px;
  font-weight: 400;
  color: #444;
  margin-bottom: 5px;
}

.shChapterItem li a h5 {
  font-family: "Open Sans", sans-serif;
}

.loginPopup {
  top: 0;
  z-index: 3;
  background: rgb(0 0 0 / 40%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginPopupMain {
  width: 900px;
  font-family: "Open Sans", sans-serif;
  background: #fff;
  box-shadow: 8px 16px 32px rgb(0 0 0 / 10%);
}

.loginLt {
  float: left;
  width: 50%;
  padding-top: 70px;
  text-align: center;
}

.btn-donate {
  background-color: #da3a50;
  padding: 12px 110px;
  font-size: 16px;
  border-radius: 30px;
  color: #fff;
}

.loginRt {
  float: left;
  width: 50%;
  background-color: #e2e2e2;
  padding: 60px 100px 60px 70px;
  position: relative;
}

.loginRt .loginPopupClose {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 40px;
  right: 40px;
}

.loginRt h2 {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 16px;
  color: #2a2a2a;
}

.newUser {
  font-size: 16px;
  font-weight: 700;
  color: #2a2a2a;
}

.createAcc {
  color: #da3a50;
  font-weight: 400;
  margin-left: 10px;
}

.createAcc:hover {
  color: #da3a50;
}

.loginField {
  margin-top: 30px;
}

.loginField .form-control {
  font-size: 16px;
  padding: 13px 16px;
  border: 0;
  border-radius: 0;
  color: #444;
}

.loginField .btn-donate {
  color: #da3a50;
  background-color: #fff;
}

.viaSocialLabel {
  position: relative;
  margin: 30px 0;
  width: 100%;
}

.viaSocialLabel:after {
  content: "";
  width: 100%;
  height: 2px;
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 10px;
}

.viaSocialSec label {
  font-size: 13px;
  color: #2a2a2a;
  background-color: #e2e2e2;
  position: relative;
  z-index: 1;
  margin-bottom: 0;
  padding: 0 10px;
}

.viaSocialLinkGrp {
  display: flex;
  justify-content: space-around;
  padding: 0 40px;
}

.viaSocialLinkGrp a {
  width: 48px;
  height: 48px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.viaSocialLinkGrp a img {
  width: 24px;
}

.loginCheckBox .shCheckCon {
  color: #686868;
  font-weight: 600;
  font-size: 16px;
}

.loginCheckBox .shCheckCon input:checked ~ .checkmark,
.loginCheckBox .shCheckCon:hover input ~ .checkmark {
  background-color: #fff;
}

.loginCheckBox .checkmark {
  height: 24px;
  width: 24px;
  background-color: #fff;
}

.loginCheckBox .shCheckCon .checkmark:after {
  border-color: #686868;
}

.loginCheckBox .shCheckCon .checkmark:after {
  top: 6px;
  border-width: 0 2px 2px 0;
}

.shTrailBnSec2 {
  position: relative;
}

.shTrailBnSec2 .pageImg .pageImgItem p {
  padding-left: 0;
  margin-bottom: 0;
}

.shTrailBnSec2 .shTrailBnText {
  padding: 20px 90px 0;
  overflow-y: auto;
}

.trailPlaceMain .shTrailBnSec2 .shBnText .shTrailDesc {
  font-size: 14px;
  line-height: 24px;
  font-style: normal;
}

.shTrailBnSec2 .pageImg {
  margin-bottom: 20px;
}

.shTrailBnSec2 .pageImg .pageImgItem img {
  height: 280px;
}

/* .locMapPopup  */
.leaflet-div-icon {
  background: transparent !important;
  border: 1px solid transparent !important;
}

.mapMarker {
  position: absolute;
  width: 26px;
  height: 26px;
  border: 1px solid #fff;
  border-radius: 4px;
}

/*.mapMarker:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 8px solid #000;
    left: 8px;
    bottom: -8px;
    z-index: 1;
}*/
.mapMarker:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 10px solid #fff;
  left: 6px;
  bottom: -10px;
}

.map-container {
  height: 350px;
  margin-bottom: 75px;
  width: 100%;
}

.inner-map-container {
  height: 400px;
  position: relative;
}

.leaflet-top.leaflet-right {
  display: none;
}

.category-filter {
  position: relative;
  top: -10px;
  z-index: 2;
}

#category {
  height: 30px;
  width: 100px;
}

#map {
  width: 100%;
  height: 100%;
}

.landing-map-wrapper {
  margin: 0;
  padding: 0;
  width: 100%;
  height: calc(100% - 15px);
}

/* .inner-map-container #map {
    top: -75px;
} */
.inner-map-container .shMapSel {
  position: absolute;
  left: 10px;
  font-family: "Open Sans", sans-serif;
}
#ham-menu {
  display: none;
}

#ham-menu.active {
  display: block;
}

.fullBoxMain > div {
  z-index: 99;
  position: relative;
}
.shBnCat {
  font-size: 16px !important;
  margin-bottom: 12px;
  font-family: "Open Sans", sans-serif;
  color: #fff;
}
.shBnCat:hover {
  text-decoration: underline;
  color: #fff;
}

.react-fullscreen-slideshow-modal-content .react-fullscreen-slideshow-slides img {
  width: auto !important;
  height: 100% !important
}
.shAllMenu .stFtTopItem h5 img {
  float: right;
  margin-top: 2px;
  width: 12px;
  display: none;
}
.shMenuAccountList {
  padding: 20px 0;
  border-top: 5px solid #f5dcdd;
  display: none;
}
.shMenuAccountList li {
  display: block;
}
.shMenuAccountList li a {
  display: block;
  font-size: 12px;
  color: #444;
  padding: 5px 30px;
}
.shMenuAccountList li.shMenuSignOut {
  margin-top: 10px;
}
.shMenuAccountList li.shMenuSignOut a {
  color: #da3a50;
  font-size: 12px;
}
.shGalSec {
  font-family: "Open Sans", sans-serif;
  margin-top: 40px;
}
.shTotalImg {
  color: #fff;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}
.shViewGal {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  margin-left: 50px;
}
.shTotalImg img {
  margin-right: 5px;
}
.shTotalImg img,
.shViewGal img {
  width: 24px;
  display: inline-block;
}
.shTotalImg:hover,
.shViewGal:hover {
  color: #fff;
}
/* .react-fullscreen-slideshow-view-all-thumbnails {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap; */
/* } */

/* .react-fullscreen-slideshow-modal-wrapper {
    z-index: 3;
} */

.createNewTrig {
  margin-bottom: 60px;
  font-size: 16px;
  font-weight: 700;
  color: #444;
  font-family: "Open Sans", sans-serif;
}
.createNewTrig i {
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
  margin-top: -4px;
  background: url("../assets/img/ic_create.svg") 0 0 no-repeat;
}
.createNewTrig:hover {
  color: #da3a50;
}
.createNewTrig:hover i {
  background-position: -24px 0;
}

#expand-bar .shSideMenuTrig a {
  display: none;
}

/* #react-fullscreen-slideshow-caption > p {
    color: #fff;
    font-weight: 400;
    padding-bottom: 0;
    margin-bottom: 60px;
} */

.shBnCat:hover,
#author_entity h3.float-left:hover {
  color: #da3a50;
}

.leaflet-container a.leaflet-popup-close-button {
  z-index: 99 !important;
}

.leaflet-popup-content {
  /* width: 100% !important; */
  width: auto !important;
  margin: 0;
}

.leaflet-popup-content-wrapper {
  border-radius: 2.5px;
}

.content-wrapper {
  width: 100%;
}

a i.inbuilt.filter-item.selected {
  background: #da3a50;
}

#attr > a {
  float: right;
}

/* #expand-bar .shSideMenuTrig a img.active {
    display: block;
} */

.leaflet-popup.leaflet-zoom-animated {
  width: 280px;
}

.shVidBanner .shBnText {
  left: 0;
  /* bottom: -350px; */
  color: #444;
  max-width: 66.666667%;
  padding-right: 15px;
  padding-left: 0px;
  position: inherit;
  margin-top: 4%;
}
.videoPageTheme .moreMainSec {
  margin-top: -35%;
}
/* .videoPageTheme .articlePage {
    margin-top: 300px;
} */
.video-content .articlePageTheme {
  padding-top: 0px !important;
}

#player {
  padding-top: 25px;
}

#player .youtube-chapter-wrapper .youtube-wrapper {
  -webkit-box-flex: 3;
  -ms-flex: 3 1 100%;
  flex: 3 1 100%;
}

#player .youtube-video {
  width: 100%;
  height: 584px;
}

#player .youtube-chapter-wrapper .youtube-wrapper iframe {
  width: 100%;
  height: 100%;
}

#player .youtube-chapter-wrapper .chapters-wrapper {
  border: 0;
  width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  overflow-x: overlay;
  margin-top: 20px;
  margin-left: 25px;
}

#player .youtube-chapter-wrapper .chapters-wrapper .chapter-point-wrapper {
  border-top: 1px solid rgb(159 159 159/50%);
  cursor: pointer;
  float: left;
  width: 18%;
  margin-right: 22px;
  opacity: 0.7;
  height: 100px;
}

#player
  .youtube-chapter-wrapper
  .chapters-wrapper
  .chapter-point-wrapper
  .chapter-point {
  padding: 10px 0;
}

#player
  .youtube-chapter-wrapper
  .chapters-wrapper
  .chapter-point-wrapper:nth-child(2n) {
  background-color: transparent;
}

#player
  .youtube-chapter-wrapper
  .chapters-wrapper
  .chapter-point-wrapper:last-child {
  border-bottom: 0;
}

#player
  .youtube-chapter-wrapper
  .chapters-wrapper
  .chapter-point-wrapper
  .chapter-point
  h5 {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
}

#player
  .youtube-chapter-wrapper
  .chapters-wrapper
  .chapter-point-wrapper
  .chapter-point
  h6 {
  font-size: 14px;
}

#player
  .youtube-chapter-wrapper
  .chapters-wrapper
  .chapter-point-wrapper:hover {
  background-color: transparent;
}

#player
  .youtube-chapter-wrapper
  .chapters-wrapper
  .chapter-point-wrapper.current,
#player
  .youtube-chapter-wrapper
  .chapters-wrapper
  .chapter-point-wrapper.current:hover {
  background-color: transparent;
  border-top: 1px solid #da3a50;
  opacity: 1;
}

#playerMobile .youtube-chapter-wrapper .youtube-wrapper {
  -webkit-box-flex: 3;
  -ms-flex: 3 1 100%;
  flex: 3 1 100%;
}

#playerMobile .youtube-video {
  width: 100%;
  height: 584px;
}

#playerMobile .youtube-chapter-wrapper .youtube-wrapper iframe {
  width: 100%;
  height: 100%;
}

#playerMobile .youtube-chapter-wrapper .chapters-wrapper {
  border: 0;
  width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  overflow-x: overlay;
  margin-top: 20px;
}

#playerMobile
  .youtube-chapter-wrapper
  .chapters-wrapper
  .chapter-point-wrapper {
  border-top: 1px solid rgb(159 159 159/50%);
  cursor: pointer;
  float: left;
  width: 18%;
  margin-right: 22px;
  opacity: 0.7;
  height: 100px;
}

#playerMobile
  .youtube-chapter-wrapper
  .chapters-wrapper
  .chapter-point-wrapper
  .chapter-point {
  padding: 10px 0;
}

#playerMobile
  .youtube-chapter-wrapper
  .chapters-wrapper
  .chapter-point-wrapper:nth-child(2n) {
  background-color: transparent;
}

#playerMobile
  .youtube-chapter-wrapper
  .chapters-wrapper
  .chapter-point-wrapper:last-child {
  border-bottom: 0;
}

#playerMobile
  .youtube-chapter-wrapper
  .chapters-wrapper
  .chapter-point-wrapper
  .chapter-point
  h5 {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
}

#playerMobile
  .youtube-chapter-wrapper
  .chapters-wrapper
  .chapter-point-wrapper
  .chapter-point
  h6 {
  font-size: 14px;
}

#playerMobile
  .youtube-chapter-wrapper
  .chapters-wrapper
  .chapter-point-wrapper:hover {
  background-color: transparent;
}

#playerMobile
  .youtube-chapter-wrapper
  .chapters-wrapper
  .chapter-point-wrapper.current,
#player
  .youtube-chapter-wrapper
  .chapters-wrapper
  .chapter-point-wrapper.current:hover {
  background-color: transparent;
  border-top: 1px solid #da3a50;
  opacity: 1;
}

.text-wrapper {
  display: none;
}

.react-fullscreen-slideshow-tile-button {
  height: 30px;
  width: 30px;
}
.react-fullscreen-slideshow-view-all-thumbnails img {
  width: auto;
  display: inline-block;
  max-height: 20vh;
}


.react-fullscreen-slideshow-modal-wrapper {
  z-index: 2;
}

.sLogo1{display:none}

#embeddedMedia {
  display: none;
}

.react-fullscreen-slideshow-slides {
  flex: 0 0 60% !important;
}

.layer-item-wrap {
  display: none;
}

/* .museumMainTxt div {
    padding-bottom: 0!important;
    line-height: 2!important;
    letter-spacing: 0!important;
    margin: 0!important;
} */

.react-fullscreen-slideshow-close {
  display: block !important;
  right: 25px;
  top: 90px;
  left: auto !important;
  z-index: 1;
}

.react-fullscreen-slideshow-close-text {
  display: none !important;
}

.react-fullscreen-slideshow-tile-row {
  width: 20px !important;
}

.react-fullscreen-slideshow-close-view-all {
  top: 40px !important;
}

.react-fullscreen-slideshow-bottom-text {
  margin: 4px 0 0 10px;
  font-size: 14px;
  font-family: sans-serif;
}

.flickity-prev-next-button {
  margin-top: -20px;
}

/* .flickity-prev-next-button.previous {
    left: 70px !important;
} */

/* .flickity-prev-next-button.next {
    right: 70px !important;
} */

#react-fullscreen-slideshow-caption {
  position: absolute;
  margin: 60px 50px 0 10px;
  max-height: 170px;
  overflow-y: auto;
  bottom: 70px;
}

#react-fullscreen-slideshow-caption > p {
  color: #fff;
  font-weight: 400;
  padding-bottom: 0;
  margin-bottom: 0;
  margin-top: 105px;
  font-size: 20px;
  text-align: left;
}
.react-fullscreen-slideshow-numbertext {
  font-family: "Open Sans", sans-serif;
}

.popup-content {
  position: absolute;
  z-index: 999;
  pointer-events: auto;
  top: 520.6px;
  left: 321.6px;
}

.tooltip-outer {
  height: 8px;
  width: 16px;
  position: absolute;
  background: transparent;
  color: rgb(255, 255, 255);
  z-index: -1;
  transform: rotate(-90deg) translateY(-150%) translateX(25%);
  top: 50%;
  left: 0%;
}

.react-fullscreen-slideshow-modal-top {
  border: none;
}
.highlightEmail {
  color: #da3a50;
}

.notTabMain {
  font-size: 14px;
  word-break: break-word;
}
.articleContainer .mobileSocialView {
  font-size: 26px;
  margin-top: -16px;
  margin-right: 6px;
  margin-bottom: 2px;
}
.socialNew3 .mobileSocialView {
filter:invert(25%)!important;
font-size:26px;
}
#playerMobile{display:none;}
.shVidBannerText .shSocialList li{
filter:invert(75%);
}
.carousel{height:460px;margin: 0 auto 40px;}
.Museum-Page #author_entity h3{font-size:16px;float:left;}
.Museum-Page .flickity-button
.museumLanguage{font-size: 13.33px;font-weight: 700;font-family: Arial;}
.dropdownLang{min-width:8rem;cursor: pointer;}
.Museum-Page .timeline-summary p{padding-bottom: 0px !important;}
.shBnTimeline .timeline-summary p{color:#6b6b6b !important;font-family:'Libre Baskerville'; font-weight:100;margin-bottom:12px; padding-bottom:0px;}

.inActive{display:none;}
#author_entity div {
background-color: inherit !important;
}
.shDateSec .popup-content{
width:50% !important;
}
.projSection{text-align: center;}
.Museum-Page .flickity-button{background: hsla(0,0%,100%,.30) !important;}
/* .Museum-Page .flickity-button:hover{background:#fff !important;} */
/* .Museum-Page .flickity-button:hover{background:#fff !important;} */

.Museum-Page .flickity-prev-next-button{
width: 36px;
height: 36px;}
.newTrail{font-family: sans-serif;}
.Timeline-Page .mobileSocialView{
font-size:26px;
}
.Timeline-Page .shAuthBio{
top:75%;
}
.socialNew2 .mobileSocialView{
font-size:27.5px;
margin-bottom: 8px;
}
.shBnTimeline p b{
font-weight: 600;
font-family: sans-serif;
font-size:14px;
color:#444444;
}
.signMobile{
display:none;
}
.museumReadmore{
padding-left: 15px;
padding-right: 15px;
margin-left: 62.5;
margin-right: 62.5;
}

.wrapper-pop{height:180px;overflow-y: auto;}
.article-book{
  filter: none !important;
}
.article-book #donate{
  margin-top: -32px !important;
}
.article-book .signinlogo{
  margin-top: 114px !important;
}
@media screen and (max-width: 360px) {

   .sahaLogo, .slogo{
    margin-right:0 !important;
  }
  
    .socialMuseum{
        padding:1% 20%;
    }

    .artNotesTabSocial .someRight{
        float:left !important;
        padding:1% 8%;
    }
    /* .sahaLogo {
        width: 60%;
        margin:1% 8% !important;
    } */
    /* .shHeader.smHeader .topLinks{
        display:none;
    } */
      .sahaLogo {
        width: 60%;
        margin: 1% 9% !important;
        
      }
      
      .shHeader.smHeader .topLinks {
        display: none;
      }
}
@media screen and (max-width: 280px) {
   
    .shHeader.smHeader .topLinks{
        display:none;
    }
    .shHeader .shSearchSec{
      display:block;
  }
    .shMenu{
      margin-left: -20% !important;
      margin-top: 40% !important;
    }
    .sahaLogo { 
      margin-right:10% !important;
      margin-top: -10% !important;
    }
    .sLogo{
      margin-top:-25% !important;
     float:right ;
     margin-right: -140% !important;
    }
  .shSearch{
    margin-left: 50px!important;
    margin-top: -8px !important;
    display:none;
  }
  .shHeader2 .smHeader .topLinks{
    display:none;
}
.shHeader2 .shSearchSec{
  display:none;
}
.shHeader2 .shMenu{
  margin-left: -20% !important;
  margin-top: 28% !important;
}
.shHeader2 .sahaLogo { 
  margin-right:10% !important;
  margin-top: -2% !important;
}
.shHeader2 .sLogo{
  margin-top:-36% !important;
 float:right ;
 margin-right: -54% !important;
}
.shHeader2 .shSearch{
margin-left: 50px!important;
margin-top: -8px !important;
}
  }

@media screen and (max-width: 1150px) {
.shHeader.smHeader .topLinks {
display: none;
}
.topLinks li {
margin: 0 0 0 0px;
}
#title_heading_entity {
font-size: 30px !important;
margin-bottom: 10px;
/* color: #444; */
}
}
@media screen and (max-width: 960px) {
.shHeader.smHeader .topLinks {
display: none;
}
/* .react-fullscreen-slideshow-caption-container {
position: fixed;
} */
#react-fullscreen-slideshow-caption {
/* padding-top: 20px; */
max-width: 100%;
}
}
@media screen and (max-width: 768px) {
  .shSearchSec .shProfile{display:block !important}
  .roundLogo{display:none}
  .sLogo{display:none}
  .sLogo1{display:block}
  .sLogo1 .slogo{display:block}
  .Navigate-mobile{
    color: #444;
    margin-bottom: 0;
    padding: 2% 20px;
    font-size: 12px;
    border-bottom: 1px solid rgb(218 58 80/15%);
  }
 .react-fullscreen-slideshow-modal-bottom .react-fullscreen-slideshow-bottom-left-pane{padding:5px 5px 5px 20px !important}

.react-fullscreen-slideshow-modal-content .react-fullscreen-slideshow-slides img{width: auto !important}
.flickity-button-icon{fill:white;}
.flickity-button{background-color: #bcbcbc !important;}
.react-fullscreen-slideshow-modal-bottom{position:inherit !important;}
.react-fullscreen-slideshow-gallery-icon-bar .share-button{width:53px !important;padding: 5px 6px !important;}
.react-fullscreen-slideshow-caption-container #react-fullscreen-slideshow-caption{bottom:auto !important; max-height: 170px !important}
.react-fullscreen-slideshow-modal-content .react-fullscreen-slideshow-caption-container{bottom:auto !important}
#react-fullscreen-slideshow-caption > p{font-size: 16px;}
.flickity-enabled{background-color: #f5f5f5;width:119% !important;margin:0px 0px 0px -30px !important;}
.banner-details{padding: 3px 10px !important;}
.react-fullscreen-slideshow-slides{height:55%}
.mobileBann{
display: none;
}
.smHeader .sahaLogo, .smHeader .slogo{
display: none;
}
.articlePageTheme.museumPageTheme {
padding-top: 0px !important;
margin-top: 0px !important;
}

.leaflet-popup.leaflet-zoom-animated{
width:210px;
}
.mobile-card-at{padding:0px}
.shHeader.smHeader .topLinks{
display:block;
}
.projSection{text-align: left !important;}

.Museum-Page #author_entity h3{font-size:14px;}

.shAuthDesc2{width:230px !important}
.shAuthDesc{height:200px;overflow-y: scroll;padding-left:20px !important}
.articleContainer .shAuthBio{width:320px !important;}
.Museum-Page .shAuthBio{width:100% !important;}
.dropdown-menu.show{
top: 30px !important;
}
.shLang .btn-secondary img{
filter: invert(30%);
width:10px !important;
margin-top:0px !important;
}
.shArtLogo img{
background: #fff;
margin: 20px 0px;
height: 267px !important;
width: 100%;
text-align: center;
}
.sectioning{
margin-top: 300px;
} 
.Timeline-Page .mobileSocialView{
font-size:20px;
margin-top: 1%;
}
.shArtLogo.shArtLogoSec img{
width:100%!important;
height: 200px !important;
display: inline-block;
margin:0% 15%;
}
/* .shArtLogo.shArtLogoSec img {
width:auto;
height: 200px;
display: inline-block;
} */
.carousel {
height: 275px !important;
margin: 0 auto 0px;
}

.videoPageTheme .moreMainSec {
margin-top: 0% !important;
}
.shVidBannerText .shSocialList li {
filter: invert(25%) !important;
}
.articleMainTxt .paraItalic p {
padding-right: 0px !important;
}
#playerMobile {
display: block !important;
}
#player {
display: none;
}

.articleContainer .mobileSocialView {
font-size: 20px;
margin-top: -9px!important;
}
.socialNew3 .mobileSocialView {
/* filter:invert(25%)!important; */
font-size: 20px;
}

.articleContainer {
margin-top: 20%;
padding: 0px;
}

.shSocialList img {
 filter: invert(75%);
}

.shSocialList li img {
width: 18px;
}
.highlightPhone {
color: #da3a50;
}
/* .shArtLogo{
height:100%;
margin-top:7%;
padding:0px 32px;
} */
.moreMainSec {
width: 100% !important;
}

    .shHeader {
        z-index: 3;
    }

    .shTopHeader {
        padding: 8px 20px;
        height: 56px;
    }

    .shMenu {
        width: 24px;
        float: left;
        margin-top: 8px;
    }

    .sahaLogo {
    
      margin-left: 40px;
        width: 40px; 
        
        
   }
   .sLogo{
      
       margin-top: -50;
       width: 130px;
   }


    .shSearch {
        margin-right: 10%;
        margin-top: 8px;
    }

    .shProfile {
        display: block !important;
    }

    .logoSecp {
        width: calc(50% - 40px);
        
    }

    .shBanner {
        margin-top: 56px;
    }

    .shBnText {
        position: relative;
        left: 0;
        bottom: 0;
        color: #444;
        padding: 0px 15px;
    }
    .shBnTimeline{
        position: relative;
        color: #fff;
        z-index: 1;
        margin-left: -80px;
        width:85% !important;
    }
    .shBnTimeline p {
        font-size: 15px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 100;
        color: #444;
    }
    .shBnTimeline h2 {
        font-size: 20px;
        margin-bottom: 10px;
        color: #444;
    }
    
    .shBnText p,
    .shBnText .shDateSec h3 {
        font-size: 12px;
    }

    .shBnText h2 {
        font-size: 20px;
    }

    .shLang .btn-secondary {
        color: #444 !important;
    }

    .shSocialSec .shLang {
        margin-left: 0;
        margin-top: 10px;
    }

    .shSocialSec {
        margin-top: 4px;
    }

    .shBnText .shDateSec p {
        float: right !important;
    }

    .shSocialSec .shSocialList {
        width: 100%;
    }

    .shSocialList li.shTag {
        float: right;
        margin-right: 5px;
    }

    .shBnCat {
        color: #DA3A50;
    }

    .shBnCat > a {
        color: #fff;
    }

    .shBnOverlay {
        display: none;
    }

    .shBnImg, .shBnImg > img.banner-image {
        height: 250px;
    }
    .shBnImg > iframe.banner-image {
        display: block;
        height: auto;
        border:none;
        width: 100%;
    }
    .shBnIfr, .shBnIfr > img.banner-image {
      height: 100% !important;
  }
  .shBnIfr > iframe.banner-image {
      display: block;
   
      border:none;
      width: 100%;
  }
  .balign {
    margin-left: 0px;
  }

    .articlePage,
    .moreMainSec {
        width: auto;
    }

    .articleMainTxt p.paraItalic {
        padding-right: 0;
        margin-bottom: 20px;
        padding-bottom: 0;
        font-size: 15px;
    }

    .articleMainTxt p {
        padding-bottom: 30px!important;
        line-height: 24px;
        font-size: 18px!important;
        margin-bottom: 0;
    }

    .pageImg {
        margin-bottom: 24px;
    }

    .artCarouselSec {
        margin-left: 0;
    }

    .playItem {
        bottom: 40px;
    }

    .playItem a {
        width: 48px;
    }

    .playItem h6 {
        font-size: 16px;
        padding: 0 60px;
        margin-top: 10px;
        line-height: 24px;
    }

    .playItem .btn-con {
        margin-top: 0;
        background-color: #fff;
    }

    .articleNotesTab .nav-tabs .nav-link {
        font-size: 12px;
    }

    .articleNotesTab .tab-content {
        padding: 20px;
        height: auto;
        font-size:14px;
    }

    .artNotesTabSocial .someRight {
        font-size: 12px;
    }
    .artNotesTabSocial .someRight span{
        margin:3px 0px;
    }

    .articlePlaceMain {
        margin-top: 56px;
    }

    .articlePageTheme {
        padding: 20px 15px 0px 15px;
    }

    .articleMainTxt .pageImg p {
        padding-bottom: 0;
        padding-left: 0;
        line-height: 16px;
        margin-top: 10px;
    }

    .tantraSec {
        margin: 0px 0 30px 0;
        text-decoration: none;
    }

    .notTabMain p {
        font-size: 12px;
        word-break: break-word;
    }
    .notTabMain a{
        font-size: 12px;
        word-break: break-word;
    }

    .artNotesTabSocial {
        padding: 20px 0 20px 0;
    }

    .moreTopicSec {
        margin-bottom: 0px;
    }

    .moreExtra {
        border-top: 1px solid #D6D6D6;
        padding-top: 48px;
        text-align: center;
    }

    .moreExtra .moreExtraItem {
        width: 260px;
        display: inline-block;
    }

    .moreExtra .moreExtraItem .moreExtraItemTxt {
        text-align: left;
    }

    .readMore {
        padding: 40px 0 23px 0;
    }

    .readMore a {
        font-weight: 700;
    }

    .stThumbItem h2 {
        font-size: 16px;
        margin-bottom: 0;
    }

    .stThumbItem .stThumbSculp {
        font-size: 12px;
    }

    .stThumbItem .stThumbOw {
        margin-top: 12px;
        font-size: 14px;
    }

    .stThumbItem p {
        margin-bottom: 5px;
    }

    .btn-load {
        font-size: 12px;
    }

    .stFootBtm {
        padding-bottom: 40px;
        /* padding-left: 50px; */
        position: relative;
        text-align: left;
        margin-top: 30px;
    }

    .stFootBtm a {
        font-size: 14px;
        margin: 0 0 10px 0;
        width: 48%;
    }

    .stFootBtm a:first-child {
        /* position: absolute;
        padding:1% 16%; */
        left: 0;
        font-size: 12px;
        /* width: 100%; */
        text-align: center;
        margin-top: 2%;
    }

    .stFtTopList {
        display: block;
        background-color: rgb(218 58 80 / 15%);
        padding: 10px
    }

    .stFtTopItem {
        text-align: center;
        margin-bottom: 0;
    }

    .stFtTopItem h5 {
        font-size: 14px;
        margin-bottom: 0;
        padding: 10px;
        border-bottom: 1px solid rgb(218 58 80 / 15%);
    }

    .stFtTopList li {
        margin-bottom: 10px;
    }

    .stFtTopList li a {
        font-size: 12px;
    }

    .stFooter {
        padding-top: 0;
    }

    .stFootLogo {
        display: none;
    }

    .stFtThumb .col-md-4,
    .stFootTop .col-md-4,
    .stFootTop .col-md-8,
    .stFootTop .col-md-6,
    .shSearchResFound .col-md-12,
    .shSearchLibSec .col-md-4 {
        padding-right: 0;
        padding-left: 0;
    }

    .stThumbItemDesc {
        padding: 0 40px;
    }

    .stThumbItemCtrl {
        margin-top: -26px;
    }

    .artCarouselSec .owl-carousel .owl-item .artCarouselImg {
        height: 260px;
        width: 100vh;
    }

    .artCarouselSec .owl-theme .owl-nav {
        top: 60%;
    }

    .stThumbItem .stThumbCat {
        margin-bottom: 0;
    }

    .landingMapSec {
        margin-top: 0;
        margin-bottom: 40px;
    }

    .shAllMaps {
        width: auto;
        padding-left: 20px;
        font-size: 12px;
    }

    .shSubHdRt {
        padding-left: 90px;
    }

    .shSubNav a.shSubLoc {
        font-size: 12px;
    }

    .shSubNav a {
        padding: 0 10px;
    }

    .shBreadcrumb .breadcrumb .breadcrumb-item.active {
        display: none;
    }

    .shSubHeader {
        padding-right: 20px;
    }

    .shMapSearchBox {
        width: 210px;
    }

    .shMapSel {
        left: 68px;
    }

    .shMapSearchTrig {
        margin-right: 20px;
    }

    .shMapSearchResList li a p {
        margin-bottom: 0;
    }

    .shSubHdRt.shSubHdRtAbt {
        padding-left: 20px;
    }

    .aboutPlaceMain {
        margin-top: 86px;
    }

    .aboutNav {
        text-align: center;
        font-family: 'Open Sans', sans-serif;
        padding: 40px 0 30px;
    }

    .shSubNav.aboutNav a {
        font-size: 12px;
        padding: 0 20px;
    }

    .aboutMain p {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 30px;
    }

    .aboutMain h2 {
        font-size: 20px;
        margin-bottom: 16px;
    }

    .WorkList li {
        margin-bottom: 30px;
        font-size: 14px;
    }

    .aboutMain {
        width: auto;
        padding-top: 30px;
    }

    .aboutBanner {
        margin-left: -15px;
        margin-right: -15px;
    }

    .teamMemDesc {
        font-size: 14px;
        line-height: 24px;
    }

    .allTeamItem h4,
    .allTeamItem p {
        font-size: 12px;
    }

    .teamMemDetail .allTeamItem {
        margin-bottom: 30px;
    }

    .closeBox {
        right: 20px;
    }

    .teamMemDetailMain {
        width: 100%;
        height: calc(100vh - 86px);
        margin-left: 0;
        padding: 20px;
    }

    .teamMemDetail {
        top: 86px;
    }

    .overlayTeam {
        display: none;
    }

    .contactSec {
        width: auto;
        margin: 0 auto;
    }

    .contactItemWithHead {
        margin-bottom: 30px;
    }

    .contactItemWithHead h3 {
        font-size: 24px;
        margin-bottom: 20px;
    }

    .contactItemWithHead p {
        font-size: 14px;
    }

    .shSearchResList li .stThumbItem .stThumbItemImg {
        display: none;
    }

    .shSearchResListItem {
        width: auto;
    }

    .shSearchResList li .stThumbItem .stThumbItemDesc {
        width: auto;
        padding: 0;
    }

    .shSearchResList li {
        padding-bottom: 0;
        border-bottom: 0;
    }

    .shSearhResFoundFilter {
        float: left;
        margin-left: 0;
        width: 100%;
    }

    .shSearchInputItem {
        width: auto;
    }

    .shSearhTypeView {
        padding-top: 40px;
        padding-bottom: 4px;
    }

    .shResultCount {
        width: 100%;
        text-align: center;
    }

    .shSearhResFoundFilterItem {
        margin-right: 0;
        width: 48%;
    }

    .shSearhResFoundFilterItem .shSearhResFoundFilterItemSel {
        margin-left: 0;
        width: 100%;
    }

    .shSearhResFoundFilterItem .shSearhResFoundFilterItemSel .dropdown-toggle {
        width: 100%;
    }

    .shSearhResFoundFilter .shSearhResFoundFilterItem:last-child {
        float: right;
    }

    .shSearchResFoundBlk {
        padding-top: 0;
        border-top: 0;
    }

    .shSearchResListSec {
        padding-top: 30px;
    }

    .searchPlaceMain,
    .libraryPlaceMain {
        margin-top: 56px;
    }

    .libraryPlaceMain .shSearhResFoundFilter {
        text-align: center;
    }

    .libraryPlaceMain .shSearhResFoundFilter .shSearhResFoundFilterItem:last-child {
        display: inline-block;
        float: none;
        width: 70%;
    }

    .libraryPlaceMain .shSearhResFoundFilterItem label {
        display: block;
        text-align: center;
        float: none;
    }

    .libraryPlaceMain .shSearchInputSec p {
        margin-bottom: 0;
    }

    .shSearchResListSec.shSearchLibSec .stThumbItem h2 {
        margin-top: 0;
    }

    .allTeamSec .col-md-3 {
        width: 50%;
    }

    .teamMemPic {
        height: 185px;
        width: 100%;
    }

    .allTeamItem {
        margin-bottom: 30px;
    }

    .teamMemDetailMain .teamMemPic {
        width: 160px;
    }

    .loginPopupMain {
        width: 100%;
    }

    .loginLt {
        display: none;
    }

    .loginRt {
        width: 100%;
        height: 100vh;
        padding: 60px 40px;
    }
    .locMapItem {
        width: auto;
        height: 85vh;
        margin: 8% 10px;
    }
    .shAllMenu {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 56px);
    top: 56px;
    z-index: 3;
  }

  .shTopHeader {
    padding: 8px 20px;
    height: 56px;
  }

  .shMenu {
    width: 24px;
    float: left;
    margin-top: 8px;
  }

  .sahaLogo {
    width: 30px;
    margin-top: 1.5%;
  }
  .sLogo {
    margin-left: 27%;
    margin-top: -12;
    width: 130px;
  }

  .shSearch {
    margin-right: 10%;
    margin-top: 8px;
  }

  .shProfile {
    display: none;
  }

  .logoSecp {
    width: calc(50% - 40px);
  }

  .shBanner {
    margin-top: 56px;
  }

  .shBnText {
    position: relative;
    left: 0;
    bottom: 0;
    color: #444;
    padding: 15px 15px 0px 10px;
  }
  .shBnTimeline {
    position: relative;
    color: #fff;
    z-index: 1;
    margin-left: -100px;
    width: 85% !important;
  }
  .shBnTimeline p {
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    font-weight: 100;
    color: #444;
  }
  .shBnTimeline h2 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #444;
  }
  .shBnText p,
  .shBnText .shDateSec h3 {
    font-size: 12px;
  }

  .shBnText h2 {
    font-size: 20px;
  }

  .shLang .btn-secondary {
    color: #444 !important;
    font-size: 12px!important;
  }

  .shSocialSec .shLang {
    margin-left: 0;
    margin-top: 10px;
  }

  .shSocialSec {
    margin-top: 4px;
  }

  .shBnText .shDateSec p {
    float: right !important;
  }

  .shSocialSec .shSocialList {
    width: 65%;
  }

  .shSocialList li.shTag {
    float: right;
    margin-right: 12px;
  }

  .shBnCat {
    color: #da3a50;
  }

  .shBnCat > a {
    color: #fff;
  }

  .shBnOverlay {
    display: none;
  }

  .shBnImg,
  .shBnImg > img.banner-image {
    height: 250px;
  }
  .shBnImg > iframe.banner-image {
    display: block;
    height: auto;
    border: none;
    width: 100%;
  }

  .articlePage,
  .moreMainSec {
    width: auto;
  }

  .articleMainTxt p.paraItalic {
    padding-right: 0;
    margin-bottom: 20px;
    padding-bottom: 0;
    font-size: 15px;
  }

  .articlePage .articleMainTxt p {
    padding-bottom: 30px !important;
    line-height: 24px;
    font-size: 14px !important;
    margin-bottom: 0;
  }

  .pageImg {
    margin-bottom: 24px;
  }

  .artCarouselSec {
    margin-left: 0;
  }

  .playItem {
    bottom: 40px;
  }

  .playItem a {
    width: 48px;
  }

  .playItem h6 {
    font-size: 16px;
    padding: 0 60px;
    margin-top: 10px;
    line-height: 24px;
  }

  .playItem .btn-con {
    margin-top: 0;
    background-color: #fff;
  }

  .articleNotesTab .nav-tabs .nav-link {
    font-size: 12px;
  }

  .articleNotesTab .tab-content {
    padding: 20px;
    height: auto;
    font-size: 14px;
  }

  .artNotesTabSocial .someRight {
    font-size: 12px;
  }
  .artNotesTabSocial .someRight span {
    margin: 3px 0px;
  }

  .articlePlaceMain {
    margin-top: 56px;
  }

  .articlePageTheme {
    padding: 20px 15px 0px 15px;
  }

  .articleMainTxt .pageImg p {
    padding-bottom: 0;
    padding-left: 0;
    line-height: 16px;
    margin-top: 10px;
  }

  .tantraSec {
    margin: 0px 0 30px 0;
  }

  .notTabMain p {
    font-size: 12px;
    word-break: break-word;
  }
  .notTabMain a {
    font-size: 12px;
    word-break: break-word;
  }

  .artNotesTabSocial {
    padding: 24px 0 40px 0;
  }

  

  .moreExtra {
    border-top: 1px solid #d6d6d6;
    padding-top: 48px;
    text-align: center;
  }

  .moreExtra .moreExtraItem {
    width: 260px;
    display: inline-block;
  }

  .moreExtra .moreExtraItem .moreExtraItemTxt {
    text-align: left;
  }

  .readMore {
    padding: 40px 0 23px 0;
  }

  .readMore a {
    font-weight: 700;
  }

  .stThumbItem h2 {
    font-size: 16px;
    margin-bottom: 0;
  }

  .stThumbItem .stThumbSculp {
    font-size: 12px;
  }

  .stThumbItem .stThumbOw {
    margin-top: 12px;
    font-size: 14px;
  }

  .stThumbItem p {
    margin-bottom: 5px;
  }

  .btn-load {
    font-size: 12px;
  }

  .stFootBtm {
    padding-bottom: 40px;
    /* padding-left: 50px; */
    position: relative;
    text-align: left;
    margin-top: 30px;
  }

  .stFootBtm a {
    font-size: 14px;
    margin: 0 0 10px 0;
    width: 48%;
  }

  .stFootBtm a:first-child {
    /* position: absolute;
        padding:1% 16%; */
    left: 0;
    font-size: 14px;
    /* width: 100%; */
    text-align: center;
    margin-top: 2%;
  }

  .stFtTopList {
    display: block;
    background-color: rgb(218 58 80 / 15%);
    padding: 10px;
  }

  .stFtTopItem {
    text-align: center;
    margin-bottom: 0;
  }

  .stFtTopItem h5 {
    font-size: 14px;
    margin-bottom: 0;
    padding: 10px;
    border-bottom: 1px solid rgb(218 58 80 / 15%);
  }

  .stFtTopList li {
    margin-bottom: 10px;
  }

  .stFtTopList li a {
    font-size: 12px;
  }

  .stFooter {
    padding-top: 0;
  }

  .stFootLogo {
    display: none;
  }

  .stFtThumb .col-md-4,
  .stFootTop .col-md-4,
  .stFootTop .col-md-8,
  .stFootTop .col-md-6,
  .shSearchResFound .col-md-12,
  .shSearchLibSec .col-md-4 {
    padding-right: 0;
    padding-left: 0;
  }

  .stThumbItemDesc {
    padding: 0 40px;
  }

  .stThumbItemCtrl {
    margin-top: -26px;
  }

  .artCarouselSec .owl-carousel .owl-item .artCarouselImg {
    height: 260px;
    width: 100vh;
  }

  .artCarouselSec .owl-theme .owl-nav {
    top: 60%;
  }

  .stThumbItem .stThumbCat {
    margin-bottom: 0;
  }

  .landingMapSec {
    margin-top: 0;
    margin-bottom: 40px;
  }

  .shAllMaps {
    width: auto;
    padding-left: 20px;
    font-size: 12px;
  }

  .shSubHdRt {
    padding-left: 90px;
  }

  .shSubNav a.shSubLoc {
    font-size: 12px;
  }

  .shSubNav a {
    padding: 0 10px;
  }

  .shBreadcrumb .breadcrumb .breadcrumb-item.active {
    display: none;
  }

  .shSubHeader {
    padding-right: 20px;
  }

  .shMapSearchBox {
    width: 210px;
  }

  .shMapSel {
    left: 68px;
  }

  .shMapSearchTrig {
    margin-right: 20px;
  }

  .shMapSearchResList li a p {
    margin-bottom: 0;
  }

  .shSubHdRt.shSubHdRtAbt {
    padding-left: 20px;
  }

  .aboutPlaceMain {
    margin-top: 86px;
  }

  .aboutNav {
    text-align: center;
    font-family: "Open Sans", sans-serif;
    padding: 40px 0 30px;
  }

  .shSubNav.aboutNav a {
    font-size: 12px;
    padding: 0 20px;
  }

  .aboutMain p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 30px;
  }

  .aboutMain h2 {
    font-size: 20px;
    margin-bottom: 16px;
  }

  .WorkList li {
    margin-bottom: 30px;
    font-size: 14px;
  }

  .aboutMain {
    width: auto;
    padding-top: 30px;
  }

  .aboutBanner {
    margin-left: -15px;
    margin-right: -15px;
  }

  .teamMemDesc {
    font-size: 14px;
    line-height: 24px;
  }

  .allTeamItem h4,
  .allTeamItem p {
    font-size: 12px;
  }

  .teamMemDetail .allTeamItem {
    margin-bottom: 30px;
  }

  .closeBox {
    right: 20px;
  }

  .teamMemDetailMain {
    width: 100%;
    height: calc(100vh - 86px);
    margin-left: 0;
    padding: 20px;
  }

  .teamMemDetail {
    top: 86px;
  }

  .overlayTeam {
    display: none;
  }

  .contactSec {
    width: auto;
    margin: 0 auto;
  }

  .contactItemWithHead {
    margin-bottom: 30px;
  }

  .contactItemWithHead h3 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .contactItemWithHead p {
    font-size: 14px;
  }

  .shSearchResList li .stThumbItem .stThumbItemImg {
    display: none;
  }

  .shSearchResListItem {
    width: auto;
  }

  .shSearchResList li .stThumbItem .stThumbItemDesc {
    width: auto;
    padding: 0;
  }

  .shSearchResList li {
    padding-bottom: 0;
    border-bottom: 0;
  }

  .shSearhResFoundFilter {
    float: left;
    margin-left: 0;
    width: 100%;
  }

  .shSearchInputItem {
    width: auto;
  }

  .shSearhTypeView {
    padding-top: 40px;
    padding-bottom: 4px;
  }

  .shResultCount {
    width: 100%;
    text-align: center;
  }

  .shSearhResFoundFilterItem {
    margin-right: 0;
    width: 48%;
  }

  .shSearhResFoundFilterItem .shSearhResFoundFilterItemSel {
    margin-left: 0;
    width: 100%;
  }

  .shSearhResFoundFilterItem .shSearhResFoundFilterItemSel .dropdown-toggle {
    width: 100%;
  }

  .shSearhResFoundFilter .shSearhResFoundFilterItem:last-child {
    float: right;
  }

  .shSearchResFoundBlk {
    padding-top: 0;
    border-top: 0;
  }

  .shSearchResListSec {
    padding-top: 30px;
  }

  .searchPlaceMain,
  .libraryPlaceMain {
    margin-top: 56px;
  }

  .libraryPlaceMain .shSearhResFoundFilter {
    text-align: center;
  }

  .libraryPlaceMain
    .shSearhResFoundFilter
    .shSearhResFoundFilterItem:last-child {
    display: inline-block;
    float: none;
    width: 70%;
  }

  .libraryPlaceMain .shSearhResFoundFilterItem label {
    display: block;
    text-align: center;
    float: none;
  }

  .libraryPlaceMain .shSearchInputSec p {
    margin-bottom: 0;
  }

  .shSearchResListSec.shSearchLibSec .stThumbItem h2 {
    margin-top: 0;
  }

  .allTeamSec .col-md-3 {
    width: 50%;
  }

  .teamMemPic {
    height: 185px;
    width: 100%;
  }

  .allTeamItem {
    margin-bottom: 30px;
  }

  .teamMemDetailMain .teamMemPic {
    width: 160px;
  }

  .loginPopupMain {
    width: 100%;
  }

  .loginLt {
    display: none;
  }

  .loginRt {
    width: 100%;
    height: 100vh;
    padding: 60px 40px;
  }
  .locMapItem {
    width: auto;
    height: 85vh;
    margin: 8% 10px;
  }
  .shAllMenu {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 56px);
    top: 10%;
    z-index: 3;
    padding-top: 0;
    background-color: #fff;
  }
  .shAllMenu .stFtTopItem {
    text-align: left;
    margin-top: 0;
  }
  .shAllMenu .container {
    padding-right: 0;
    padding-left: 0;
  }
  .shAllMenu .stFtTopItem h5 {
    color: #444;
    margin-bottom: 0;
    padding-left: 30px;
    font-size: 12px;
    padding-right: 20px;
  }
  .shAllMenu .stFtTopItem h5.active img {
    transform: rotate(-180deg);
    transition: 0.3s ease;
  }
  .shAllMenu .stFtTopItem h5 img,
  .shMenuAccountList {
    display: none;
  }
  .shAllMenu .stFtTopList {
    padding-left: 48px;
    height:150px;
    overflow-y: auto;
  }
  .shGalSec {
    position: absolute;
    margin-top: 28%;
    text-align: center;
    width: 100%;
    left: 0;
  }
  .shGalSec a {
    display: block;
  }
  .shViewGal {
    margin-left: 0;
    display: block;
    margin-top: 10px;
  }
  .shChapterItem {
    white-space: nowrap;
    overflow-x: auto;
  }

  .shChapterItem li {
    width: 50%;
    float: none;
    display: inline-block;
  }
  .createNewTrig {
    margin-bottom: 30px;
  }

  .shVidBanner .shBnText {
    left: 0;
    bottom: 0;
    color: #444;
    max-width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
  .videoPageTheme .articlePage {
    margin-top: 0;
  }
  .moreMainSec {
    width: 100%;
  }

  #player .youtube-chapter-wrapper .chapters-wrapper ul {
    white-space: nowrap;
    overflow-x: auto;
  }

  #player .youtube-chapter-wrapper .chapters-wrapper .chapter-point-wrapper {
    width: auto;
    float: none;
    display: inline-block;
  }

  #player .youtube-video {
    height: auto;
  }
  #playerMobile .youtube-chapter-wrapper .chapters-wrapper ul {
    white-space: nowrap;
    overflow-x: auto;
    margin-left: 10px;
    margin-right: 10px;
  }

  #playerMobile
    .youtube-chapter-wrapper
    .chapters-wrapper
    .chapter-point-wrapper {
    width: auto;
    float: none;
    display: inline-block;
    overflow: scroll;
    scrollbar-width: none;
  }
  #playerMobile
    .youtube-chapter-wrapper
    .chapters-wrapper
    .chapter-point-wrapper::-webkit-scrollbar {
    display: none;
  }

  #playerMobile .youtube-video {
    height: auto;
  }

  .flickity-prev-next-button.previous {
    left: 1px !important;
    width: 25px;
    height: 25px;
    background-color: #b4b4b4 !important;

  }

  .flickity-prev-next-button.next {
    right: 1px !important;
    width: 25px;
    height: 25px;
    background-color: #b4b4b4 !important;
  }

  .react-fullscreen-slideshow-modal-content .react-fullscreen-slideshow-slides {
    flex: 0 0 100% !important;
  }

  .react-fullscreen-slideshow-close {
    top: 1px !important;
  }

  .react-fullscreen-slideshow-prev-mobile,
  .react-fullscreen-slideshow-next-mobile {
    top: 35% !important;
  }

  .react-fullscreen-slideshow-modal-bottom .react-fullscreen-slideshow-bottom-left-pane {
    left: 0;
    right: 0;
    padding-left: 20px;
    justify-content: left;
    width:50% !important;
  }

  .react-fullscreen-slideshow-caption-container {
    position: relative !important;
    margin: 0px 15px !important;
  }
  .react-fullscreen-slideshow-caption-container .react-fullscreen-slideshow-gallery-icon-bar {
    position: absolute;
    bottom: 50% !important;
    left: 2% !important;
    display: block !important;
  }
  .react-fullscreen-slideshow-modal-content {
    height: 60% !important;
  }

  #react-fullscreen-slideshow-caption {
    max-width: 100%;
    bottom: 100px;
    padding: 0 10px;
    margin: 60px 50px 0 10px !important;
  }

  .react-fullscreen-slideshow-modal-content {
    display: block;
  }
  .share-button{
    width:40px !important;
    padding: 5px 8px !important;
  }
  .react-fullscreen-slideshow-next-mobile{
    right:1% !important;
    opacity:0.75 !important;
    border-radius: 35px;
    padding:0.5vw;
    background-color: #121212;
    width:32px  !important;
    height:32px !important;
    font-size:14px !important
  }
  .react-fullscreen-slideshow-prev-mobile{
    left:1%;
    opacity:0.75 !important;
    border-radius: 35px;
    padding:0.5vw;
    background-color: #121212;
    width:32px !important;
    height:32px !important;
    font-size:14px !important

  }
}
/* .medium-insert-embeds{display:none;} */
.staticSocial li {
  filter: invert(1);
}
.shHeader {
  height: 70px;
}

.flickity-page-dots {
  display: none;
}
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a9a9a9;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #444444;
}
.theme-t .articleMainTxt .museumMainTxt p {
  padding-bottom: 40px;
  line-height: 2 !important;
  color: #444;
  font-size: 18px;
  /* letter-spacing: 1px; */
  margin-bottom: 0px;
  margin-top: 1%;
}
div.banner-details div.left-des {
  width: auto;
  font-size: 12px;
}
div.banner-details {
  color: #1b1b1b;
}
.hide {
  display: none;
}
.share-button{
  padding:10px;
}
.fullBoxMain .react-fullscreen-slideshow-modal-content .react-fullscreen-slideshow-slides{
  flex: 0 0 77%;
}
.react-fullscreen-slideshow-modal-bottom{
  width:0;
  border-top: none !important;
}
.react-fullscreen-slideshow-bottom-left-pane{top:90%; padding:5px 5px 5px 0px !important}
.react-fullscreen-slideshow-bottom-right-pane{top:91%}
.react-fullscreen-slideshow-slides img{
  width: 100% !important;
  height:auto !important;
}
.react-fullscreen-slideshow-gallery-icon-bar .share-button{
  width:60px ;
  padding: 5px 12px 5px 12px !important;
}
.react-fullscreen-slideshow-modal-bottom .react-fullscreen-slideshow-bottom-left-pane{
  right: 15%;
  width:8%;
  left:5px;
  background-color: #121212;
  z-index: 10000;
}
.react-fullscreen-slideshow-modal-bottom .react-fullscreen-slideshow-bottom-text{
  font-size: 18px !important;
}
.react-fullscreen-slideshow-numbertext{
  font-size:18px !important;
  color:#fff !important;
}
.react-fullscreen-slideshow-caption-container{
  bottom:10% !important
}
.react-fullscreen-slideshow-caption-container .react-fullscreen-slideshow-gallery-icon-bar{
  bottom:1% ;
}
.react-fullscreen-slideshow-bottom-left-pane:before{
  content: "\f06e" !important;
}
.react-fullscreen-slideshow-modal-content .react-fullscreen-slideshow-prev-mobile, .react-fullscreen-slideshow-next-mobile{
  position: absolute;
  display: block !important;
}
.react-fullscreen-slideshow-modal-content .react-fullscreen-slideshow-next-mobile{
  right:26%;
  opacity:1;
  border-radius: 35px;
  padding:0.5vw;
  background-color: #121212;
  width:64px;
  height:64px
}
.react-fullscreen-slideshow-modal-content .react-fullscreen-slideshow-prev-mobile{
  left:1%;
  opacity:1;
  border-radius: 35px;
  padding:0.5vw;
  background-color: #121212;
  width:64px;
  height:64px
}
.react-fullscreen-slideshow-prev{
  display:none
}
.react-fullscreen-slideshow-next{
  display:none
}
.react-fullscreen-slideshow-modal-content{height: 100%;}
div.carousel-cell div.embed-image img{
  height:480px !important;
}
/* .banner-details{height:80px} */
.articlePage .museumMainTxt .carousel{height:500px !important; width: 100%!important;}
div.carousel-cell div.embed-image{height:480px;}

.landing-page-lp .leaflet-popup-content{
  margin:0px 0px 0px 0px !important;
}
.landing-page-lp .leaflet-popup-content-wrapper{
  border-radius:0px !important;
}
.landing-page-lp .leaflet-popup-content p{
  margin: 0px 0 !important;
}


.theme-t .leaflet-popup-content{
  margin:0px 0px;
}
.theme-t .leaflet-popup-content-wrapper{
  border-radius:0px;
}
.wrapper-pop .shPoiBody p{
  font-size:14px !important;
  padding-bottom: 0px !important;
}
.react-fullscreen-slideshow-modal-wrapper {
  z-index: 3 !important;
}
.theme-t .wrapper-pop{
  height:135px;
  overflow-y: auto;
}
.theme-t .leaflet-popup-content p{
  margin:0px 0px;
}
.theme-t .leaflet-popup.leaflet-zoom-animated{
  width:240px !important;
}

.theme-t .inner-map-container{
  height:560px !important;
}
.theme-t .shPoiBreadcrumb p{
  padding-bottom: 0px;
}
.disableDrop{background-color: #d7d7d7 !important;}
.landing-page-lp .btn-secondary{border-color: #d7d7d7 !important;}

.stFtTopItem ul {
  display: block; 
}
.stFtTopItem.active ul {
  display: block !important;
  height:200px;
  overflow-y: auto;
}
.articleNew .shBnImg{
  height:100%;
  width:100%;
  background-size: 100% 100%;
}
.articleMainTxt .carousel{
  margin:0 auto 0px !important;
}
.medium-insert-active{display: block;}
.theme-fullscreen{
  position: absolute;
  z-index: 2;
  right:0.5%;
  top:90%;
  background-color: #fff;
  border: 1px solid #efefef;
  border-radius: 50%;
  padding: 0.5%;
  box-shadow:0 2px 4px rgb(0 0 0 / 20%);
}

/* Popup style */
.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.box {
  position: relative;
  width: 70%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
}

.close-icon {
  content: 'x';
  cursor: pointer;
  position: fixed;
  right: calc(15% - 30px);
  top: calc(100vh - 85vh - 33px);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}
.react-fullscreen-slideshow-view-all-modal{
  z-index: 3 !important;
}
.theme-t-full .inner-map-container{
  height:100% !important;
}
.theme-t-full .shMapSel{
  margin-top:5%;
  margin-left:2%;
}
.articleNotesTab a{
  color:#da3a50;
  display: block;
}
.articlePage a{
  color:#da3a50;
}

.team .imgLink{
    font-size: 16px;
    font-weight: 600;
    font-family: sans-serif;
    margin-top: 4%;
}
.team span{
  font-size: 16px;
    font-family: sans-serif;
}
.Museum-Page header{
height:100% !important;
background-size: 100% 100%;
}
.share-button [aria-label="pinterest"]{display:none;}
.navigateVideo .navigateButton{
  background-color: rgb(218 58 80 / 0.2);
  color:#da3a50;
}
.navigateVideo .timer{
  display:none;
}
.articleMainTxt .medium-insert-images p{
  text-align: center;
  padding-bottom:0px !important;
  font-size: 14px !important;
}
.articleMainTxt ul li p{
  padding-bottom:10px !important;
}
.articleMainTxt ul li ul li{
  list-style-type: disc;
}
.notes:after{
            counter-increment: my-sec-counter;
            content: "[" counter(my-sec-counter) "]";
            color:#267fec !important;
        }
        .lineNote{line-height: 2.5 !important;
        display: none;
      }

.museumPageTheme .museumMainTxt p{
  padding-bottom: 0px !important;
}