@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville&family=Open+Sans:wght@400;600&display=swap");


.only-mobile {
  display: none;
}
.only-desktop {
  display: block;
}
nav {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  padding: 15px 40px;
  background-color: white;
  z-index: 99;
}

.nav-left {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-right {
  display: flex;
  justify-content: center;
  align-items: center;
}
#burger-menu {
  width: 28px;
  cursor: pointer;
}

#nav-logo {
  margin-left: 30px;
  width: 188px;
  cursor: pointer;
}

#nav-search {
  margin-right: 60px;
  width: 24px;
  cursor: pointer;
}
.modalClass {  
  text-align: center;  
  margin-top: 100px;  
} 
.logo {
  position: absolute;
  width: 100%;
  color: #fff;
  top: 50%;
  left: 44%;
  margin-top: 13%;
 }
.img1 {
  width: 100%;
  height: 100px;
  transform: scale(1.2);
  vertical-align: middle;
}
.img-hover-zoom {
  overflow: hidden; 
  height: 574px;
  }
.img-hover-zoom img {
  transition: transform .5s ease !important;
}
.img-hover-zoom:hover img {
  transform: scale(1.5) !important;
  visibility: visible !important;
}
.centered {
  position:absolute;
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
  text-align: center;
  text-overflow: visible;
  font-weight: bolder;
  width: 100%;
  z-index: 100;
  font-size: 24px;
  color: #fff;
  top: 48%;
  overflow: visible;
}
.react-multi-carousel-list {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
}
.react-multi-carousel-track {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  position: relative;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  will-change: transform,transition;
}
.react-multi-carousel-item {
    transform-style: preserve-3d;
    backface-visibility: hidden;
}

.react-multiple-carousel__arrow {
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 50%;
  z-index: 500;
  border: 0;
  background: rgb(65, 61, 61);
  -webkit-tap-highlight-color: transparent;
  min-width: 36px;
  min-height: 36px;
  height: 36px;
  opacity: 1;
  cursor: pointer;
  margin-right: -34px;
  -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.26);
}
.react-multiple-carousel__arrow:hover {
  background: rgba(0, 0, 0, 0.8);
}
.react-multiple-carousel__arrow--left {
  margin-left: -3%;
}
.react-multiple-carousel__arrow::before {
  color: #fff;
  font-weight: bolder;
  font-size: 16px;
}
@media screen and (max-width: 1024px) {
  .logo {
    position: absolute;
    width: 100%;
    color: #fff;
    top: 50%;
    left: 44%;
    margin-top: 13%;
   }

}
@media screen and (max-width: 768px) {
  .img1 {
    display: block;
    object-fit: cover;
  }
  .centered {
    position: absolute;
    font-family: "Open Sans", sans-serif;
    text-align: center;
    font-size: 20px;
    color: #fff; 
    top:50%;
    height: auto;
    width:100%;
  }
  .zoom {
    width: 100%;
  }
  .only-mobile {
    display: block;
  }
  .only-desktop {
    display: none;
  }
  nav {
    padding: 15px 16px;
  }
  #nav-search {
    margin-right: 0;
  }
  .img1 {
    width: 100%;
    height: 200px;
    display: block;
    
  }
  .zoom {
    width: 100%;
  }
}
.logo{left:47%;top:45%}