div.embed-image{
    width: 100%;
    
    margin: 8px 0px;
    /* padding: 0px 20%; */
    box-sizing: border-box;
    color:rgb(191, 13, 0);
    font-size: 25px;
    font-family: Georgia, 'Times New Roman', Times, serif;
}
div.carousel-cell div.embed-image{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
div.carousel-cell div.embed-image img{
    max-width: 100%;
    max-height: 90%;
    height: auto;
width: auto;
}
.carousel-cell .center1{

    height: 100%;
    width: 100%;
}
div.embed-image img{
    background-color: rgb(233,241,217) ;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
}
div.banner-details{
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    color: rgb(78,79,93);
    padding: 3px 0px;
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
}
div.banner-details div.left-des{
    width: 0%;
    font-size: 16px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}
div.banner-details div.right-des{
    font-family: 'Open Sans';
    font-weight: 600;    
    letter-spacing: 1px;
    margin: auto;
}
.react-fullscreen-slideshow-view-all-thumbnails img {
    max-width: 25vw !important;
    max-height: 24vh !important;
}
@media(max-width:600px){
    div.carousel-cell div.embed-image img{
        max-height: 75%;
    }
}