.center1 {
    display     : block;
    margin-left : auto;
    margin-right: auto;
    width       : 50%;
}

@media(max-width:910px) {
    .center1 {
        width: 80%;
    }

}