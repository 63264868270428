@import url(https://fonts.googleapis.com/css2?family=Libre+Baskerville&family=Open+Sans:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Libre+Baskerville&family=Open+Sans:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Libre+Baskerville&family=Open+Sans:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Libre+Baskerville&family=Open+Sans:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Libre+Baskerville&family=Open+Sans:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Open+Sans:wght@400;600&display=swap);
/* Flickity fullscreen v1.0.1
------------------------- */

.flickity-enabled.is-fullscreen {
    Z-INDEX: 1000 !important;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: hsla(0, 0%, 0%, 0.9);
    padding-bottom: 35px;
    z-index: 1;
  }
  

  .flickity-enabled.is-fullscreen 
  
  .flickity-enabled.is-fullscreen .flickity-page-dots {
    bottom: 10px;
  }
  
  .flickity-enabled.is-fullscreen .flickity-page-dots .dot {
    background: white;
  }
  
  /* prevent page scrolling when flickity is fullscreen */
  html.is-flickity-fullscreen {
    overflow: hidden;
  }

  html.is-flickity-fullscreen .magnifying-glass {
    display: block;
  }

  .magnifying-glass {
    display: none;
  }

  .carousel.is-fullscreen .carousel-cell .magnifier .magnifying-glass {
    display: block !important;
  }

  .carousel.flickity-enabled.is-draggable {
      /* margin: 0 0 50px 0; */
  }


  
  /* ---- flickity-fullscreen-button ---- */
  
  .flickity-fullscreen-button {
    display: block;
    right: 10px;
    top: 10px;
    width: 24px;
    height: 24px;
    border-radius: 4px;
  }
  
  /* right-to-left */
  .flickity-rtl .flickity-fullscreen-button {
    right: auto;
    left: 10px;
  }
  
  .flickity-fullscreen-button-exit { display: none; }
  
  .flickity-enabled.is-fullscreen .flickity-fullscreen-button-exit { display: block; }
  .flickity-enabled.is-fullscreen .flickity-fullscreen-button-view { display: none; }
  
  .flickity-fullscreen-button .flickity-button-icon {
    position: absolute;
    width: 16px;
    height: 16px;
    left: 4px;
    top: 4px;
  }

  .carousel {
    margin: 0 auto 60px;
    text-align: center;
    height: 524px;
    
  }
  
  /* cell inherit height from carousel */
  .carousel-cell {
    position: relative;
    height: 100%;
    width:100%;
    /* center images in cells with flexbox */
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .carousel.is-fullscreen{
    margin: 0;
  }
  .carousel.is-fullscreen .CarouselCaption{
    color: #fff;
  }
  .carousel.is-fullscreen .carousel-cell {
    height: 100%;
    width: 100%;
  }

  .carousel.is-fullscreen .carousel-cell .caption {
    display: block !important;
  }
  
  .carousel-cell img {
    display: block;
  }

  .carousel-cell .caption {
    display: none;
    position: absolute; /* Position the background text */
    bottom: 0; /* At the bottom. Use top:0 to append it to the top */
    background: rgba(0, 0, 0, 0.475); /* Fallback color */
    color: #f1f1f1; /* Grey text */
    width: 100%; /* Full width */
    padding: 20px; /* Some padding */
  }
  @media(max-width:910px) {
    .flickity-page-dots {
      display: none;
    }
    

  }
  @media(max-width:420px) {
    .carousel{
      height: 320px;
    }
  }
  
div.embed-image{
    width: 100%;
    
    margin: 8px 0px;
    /* padding: 0px 20%; */
    box-sizing: border-box;
    color:rgb(191, 13, 0);
    font-size: 25px;
    font-family: Georgia, 'Times New Roman', Times, serif;
}
div.carousel-cell div.embed-image{
    height: 100%;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}
div.carousel-cell div.embed-image img{
    max-width: 100%;
    max-height: 90%;
    height: auto;
width: auto;
}
.carousel-cell .center1{

    height: 100%;
    width: 100%;
}
div.embed-image img{
    background-color: rgb(233,241,217) ;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column;
            flex-flow: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}
div.banner-details{
    display: -webkit-flex;
    display: flex;
    box-sizing: border-box;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    color: rgb(78,79,93);
    padding: 3px 0px;
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
}
div.banner-details div.left-des{
    width: 0%;
    font-size: 16px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}
div.banner-details div.right-des{
    font-family: 'Open Sans';
    font-weight: 600;    
    letter-spacing: 1px;
    margin: auto;
}
.react-fullscreen-slideshow-view-all-thumbnails img {
    max-width: 25vw !important;
    max-height: 24vh !important;
}
@media(max-width:600px){
    div.carousel-cell div.embed-image img{
        max-height: 75%;
    }
}
.center1 {
    display     : block;
    margin-left : auto;
    margin-right: auto;
    width       : 50%;
}

@media(max-width:910px) {
    .center1 {
        width: 80%;
    }

}
/*body {
    font-family: "Libre Baskerville", serif;
    font-weight: normal;
    line-height: 1.7;
    color: #fff;
    background-color:#fff;
  }*/
  a {
    cursor: pointer;
}
  .only-mobile {
    display: none;
  }
  .only-desktop {
    display: block;
  }
  .boxes{
    background-color: #da3a50 !important;
    height: 313px;
    border: 10px ;
    margin-top: 4%;
  }
  .row{
      font-size:21px !important ;
      font-family: "Libre Baskerville", serif;
  }
  .column{
    position: relative;
    width: 50%;
    padding-right: 10px;
    padding-left: 75px;
    top: 80px;
    left:30px;
  }
  .column1{
    position: absolute;
    width: 50%;
    padding-right: 10px;
    padding-left: 75px;
    top: 135px;
    left:30px;
  }
  .column2{
    position: absolute;
    width: 50%;
    padding-right: 10px;
    padding-left: 175px;
    top: 135px;
    right:30px;
  }
  .outer_circle {
    position: relative;
    width: 42px;
    height: 42px;
    float: right;
    top: -220px;
    margin: 290px 20px;
    border-radius: 50%;
    background: #fff;
}
  
.inner_circle {
    position: relative;
    width: 37px;
    height: 37px;
    -webkit-transform: translate(7.5% , 7.5%);
            transform: translate(7.5% , 7.5%);
    border-radius: 50%;
    background-color:#da3a50;
}
  .cls{
    font-size: 24px;
    font-family: "Open-Sans", sans-serif;
    color:#fff;
    top:3.5%;
    float: right;
    right: 10px;
    position: fixed;
  } 
  .button {
    color: #da3a50;
    padding: 15px 50px;
    border-radius:4px;
    text-align: center;
    font-size: 18px;
    font-family:  "Open-Sans", sans-serif;
    margin: 22px 55px;
  }
  button:not(:disabled) {
    cursor: pointer;
}
  .button1 {
      background-color: #fff;
      margin-top:15%;
    }
    @media screen and (max-width: 768px)  {
      .only-mobile {
        display: block !important;
      }
      .only-desktop {
        display: none !important;
      }
      .boxes{
        background-color: #da3a50 !important;
        width: 100vw;
        height: 289px;
        margin-top: 12%;
        border: 10px ;
        padding: 40px;
      }
      .row{
        font-size:16px !important ;
        font-family: "Libre Baskerville", serif;
        color: #fff;
        line-height: 1.7;
    }
    .entire{
      margin-left: -2px;
      margin-right: 0px;
      width: 80vw;
    }
    .outer_circle {
      position: relative;
      width: 42px;
      height: 42px;
      float: right;
      top: -21px;
      margin: 290px 20px;
      border-radius: 50%;
      background: #da3a50;
  }
    
  .inner_circle {
      position: relative;
      width: 37px;
      height: 37px;
      -webkit-transform: translate(7.5% , 7.5%);
              transform: translate(7.5% , 7.5%);
      border-radius: 50%;
      background-color:#da3a50;
  }
    .cls{
    font-size: 20px;
    font-family: "Open-Sans", sans-serif;
    color:#fff;
    top:-500%;
    position: fixed;
    } 
    .button {
      color: #da3a50;
      padding: 2px 70px;
      border-radius:4px;
      text-align: center;
      font-size: 18px;
      font-family:  "Open-Sans", sans-serif;
      margin: 25px -4px;
      cursor: pointer;
    }
    .button1 {
        background-color: #fff;
      }
  }
.App {
  text-align: Left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/*!
 * Bootstrap Grid v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@-ms-viewport {
  width: device-width;
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -webkit-flex-basis: 0;
          flex-basis: 0;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  -webkit-flex: 0 0 8.333333%;
          flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  -webkit-flex: 0 0 16.666667%;
          flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  -webkit-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -webkit-flex: 0 0 33.333333%;
          flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  -webkit-flex: 0 0 41.666667%;
          flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  -webkit-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -webkit-flex: 0 0 58.333333%;
          flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  -webkit-flex: 0 0 66.666667%;
          flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  -webkit-flex: 0 0 75%;
          flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -webkit-flex: 0 0 83.333333%;
          flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  -webkit-flex: 0 0 91.666667%;
          flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  -webkit-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -webkit-order: -1;
          order: -1;
}

.order-last {
  -webkit-order: 13;
          order: 13;
}

.order-0 {
  -webkit-order: 0;
          order: 0;
}

.order-1 {
  -webkit-order: 1;
          order: 1;
}

.order-2 {
  -webkit-order: 2;
          order: 2;
}

.order-3 {
  -webkit-order: 3;
          order: 3;
}

.order-4 {
  -webkit-order: 4;
          order: 4;
}

.order-5 {
  -webkit-order: 5;
          order: 5;
}

.order-6 {
  -webkit-order: 6;
          order: 6;
}

.order-7 {
  -webkit-order: 7;
          order: 7;
}

.order-8 {
  -webkit-order: 8;
          order: 8;
}

.order-9 {
  -webkit-order: 9;
          order: 9;
}

.order-10 {
  -webkit-order: 10;
          order: 10;
}

.order-11 {
  -webkit-order: 11;
          order: 11;
}

.order-12 {
  -webkit-order: 12;
          order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    -webkit-flex: 0 0 8.333333%;
            flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    -webkit-flex: 0 0 16.666667%;
            flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -webkit-flex: 0 0 33.333333%;
            flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    -webkit-flex: 0 0 41.666667%;
            flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -webkit-flex: 0 0 58.333333%;
            flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    -webkit-flex: 0 0 66.666667%;
            flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -webkit-flex: 0 0 83.333333%;
            flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    -webkit-flex: 0 0 91.666667%;
            flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -webkit-order: -1;
            order: -1;
  }
  .order-sm-last {
    -webkit-order: 13;
            order: 13;
  }
  .order-sm-0 {
    -webkit-order: 0;
            order: 0;
  }
  .order-sm-1 {
    -webkit-order: 1;
            order: 1;
  }
  .order-sm-2 {
    -webkit-order: 2;
            order: 2;
  }
  .order-sm-3 {
    -webkit-order: 3;
            order: 3;
  }
  .order-sm-4 {
    -webkit-order: 4;
            order: 4;
  }
  .order-sm-5 {
    -webkit-order: 5;
            order: 5;
  }
  .order-sm-6 {
    -webkit-order: 6;
            order: 6;
  }
  .order-sm-7 {
    -webkit-order: 7;
            order: 7;
  }
  .order-sm-8 {
    -webkit-order: 8;
            order: 8;
  }
  .order-sm-9 {
    -webkit-order: 9;
            order: 9;
  }
  .order-sm-10 {
    -webkit-order: 10;
            order: 10;
  }
  .order-sm-11 {
    -webkit-order: 11;
            order: 11;
  }
  .order-sm-12 {
    -webkit-order: 12;
            order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    -webkit-flex: 0 0 8.333333%;
            flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    -webkit-flex: 0 0 16.666667%;
            flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -webkit-flex: 0 0 33.333333%;
            flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    -webkit-flex: 0 0 41.666667%;
            flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -webkit-flex: 0 0 58.333333%;
            flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    -webkit-flex: 0 0 66.666667%;
            flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -webkit-flex: 0 0 83.333333%;
            flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    -webkit-flex: 0 0 91.666667%;
            flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -webkit-order: -1;
            order: -1;
  }
  .order-md-last {
    -webkit-order: 13;
            order: 13;
  }
  .order-md-0 {
    -webkit-order: 0;
            order: 0;
  }
  .order-md-1 {
    -webkit-order: 1;
            order: 1;
  }
  .order-md-2 {
    -webkit-order: 2;
            order: 2;
  }
  .order-md-3 {
    -webkit-order: 3;
            order: 3;
  }
  .order-md-4 {
    -webkit-order: 4;
            order: 4;
  }
  .order-md-5 {
    -webkit-order: 5;
            order: 5;
  }
  .order-md-6 {
    -webkit-order: 6;
            order: 6;
  }
  .order-md-7 {
    -webkit-order: 7;
            order: 7;
  }
  .order-md-8 {
    -webkit-order: 8;
            order: 8;
  }
  .order-md-9 {
    -webkit-order: 9;
            order: 9;
  }
  .order-md-10 {
    -webkit-order: 10;
            order: 10;
  }
  .order-md-11 {
    -webkit-order: 11;
            order: 11;
  }
  .order-md-12 {
    -webkit-order: 12;
            order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    -webkit-flex: 0 0 8.333333%;
            flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    -webkit-flex: 0 0 16.666667%;
            flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -webkit-flex: 0 0 33.333333%;
            flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    -webkit-flex: 0 0 41.666667%;
            flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -webkit-flex: 0 0 58.333333%;
            flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    -webkit-flex: 0 0 66.666667%;
            flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -webkit-flex: 0 0 83.333333%;
            flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    -webkit-flex: 0 0 91.666667%;
            flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -webkit-order: -1;
            order: -1;
  }
  .order-lg-last {
    -webkit-order: 13;
            order: 13;
  }
  .order-lg-0 {
    -webkit-order: 0;
            order: 0;
  }
  .order-lg-1 {
    -webkit-order: 1;
            order: 1;
  }
  .order-lg-2 {
    -webkit-order: 2;
            order: 2;
  }
  .order-lg-3 {
    -webkit-order: 3;
            order: 3;
  }
  .order-lg-4 {
    -webkit-order: 4;
            order: 4;
  }
  .order-lg-5 {
    -webkit-order: 5;
            order: 5;
  }
  .order-lg-6 {
    -webkit-order: 6;
            order: 6;
  }
  .order-lg-7 {
    -webkit-order: 7;
            order: 7;
  }
  .order-lg-8 {
    -webkit-order: 8;
            order: 8;
  }
  .order-lg-9 {
    -webkit-order: 9;
            order: 9;
  }
  .order-lg-10 {
    -webkit-order: 10;
            order: 10;
  }
  .order-lg-11 {
    -webkit-order: 11;
            order: 11;
  }
  .order-lg-12 {
    -webkit-order: 12;
            order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    -webkit-flex: 0 0 8.333333%;
            flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    -webkit-flex: 0 0 16.666667%;
            flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -webkit-flex: 0 0 33.333333%;
            flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    -webkit-flex: 0 0 41.666667%;
            flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -webkit-flex: 0 0 58.333333%;
            flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    -webkit-flex: 0 0 66.666667%;
            flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -webkit-flex: 0 0 83.333333%;
            flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    -webkit-flex: 0 0 91.666667%;
            flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -webkit-order: -1;
            order: -1;
  }
  .order-xl-last {
    -webkit-order: 13;
            order: 13;
  }
  .order-xl-0 {
    -webkit-order: 0;
            order: 0;
  }
  .order-xl-1 {
    -webkit-order: 1;
            order: 1;
  }
  .order-xl-2 {
    -webkit-order: 2;
            order: 2;
  }
  .order-xl-3 {
    -webkit-order: 3;
            order: 3;
  }
  .order-xl-4 {
    -webkit-order: 4;
            order: 4;
  }
  .order-xl-5 {
    -webkit-order: 5;
            order: 5;
  }
  .order-xl-6 {
    -webkit-order: 6;
            order: 6;
  }
  .order-xl-7 {
    -webkit-order: 7;
            order: 7;
  }
  .order-xl-8 {
    -webkit-order: 8;
            order: 8;
  }
  .order-xl-9 {
    -webkit-order: 9;
            order: 9;
  }
  .order-xl-10 {
    -webkit-order: 10;
            order: 10;
  }
  .order-xl-11 {
    -webkit-order: 11;
            order: 11;
  }
  .order-xl-12 {
    -webkit-order: 12;
            order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -webkit-flex !important;
  display: flex !important;
}

.d-inline-flex {
  display: -webkit-inline-flex !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -webkit-flex !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -webkit-flex !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -webkit-flex !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -webkit-flex !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -webkit-flex !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important;
  }
}

.flex-row {
  -webkit-flex-direction: row !important;
          flex-direction: row !important;
}

.flex-column {
  -webkit-flex-direction: column !important;
          flex-direction: column !important;
}

.flex-row-reverse {
  -webkit-flex-direction: row-reverse !important;
          flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -webkit-flex-direction: column-reverse !important;
          flex-direction: column-reverse !important;
}

.flex-wrap {
  -webkit-flex-wrap: wrap !important;
          flex-wrap: wrap !important;
}

.flex-nowrap {
  -webkit-flex-wrap: nowrap !important;
          flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -webkit-flex-wrap: wrap-reverse !important;
          flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  -webkit-justify-content: flex-start !important;
          justify-content: flex-start !important;
}

.justify-content-end {
  -webkit-justify-content: flex-end !important;
          justify-content: flex-end !important;
}

.justify-content-center {
  -webkit-justify-content: center !important;
          justify-content: center !important;
}

.justify-content-between {
  -webkit-justify-content: space-between !important;
          justify-content: space-between !important;
}

.justify-content-around {
  -webkit-justify-content: space-around !important;
          justify-content: space-around !important;
}

.align-items-start {
  -webkit-align-items: flex-start !important;
          align-items: flex-start !important;
}

.align-items-end {
  -webkit-align-items: flex-end !important;
          align-items: flex-end !important;
}

.align-items-center {
  -webkit-align-items: center !important;
          align-items: center !important;
}

.align-items-baseline {
  -webkit-align-items: baseline !important;
          align-items: baseline !important;
}

.align-items-stretch {
  -webkit-align-items: stretch !important;
          align-items: stretch !important;
}

.align-content-start {
  -webkit-align-content: flex-start !important;
          align-content: flex-start !important;
}

.align-content-end {
  -webkit-align-content: flex-end !important;
          align-content: flex-end !important;
}

.align-content-center {
  -webkit-align-content: center !important;
          align-content: center !important;
}

.align-content-between {
  -webkit-align-content: space-between !important;
          align-content: space-between !important;
}

.align-content-around {
  -webkit-align-content: space-around !important;
          align-content: space-around !important;
}

.align-content-stretch {
  -webkit-align-content: stretch !important;
          align-content: stretch !important;
}

.align-self-auto {
  -webkit-align-self: auto !important;
          align-self: auto !important;
}

.align-self-start {
  -webkit-align-self: flex-start !important;
          align-self: flex-start !important;
}

.align-self-end {
  -webkit-align-self: flex-end !important;
          align-self: flex-end !important;
}

.align-self-center {
  -webkit-align-self: center !important;
          align-self: center !important;
}

.align-self-baseline {
  -webkit-align-self: baseline !important;
          align-self: baseline !important;
}

.align-self-stretch {
  -webkit-align-self: stretch !important;
          align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important;
  }
  .flex-sm-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -webkit-justify-content: center !important;
            justify-content: center !important;
  }
  .justify-content-sm-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important;
  }
  .align-items-sm-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important;
  }
  .align-items-sm-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important;
  }
  .align-items-sm-center {
    -webkit-align-items: center !important;
            align-items: center !important;
  }
  .align-items-sm-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important;
  }
  .align-content-sm-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important;
  }
  .align-content-sm-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important;
  }
  .align-content-sm-center {
    -webkit-align-content: center !important;
            align-content: center !important;
  }
  .align-content-sm-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important;
  }
  .align-content-sm-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important;
  }
  .align-self-sm-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important;
  }
  .align-self-sm-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important;
  }
  .align-self-sm-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important;
  }
  .align-self-sm-center {
    -webkit-align-self: center !important;
            align-self: center !important;
  }
  .align-self-sm-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important;
  }
  .flex-md-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -webkit-justify-content: center !important;
            justify-content: center !important;
  }
  .justify-content-md-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important;
  }
  .justify-content-md-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important;
  }
  .align-items-md-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important;
  }
  .align-items-md-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important;
  }
  .align-items-md-center {
    -webkit-align-items: center !important;
            align-items: center !important;
  }
  .align-items-md-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important;
  }
  .align-items-md-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important;
  }
  .align-content-md-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important;
  }
  .align-content-md-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important;
  }
  .align-content-md-center {
    -webkit-align-content: center !important;
            align-content: center !important;
  }
  .align-content-md-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important;
  }
  .align-content-md-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important;
  }
  .align-content-md-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important;
  }
  .align-self-md-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important;
  }
  .align-self-md-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important;
  }
  .align-self-md-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important;
  }
  .align-self-md-center {
    -webkit-align-self: center !important;
            align-self: center !important;
  }
  .align-self-md-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important;
  }
  .align-self-md-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important;
  }
  .flex-lg-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -webkit-justify-content: center !important;
            justify-content: center !important;
  }
  .justify-content-lg-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important;
  }
  .align-items-lg-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important;
  }
  .align-items-lg-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important;
  }
  .align-items-lg-center {
    -webkit-align-items: center !important;
            align-items: center !important;
  }
  .align-items-lg-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important;
  }
  .align-content-lg-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important;
  }
  .align-content-lg-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important;
  }
  .align-content-lg-center {
    -webkit-align-content: center !important;
            align-content: center !important;
  }
  .align-content-lg-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important;
  }
  .align-content-lg-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important;
  }
  .align-self-lg-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important;
  }
  .align-self-lg-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important;
  }
  .align-self-lg-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important;
  }
  .align-self-lg-center {
    -webkit-align-self: center !important;
            align-self: center !important;
  }
  .align-self-lg-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important;
  }
  .flex-xl-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -webkit-justify-content: center !important;
            justify-content: center !important;
  }
  .justify-content-xl-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important;
  }
  .align-items-xl-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important;
  }
  .align-items-xl-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important;
  }
  .align-items-xl-center {
    -webkit-align-items: center !important;
            align-items: center !important;
  }
  .align-items-xl-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important;
  }
  .align-content-xl-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important;
  }
  .align-content-xl-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important;
  }
  .align-content-xl-center {
    -webkit-align-content: center !important;
            align-content: center !important;
  }
  .align-content-xl-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important;
  }
  .align-content-xl-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important;
  }
  .align-self-xl-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important;
  }
  .align-self-xl-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important;
  }
  .align-self-xl-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important;
  }
  .align-self-xl-center {
    -webkit-align-self: center !important;
            align-self: center !important;
  }
  .align-self-xl-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important;
  }
}
/*# sourceMappingURL=bootstrap-grid.css.map */
/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #007bff;
    --secondary: #6c757d;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: transparent;
}

@-ms-viewport {
    width: device-width;
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
}

[tabindex="-1"]:focus {
    outline: 0 !important;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
}

ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0;
}

dt {
    font-weight: 700;
}

dd {
    margin-bottom: .5rem;
    margin-left: 0;
}

blockquote {
    margin: 0 0 1rem;
}

dfn {
    font-style: italic;
}

b,
strong {
    font-weight: bolder;
}

small {
    font-size: 80%;
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

sub {
    bottom: -.25em;
}

sup {
    top: -.5em;
}

a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}

a:hover {
    color: #0056b3;
    text-decoration: underline;
}

a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
}

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
    outline: 0;
}

pre,
code,
kbd,
samp {
    font-family: monospace, monospace;
    font-size: 1em;
}

pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar;
}

figure {
    margin: 0 0 1rem;
}

img {
    vertical-align: middle;
    border-style: none;
}

svg:not(:root) {
    overflow: hidden;
}

table {
    border-collapse: collapse;
}

caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #6c757d;
    text-align: left;
    caption-side: bottom;
}

th {
    text-align: inherit;
}

label {
    display: inline-block;
    margin-bottom: .5rem;
}

button {
    border-radius: 0;
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
    -webkit-appearance: listbox;
}

textarea {
    overflow: auto;
    resize: vertical;
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
}

progress {
    vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

[type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}

output {
    display: inline-block;
}

summary {
    display: list-item;
    cursor: pointer;
}

template {
    display: none;
}

[hidden] {
    display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
}

h1,
.h1 {
    font-size: 2.5rem;
}

h2,
.h2 {
    font-size: 2rem;
}

h3,
.h3 {
    font-size: 1.75rem;
}

h4,
.h4 {
    font-size: 1.5rem;
}

h5,
.h5 {
    font-size: 1.25rem;
}

h6,
.h6 {
    font-size: 1rem;
}

.lead {
    font-size: 1.25rem;
    font-weight: 300;
}

.display-1 {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.2;
}

.display-2 {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.2;
}

.display-3 {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.2;
}

.display-4 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2;
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
    font-size: 80%;
    font-weight: 400;
}

mark,
.mark {
    padding: 0.2em;
    background-color: #fcf8e3;
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
}

.list-inline {
    padding-left: 0;
    list-style: none;
}

.list-inline-item {
    display: inline-block;
}

.list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
}

.initialism {
    font-size: 90%;
    text-transform: uppercase;
}

.blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem;
}

.blockquote-footer {
    display: block;
    font-size: 80%;
    color: #6c757d;
}

.blockquote-footer::before {
    content: "\2014 \00A0";
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.img-thumbnail {
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    max-width: 100%;
    height: auto;
}

.figure {
    display: inline-block;
}

.figure-img {
    margin-bottom: 0.5rem;
    line-height: 1;
}

.figure-caption {
    font-size: 90%;
    color: #6c757d;
}

code,
kbd,
pre,
samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

code {
    font-size: 87.5%;
    color: #e83e8c;
    word-break: break-word;
}

a>code {
    color: inherit;
}

kbd {
    padding: 0.2rem 0.4rem;
    font-size: 87.5%;
    color: #fff;
    background-color: #212529;
    border-radius: 0.2rem;
}

kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700;
}

pre {
    display: block;
    font-size: 87.5%;
    color: #212529;
}

pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
}

.pre-scrollable {
    max-height: 340px;
    overflow-y: scroll;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}

.container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.row {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.no-gutters {
    margin-right: 0;
    margin-left: 0;
}

.no-gutters>.col,
.no-gutters>[class*="col-"] {
    padding-right: 0;
    padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%;
}

.col-auto {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
}

.col-1 {
    -webkit-flex: 0 0 8.333333%;
            flex: 0 0 8.333333%;
    max-width: 8.333333%;
}

.col-2 {
    -webkit-flex: 0 0 16.666667%;
            flex: 0 0 16.666667%;
    max-width: 16.666667%;
}

.col-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
}

.col-4 {
    -webkit-flex: 0 0 33.333333%;
            flex: 0 0 33.333333%;
    max-width: 33.333333%;
}

.col-5 {
    -webkit-flex: 0 0 41.666667%;
            flex: 0 0 41.666667%;
    max-width: 41.666667%;
}

.col-6 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
}

.col-7 {
    -webkit-flex: 0 0 58.333333%;
            flex: 0 0 58.333333%;
    max-width: 58.333333%;
}

.col-8 {
    -webkit-flex: 0 0 66.666667%;
            flex: 0 0 66.666667%;
    max-width: 66.666667%;
}

.col-9 {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
}

.col-10 {
    -webkit-flex: 0 0 83.333333%;
            flex: 0 0 83.333333%;
    max-width: 83.333333%;
}

.col-11 {
    -webkit-flex: 0 0 91.666667%;
            flex: 0 0 91.666667%;
    max-width: 91.666667%;
}

.col-12 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
}

.order-first {
    -webkit-order: -1;
            order: -1;
}

.order-last {
    -webkit-order: 13;
            order: 13;
}

.order-0 {
    -webkit-order: 0;
            order: 0;
}

.order-1 {
    -webkit-order: 1;
            order: 1;
}

.order-2 {
    -webkit-order: 2;
            order: 2;
}

.order-3 {
    -webkit-order: 3;
            order: 3;
}

.order-4 {
    -webkit-order: 4;
            order: 4;
}

.order-5 {
    -webkit-order: 5;
            order: 5;
}

.order-6 {
    -webkit-order: 6;
            order: 6;
}

.order-7 {
    -webkit-order: 7;
            order: 7;
}

.order-8 {
    -webkit-order: 8;
            order: 8;
}

.order-9 {
    -webkit-order: 9;
            order: 9;
}

.order-10 {
    -webkit-order: 10;
            order: 10;
}

.order-11 {
    -webkit-order: 11;
            order: 11;
}

.order-12 {
    -webkit-order: 12;
            order: 12;
}

.offset-1 {
    margin-left: 8.333333%;
}

.offset-2 {
    margin-left: 16.666667%;
}

.offset-3 {
    margin-left: 25%;
}

.offset-4 {
    margin-left: 33.333333%;
}

.offset-5 {
    margin-left: 41.666667%;
}

.offset-6 {
    margin-left: 50%;
}

.offset-7 {
    margin-left: 58.333333%;
}

.offset-8 {
    margin-left: 66.666667%;
}

.offset-9 {
    margin-left: 75%;
}

.offset-10 {
    margin-left: 83.333333%;
}

.offset-11 {
    margin-left: 91.666667%;
}

@media (min-width: 576px) {
    .col-sm {
        -webkit-flex-basis: 0;
                flex-basis: 0;
        -webkit-flex-grow: 1;
                flex-grow: 1;
        max-width: 100%;
    }

    .col-sm-auto {
        -webkit-flex: 0 0 auto;
                flex: 0 0 auto;
        width: auto;
        max-width: none;
    }

    .col-sm-1 {
        -webkit-flex: 0 0 8.333333%;
                flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }

    .col-sm-2 {
        -webkit-flex: 0 0 16.666667%;
                flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }

    .col-sm-3 {
        -webkit-flex: 0 0 25%;
                flex: 0 0 25%;
        max-width: 25%;
    }

    .col-sm-4 {
        -webkit-flex: 0 0 33.333333%;
                flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .col-sm-5 {
        -webkit-flex: 0 0 41.666667%;
                flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    .col-sm-6 {
        -webkit-flex: 0 0 50%;
                flex: 0 0 50%;
        max-width: 50%;
    }

    .col-sm-7 {
        -webkit-flex: 0 0 58.333333%;
                flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }

    .col-sm-8 {
        -webkit-flex: 0 0 66.666667%;
                flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }

    .col-sm-9 {
        -webkit-flex: 0 0 75%;
                flex: 0 0 75%;
        max-width: 75%;
    }

    .col-sm-10 {
        -webkit-flex: 0 0 83.333333%;
                flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }

    .col-sm-11 {
        -webkit-flex: 0 0 91.666667%;
                flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }

    .col-sm-12 {
        -webkit-flex: 0 0 100%;
                flex: 0 0 100%;
        max-width: 100%;
    }

    .order-sm-first {
        -webkit-order: -1;
                order: -1;
    }

    .order-sm-last {
        -webkit-order: 13;
                order: 13;
    }

    .order-sm-0 {
        -webkit-order: 0;
                order: 0;
    }

    .order-sm-1 {
        -webkit-order: 1;
                order: 1;
    }

    .order-sm-2 {
        -webkit-order: 2;
                order: 2;
    }

    .order-sm-3 {
        -webkit-order: 3;
                order: 3;
    }

    .order-sm-4 {
        -webkit-order: 4;
                order: 4;
    }

    .order-sm-5 {
        -webkit-order: 5;
                order: 5;
    }

    .order-sm-6 {
        -webkit-order: 6;
                order: 6;
    }

    .order-sm-7 {
        -webkit-order: 7;
                order: 7;
    }

    .order-sm-8 {
        -webkit-order: 8;
                order: 8;
    }

    .order-sm-9 {
        -webkit-order: 9;
                order: 9;
    }

    .order-sm-10 {
        -webkit-order: 10;
                order: 10;
    }

    .order-sm-11 {
        -webkit-order: 11;
                order: 11;
    }

    .order-sm-12 {
        -webkit-order: 12;
                order: 12;
    }

    .offset-sm-0 {
        margin-left: 0;
    }

    .offset-sm-1 {
        margin-left: 8.333333%;
    }

    .offset-sm-2 {
        margin-left: 16.666667%;
    }

    .offset-sm-3 {
        margin-left: 25%;
    }

    .offset-sm-4 {
        margin-left: 33.333333%;
    }

    .offset-sm-5 {
        margin-left: 41.666667%;
    }

    .offset-sm-6 {
        margin-left: 50%;
    }

    .offset-sm-7 {
        margin-left: 58.333333%;
    }

    .offset-sm-8 {
        margin-left: 66.666667%;
    }

    .offset-sm-9 {
        margin-left: 75%;
    }

    .offset-sm-10 {
        margin-left: 83.333333%;
    }

    .offset-sm-11 {
        margin-left: 91.666667%;
    }
}

@media (min-width: 768px) {
    .col-md {
        -webkit-flex-basis: 0;
                flex-basis: 0;
        -webkit-flex-grow: 1;
                flex-grow: 1;
        max-width: 100%;
    }

    .col-md-auto {
        -webkit-flex: 0 0 auto;
                flex: 0 0 auto;
        width: auto;
        max-width: none;
    }

    .col-md-1 {
        -webkit-flex: 0 0 8.333333%;
                flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }

    .col-md-2 {
        -webkit-flex: 0 0 16.666667%;
                flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }

    .col-md-3 {
        -webkit-flex: 0 0 25%;
                flex: 0 0 25%;
        max-width: 25%;
    }

    .col-md-4 {
        -webkit-flex: 0 0 33.333333%;
                flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .col-md-5 {
        -webkit-flex: 0 0 41.666667%;
                flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    .col-md-6 {
        -webkit-flex: 0 0 50%;
                flex: 0 0 50%;
        max-width: 50%;
    }

    .col-md-7 {
        -webkit-flex: 0 0 58.333333%;
                flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }

    .col-md-8 {
        -webkit-flex: 0 0 66.666667%;
                flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }

    .col-md-9 {
        -webkit-flex: 0 0 75%;
                flex: 0 0 75%;
        max-width: 75%;
    }

    .col-md-10 {
        -webkit-flex: 0 0 83.333333%;
                flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }

    .col-md-11 {
        -webkit-flex: 0 0 91.666667%;
                flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }

    .col-md-12 {
        -webkit-flex: 0 0 100%;
                flex: 0 0 100%;
        max-width: 100%;
    }

    .order-md-first {
        -webkit-order: -1;
                order: -1;
    }

    .order-md-last {
        -webkit-order: 13;
                order: 13;
    }

    .order-md-0 {
        -webkit-order: 0;
                order: 0;
    }

    .order-md-1 {
        -webkit-order: 1;
                order: 1;
    }

    .order-md-2 {
        -webkit-order: 2;
                order: 2;
    }

    .order-md-3 {
        -webkit-order: 3;
                order: 3;
    }

    .order-md-4 {
        -webkit-order: 4;
                order: 4;
    }

    .order-md-5 {
        -webkit-order: 5;
                order: 5;
    }

    .order-md-6 {
        -webkit-order: 6;
                order: 6;
    }

    .order-md-7 {
        -webkit-order: 7;
                order: 7;
    }

    .order-md-8 {
        -webkit-order: 8;
                order: 8;
    }

    .order-md-9 {
        -webkit-order: 9;
                order: 9;
    }

    .order-md-10 {
        -webkit-order: 10;
                order: 10;
    }

    .order-md-11 {
        -webkit-order: 11;
                order: 11;
    }

    .order-md-12 {
        -webkit-order: 12;
                order: 12;
    }

    .offset-md-0 {
        margin-left: 0;
    }

    .offset-md-1 {
        margin-left: 8.333333%;
    }

    .offset-md-2 {
        margin-left: 16.666667%;
    }

    .offset-md-3 {
        margin-left: 25%;
    }

    .offset-md-4 {
        margin-left: 33.333333%;
    }

    .offset-md-5 {
        margin-left: 41.666667%;
    }

    .offset-md-6 {
        margin-left: 50%;
    }

    .offset-md-7 {
        margin-left: 58.333333%;
    }

    .offset-md-8 {
        margin-left: 66.666667%;
    }

    .offset-md-9 {
        margin-left: 75%;
    }

    .offset-md-10 {
        margin-left: 83.333333%;
    }

    .offset-md-11 {
        margin-left: 91.666667%;
    }
}

@media (min-width: 992px) {
    .col-lg {
        -webkit-flex-basis: 0;
                flex-basis: 0;
        -webkit-flex-grow: 1;
                flex-grow: 1;
        max-width: 100%;
    }

    .col-lg-auto {
        -webkit-flex: 0 0 auto;
                flex: 0 0 auto;
        width: auto;
        max-width: none;
    }

    .col-lg-1 {
        -webkit-flex: 0 0 8.333333%;
                flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }

    .col-lg-2 {
        -webkit-flex: 0 0 16.666667%;
                flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }

    .col-lg-3 {
        -webkit-flex: 0 0 25%;
                flex: 0 0 25%;
        max-width: 25%;
    }

    .col-lg-4 {
        -webkit-flex: 0 0 33.333333%;
                flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .col-lg-5 {
        -webkit-flex: 0 0 41.666667%;
                flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    .col-lg-6 {
        -webkit-flex: 0 0 50%;
                flex: 0 0 50%;
        max-width: 50%;
    }

    .col-lg-7 {
        -webkit-flex: 0 0 58.333333%;
                flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }

    .col-lg-8 {
        -webkit-flex: 0 0 66.666667%;
                flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }

    .col-lg-9 {
        -webkit-flex: 0 0 75%;
                flex: 0 0 75%;
        max-width: 75%;
    }

    .col-lg-10 {
        -webkit-flex: 0 0 83.333333%;
                flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }

    .col-lg-11 {
        -webkit-flex: 0 0 91.666667%;
                flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }

    .col-lg-12 {
        -webkit-flex: 0 0 100%;
                flex: 0 0 100%;
        max-width: 100%;
    }

    .order-lg-first {
        -webkit-order: -1;
                order: -1;
    }

    .order-lg-last {
        -webkit-order: 13;
                order: 13;
    }

    .order-lg-0 {
        -webkit-order: 0;
                order: 0;
    }

    .order-lg-1 {
        -webkit-order: 1;
                order: 1;
    }

    .order-lg-2 {
        -webkit-order: 2;
                order: 2;
    }

    .order-lg-3 {
        -webkit-order: 3;
                order: 3;
    }

    .order-lg-4 {
        -webkit-order: 4;
                order: 4;
    }

    .order-lg-5 {
        -webkit-order: 5;
                order: 5;
    }

    .order-lg-6 {
        -webkit-order: 6;
                order: 6;
    }

    .order-lg-7 {
        -webkit-order: 7;
                order: 7;
    }

    .order-lg-8 {
        -webkit-order: 8;
                order: 8;
    }

    .order-lg-9 {
        -webkit-order: 9;
                order: 9;
    }

    .order-lg-10 {
        -webkit-order: 10;
                order: 10;
    }

    .order-lg-11 {
        -webkit-order: 11;
                order: 11;
    }

    .order-lg-12 {
        -webkit-order: 12;
                order: 12;
    }

    .offset-lg-0 {
        margin-left: 0;
    }

    .offset-lg-1 {
        margin-left: 8.333333%;
    }

    .offset-lg-2 {
        margin-left: 16.666667%;
    }

    .offset-lg-3 {
        margin-left: 25%;
    }

    .offset-lg-4 {
        margin-left: 33.333333%;
    }

    .offset-lg-5 {
        margin-left: 41.666667%;
    }

    .offset-lg-6 {
        margin-left: 50%;
    }

    .offset-lg-7 {
        margin-left: 58.333333%;
    }

    .offset-lg-8 {
        margin-left: 66.666667%;
    }

    .offset-lg-9 {
        margin-left: 75%;
    }

    .offset-lg-10 {
        margin-left: 83.333333%;
    }

    .offset-lg-11 {
        margin-left: 91.666667%;
    }
}

@media (min-width: 1200px) {
    .col-xl {
        -webkit-flex-basis: 0;
                flex-basis: 0;
        -webkit-flex-grow: 1;
                flex-grow: 1;
        max-width: 100%;
    }

    .col-xl-auto {
        -webkit-flex: 0 0 auto;
                flex: 0 0 auto;
        width: auto;
        max-width: none;
    }

    .col-xl-1 {
        -webkit-flex: 0 0 8.333333%;
                flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }

    .col-xl-2 {
        -webkit-flex: 0 0 16.666667%;
                flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }

    .col-xl-3 {
        -webkit-flex: 0 0 25%;
                flex: 0 0 25%;
        max-width: 25%;
    }

    .col-xl-4 {
        -webkit-flex: 0 0 33.333333%;
                flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .col-xl-5 {
        -webkit-flex: 0 0 41.666667%;
                flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    .col-xl-6 {
        -webkit-flex: 0 0 50%;
                flex: 0 0 50%;
        max-width: 50%;
    }

    .col-xl-7 {
        -webkit-flex: 0 0 58.333333%;
                flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }

    .col-xl-8 {
        -webkit-flex: 0 0 66.666667%;
                flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }

    .col-xl-9 {
        -webkit-flex: 0 0 75%;
                flex: 0 0 75%;
        max-width: 75%;
    }

    .col-xl-10 {
        -webkit-flex: 0 0 83.333333%;
                flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }

    .col-xl-11 {
        -webkit-flex: 0 0 91.666667%;
                flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }

    .col-xl-12 {
        -webkit-flex: 0 0 100%;
                flex: 0 0 100%;
        max-width: 100%;
    }

    .order-xl-first {
        -webkit-order: -1;
                order: -1;
    }

    .order-xl-last {
        -webkit-order: 13;
                order: 13;
    }

    .order-xl-0 {
        -webkit-order: 0;
                order: 0;
    }

    .order-xl-1 {
        -webkit-order: 1;
                order: 1;
    }

    .order-xl-2 {
        -webkit-order: 2;
                order: 2;
    }

    .order-xl-3 {
        -webkit-order: 3;
                order: 3;
    }

    .order-xl-4 {
        -webkit-order: 4;
                order: 4;
    }

    .order-xl-5 {
        -webkit-order: 5;
                order: 5;
    }

    .order-xl-6 {
        -webkit-order: 6;
                order: 6;
    }

    .order-xl-7 {
        -webkit-order: 7;
                order: 7;
    }

    .order-xl-8 {
        -webkit-order: 8;
                order: 8;
    }

    .order-xl-9 {
        -webkit-order: 9;
                order: 9;
    }

    .order-xl-10 {
        -webkit-order: 10;
                order: 10;
    }

    .order-xl-11 {
        -webkit-order: 11;
                order: 11;
    }

    .order-xl-12 {
        -webkit-order: 12;
                order: 12;
    }

    .offset-xl-0 {
        margin-left: 0;
    }

    .offset-xl-1 {
        margin-left: 8.333333%;
    }

    .offset-xl-2 {
        margin-left: 16.666667%;
    }

    .offset-xl-3 {
        margin-left: 25%;
    }

    .offset-xl-4 {
        margin-left: 33.333333%;
    }

    .offset-xl-5 {
        margin-left: 41.666667%;
    }

    .offset-xl-6 {
        margin-left: 50%;
    }

    .offset-xl-7 {
        margin-left: 58.333333%;
    }

    .offset-xl-8 {
        margin-left: 66.666667%;
    }

    .offset-xl-9 {
        margin-left: 75%;
    }

    .offset-xl-10 {
        margin-left: 83.333333%;
    }

    .offset-xl-11 {
        margin-left: 91.666667%;
    }
}

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
}

.table th,
.table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
}

.table tbody+tbody {
    border-top: 2px solid #dee2e6;
}

.table .table {
    background-color: #fff;
}

.table-sm th,
.table-sm td {
    padding: 0.3rem;
}

.table-bordered {
    border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
    border-bottom-width: 2px;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary>th,
.table-primary>td {
    background-color: #b8daff;
}

.table-hover .table-primary:hover {
    background-color: #9fcdff;
}

.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
    background-color: #9fcdff;
}

.table-secondary,
.table-secondary>th,
.table-secondary>td {
    background-color: #d6d8db;
}

.table-hover .table-secondary:hover {
    background-color: #c8cbcf;
}

.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
    background-color: #c8cbcf;
}

.table-success,
.table-success>th,
.table-success>td {
    background-color: #c3e6cb;
}

.table-hover .table-success:hover {
    background-color: #b1dfbb;
}

.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
    background-color: #b1dfbb;
}

.table-info,
.table-info>th,
.table-info>td {
    background-color: #bee5eb;
}

.table-hover .table-info:hover {
    background-color: #abdde5;
}

.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
    background-color: #abdde5;
}

.table-warning,
.table-warning>th,
.table-warning>td {
    background-color: #ffeeba;
}

.table-hover .table-warning:hover {
    background-color: #ffe8a1;
}

.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
    background-color: #ffe8a1;
}

.table-danger,
.table-danger>th,
.table-danger>td {
    background-color: #f5c6cb;
}

.table-hover .table-danger:hover {
    background-color: #f1b0b7;
}

.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
    background-color: #f1b0b7;
}

.table-light,
.table-light>th,
.table-light>td {
    background-color: #fdfdfe;
}

.table-hover .table-light:hover {
    background-color: #ececf6;
}

.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
    background-color: #ececf6;
}

.table-dark,
.table-dark>th,
.table-dark>td {
    background-color: #c6c8ca;
}

.table-hover .table-dark:hover {
    background-color: #b9bbbe;
}

.table-hover .table-dark:hover>td,
.table-hover .table-dark:hover>th {
    background-color: #b9bbbe;
}

.table-active,
.table-active>th,
.table-active>td {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
    background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
    color: #fff;
    background-color: #212529;
    border-color: #32383e;
}

.table .thead-light th {
    color: #495057;
    background-color: #e9ecef;
    border-color: #dee2e6;
}

.table-dark {
    color: #fff;
    background-color: #212529;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
    border-color: #32383e;
}

.table-dark.table-bordered {
    border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
    .table-responsive-sm {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    .table-responsive-sm>.table-bordered {
        border: 0;
    }
}

@media (max-width: 767.98px) {
    .table-responsive-md {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    .table-responsive-md>.table-bordered {
        border: 0;
    }
}

@media (max-width: 991.98px) {
    .table-responsive-lg {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    .table-responsive-lg>.table-bordered {
        border: 0;
    }
}

@media (max-width: 1199.98px) {
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    .table-responsive-xl>.table-bordered {
        border: 0;
    }
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive>.table-bordered {
    border: 0;
}

.form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control::-ms-expand {
    background-color: transparent;
    border: 0;
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1;
}

.form-control:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1;
}

.form-control::placeholder {
    color: #6c757d;
    opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
}

select.form-control:not([size]):not([multiple]) {
    height: calc(2.25rem + 2px);
}

select.form-control:focus::-ms-value {
    color: #495057;
    background-color: #fff;
}

.form-control-file,
.form-control-range {
    display: block;
    width: 100%;
}

.col-form-label {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
}

.col-form-label-lg {
    padding-top: calc(0.5rem + 1px);
    padding-bottom: calc(0.5rem + 1px);
    font-size: 1.25rem;
    line-height: 1.5;
}

.col-form-label-sm {
    padding-top: calc(0.25rem + 1px);
    padding-bottom: calc(0.25rem + 1px);
    font-size: 0.875rem;
    line-height: 1.5;
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    margin-bottom: 0;
    line-height: 1.5;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.input-group-sm>.form-control-plaintext.form-control,
.input-group-sm>.input-group-prepend>.form-control-plaintext.input-group-text,
.input-group-sm>.input-group-append>.form-control-plaintext.input-group-text,
.input-group-sm>.input-group-prepend>.form-control-plaintext.btn,
.input-group-sm>.input-group-append>.form-control-plaintext.btn,
.form-control-plaintext.form-control-lg,
.input-group-lg>.form-control-plaintext.form-control,
.input-group-lg>.input-group-prepend>.form-control-plaintext.input-group-text,
.input-group-lg>.input-group-append>.form-control-plaintext.input-group-text,
.input-group-lg>.input-group-prepend>.form-control-plaintext.btn,
.input-group-lg>.input-group-append>.form-control-plaintext.btn {
    padding-right: 0;
    padding-left: 0;
}

.form-control-sm,
.input-group-sm>.form-control,
.input-group-sm>.input-group-prepend>.input-group-text,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-append>.btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

select.form-control-sm:not([size]):not([multiple]),
.input-group-sm>select.form-control:not([size]):not([multiple]),
.input-group-sm>.input-group-prepend>select.input-group-text:not([size]):not([multiple]),
.input-group-sm>.input-group-append>select.input-group-text:not([size]):not([multiple]),
.input-group-sm>.input-group-prepend>select.btn:not([size]):not([multiple]),
.input-group-sm>.input-group-append>select.btn:not([size]):not([multiple]) {
    height: calc(1.8125rem + 2px);
}

.form-control-lg,
.input-group-lg>.form-control,
.input-group-lg>.input-group-prepend>.input-group-text,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-append>.btn {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

select.form-control-lg:not([size]):not([multiple]),
.input-group-lg>select.form-control:not([size]):not([multiple]),
.input-group-lg>.input-group-prepend>select.input-group-text:not([size]):not([multiple]),
.input-group-lg>.input-group-append>select.input-group-text:not([size]):not([multiple]),
.input-group-lg>.input-group-prepend>select.btn:not([size]):not([multiple]),
.input-group-lg>.input-group-append>select.btn:not([size]):not([multiple]) {
    height: calc(2.875rem + 2px);
}

.form-group {
    margin-bottom: 1rem;
}

.form-text {
    display: block;
    margin-top: 0.25rem;
}

.form-row {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}

.form-row>.col,
.form-row>[class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
}

.form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
}

.form-check-input {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem;
}

.form-check-input:disabled~.form-check-label {
    color: #6c757d;
}

.form-check-label {
    margin-bottom: 0;
}

.form-check-inline {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
    padding-left: 0;
    margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0;
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #28a745;
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .5rem;
    margin-top: .1rem;
    font-size: .875rem;
    line-height: 1;
    color: #fff;
    background-color: rgba(40, 167, 69, 0.8);
    border-radius: .2rem;
}

.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated .custom-select:valid,
.custom-select.is-valid {
    border-color: #28a745;
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-control:valid~.valid-feedback,
.was-validated .form-control:valid~.valid-tooltip,
.form-control.is-valid~.valid-feedback,
.form-control.is-valid~.valid-tooltip,
.was-validated .custom-select:valid~.valid-feedback,
.was-validated .custom-select:valid~.valid-tooltip,
.custom-select.is-valid~.valid-feedback,
.custom-select.is-valid~.valid-tooltip {
    display: block;
}

.was-validated .form-check-input:valid~.form-check-label,
.form-check-input.is-valid~.form-check-label {
    color: #28a745;
}

.was-validated .form-check-input:valid~.valid-feedback,
.was-validated .form-check-input:valid~.valid-tooltip,
.form-check-input.is-valid~.valid-feedback,
.form-check-input.is-valid~.valid-tooltip {
    display: block;
}

.was-validated .custom-control-input:valid~.custom-control-label,
.custom-control-input.is-valid~.custom-control-label {
    color: #28a745;
}

.was-validated .custom-control-input:valid~.custom-control-label::before,
.custom-control-input.is-valid~.custom-control-label::before {
    background-color: #71dd8a;
}

.was-validated .custom-control-input:valid~.valid-feedback,
.was-validated .custom-control-input:valid~.valid-tooltip,
.custom-control-input.is-valid~.valid-feedback,
.custom-control-input.is-valid~.valid-tooltip {
    display: block;
}

.was-validated .custom-control-input:valid:checked~.custom-control-label::before,
.custom-control-input.is-valid:checked~.custom-control-label::before {
    background-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus~.custom-control-label::before,
.custom-control-input.is-valid:focus~.custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-file-input:valid~.custom-file-label,
.custom-file-input.is-valid~.custom-file-label {
    border-color: #28a745;
}

.was-validated .custom-file-input:valid~.custom-file-label::before,
.custom-file-input.is-valid~.custom-file-label::before {
    border-color: inherit;
}

.was-validated .custom-file-input:valid~.valid-feedback,
.was-validated .custom-file-input:valid~.valid-tooltip,
.custom-file-input.is-valid~.valid-feedback,
.custom-file-input.is-valid~.valid-tooltip {
    display: block;
}

.was-validated .custom-file-input:valid:focus~.custom-file-label,
.custom-file-input.is-valid:focus~.custom-file-label {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .5rem;
    margin-top: .1rem;
    font-size: .875rem;
    line-height: 1;
    color: #fff;
    background-color: rgba(220, 53, 69, 0.8);
    border-radius: .2rem;
}

.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
    border-color: #dc3545;
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-control:invalid~.invalid-feedback,
.was-validated .form-control:invalid~.invalid-tooltip,
.form-control.is-invalid~.invalid-feedback,
.form-control.is-invalid~.invalid-tooltip,
.was-validated .custom-select:invalid~.invalid-feedback,
.was-validated .custom-select:invalid~.invalid-tooltip,
.custom-select.is-invalid~.invalid-feedback,
.custom-select.is-invalid~.invalid-tooltip {
    display: block;
}

.was-validated .form-check-input:invalid~.form-check-label,
.form-check-input.is-invalid~.form-check-label {
    color: #dc3545;
}

.was-validated .form-check-input:invalid~.invalid-feedback,
.was-validated .form-check-input:invalid~.invalid-tooltip,
.form-check-input.is-invalid~.invalid-feedback,
.form-check-input.is-invalid~.invalid-tooltip {
    display: block;
}

.was-validated .custom-control-input:invalid~.custom-control-label,
.custom-control-input.is-invalid~.custom-control-label {
    color: #dc3545;
}

.was-validated .custom-control-input:invalid~.custom-control-label::before,
.custom-control-input.is-invalid~.custom-control-label::before {
    background-color: #efa2a9;
}

.was-validated .custom-control-input:invalid~.invalid-feedback,
.was-validated .custom-control-input:invalid~.invalid-tooltip,
.custom-control-input.is-invalid~.invalid-feedback,
.custom-control-input.is-invalid~.invalid-tooltip {
    display: block;
}

.was-validated .custom-control-input:invalid:checked~.custom-control-label::before,
.custom-control-input.is-invalid:checked~.custom-control-label::before {
    background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus~.custom-control-label::before,
.custom-control-input.is-invalid:focus~.custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-file-input:invalid~.custom-file-label,
.custom-file-input.is-invalid~.custom-file-label {
    border-color: #dc3545;
}

.was-validated .custom-file-input:invalid~.custom-file-label::before,
.custom-file-input.is-invalid~.custom-file-label::before {
    border-color: inherit;
}

.was-validated .custom-file-input:invalid~.invalid-feedback,
.was-validated .custom-file-input:invalid~.invalid-tooltip,
.custom-file-input.is-invalid~.invalid-feedback,
.custom-file-input.is-invalid~.invalid-tooltip {
    display: block;
}

.was-validated .custom-file-input:invalid:focus~.custom-file-label,
.custom-file-input.is-invalid:focus~.custom-file-label {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-align-items: center;
            align-items: center;
}

.form-inline .form-check {
    width: 100%;
}

@media (min-width: 576px) {
    .form-inline label {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
        margin-bottom: 0;
    }

    .form-inline .form-group {
        display: -webkit-flex;
        display: flex;
        -webkit-flex: 0 0 auto;
                flex: 0 0 auto;
        -webkit-flex-flow: row wrap;
                flex-flow: row wrap;
        -webkit-align-items: center;
                align-items: center;
        margin-bottom: 0;
    }

    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }

    .form-inline .form-control-plaintext {
        display: inline-block;
    }

    .form-inline .input-group {
        width: auto;
    }

    .form-inline .form-check {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
        width: auto;
        padding-left: 0;
    }

    .form-inline .form-check-input {
        position: relative;
        margin-top: 0;
        margin-right: 0.25rem;
        margin-left: 0;
    }

    .form-inline .custom-control {
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
    }

    .form-inline .custom-control-label {
        margin-bottom: 0;
    }
}

.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn:hover,
.btn:focus {
    text-decoration: none;
}

.btn:focus,
.btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn.disabled,
.btn:disabled {
    opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.btn:not(:disabled):not(.disabled):active,
.btn:not(:disabled):not(.disabled).active {
    background-image: none;
}

a.btn.disabled,
fieldset:disabled a.btn {
    pointer-events: none;
}

.btn-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
}

.btn-primary:focus,
.btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
}

.btn-secondary:focus,
.btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show>.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}

.btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
}

.btn-success:focus,
.btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show>.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-info {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
}

.btn-info:focus,
.btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show>.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-warning {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}

.btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
}

.btn-warning:focus,
.btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show>.btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
}

.btn-danger:focus,
.btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show>.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-light {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
}

.btn-light:focus,
.btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show>.btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-dark {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

.btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
}

.btn-dark:focus,
.btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show>.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-primary {
    color: #007bff;
    background-color: transparent;
    background-image: none;
    border-color: #007bff;
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
    color: #6c757d;
    background-color: transparent;
    background-image: none;
    border-color: #6c757d;
}

.btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show>.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
    color: #28a745;
    background-color: transparent;
    background-image: none;
    border-color: #28a745;
}

.btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show>.btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
    color: #17a2b8;
    background-color: transparent;
    background-image: none;
    border-color: #17a2b8;
}

.btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show>.btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
    color: #ffc107;
    background-color: transparent;
    background-image: none;
    border-color: #ffc107;
}

.btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show>.btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
    color: #dc3545;
    background-color: transparent;
    background-image: none;
    border-color: #dc3545;
}

.btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show>.btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
    color: #f8f9fa;
    background-color: transparent;
    background-image: none;
    border-color: #f8f9fa;
}

.btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show>.btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
    color: #343a40;
    background-color: transparent;
    background-image: none;
    border-color: #343a40;
}

.btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show>.btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
    font-weight: 400;
    color: #007bff;
    background-color: transparent;
}

.btn-link:hover {
    color: #0056b3;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent;
}

.btn-link:focus,
.btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none;
}

.btn-link:disabled,
.btn-link.disabled {
    color: #6c757d;
}

.btn-lg,
.btn-group-lg>.btn {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

.btn-sm,
.btn-group-sm>.btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-block+.btn-block {
    margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
    width: 100%;
}

.fade {
    opacity: 0;
    transition: opacity 0.15s linear;
}

.fade.show {
    opacity: 1;
}

.collapse {
    display: none;
}

.collapse.show {
    display: block;
}

tr.collapse.show {
    display: table-row;
}

tbody.collapse.show {
    display: table-row-group;
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
}

.dropup,
.dropdown {
    position: relative;
}

.dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
}

.dropup .dropdown-menu {
    margin-top: 0;
    margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropright .dropdown-menu {
    margin-top: 0;
    margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropright .dropdown-toggle::after {
    vertical-align: 0;
}

.dropleft .dropdown-menu {
    margin-top: 0;
    margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
}

.dropleft .dropdown-toggle::after {
    display: none;
}

.dropleft .dropdown-toggle::before {
    display: inline-block;
    width: 0;
    height: 0;
    margin-right: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropleft .dropdown-toggle::before {
    vertical-align: 0;
}

.dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid #e9ecef;
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa;
}

.dropdown-item.active,
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    color: #6c757d;
    background-color: transparent;
}

.dropdown-menu.show {
    display: block;
}

.dropdown-header {
    display: block;
    padding: 0.5rem 1.5rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    color: #6c757d;
    white-space: nowrap;
}

.btn-group,
.btn-group-vertical {
    position: relative;
    display: -webkit-inline-flex;
    display: inline-flex;
    vertical-align: middle;
}

.btn-group>.btn,
.btn-group-vertical>.btn {
    position: relative;
    -webkit-flex: 0 1 auto;
            flex: 0 1 auto;
}

.btn-group>.btn:hover,
.btn-group-vertical>.btn:hover {
    z-index: 1;
}

.btn-group>.btn:focus,
.btn-group>.btn:active,
.btn-group>.btn.active,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn.active {
    z-index: 1;
}

.btn-group .btn+.btn,
.btn-group .btn+.btn-group,
.btn-group .btn-group+.btn,
.btn-group .btn-group+.btn-group,
.btn-group-vertical .btn+.btn,
.btn-group-vertical .btn+.btn-group,
.btn-group-vertical .btn-group+.btn,
.btn-group-vertical .btn-group+.btn-group {
    margin-left: -1px;
}

.btn-toolbar {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
}

.btn-toolbar .input-group {
    width: auto;
}

.btn-group>.btn:first-child {
    margin-left: 0;
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn-group:not(:last-child)>.btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.btn-group>.btn:not(:first-child),
.btn-group>.btn-group:not(:first-child)>.btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
    padding-right: 0.5625rem;
    padding-left: 0.5625rem;
}

.dropdown-toggle-split::after {
    margin-left: 0;
}

.btn-sm+.dropdown-toggle-split,
.btn-group-sm>.btn+.dropdown-toggle-split {
    padding-right: 0.375rem;
    padding-left: 0.375rem;
}

.btn-lg+.dropdown-toggle-split,
.btn-group-lg>.btn+.dropdown-toggle-split {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
}

.btn-group-vertical {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: center;
            justify-content: center;
}

.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
    width: 100%;
}

.btn-group-vertical>.btn+.btn,
.btn-group-vertical>.btn+.btn-group,
.btn-group-vertical>.btn-group+.btn,
.btn-group-vertical>.btn-group+.btn-group {
    margin-top: -1px;
    margin-left: 0;
}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical>.btn-group:not(:last-child)>.btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child)>.btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.btn-group-toggle>.btn,
.btn-group-toggle>.btn-group>.btn {
    margin-bottom: 0;
}

.btn-group-toggle>.btn input[type="radio"],
.btn-group-toggle>.btn input[type="checkbox"],
.btn-group-toggle>.btn-group>.btn input[type="radio"],
.btn-group-toggle>.btn-group>.btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
}

.input-group {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: stretch;
            align-items: stretch;
    width: 100%;
}

.input-group>.form-control,
.input-group>.custom-select,
.input-group>.custom-file {
    position: relative;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
}

.input-group>.form-control:focus,
.input-group>.custom-select:focus,
.input-group>.custom-file:focus {
    z-index: 3;
}

.input-group>.form-control+.form-control,
.input-group>.form-control+.custom-select,
.input-group>.form-control+.custom-file,
.input-group>.custom-select+.form-control,
.input-group>.custom-select+.custom-select,
.input-group>.custom-select+.custom-file,
.input-group>.custom-file+.form-control,
.input-group>.custom-file+.custom-select,
.input-group>.custom-file+.custom-file {
    margin-left: -1px;
}

.input-group>.form-control:not(:last-child),
.input-group>.custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>.form-control:not(:first-child),
.input-group>.custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group>.custom-file {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.input-group>.custom-file:not(:last-child) .custom-file-label,
.input-group>.custom-file:not(:last-child) .custom-file-label::before {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>.custom-file:not(:first-child) .custom-file-label,
.input-group>.custom-file:not(:first-child) .custom-file-label::before {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
    display: -webkit-flex;
    display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
    position: relative;
    z-index: 2;
}

.input-group-prepend .btn+.btn,
.input-group-prepend .btn+.input-group-text,
.input-group-prepend .input-group-text+.input-group-text,
.input-group-prepend .input-group-text+.btn,
.input-group-append .btn+.btn,
.input-group-append .btn+.input-group-text,
.input-group-append .input-group-text+.input-group-text,
.input-group-append .input-group-text+.btn {
    margin-left: -1px;
}

.input-group-prepend {
    margin-right: -1px;
}

.input-group-append {
    margin-left: -1px;
}

.input-group-text {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
    margin-top: 0;
}

.input-group>.input-group-prepend>.btn,
.input-group>.input-group-prepend>.input-group-text,
.input-group>.input-group-append:not(:last-child)>.btn,
.input-group>.input-group-append:not(:last-child)>.input-group-text,
.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.input-group>.input-group-append:last-child>.input-group-text:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>.input-group-append>.btn,
.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:not(:first-child)>.btn,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.custom-control {
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
}

.custom-control-inline {
    display: -webkit-inline-flex;
    display: inline-flex;
    margin-right: 1rem;
}

.custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    background-color: #007bff;
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-control-input:active~.custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
}

.custom-control-input:disabled~.custom-control-label {
    color: #6c757d;
}

.custom-control-input:disabled~.custom-control-label::before {
    background-color: #e9ecef;
}

.custom-control-label {
    margin-bottom: 0;
}

.custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #dee2e6;
}

.custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
}

.custom-checkbox .custom-control-label::before {
    border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: #007bff;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
    background-color: #007bff;
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
    border-radius: 50%;
}

.custom-radio .custom-control-input:checked~.custom-control-label::before {
    background-color: #007bff;
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
    background-size: 8px 10px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-appearance: none;
    appearance: none;
}

.custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(128, 189, 255, 0.5);
}

.custom-select:focus::-ms-value {
    color: #495057;
    background-color: #fff;
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none;
}

.custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef;
}

.custom-select::-ms-expand {
    opacity: 0;
}

.custom-select-sm {
    height: calc(1.8125rem + 2px);
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    font-size: 75%;
}

.custom-select-lg {
    height: calc(2.875rem + 2px);
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    font-size: 125%;
}

.custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 2px);
    margin-bottom: 0;
}

.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(2.25rem + 2px);
    margin: 0;
    opacity: 0;
}

.custom-file-input:focus~.custom-file-control {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-file-input:focus~.custom-file-control::before {
    border-color: #80bdff;
}

.custom-file-input:lang(en)~.custom-file-label::after {
    content: "Browse";
}

.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(calc(2.25rem + 2px) - 1px * 2);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: 1px solid #ced4da;
    border-radius: 0 0.25rem 0.25rem 0;
}

.nav {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav-link {
    display: block;
    padding: 0.5rem 1rem;
}

.nav-link:hover,
.nav-link:focus {
    text-decoration: none;
}

.nav-link.disabled {
    color: #6c757d;
}

.nav-tabs {
    border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
    margin-bottom: -1px;
}

.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
    border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.nav-pills .nav-link {
    border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #007bff;
}

.nav-fill .nav-item {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    text-align: center;
}

.nav-justified .nav-item {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    text-align: center;
}

.tab-content>.tab-pane {
    display: none;
}

.tab-content>.active {
    display: block;
}

.navbar {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 0.5rem 1rem;
}

.navbar>.container,
.navbar>.container-fluid {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.navbar-brand {
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
    text-decoration: none;
}

.navbar-nav {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
}

.navbar-nav .dropdown-menu {
    position: static;
    float: none;
}

.navbar-text {
    display: inline-block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.navbar-collapse {
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-align-items: center;
            align-items: center;
}

.navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
    text-decoration: none;
}

.navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
}

@media (max-width: 575.98px) {

    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 576px) {
    .navbar-expand-sm {
        -webkit-flex-flow: row nowrap;
                flex-flow: row nowrap;
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
    }

    .navbar-expand-sm .navbar-nav {
        -webkit-flex-direction: row;
                flex-direction: row;
    }

    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-sm .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto;
    }

    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid {
        -webkit-flex-wrap: nowrap;
                flex-wrap: nowrap;
    }

    .navbar-expand-sm .navbar-collapse {
        display: -webkit-flex !important;
        display: flex !important;
        -webkit-flex-basis: auto;
                flex-basis: auto;
    }

    .navbar-expand-sm .navbar-toggler {
        display: none;
    }

    .navbar-expand-sm .dropup .dropdown-menu {
        top: auto;
        bottom: 100%;
    }
}

@media (max-width: 767.98px) {

    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 768px) {
    .navbar-expand-md {
        -webkit-flex-flow: row nowrap;
                flex-flow: row nowrap;
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
    }

    .navbar-expand-md .navbar-nav {
        -webkit-flex-direction: row;
                flex-direction: row;
    }

    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-md .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto;
    }

    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid {
        -webkit-flex-wrap: nowrap;
                flex-wrap: nowrap;
    }

    .navbar-expand-md .navbar-collapse {
        display: -webkit-flex !important;
        display: flex !important;
        -webkit-flex-basis: auto;
                flex-basis: auto;
    }

    .navbar-expand-md .navbar-toggler {
        display: none;
    }

    .navbar-expand-md .dropup .dropdown-menu {
        top: auto;
        bottom: 100%;
    }
}

@media (max-width: 991.98px) {

    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg {
        -webkit-flex-flow: row nowrap;
                flex-flow: row nowrap;
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
    }

    .navbar-expand-lg .navbar-nav {
        -webkit-flex-direction: row;
                flex-direction: row;
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid {
        -webkit-flex-wrap: nowrap;
                flex-wrap: nowrap;
    }

    .navbar-expand-lg .navbar-collapse {
        display: -webkit-flex !important;
        display: flex !important;
        -webkit-flex-basis: auto;
                flex-basis: auto;
    }

    .navbar-expand-lg .navbar-toggler {
        display: none;
    }

    .navbar-expand-lg .dropup .dropdown-menu {
        top: auto;
        bottom: 100%;
    }
}

@media (max-width: 1199.98px) {

    .navbar-expand-xl>.container,
    .navbar-expand-xl>.container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 1200px) {
    .navbar-expand-xl {
        -webkit-flex-flow: row nowrap;
                flex-flow: row nowrap;
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
    }

    .navbar-expand-xl .navbar-nav {
        -webkit-flex-direction: row;
                flex-direction: row;
    }

    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-xl .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto;
    }

    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .navbar-expand-xl>.container,
    .navbar-expand-xl>.container-fluid {
        -webkit-flex-wrap: nowrap;
                flex-wrap: nowrap;
    }

    .navbar-expand-xl .navbar-collapse {
        display: -webkit-flex !important;
        display: flex !important;
        -webkit-flex-basis: auto;
                flex-basis: auto;
    }

    .navbar-expand-xl .navbar-toggler {
        display: none;
    }

    .navbar-expand-xl .dropup .dropdown-menu {
        top: auto;
        bottom: 100%;
    }
}

.navbar-expand {
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
}

.navbar-expand>.container,
.navbar-expand>.container-fluid {
    padding-right: 0;
    padding-left: 0;
}

.navbar-expand .navbar-nav {
    -webkit-flex-direction: row;
            flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
}

.navbar-expand .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

.navbar-expand>.container,
.navbar-expand>.container-fluid {
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-flex-basis: auto;
            flex-basis: auto;
}

.navbar-expand .navbar-toggler {
    display: none;
}

.navbar-expand .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
}

.navbar-light .navbar-brand {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
    color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
    color: #fff;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
    color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show>.nav-link,
.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
    color: #fff;
}

.navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.5);
    border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
    color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
    color: #fff;
}

.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
    color: #fff;
}

.card {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
}

.card>hr {
    margin-right: 0;
    margin-left: 0;
}

.card>.list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.card>.list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.card-body {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    padding: 1.25rem;
}

.card-title {
    margin-bottom: 0.75rem;
}

.card-subtitle {
    margin-top: -0.375rem;
    margin-bottom: 0;
}

.card-text:last-child {
    margin-bottom: 0;
}

.card-link:hover {
    text-decoration: none;
}

.card-link+.card-link {
    margin-left: 1.25rem;
}

.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header+.list-group .list-group-item:first-child {
    border-top: 0;
}

.card-footer {
    padding: 0.75rem 1.25rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
    margin-right: -0.625rem;
    margin-bottom: -0.75rem;
    margin-left: -0.625rem;
    border-bottom: 0;
}

.card-header-pills {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
}

.card-img {
    width: 100%;
    border-radius: calc(0.25rem - 1px);
}

.card-img-top {
    width: 100%;
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
    width: 100%;
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.card-deck .card {
    margin-bottom: 15px;
}

@media (min-width: 576px) {
    .card-deck {
        -webkit-flex-flow: row wrap;
                flex-flow: row wrap;
        margin-right: -15px;
        margin-left: -15px;
    }

    .card-deck .card {
        display: -webkit-flex;
        display: flex;
        -webkit-flex: 1 0;
                flex: 1 0;
        -webkit-flex-direction: column;
                flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px;
    }
}

.card-group {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.card-group>.card {
    margin-bottom: 15px;
}

@media (min-width: 576px) {
    .card-group {
        -webkit-flex-flow: row wrap;
                flex-flow: row wrap;
    }

    .card-group>.card {
        -webkit-flex: 1 0;
                flex: 1 0;
        margin-bottom: 0;
    }

    .card-group>.card+.card {
        margin-left: 0;
        border-left: 0;
    }

    .card-group>.card:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .card-group>.card:first-child .card-img-top,
    .card-group>.card:first-child .card-header {
        border-top-right-radius: 0;
    }

    .card-group>.card:first-child .card-img-bottom,
    .card-group>.card:first-child .card-footer {
        border-bottom-right-radius: 0;
    }

    .card-group>.card:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .card-group>.card:last-child .card-img-top,
    .card-group>.card:last-child .card-header {
        border-top-left-radius: 0;
    }

    .card-group>.card:last-child .card-img-bottom,
    .card-group>.card:last-child .card-footer {
        border-bottom-left-radius: 0;
    }

    .card-group>.card:only-child {
        border-radius: 0.25rem;
    }

    .card-group>.card:only-child .card-img-top,
    .card-group>.card:only-child .card-header {
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
    }

    .card-group>.card:only-child .card-img-bottom,
    .card-group>.card:only-child .card-footer {
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
    }

    .card-group>.card:not(:first-child):not(:last-child):not(:only-child) {
        border-radius: 0;
    }

    .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
    .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
    .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-header,
    .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
        border-radius: 0;
    }
}

.card-columns .card {
    margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
    .card-columns {
        -webkit-column-count: 3;
        column-count: 3;
        -webkit-column-gap: 1.25rem;
        -moz-column-gap: 1.25rem;
        grid-column-gap: 1.25rem;
        column-gap: 1.25rem;
    }

    .card-columns .card {
        display: inline-block;
        width: 100%;
    }
}

.breadcrumb {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #e9ecef;
    border-radius: 0.25rem;
}

.breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    color: #6c757d;
    content: "/";
}

.breadcrumb-item+.breadcrumb-item:hover::before {
    text-decoration: underline;
}

.breadcrumb-item+.breadcrumb-item:hover::before {
    text-decoration: none;
}

.breadcrumb-item.active {
    color: #6c757d;
}

.pagination {
    display: -webkit-flex;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
}

.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #007bff;
    background-color: #fff;
    border: 1px solid #dee2e6;
}

.page-link:hover {
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6;
}

.page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-link:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
}

.pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
}

.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
}

.badge:empty {
    display: none;
}

.btn .badge {
    position: relative;
    top: -1px;
}

.badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
}

.badge-primary {
    color: #fff;
    background-color: #007bff;
}

.badge-primary[href]:hover,
.badge-primary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #0062cc;
}

.badge-secondary {
    color: #fff;
    background-color: #6c757d;
}

.badge-secondary[href]:hover,
.badge-secondary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #545b62;
}

.badge-success {
    color: #fff;
    background-color: #28a745;
}

.badge-success[href]:hover,
.badge-success[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1e7e34;
}

.badge-info {
    color: #fff;
    background-color: #17a2b8;
}

.badge-info[href]:hover,
.badge-info[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #117a8b;
}

.badge-warning {
    color: #212529;
    background-color: #ffc107;
}

.badge-warning[href]:hover,
.badge-warning[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #d39e00;
}

.badge-danger {
    color: #fff;
    background-color: #dc3545;
}

.badge-danger[href]:hover,
.badge-danger[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #bd2130;
}

.badge-light {
    color: #212529;
    background-color: #f8f9fa;
}

.badge-light[href]:hover,
.badge-light[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #dae0e5;
}

.badge-dark {
    color: #fff;
    background-color: #343a40;
}

.badge-dark[href]:hover,
.badge-dark[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1d2124;
}

.jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #e9ecef;
    border-radius: 0.3rem;
}

@media (min-width: 576px) {
    .jumbotron {
        padding: 4rem 2rem;
    }
}

.jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0;
}

.alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

.alert-heading {
    color: inherit;
}

.alert-link {
    font-weight: 700;
}

.alert-dismissible {
    padding-right: 4rem;
}

.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit;
}

.alert-primary {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
}

.alert-primary hr {
    border-top-color: #9fcdff;
}

.alert-primary .alert-link {
    color: #002752;
}

.alert-secondary {
    color: #383d41;
    background-color: #e2e3e5;
    border-color: #d6d8db;
}

.alert-secondary hr {
    border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
    color: #202326;
}

.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.alert-success hr {
    border-top-color: #b1dfbb;
}

.alert-success .alert-link {
    color: #0b2e13;
}

.alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
}

.alert-info hr {
    border-top-color: #abdde5;
}

.alert-info .alert-link {
    color: #062c33;
}

.alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
}

.alert-warning hr {
    border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
    color: #533f03;
}

.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

.alert-danger hr {
    border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
    color: #491217;
}

.alert-light {
    color: #818182;
    background-color: #fefefe;
    border-color: #fdfdfe;
}

.alert-light hr {
    border-top-color: #ececf6;
}

.alert-light .alert-link {
    color: #686868;
}

.alert-dark {
    color: #1b1e21;
    background-color: #d6d8d9;
    border-color: #c6c8ca;
}

.alert-dark hr {
    border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
    color: #040505;
}

@-webkit-keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0;
    }

    to {
        background-position: 0 0;
    }
}

@keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0;
    }

    to {
        background-position: 0 0;
    }
}

.progress {
    display: -webkit-flex;
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 0.25rem;
}

.progress-bar {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    color: #fff;
    text-align: center;
    background-color: #007bff;
    transition: width 0.6s ease;
}

.progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem;
}

.progress-bar-animated {
    -webkit-animation: progress-bar-stripes 1s linear infinite;
    animation: progress-bar-stripes 1s linear infinite;
}

.media {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}

.media-body {
    -webkit-flex: 1 1;
            flex: 1 1;
}

.list-group {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
}

.list-group-item-action {
    width: 100%;
    color: #495057;
    text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa;
}

.list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef;
}

.list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.list-group-item:hover,
.list-group-item:focus {
    z-index: 1;
    text-decoration: none;
}

.list-group-item.disabled,
.list-group-item:disabled {
    color: #6c757d;
    background-color: #fff;
}

.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.list-group-flush .list-group-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0;
}

.list-group-flush:first-child .list-group-item:first-child {
    border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
    border-bottom: 0;
}

.list-group-item-primary {
    color: #004085;
    background-color: #b8daff;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9fcdff;
}

.list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085;
}

.list-group-item-secondary {
    color: #383d41;
    background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41;
}

.list-group-item-success {
    color: #155724;
    background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724;
}

.list-group-item-info {
    color: #0c5460;
    background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460;
}

.list-group-item-warning {
    color: #856404;
    background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404;
}

.list-group-item-danger {
    color: #721c24;
    background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24;
}

.list-group-item-light {
    color: #818182;
    background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182;
}

.list-group-item-dark {
    color: #1b1e21;
    background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21;
}

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    opacity: .75;
}

.close:not(:disabled):not(.disabled) {
    cursor: pointer;
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
}

.modal-open {
    overflow: hidden;
}

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    outline: 0;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
}

.modal.fade .modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -25%);
    transform: translate(0, -25%);
}

.modal.show .modal-dialog {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
}

.modal-dialog-centered {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    min-height: calc(100% - (0.5rem * 2));
}

.modal-content {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000;
}

.modal-backdrop.fade {
    opacity: 0;
}

.modal-backdrop.show {
    opacity: 0.5;
}

.modal-header {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #e9ecef;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}

.modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto;
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
}

.modal-body {
    position: relative;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    padding: 1rem;
}

.modal-footer {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #e9ecef;
}

.modal-footer> :not(:first-child) {
    margin-left: .25rem;
}

.modal-footer> :not(:last-child) {
    margin-right: .25rem;
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
    }

    .modal-dialog-centered {
        min-height: calc(100% - (1.75rem * 2));
    }

    .modal-sm {
        max-width: 300px;
    }
}

@media (min-width: 992px) {
    .modal-lg {
        max-width: 800px;
    }
}

.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    opacity: 0;
}

.tooltip.show {
    opacity: 0.9;
}

.tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem;
}

.tooltip .arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
    padding: 0.4rem 0;
}

.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    top: 0;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #000;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^="right"] {
    padding: 0 0.4rem;
}

.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    right: 0;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^="bottom"] {
    padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    bottom: 0;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #000;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^="left"] {
    padding: 0 0.4rem;
}

.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
    left: 0;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #000;
}

.tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 0.25rem;
}

.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
}

.popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem;
}

.popover .arrow::before,
.popover .arrow::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
}

.bs-popover-top,
.bs-popover-auto[x-placement^="top"] {
    margin-bottom: 0.5rem;
}

.bs-popover-top .arrow,
.bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 1px) * -1);
}

.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0;
}

.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: #fff;
}

.bs-popover-right,
.bs-popover-auto[x-placement^="right"] {
    margin-left: 0.5rem;
}

.bs-popover-right .arrow,
.bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
}

.bs-popover-right .arrow::before,
.bs-popover-auto[x-placement^="right"] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0;
}

.bs-popover-right .arrow::before,
.bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: #fff;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
    margin-top: 0.5rem;
}

.bs-popover-bottom .arrow,
.bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 1px) * -1);
}

.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem;
}

.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left,
.bs-popover-auto[x-placement^="left"] {
    margin-right: 0.5rem;
}

.bs-popover-left .arrow,
.bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
}

.bs-popover-left .arrow::before,
.bs-popover-auto[x-placement^="left"] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem;
}

.bs-popover-left .arrow::before,
.bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 1px;
    border-left-color: #fff;
}

.popover-header {
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    color: inherit;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
    display: none;
}

.popover-body {
    padding: 0.5rem 0.75rem;
    color: #212529;
}

.carousel {
    position: relative;
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.carousel-item {
    position: relative;
    display: none;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    transition: -webkit-transform 0.6s ease;
    transition: transform 0.6s ease;
    transition: transform 0.6s ease, -webkit-transform 0.6s ease;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000px;
    perspective: 1000px;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
    display: block;
}

.carousel-item-next,
.carousel-item-prev {
    position: absolute;
    top: 0;
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {

    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.carousel-item-next,
.active.carousel-item-right {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {

    .carousel-item-next,
    .active.carousel-item-right {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
}

.carousel-item-prev,
.active.carousel-item-left {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {

    .carousel-item-prev,
    .active.carousel-item-left {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
}

.carousel-control-prev,
.carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: 0.5;
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9;
}

.carousel-control-prev {
    left: 0;
}

.carousel-control-next {
    right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: transparent no-repeat center center;
    background-size: 100% 100%;
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 10px;
    left: 0;
    z-index: 15;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
}

.carousel-indicators li {
    position: relative;
    -webkit-flex: 0 1 auto;
            flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    background-color: rgba(255, 255, 255, 0.5);
}

.carousel-indicators li::before {
    position: absolute;
    top: -10px;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 10px;
    content: "";
}

.carousel-indicators li::after {
    position: absolute;
    bottom: -10px;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 10px;
    content: "";
}

.carousel-indicators .active {
    background-color: #fff;
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
}

.align-baseline {
    vertical-align: baseline !important;
}

.align-top {
    vertical-align: top !important;
}

.align-middle {
    vertical-align: middle !important;
}

.align-bottom {
    vertical-align: bottom !important;
}

.align-text-bottom {
    vertical-align: text-bottom !important;
}

.align-text-top {
    vertical-align: text-top !important;
}

.bg-primary {
    background-color: #007bff !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
    background-color: #0062cc !important;
}

.bg-secondary {
    background-color: #6c757d !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
    background-color: #545b62 !important;
}

.bg-success {
    background-color: #28a745 !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
    background-color: #1e7e34 !important;
}

.bg-info {
    background-color: #17a2b8 !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
    background-color: #117a8b !important;
}

.bg-warning {
    background-color: #ffc107 !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
    background-color: #d39e00 !important;
}

.bg-danger {
    background-color: #dc3545 !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
    background-color: #bd2130 !important;
}

.bg-light {
    background-color: #f8f9fa !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
    background-color: #dae0e5 !important;
}

.bg-dark {
    background-color: #343a40 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
    background-color: #1d2124 !important;
}

.bg-white {
    background-color: #fff !important;
}

.bg-transparent {
    background-color: transparent !important;
}

.border {
    border: 1px solid #dee2e6 !important;
}

.border-top {
    border-top: 1px solid #dee2e6 !important;
}

.border-right {
    border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
    border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
    border-left: 1px solid #dee2e6 !important;
}

.border-0 {
    border: 0 !important;
}

.border-top-0 {
    border-top: 0 !important;
}

.border-right-0 {
    border-right: 0 !important;
}

.border-bottom-0 {
    border-bottom: 0 !important;
}

.border-left-0 {
    border-left: 0 !important;
}

.border-primary {
    border-color: #007bff !important;
}

.border-secondary {
    border-color: #6c757d !important;
}

.border-success {
    border-color: #28a745 !important;
}

.border-info {
    border-color: #17a2b8 !important;
}

.border-warning {
    border-color: #ffc107 !important;
}

.border-danger {
    border-color: #dc3545 !important;
}

.border-light {
    border-color: #f8f9fa !important;
}

.border-dark {
    border-color: #343a40 !important;
}

.border-white {
    border-color: #fff !important;
}

.rounded {
    border-radius: 0.25rem !important;
}

.rounded-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
}

.rounded-right {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
}

.rounded-circle {
    border-radius: 50% !important;
}

.rounded-0 {
    border-radius: 0 !important;
}

.clearfix::after {
    display: block;
    clear: both;
    content: "";
}

.d-none {
    display: none !important;
}

.d-inline {
    display: inline !important;
}

.d-inline-block {
    display: inline-block !important;
}

.d-block {
    display: block !important;
}

.d-table {
    display: table !important;
}

.d-table-row {
    display: table-row !important;
}

.d-table-cell {
    display: table-cell !important;
}

.d-flex {
    display: -webkit-flex !important;
    display: flex !important;
}

.d-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important;
}

@media (min-width: 576px) {
    .d-sm-none {
        display: none !important;
    }

    .d-sm-inline {
        display: inline !important;
    }

    .d-sm-inline-block {
        display: inline-block !important;
    }

    .d-sm-block {
        display: block !important;
    }

    .d-sm-table {
        display: table !important;
    }

    .d-sm-table-row {
        display: table-row !important;
    }

    .d-sm-table-cell {
        display: table-cell !important;
    }

    .d-sm-flex {
        display: -webkit-flex !important;
        display: flex !important;
    }

    .d-sm-inline-flex {
        display: -webkit-inline-flex !important;
        display: inline-flex !important;
    }
}

@media (min-width: 768px) {
    .d-md-none {
        display: none !important;
    }

    .d-md-inline {
        display: inline !important;
    }

    .d-md-inline-block {
        display: inline-block !important;
    }

    .d-md-block {
        display: block !important;
    }

    .d-md-table {
        display: table !important;
    }

    .d-md-table-row {
        display: table-row !important;
    }

    .d-md-table-cell {
        display: table-cell !important;
    }

    .d-md-flex {
        display: -webkit-flex !important;
        display: flex !important;
    }

    .d-md-inline-flex {
        display: -webkit-inline-flex !important;
        display: inline-flex !important;
    }
}

@media (min-width: 992px) {
    .d-lg-none {
        display: none !important;
    }

    .d-lg-inline {
        display: inline !important;
    }

    .d-lg-inline-block {
        display: inline-block !important;
    }

    .d-lg-block {
        display: block !important;
    }

    .d-lg-table {
        display: table !important;
    }

    .d-lg-table-row {
        display: table-row !important;
    }

    .d-lg-table-cell {
        display: table-cell !important;
    }

    .d-lg-flex {
        display: -webkit-flex !important;
        display: flex !important;
    }

    .d-lg-inline-flex {
        display: -webkit-inline-flex !important;
        display: inline-flex !important;
    }
}

@media (min-width: 1200px) {
    .d-xl-none {
        display: none !important;
    }

    .d-xl-inline {
        display: inline !important;
    }

    .d-xl-inline-block {
        display: inline-block !important;
    }

    .d-xl-block {
        display: block !important;
    }

    .d-xl-table {
        display: table !important;
    }

    .d-xl-table-row {
        display: table-row !important;
    }

    .d-xl-table-cell {
        display: table-cell !important;
    }

    .d-xl-flex {
        display: -webkit-flex !important;
        display: flex !important;
    }

    .d-xl-inline-flex {
        display: -webkit-inline-flex !important;
        display: inline-flex !important;
    }
}

@media print {
    .d-print-none {
        display: none !important;
    }

    .d-print-inline {
        display: inline !important;
    }

    .d-print-inline-block {
        display: inline-block !important;
    }

    .d-print-block {
        display: block !important;
    }

    .d-print-table {
        display: table !important;
    }

    .d-print-table-row {
        display: table-row !important;
    }

    .d-print-table-cell {
        display: table-cell !important;
    }

    .d-print-flex {
        display: -webkit-flex !important;
        display: flex !important;
    }

    .d-print-inline-flex {
        display: -webkit-inline-flex !important;
        display: inline-flex !important;
    }
}

.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
}

.embed-responsive::before {
    display: block;
    content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.embed-responsive-21by9::before {
    padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
    padding-top: 56.25%;
}

.embed-responsive-4by3::before {
    padding-top: 75%;
}

.embed-responsive-1by1::before {
    padding-top: 100%;
}

.flex-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important;
}

.flex-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
}

.flex-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
}

.flex-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
}

.flex-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important;
}

.flex-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important;
}

.justify-content-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important;
}

.justify-content-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important;
}

.justify-content-center {
    -webkit-justify-content: center !important;
            justify-content: center !important;
}

.justify-content-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important;
}

.justify-content-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important;
}

.align-items-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important;
}

.align-items-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important;
}

.align-items-center {
    -webkit-align-items: center !important;
            align-items: center !important;
}

.align-items-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important;
}

.align-items-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important;
}

.align-content-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important;
}

.align-content-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important;
}

.align-content-center {
    -webkit-align-content: center !important;
            align-content: center !important;
}

.align-content-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important;
}

.align-content-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important;
}

.align-content-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important;
}

.align-self-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important;
}

.align-self-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important;
}

.align-self-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important;
}

.align-self-center {
    -webkit-align-self: center !important;
            align-self: center !important;
}

.align-self-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important;
}

.align-self-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important;
}

@media (min-width: 576px) {
    .flex-sm-row {
        -webkit-flex-direction: row !important;
                flex-direction: row !important;
    }

    .flex-sm-column {
        -webkit-flex-direction: column !important;
                flex-direction: column !important;
    }

    .flex-sm-row-reverse {
        -webkit-flex-direction: row-reverse !important;
                flex-direction: row-reverse !important;
    }

    .flex-sm-column-reverse {
        -webkit-flex-direction: column-reverse !important;
                flex-direction: column-reverse !important;
    }

    .flex-sm-wrap {
        -webkit-flex-wrap: wrap !important;
                flex-wrap: wrap !important;
    }

    .flex-sm-nowrap {
        -webkit-flex-wrap: nowrap !important;
                flex-wrap: nowrap !important;
    }

    .flex-sm-wrap-reverse {
        -webkit-flex-wrap: wrap-reverse !important;
                flex-wrap: wrap-reverse !important;
    }

    .justify-content-sm-start {
        -webkit-justify-content: flex-start !important;
                justify-content: flex-start !important;
    }

    .justify-content-sm-end {
        -webkit-justify-content: flex-end !important;
                justify-content: flex-end !important;
    }

    .justify-content-sm-center {
        -webkit-justify-content: center !important;
                justify-content: center !important;
    }

    .justify-content-sm-between {
        -webkit-justify-content: space-between !important;
                justify-content: space-between !important;
    }

    .justify-content-sm-around {
        -webkit-justify-content: space-around !important;
                justify-content: space-around !important;
    }

    .align-items-sm-start {
        -webkit-align-items: flex-start !important;
                align-items: flex-start !important;
    }

    .align-items-sm-end {
        -webkit-align-items: flex-end !important;
                align-items: flex-end !important;
    }

    .align-items-sm-center {
        -webkit-align-items: center !important;
                align-items: center !important;
    }

    .align-items-sm-baseline {
        -webkit-align-items: baseline !important;
                align-items: baseline !important;
    }

    .align-items-sm-stretch {
        -webkit-align-items: stretch !important;
                align-items: stretch !important;
    }

    .align-content-sm-start {
        -webkit-align-content: flex-start !important;
                align-content: flex-start !important;
    }

    .align-content-sm-end {
        -webkit-align-content: flex-end !important;
                align-content: flex-end !important;
    }

    .align-content-sm-center {
        -webkit-align-content: center !important;
                align-content: center !important;
    }

    .align-content-sm-between {
        -webkit-align-content: space-between !important;
                align-content: space-between !important;
    }

    .align-content-sm-around {
        -webkit-align-content: space-around !important;
                align-content: space-around !important;
    }

    .align-content-sm-stretch {
        -webkit-align-content: stretch !important;
                align-content: stretch !important;
    }

    .align-self-sm-auto {
        -webkit-align-self: auto !important;
                align-self: auto !important;
    }

    .align-self-sm-start {
        -webkit-align-self: flex-start !important;
                align-self: flex-start !important;
    }

    .align-self-sm-end {
        -webkit-align-self: flex-end !important;
                align-self: flex-end !important;
    }

    .align-self-sm-center {
        -webkit-align-self: center !important;
                align-self: center !important;
    }

    .align-self-sm-baseline {
        -webkit-align-self: baseline !important;
                align-self: baseline !important;
    }

    .align-self-sm-stretch {
        -webkit-align-self: stretch !important;
                align-self: stretch !important;
    }
}

@media (min-width: 768px) {
    .flex-md-row {
        -webkit-flex-direction: row !important;
                flex-direction: row !important;
    }

    .flex-md-column {
        -webkit-flex-direction: column !important;
                flex-direction: column !important;
    }

    .flex-md-row-reverse {
        -webkit-flex-direction: row-reverse !important;
                flex-direction: row-reverse !important;
    }

    .flex-md-column-reverse {
        -webkit-flex-direction: column-reverse !important;
                flex-direction: column-reverse !important;
    }

    .flex-md-wrap {
        -webkit-flex-wrap: wrap !important;
                flex-wrap: wrap !important;
    }

    .flex-md-nowrap {
        -webkit-flex-wrap: nowrap !important;
                flex-wrap: nowrap !important;
    }

    .flex-md-wrap-reverse {
        -webkit-flex-wrap: wrap-reverse !important;
                flex-wrap: wrap-reverse !important;
    }

    .justify-content-md-start {
        -webkit-justify-content: flex-start !important;
                justify-content: flex-start !important;
    }

    .justify-content-md-end {
        -webkit-justify-content: flex-end !important;
                justify-content: flex-end !important;
    }

    .justify-content-md-center {
        -webkit-justify-content: center !important;
                justify-content: center !important;
    }

    .justify-content-md-between {
        -webkit-justify-content: space-between !important;
                justify-content: space-between !important;
    }

    .justify-content-md-around {
        -webkit-justify-content: space-around !important;
                justify-content: space-around !important;
    }

    .align-items-md-start {
        -webkit-align-items: flex-start !important;
                align-items: flex-start !important;
    }

    .align-items-md-end {
        -webkit-align-items: flex-end !important;
                align-items: flex-end !important;
    }

    .align-items-md-center {
        -webkit-align-items: center !important;
                align-items: center !important;
    }

    .align-items-md-baseline {
        -webkit-align-items: baseline !important;
                align-items: baseline !important;
    }

    .align-items-md-stretch {
        -webkit-align-items: stretch !important;
                align-items: stretch !important;
    }

    .align-content-md-start {
        -webkit-align-content: flex-start !important;
                align-content: flex-start !important;
    }

    .align-content-md-end {
        -webkit-align-content: flex-end !important;
                align-content: flex-end !important;
    }

    .align-content-md-center {
        -webkit-align-content: center !important;
                align-content: center !important;
    }

    .align-content-md-between {
        -webkit-align-content: space-between !important;
                align-content: space-between !important;
    }

    .align-content-md-around {
        -webkit-align-content: space-around !important;
                align-content: space-around !important;
    }

    .align-content-md-stretch {
        -webkit-align-content: stretch !important;
                align-content: stretch !important;
    }

    .align-self-md-auto {
        -webkit-align-self: auto !important;
                align-self: auto !important;
    }

    .align-self-md-start {
        -webkit-align-self: flex-start !important;
                align-self: flex-start !important;
    }

    .align-self-md-end {
        -webkit-align-self: flex-end !important;
                align-self: flex-end !important;
    }

    .align-self-md-center {
        -webkit-align-self: center !important;
                align-self: center !important;
    }

    .align-self-md-baseline {
        -webkit-align-self: baseline !important;
                align-self: baseline !important;
    }

    .align-self-md-stretch {
        -webkit-align-self: stretch !important;
                align-self: stretch !important;
    }
}

@media (min-width: 992px) {
    .flex-lg-row {
        -webkit-flex-direction: row !important;
                flex-direction: row !important;
    }

    .flex-lg-column {
        -webkit-flex-direction: column !important;
                flex-direction: column !important;
    }

    .flex-lg-row-reverse {
        -webkit-flex-direction: row-reverse !important;
                flex-direction: row-reverse !important;
    }

    .flex-lg-column-reverse {
        -webkit-flex-direction: column-reverse !important;
                flex-direction: column-reverse !important;
    }

    .flex-lg-wrap {
        -webkit-flex-wrap: wrap !important;
                flex-wrap: wrap !important;
    }

    .flex-lg-nowrap {
        -webkit-flex-wrap: nowrap !important;
                flex-wrap: nowrap !important;
    }

    .flex-lg-wrap-reverse {
        -webkit-flex-wrap: wrap-reverse !important;
                flex-wrap: wrap-reverse !important;
    }

    .justify-content-lg-start {
        -webkit-justify-content: flex-start !important;
                justify-content: flex-start !important;
    }

    .justify-content-lg-end {
        -webkit-justify-content: flex-end !important;
                justify-content: flex-end !important;
    }

    .justify-content-lg-center {
        -webkit-justify-content: center !important;
                justify-content: center !important;
    }

    .justify-content-lg-between {
        -webkit-justify-content: space-between !important;
                justify-content: space-between !important;
    }

    .justify-content-lg-around {
        -webkit-justify-content: space-around !important;
                justify-content: space-around !important;
    }

    .align-items-lg-start {
        -webkit-align-items: flex-start !important;
                align-items: flex-start !important;
    }

    .align-items-lg-end {
        -webkit-align-items: flex-end !important;
                align-items: flex-end !important;
    }

    .align-items-lg-center {
        -webkit-align-items: center !important;
                align-items: center !important;
    }

    .align-items-lg-baseline {
        -webkit-align-items: baseline !important;
                align-items: baseline !important;
    }

    .align-items-lg-stretch {
        -webkit-align-items: stretch !important;
                align-items: stretch !important;
    }

    .align-content-lg-start {
        -webkit-align-content: flex-start !important;
                align-content: flex-start !important;
    }

    .align-content-lg-end {
        -webkit-align-content: flex-end !important;
                align-content: flex-end !important;
    }

    .align-content-lg-center {
        -webkit-align-content: center !important;
                align-content: center !important;
    }

    .align-content-lg-between {
        -webkit-align-content: space-between !important;
                align-content: space-between !important;
    }

    .align-content-lg-around {
        -webkit-align-content: space-around !important;
                align-content: space-around !important;
    }

    .align-content-lg-stretch {
        -webkit-align-content: stretch !important;
                align-content: stretch !important;
    }

    .align-self-lg-auto {
        -webkit-align-self: auto !important;
                align-self: auto !important;
    }

    .align-self-lg-start {
        -webkit-align-self: flex-start !important;
                align-self: flex-start !important;
    }

    .align-self-lg-end {
        -webkit-align-self: flex-end !important;
                align-self: flex-end !important;
    }

    .align-self-lg-center {
        -webkit-align-self: center !important;
                align-self: center !important;
    }

    .align-self-lg-baseline {
        -webkit-align-self: baseline !important;
                align-self: baseline !important;
    }

    .align-self-lg-stretch {
        -webkit-align-self: stretch !important;
                align-self: stretch !important;
    }
}

@media (min-width: 1200px) {
    .flex-xl-row {
        -webkit-flex-direction: row !important;
                flex-direction: row !important;
    }

    .flex-xl-column {
        -webkit-flex-direction: column !important;
                flex-direction: column !important;
    }

    .flex-xl-row-reverse {
        -webkit-flex-direction: row-reverse !important;
                flex-direction: row-reverse !important;
    }

    .flex-xl-column-reverse {
        -webkit-flex-direction: column-reverse !important;
                flex-direction: column-reverse !important;
    }

    .flex-xl-wrap {
        -webkit-flex-wrap: wrap !important;
                flex-wrap: wrap !important;
    }

    .flex-xl-nowrap {
        -webkit-flex-wrap: nowrap !important;
                flex-wrap: nowrap !important;
    }

    .flex-xl-wrap-reverse {
        -webkit-flex-wrap: wrap-reverse !important;
                flex-wrap: wrap-reverse !important;
    }

    .justify-content-xl-start {
        -webkit-justify-content: flex-start !important;
                justify-content: flex-start !important;
    }

    .justify-content-xl-end {
        -webkit-justify-content: flex-end !important;
                justify-content: flex-end !important;
    }

    .justify-content-xl-center {
        -webkit-justify-content: center !important;
                justify-content: center !important;
    }

    .justify-content-xl-between {
        -webkit-justify-content: space-between !important;
                justify-content: space-between !important;
    }

    .justify-content-xl-around {
        -webkit-justify-content: space-around !important;
                justify-content: space-around !important;
    }

    .align-items-xl-start {
        -webkit-align-items: flex-start !important;
                align-items: flex-start !important;
    }

    .align-items-xl-end {
        -webkit-align-items: flex-end !important;
                align-items: flex-end !important;
    }

    .align-items-xl-center {
        -webkit-align-items: center !important;
                align-items: center !important;
    }

    .align-items-xl-baseline {
        -webkit-align-items: baseline !important;
                align-items: baseline !important;
    }

    .align-items-xl-stretch {
        -webkit-align-items: stretch !important;
                align-items: stretch !important;
    }

    .align-content-xl-start {
        -webkit-align-content: flex-start !important;
                align-content: flex-start !important;
    }

    .align-content-xl-end {
        -webkit-align-content: flex-end !important;
                align-content: flex-end !important;
    }

    .align-content-xl-center {
        -webkit-align-content: center !important;
                align-content: center !important;
    }

    .align-content-xl-between {
        -webkit-align-content: space-between !important;
                align-content: space-between !important;
    }

    .align-content-xl-around {
        -webkit-align-content: space-around !important;
                align-content: space-around !important;
    }

    .align-content-xl-stretch {
        -webkit-align-content: stretch !important;
                align-content: stretch !important;
    }

    .align-self-xl-auto {
        -webkit-align-self: auto !important;
                align-self: auto !important;
    }

    .align-self-xl-start {
        -webkit-align-self: flex-start !important;
                align-self: flex-start !important;
    }

    .align-self-xl-end {
        -webkit-align-self: flex-end !important;
                align-self: flex-end !important;
    }

    .align-self-xl-center {
        -webkit-align-self: center !important;
                align-self: center !important;
    }

    .align-self-xl-baseline {
        -webkit-align-self: baseline !important;
                align-self: baseline !important;
    }

    .align-self-xl-stretch {
        -webkit-align-self: stretch !important;
                align-self: stretch !important;
    }
}

.float-left {
    
}



.float-none {
    float: none !important;
}

@media (min-width: 576px) {
    .float-sm-left {
        float: left !important;
    }

    .float-sm-right {
        float: right !important;
    }

    .float-sm-none {
        float: none !important;
    }
}

@media (min-width: 768px) {
    .float-md-left {
        float: left !important;
    }

    .float-md-right {
        float: right !important;
    }

    .float-md-none {
        float: none !important;
    }
}

@media (min-width: 992px) {
    .float-lg-left {
        float: left !important;
    }

    .float-lg-right {
        float: right !important;
    }

    .float-lg-none {
        float: none !important;
    }
}

@media (min-width: 1200px) {
    .float-xl-left {
        float: left !important;
    }

    .float-xl-right {
        float: right !important;
    }

    .float-xl-none {
        float: none !important;
    }
}

.position-static {
    position: static !important;
}

.position-relative {
    position: relative !important;
}

.position-absolute {
    position: absolute !important;
}

.position-fixed {
    position: fixed !important;
}

.position-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
    .sticky-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020;
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
    -webkit-clip-path: none;
    clip-path: none;
}

.w-25 {
    width: 25% !important;
}

.w-50 {
    width: 50% !important;
}

.w-75 {
    width: 75% !important;
}

.w-100 {
    width: 100% !important;
}

.h-25 {
    height: 25% !important;
}

.h-50 {
    height: 50% !important;
}

.h-75 {
    height: 75% !important;
}

.h-100 {
    height: 100% !important;
}

.mw-100 {
    max-width: 100% !important;
}

.mh-100 {
    max-height: 100% !important;
}

.m-0 {
    margin: 0 !important;
}

.mt-0,
.my-0 {
    margin-top: 0 !important;
}

.mr-0,
.mx-0 {
    margin-right: 0 !important;
}

.mb-0,
.my-0 {
    margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
    margin-left: 0 !important;
}

.m-1 {
    margin: 0.25rem !important;
}

.mt-1,
.my-1 {
    margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
    margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
    margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
    margin-left: 0.25rem !important;
}

.m-2 {
    margin: 0.5rem !important;
}

.mt-2,
.my-2 {
    margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
    margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
    margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
    margin-left: 0.5rem !important;
}

.m-3 {
    margin: 1rem !important;
}

.mt-3,
.my-3 {
    margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
    margin-right: 1rem !important;
}

.mb-3,
.my-3 {
    margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
    margin-left: 1rem !important;
}

.m-4 {
    margin: 1.5rem !important;
}

.mt-4,
.my-4 {
    margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
    margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important;
    width: 150;
}

.ml-4,
.mx-4 {
    margin-left: 1.5rem !important;
}

.m-5 {
    margin: 3rem !important;
}

.mt-5,
.my-5 {
    margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
    margin-right: 3rem !important;
}

.mb-5,
.my-5 {
    margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
    margin-left: 3rem !important;
}

.p-0 {
    padding: 0 !important;
}

.pt-0,
.py-0 {
    padding-top: 0 !important;
}

.pr-0,
.px-0 {
    padding-right: 0 !important;
}

.pb-0,
.py-0 {
    padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
    padding-left: 0 !important;
}

.p-1 {
    padding: 0.25rem !important;
}

.pt-1,
.py-1 {
    padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
    padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
    padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
    padding-left: 0.25rem !important;
}

.p-2 {
    padding: 0.5rem !important;
}

.pt-2,
.py-2 {
    padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
    padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
    padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
    padding-left: 0.5rem !important;
}

.p-3 {
    padding: 1rem !important;
}

.pt-3,
.py-3 {
    padding-top: 1rem !important;
}

.pr-3,
.px-3 {
    padding-right: 1rem !important;
}

.pb-3,
.py-3 {
    padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
    padding-left: 1rem !important;
}

.p-4 {
    padding: 1.5rem !important;
}

.pt-4,
.py-4 {
    padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
    padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
    padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
    padding-left: 1.5rem !important;
}

.p-5 {
    padding: 3rem !important;
}

.pt-5,
.py-5 {
    padding-top: 3rem !important;
}

.pr-5,
.px-5 {
    padding-right: 3rem !important;
}

.pb-5,
.py-5 {
    padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
    padding-left: 3rem !important;
}

.m-auto {
    margin: auto !important;
}

.mt-auto,
.my-auto {
    margin-top: auto !important;
}

.mr-auto,
.mx-auto {
    margin-right: auto !important;
}

.mb-auto,
.my-auto {
    margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
    margin-left: auto !important;
}

@media (min-width: 576px) {
    .m-sm-0 {
        margin: 0 !important;
    }

    .mt-sm-0,
    .my-sm-0 {
        margin-top: 0 !important;
    }

    .mr-sm-0,
    .mx-sm-0 {
        margin-right: 0 !important;
    }

    .mb-sm-0,
    .my-sm-0 {
        margin-bottom: 0 !important;
    }

    .ml-sm-0,
    .mx-sm-0 {
        margin-left: 0 !important;
    }

    .m-sm-1 {
        margin: 0.25rem !important;
    }

    .mt-sm-1,
    .my-sm-1 {
        margin-top: 0.25rem !important;
    }

    .mr-sm-1,
    .mx-sm-1 {
        margin-right: 0.25rem !important;
    }

    .mb-sm-1,
    .my-sm-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-sm-1,
    .mx-sm-1 {
        margin-left: 0.25rem !important;
    }

    .m-sm-2 {
        margin: 0.5rem !important;
    }

    .mt-sm-2,
    .my-sm-2 {
        margin-top: 0.5rem !important;
    }

    .mr-sm-2,
    .mx-sm-2 {
        margin-right: 0.5rem !important;
    }

    .mb-sm-2,
    .my-sm-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-sm-2,
    .mx-sm-2 {
        margin-left: 0.5rem !important;
    }

    .m-sm-3 {
        margin: 1rem !important;
    }

    .mt-sm-3,
    .my-sm-3 {
        margin-top: 1rem !important;
    }

    .mr-sm-3,
    .mx-sm-3 {
        margin-right: 1rem !important;
    }

    .mb-sm-3,
    .my-sm-3 {
        margin-bottom: 1rem !important;
    }

    .ml-sm-3,
    .mx-sm-3 {
        margin-left: 1rem !important;
    }

    .m-sm-4 {
        margin: 1.5rem !important;
    }

    .mt-sm-4,
    .my-sm-4 {
        margin-top: 1.5rem !important;
    }

    .mr-sm-4,
    .mx-sm-4 {
        margin-right: 1.5rem !important;
    }

    .mb-sm-4,
    .my-sm-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-sm-4,
    .mx-sm-4 {
        margin-left: 1.5rem !important;
    }

    .m-sm-5 {
        margin: 3rem !important;
    }

    .mt-sm-5,
    .my-sm-5 {
        margin-top: 3rem !important;
    }

    .mr-sm-5,
    .mx-sm-5 {
        margin-right: 3rem !important;
    }

    .mb-sm-5,
    .my-sm-5 {
        margin-bottom: 3rem !important;
    }

    .ml-sm-5,
    .mx-sm-5 {
        margin-left: 3rem !important;
    }

    .p-sm-0 {
        padding: 0 !important;
    }

    .pt-sm-0,
    .py-sm-0 {
        padding-top: 0 !important;
    }

    .pr-sm-0,
    .px-sm-0 {
        padding-right: 0 !important;
    }

    .pb-sm-0,
    .py-sm-0 {
        padding-bottom: 0 !important;
    }

    .pl-sm-0,
    .px-sm-0 {
        padding-left: 0 !important;
    }

    .p-sm-1 {
        padding: 0.25rem !important;
    }

    .pt-sm-1,
    .py-sm-1 {
        padding-top: 0.25rem !important;
    }

    .pr-sm-1,
    .px-sm-1 {
        padding-right: 0.25rem !important;
    }

    .pb-sm-1,
    .py-sm-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-sm-1,
    .px-sm-1 {
        padding-left: 0.25rem !important;
    }

    .p-sm-2 {
        padding: 0.5rem !important;
    }

    .pt-sm-2,
    .py-sm-2 {
        padding-top: 0.5rem !important;
    }

    .pr-sm-2,
    .px-sm-2 {
        padding-right: 0.5rem !important;
    }

    .pb-sm-2,
    .py-sm-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-sm-2,
    .px-sm-2 {
        padding-left: 0.5rem !important;
    }

    .p-sm-3 {
        padding: 1rem !important;
    }

    .pt-sm-3,
    .py-sm-3 {
        padding-top: 1rem !important;
    }

    .pr-sm-3,
    .px-sm-3 {
        padding-right: 1rem !important;
    }

    .pb-sm-3,
    .py-sm-3 {
        padding-bottom: 1rem !important;
    }

    .pl-sm-3,
    .px-sm-3 {
        padding-left: 1rem !important;
    }

    .p-sm-4 {
        padding: 1.5rem !important;
    }

    .pt-sm-4,
    .py-sm-4 {
        padding-top: 1.5rem !important;
    }

    .pr-sm-4,
    .px-sm-4 {
        padding-right: 1.5rem !important;
    }

    .pb-sm-4,
    .py-sm-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-sm-4,
    .px-sm-4 {
        padding-left: 1.5rem !important;
    }

    .p-sm-5 {
        padding: 3rem !important;
    }

    .pt-sm-5,
    .py-sm-5 {
        padding-top: 3rem !important;
    }

    .pr-sm-5,
    .px-sm-5 {
        padding-right: 3rem !important;
    }

    .pb-sm-5,
    .py-sm-5 {
        padding-bottom: 3rem !important;
    }

    .pl-sm-5,
    .px-sm-5 {
        padding-left: 3rem !important;
    }

    .m-sm-auto {
        margin: auto !important;
    }

    .mt-sm-auto,
    .my-sm-auto {
        margin-top: auto !important;
    }

    .mr-sm-auto,
    .mx-sm-auto {
        margin-right: auto !important;
    }

    .mb-sm-auto,
    .my-sm-auto {
        margin-bottom: auto !important;
    }

    .ml-sm-auto,
    .mx-sm-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 768px) {
    .m-md-0 {
        margin: 0 !important;
    }

    .mt-md-0,
    .my-md-0 {
        margin-top: 0 !important;
    }

    .mr-md-0,
    .mx-md-0 {
        margin-right: 0 !important;
    }

    .mb-md-0,
    .my-md-0 {
        margin-bottom: 0 !important;
    }

    .ml-md-0,
    .mx-md-0 {
        margin-left: 0 !important;
    }

    .m-md-1 {
        margin: 0.25rem !important;
    }

    .mt-md-1,
    .my-md-1 {
        margin-top: 0.25rem !important;
    }

    .mr-md-1,
    .mx-md-1 {
        margin-right: 0.25rem !important;
    }

    .mb-md-1,
    .my-md-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-md-1,
    .mx-md-1 {
        margin-left: 0.25rem !important;
    }

    .m-md-2 {
        margin: 0.5rem !important;
    }

    .mt-md-2,
    .my-md-2 {
        margin-top: 0.5rem !important;
    }

    .mr-md-2,
    .mx-md-2 {
        margin-right: 0.5rem !important;
    }

    .mb-md-2,
    .my-md-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-md-2,
    .mx-md-2 {
        margin-left: 0.5rem !important;
    }

    .m-md-3 {
        margin: 1rem !important;
    }

    .mt-md-3,
    .my-md-3 {
        margin-top: 1rem !important;
    }

    .mr-md-3,
    .mx-md-3 {
        margin-right: 1rem !important;
    }

    .mb-md-3,
    .my-md-3 {
        margin-bottom: 1rem !important;
    }

    .ml-md-3,
    .mx-md-3 {
        margin-left: 1rem !important;
    }

    .m-md-4 {
        margin: 1.5rem !important;
    }

    .mt-md-4,
    .my-md-4 {
        margin-top: 1.5rem !important;
    }

    .mr-md-4,
    .mx-md-4 {
        margin-right: 1.5rem !important;
    }

    .mb-md-4,
    .my-md-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-md-4,
    .mx-md-4 {
        margin-left: 1.5rem !important;
    }

    .m-md-5 {
        margin: 3rem !important;
    }

    .mt-md-5,
    .my-md-5 {
        margin-top: 3rem !important;
    }

    .mr-md-5,
    .mx-md-5 {
        margin-right: 3rem !important;
    }

    .mb-md-5,
    .my-md-5 {
        margin-bottom: 3rem !important;
    }

    .ml-md-5,
    .mx-md-5 {
        margin-left: 3rem !important;
    }

    .p-md-0 {
        padding: 0 !important;
    }

    .pt-md-0,
    .py-md-0 {
        padding-top: 0 !important;
    }

    .pr-md-0,
    .px-md-0 {
        padding-right: 0 !important;
    }

    .pb-md-0,
    .py-md-0 {
        padding-bottom: 0 !important;
    }

    .pl-md-0,
    .px-md-0 {
        padding-left: 0 !important;
    }

    .p-md-1 {
        padding: 0.25rem !important;
    }

    .pt-md-1,
    .py-md-1 {
        padding-top: 0.25rem !important;
    }

    .pr-md-1,
    .px-md-1 {
        padding-right: 0.25rem !important;
    }

    .pb-md-1,
    .py-md-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-md-1,
    .px-md-1 {
        padding-left: 0.25rem !important;
    }

    .p-md-2 {
        padding: 0.5rem !important;
    }

    .pt-md-2,
    .py-md-2 {
        padding-top: 0.5rem !important;
    }

    .pr-md-2,
    .px-md-2 {
        padding-right: 0.5rem !important;
    }

    .pb-md-2,
    .py-md-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-md-2,
    .px-md-2 {
        padding-left: 0.5rem !important;
    }

    .p-md-3 {
        padding: 1rem !important;
    }

    .pt-md-3,
    .py-md-3 {
        padding-top: 1rem !important;
    }

    .pr-md-3,
    .px-md-3 {
        padding-right: 1rem !important;
    }

    .pb-md-3,
    .py-md-3 {
        padding-bottom: 1rem !important;
    }

    .pl-md-3,
    .px-md-3 {
        padding-left: 1rem !important;
    }

    .p-md-4 {
        padding: 1.5rem !important;
    }

    .pt-md-4,
    .py-md-4 {
        padding-top: 1.5rem !important;
    }

    .pr-md-4,
    .px-md-4 {
        padding-right: 1.5rem !important;
    }

    .pb-md-4,
    .py-md-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-md-4,
    .px-md-4 {
        padding-left: 1.5rem !important;
    }

    .p-md-5 {
        padding: 3rem !important;
    }

    .pt-md-5,
    .py-md-5 {
        padding-top: 3rem !important;
    }

    .pr-md-5,
    .px-md-5 {
        padding-right: 3rem !important;
    }

    .pb-md-5,
    .py-md-5 {
        padding-bottom: 3rem !important;
    }

    .pl-md-5,
    .px-md-5 {
        padding-left: 3rem !important;
    }

    .m-md-auto {
        margin: auto !important;
    }

    .mt-md-auto,
    .my-md-auto {
        margin-top: auto !important;
    }

    .mr-md-auto,
    .mx-md-auto {
        margin-right: auto !important;
    }

    .mb-md-auto,
    .my-md-auto {
        margin-bottom: auto !important;
    }

    .ml-md-auto,
    .mx-md-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 992px) {
    .m-lg-0 {
        margin: 0 !important;
    }

    .mt-lg-0,
    .my-lg-0 {
        margin-top: 0 !important;
    }

    .mr-lg-0,
    .mx-lg-0 {
        margin-right: 0 !important;
    }

    .mb-lg-0,
    .my-lg-0 {
        margin-bottom: 0 !important;
    }

    .ml-lg-0,
    .mx-lg-0 {
        margin-left: 0 !important;
    }

    .m-lg-1 {
        margin: 0.25rem !important;
    }

    .mt-lg-1,
    .my-lg-1 {
        margin-top: 0.25rem !important;
    }

    .mr-lg-1,
    .mx-lg-1 {
        margin-right: 0.25rem !important;
    }

    .mb-lg-1,
    .my-lg-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-lg-1,
    .mx-lg-1 {
        margin-left: 0.25rem !important;
    }

    .m-lg-2 {
        margin: 0.5rem !important;
    }

    .mt-lg-2,
    .my-lg-2 {
        margin-top: 0.5rem !important;
    }

    .mr-lg-2,
    .mx-lg-2 {
        margin-right: 0.5rem !important;
    }

    .mb-lg-2,
    .my-lg-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-lg-2,
    .mx-lg-2 {
        margin-left: 0.5rem !important;
    }

    .m-lg-3 {
        margin: 1rem !important;
    }

    .mt-lg-3,
    .my-lg-3 {
        margin-top: 1rem !important;
    }

    .mr-lg-3,
    .mx-lg-3 {
        margin-right: 1rem !important;
    }

    .mb-lg-3,
    .my-lg-3 {
        margin-bottom: 1rem !important;
    }

    .ml-lg-3,
    .mx-lg-3 {
        margin-left: 1rem !important;
    }

    .m-lg-4 {
        margin: 1.5rem !important;
    }

    .mt-lg-4,
    .my-lg-4 {
        margin-top: 1.5rem !important;
    }

    .mr-lg-4,
    .mx-lg-4 {
        margin-right: 1.5rem !important;
    }

    .mb-lg-4,
    .my-lg-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-lg-4,
    .mx-lg-4 {
        margin-left: 1.5rem !important;
    }

    .m-lg-5 {
        margin: 3rem !important;
    }

    .mt-lg-5,
    .my-lg-5 {
        margin-top: 3rem !important;
    }

    .mr-lg-5,
    .mx-lg-5 {
        margin-right: 3rem !important;
    }

    .mb-lg-5,
    .my-lg-5 {
        margin-bottom: 3rem !important;
    }

    .ml-lg-5,
    .mx-lg-5 {
        margin-left: 3rem !important;
    }

    .p-lg-0 {
        padding: 0 !important;
    }

    .pt-lg-0,
    .py-lg-0 {
        padding-top: 0 !important;
    }

    .pr-lg-0,
    .px-lg-0 {
        padding-right: 0 !important;
    }

    .pb-lg-0,
    .py-lg-0 {
        padding-bottom: 0 !important;
    }

    .pl-lg-0,
    .px-lg-0 {
        padding-left: 0 !important;
    }

    .p-lg-1 {
        padding: 0.25rem !important;
    }

    .pt-lg-1,
    .py-lg-1 {
        padding-top: 0.25rem !important;
    }

    .pr-lg-1,
    .px-lg-1 {
        padding-right: 0.25rem !important;
    }

    .pb-lg-1,
    .py-lg-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-lg-1,
    .px-lg-1 {
        padding-left: 0.25rem !important;
    }

    .p-lg-2 {
        padding: 0.5rem !important;
    }

    .pt-lg-2,
    .py-lg-2 {
        padding-top: 0.5rem !important;
    }

    .pr-lg-2,
    .px-lg-2 {
        padding-right: 0.5rem !important;
    }

    .pb-lg-2,
    .py-lg-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-lg-2,
    .px-lg-2 {
        padding-left: 0.5rem !important;
    }

    .p-lg-3 {
        padding: 1rem !important;
    }

    .pt-lg-3,
    .py-lg-3 {
        padding-top: 1rem !important;
    }

    .pr-lg-3,
    .px-lg-3 {
        padding-right: 1rem !important;
    }

    .pb-lg-3,
    .py-lg-3 {
        padding-bottom: 1rem !important;
    }

    .pl-lg-3,
    .px-lg-3 {
        padding-left: 1rem !important;
    }

    .p-lg-4 {
        padding: 1.5rem !important;
    }

    .pt-lg-4,
    .py-lg-4 {
        padding-top: 1.5rem !important;
    }

    .pr-lg-4,
    .px-lg-4 {
        padding-right: 1.5rem !important;
    }

    .pb-lg-4,
    .py-lg-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-lg-4,
    .px-lg-4 {
        padding-left: 1.5rem !important;
    }

    .p-lg-5 {
        padding: 3rem !important;
    }

    .pt-lg-5,
    .py-lg-5 {
        padding-top: 3rem !important;
    }

    .pr-lg-5,
    .px-lg-5 {
        padding-right: 3rem !important;
    }

    .pb-lg-5,
    .py-lg-5 {
        padding-bottom: 3rem !important;
    }

    .pl-lg-5,
    .px-lg-5 {
        padding-left: 3rem !important;
    }

    .m-lg-auto {
        margin: auto !important;
    }

    .mt-lg-auto,
    .my-lg-auto {
        margin-top: auto !important;
    }

    .mr-lg-auto,
    .mx-lg-auto {
        margin-right: auto !important;
    }

    .mb-lg-auto,
    .my-lg-auto {
        margin-bottom: auto !important;
    }

    .ml-lg-auto,
    .mx-lg-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 1200px) {
    .m-xl-0 {
        margin: 0 !important;
    }

    .mt-xl-0,
    .my-xl-0 {
        margin-top: 0 !important;
    }

    .mr-xl-0,
    .mx-xl-0 {
        margin-right: 0 !important;
    }

    .mb-xl-0,
    .my-xl-0 {
        margin-bottom: 0 !important;
    }

    .ml-xl-0,
    .mx-xl-0 {
        margin-left: 0 !important;
    }

    .m-xl-1 {
        margin: 0.25rem !important;
    }

    .mt-xl-1,
    .my-xl-1 {
        margin-top: 0.25rem !important;
    }

    .mr-xl-1,
    .mx-xl-1 {
        margin-right: 0.25rem !important;
    }

    .mb-xl-1,
    .my-xl-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-xl-1,
    .mx-xl-1 {
        margin-left: 0.25rem !important;
    }

    .m-xl-2 {
        margin: 0.5rem !important;
    }

    .mt-xl-2,
    .my-xl-2 {
        margin-top: 0.5rem !important;
    }

    .mr-xl-2,
    .mx-xl-2 {
        margin-right: 0.5rem !important;
    }

    .mb-xl-2,
    .my-xl-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-xl-2,
    .mx-xl-2 {
        margin-left: 0.5rem !important;
    }

    .m-xl-3 {
        margin: 1rem !important;
    }

    .mt-xl-3,
    .my-xl-3 {
        margin-top: 1rem !important;
    }

    .mr-xl-3,
    .mx-xl-3 {
        margin-right: 1rem !important;
    }

    .mb-xl-3,
    .my-xl-3 {
        margin-bottom: 1rem !important;
    }

    .ml-xl-3,
    .mx-xl-3 {
        margin-left: 1rem !important;
    }

    .m-xl-4 {
        margin: 1.5rem !important;
    }

    .mt-xl-4,
    .my-xl-4 {
        margin-top: 1.5rem !important;
    }

    .mr-xl-4,
    .mx-xl-4 {
        margin-right: 1.5rem !important;
    }

    .mb-xl-4,
    .my-xl-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-xl-4,
    .mx-xl-4 {
        margin-left: 1.5rem !important;
    }

    .m-xl-5 {
        margin: 3rem !important;
    }

    .mt-xl-5,
    .my-xl-5 {
        margin-top: 3rem !important;
    }

    .mr-xl-5,
    .mx-xl-5 {
        margin-right: 3rem !important;
    }

    .mb-xl-5,
    .my-xl-5 {
        margin-bottom: 3rem !important;
    }

    .ml-xl-5,
    .mx-xl-5 {
        margin-left: 3rem !important;
    }

    .p-xl-0 {
        padding: 0 !important;
    }

    .pt-xl-0,
    .py-xl-0 {
        padding-top: 0 !important;
    }

    .pr-xl-0,
    .px-xl-0 {
        padding-right: 0 !important;
    }

    .pb-xl-0,
    .py-xl-0 {
        padding-bottom: 0 !important;
    }

    .pl-xl-0,
    .px-xl-0 {
        padding-left: 0 !important;
    }

    .p-xl-1 {
        padding: 0.25rem !important;
    }

    .pt-xl-1,
    .py-xl-1 {
        padding-top: 0.25rem !important;
    }

    .pr-xl-1,
    .px-xl-1 {
        padding-right: 0.25rem !important;
    }

    .pb-xl-1,
    .py-xl-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-xl-1,
    .px-xl-1 {
        padding-left: 0.25rem !important;
    }

    .p-xl-2 {
        padding: 0.5rem !important;
    }

    .pt-xl-2,
    .py-xl-2 {
        padding-top: 0.5rem !important;
    }

    .pr-xl-2,
    .px-xl-2 {
        padding-right: 0.5rem !important;
    }

    .pb-xl-2,
    .py-xl-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-xl-2,
    .px-xl-2 {
        padding-left: 0.5rem !important;
    }

    .p-xl-3 {
        padding: 1rem !important;
    }

    .pt-xl-3,
    .py-xl-3 {
        padding-top: 1rem !important;
    }

    .pr-xl-3,
    .px-xl-3 {
        padding-right: 1rem !important;
    }

    .pb-xl-3,
    .py-xl-3 {
        padding-bottom: 1rem !important;
    }

    .pl-xl-3,
    .px-xl-3 {
        padding-left: 1rem !important;
    }

    .p-xl-4 {
        padding: 1.5rem !important;
    }

    .pt-xl-4,
    .py-xl-4 {
        padding-top: 1.5rem !important;
    }

    .pr-xl-4,
    .px-xl-4 {
        padding-right: 1.5rem !important;
    }

    .pb-xl-4,
    .py-xl-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-xl-4,
    .px-xl-4 {
        padding-left: 1.5rem !important;
    }

    .p-xl-5 {
        padding: 3rem !important;
    }

    .pt-xl-5,
    .py-xl-5 {
        padding-top: 3rem !important;
    }

    .pr-xl-5,
    .px-xl-5 {
        padding-right: 3rem !important;
    }

    .pb-xl-5,
    .py-xl-5 {
        padding-bottom: 3rem !important;
    }

    .pl-xl-5,
    .px-xl-5 {
        padding-left: 3rem !important;
    }

    .m-xl-auto {
        margin: auto !important;
    }

    .mt-xl-auto,
    .my-xl-auto {
        margin-top: auto !important;
    }

    .mr-xl-auto,
    .mx-xl-auto {
        margin-right: auto !important;
    }

    .mb-xl-auto,
    .my-xl-auto {
        margin-bottom: auto !important;
    }

    .ml-xl-auto,
    .mx-xl-auto {
        margin-left: auto !important;
    }
}

.text-justify {
    text-align: justify !important;
}

.text-nowrap {
    white-space: nowrap !important;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

@media (min-width: 576px) {
    .text-sm-left {
        text-align: left !important;
    }

    .text-sm-right {
        text-align: right !important;
    }

    .text-sm-center {
        text-align: center !important;
    }
}

@media (min-width: 768px) {
    .text-md-left {
        text-align: left !important;
    }

    .text-md-right {
        text-align: right !important;
    }

    .text-md-center {
        text-align: center !important;
    }
}

@media (min-width: 992px) {
    .text-lg-left {
        text-align: left !important;
    }

    .text-lg-right {
        text-align: right !important;
    }

    .text-lg-center {
        text-align: center !important;
    }
}

@media (min-width: 1200px) {
    .text-xl-left {
        text-align: left !important;
    }

    .text-xl-right {
        text-align: right !important;
    }

    .text-xl-center {
        text-align: center !important;
    }
}

.text-lowercase {
    text-transform: lowercase !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}

.font-weight-light {
    font-weight: 300 !important;
}

.font-weight-normal {
    font-weight: 400 !important;
}

.font-weight-bold {
    font-weight: 700 !important;
}

.font-italic {
    font-style: italic !important;
}

.text-white {
    color: #fff !important;
}

.text-primary {
    color: #007bff !important;
}

a.text-primary:hover,
a.text-primary:focus {
    color: #0062cc !important;
}

.text-secondary {
    color: #6c757d !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
    color: #545b62 !important;
}

.text-success {
    color: #28a745 !important;
}

a.text-success:hover,
a.text-success:focus {
    color: #1e7e34 !important;
}

.text-info {
    color: #17a2b8 !important;
}

a.text-info:hover,
a.text-info:focus {
    color: #117a8b !important;
}

.text-warning {
    color: #ffc107 !important;
}

a.text-warning:hover,
a.text-warning:focus {
    color: #d39e00 !important;
}

.text-danger {
    color: #dc3545 !important;
}

a.text-danger:hover,
a.text-danger:focus {
    color: #bd2130 !important;
}

.text-light {
    color: #f8f9fa !important;
}

a.text-light:hover,
a.text-light:focus {
    color: #dae0e5 !important;
}

.text-dark {
    color: #343a40 !important;
}

a.text-dark:hover,
a.text-dark:focus {
    color: #1d2124 !important;
}

.text-muted {
    color: #6c757d !important;
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.visible {
    visibility: visible !important;
}

.invisible {
    visibility: hidden !important;
}

@media print {

    *,
    *::before,
    *::after {
        text-shadow: none !important;
        box-shadow: none !important;
    }

    a:not(.btn) {
        text-decoration: underline;
    }

    abbr[title]::after {
        content: " ("attr(title) ")";
    }

    pre {
        white-space: pre-wrap !important;
    }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }

    @page {
        size: a3;
    }

    body {
        min-width: 992px !important;
    }

    .container {
        min-width: 992px !important;
    }

    .navbar {
        display: none;
    }

    .badge {
        border: 1px solid #000;
    }

    .table {
        border-collapse: collapse !important;
    }

    .table td,
    .table th {
        background-color: #fff !important;
    }

    .table-bordered th,
    .table-bordered td {
        border: 1px solid #ddd !important;
    }
}

/*# sourceMappingURL=bootstrap.css.map */
/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
.owl-theme .owl-dots,.owl-theme .owl-nav{text-align:center;-webkit-tap-highlight-color:transparent}.owl-theme .owl-nav{margin-top:10px}.owl-theme .owl-nav [class*=owl-]{color:#FFF;font-size:14px;margin:5px;padding:4px 7px;background:#D6D6D6;display:inline-block;cursor:pointer;border-radius:3px}.owl-theme .owl-nav [class*=owl-]:hover{background:#869791;color:#FFF;text-decoration:none}.owl-theme .owl-nav .disabled{opacity:.5;cursor:default}.owl-theme .owl-nav.disabled+.owl-dots{margin-top:10px}.owl-theme .owl-dots .owl-dot{display:inline-block;zoom:1}.owl-theme .owl-dots .owl-dot span{width:10px;height:10px;margin:5px 7px;background:#D6D6D6;display:block;-webkit-backface-visibility:visible;transition:opacity .2s ease;border-radius:30px}.owl-theme .owl-dots .owl-dot.active span,.owl-theme .owl-dots .owl-dot:hover span{background:#869791}
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 64px 0 0;
  padding: 0;
  font-family: "Libre Baskerville", serif;
  font-size: 16px;
  line-height: 1.5;
}

.only-mobile {
  display: none;
}
.only-desktop {
  display: block;
}

nav {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 1px solid #e8e8e8;
  padding: 15px 40px;
  background-color: white;
  z-index: 99;
}

.nav-left {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.nav-right {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

#burger-menu {
  width: 28px;
  cursor: pointer;
}

#nav-logo {
  margin-left: 30px;
  width: 188px;
  cursor: pointer;
}

#nav-search {
  margin-right: 60px;
  width: 24px;
  cursor: pointer;
}

.profile-icon {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: #fff;
  background-color: #da3a50;
  border-color: #da3a50;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  cursor: pointer;
}

.blogHeader {
  width: 100%;
  height: 40% !important;
  background-color: #da3950;
  background-image: url(/search/static/media/header.e0d889d9.png);
  background-size: 100% 100%;
  background-position: center center;
  margin-bottom: 64px;
}

main {
  margin: 0 auto;
  max-width: 1110px;
  /* display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; */
}
.mainBlog{
  max-width: 1200px !important;

}

.slider-header {
  padding: 16px;
  position: relative;
  color: #444444;
}
.slider-title {
  font-size: 24px;
}

.slider-subtitle p {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  padding-right: 64px;
  margin-bottom: -7px;
}
.slider-subtitle {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  padding-right: 64px;
  padding: 4px 64px 4px 0px;
  font-weight:600;
}

.slider-view-all {
  position: absolute;
  bottom: 16px;
  font-weight:600;
  padding:0 15px 0 0;
  right: 16px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  cursor: pointer;
}

.splide__arrow {
  background-color: white;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.16);
  top: 25%;
  opacity: 1;
}

.splide__arrow--next {
  right: 0;
}

.splide__arrow--prev {
  left: 0;
}

.splide__pagination__page.is-active {
  background: #000;
}

.blog-card {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  /* border-bottom: 1px solid #e8e8e8; */
  padding: 0 16px 64px;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  font-family: "adobeCleanReg"!important;
}
.blog-card-bl {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  /* border-bottom: 1px solid #e8e8e8; */
  padding: 0 16px 64px;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

.card-image-bl {
  width: 100%;
  /*height: 206px;*/
  cursor: pointer;
}

.card-details-bl {
  width: 100%;
  padding-right: 0;
  font-family: "adobeCleanReg"!important;
}

.card-title-bl {
  letter-spacing: 0.5px;
  font-size: 24px;
  margin-bottom: 8px;
  line-height: 1.5;
  height:60px;
  color: #444444;
  cursor: pointer;
  margin-top: 20px;
  font-weight: normal;
  font-family: 'Libre Baskerville';
}

.card-description-bl {
  font-size: 14px;
  color: #404040;
  font-weight:600!important;
  margin-bottom: 8px;
  margin-top: 7%;
  font-family: 'Libre Baskerville';
}
.card-description-bl p {
  font-size: 14px;
  color: #444444;
  margin-bottom: 8px;
  font-weight:600;
  height: 64px;
  overflow: hidden;
}

.card-icons-bl {
  font-size: 12px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
  font-family: "Open Sans", sans-serif;
}

.card-icons-bl .card-icon-img {
  width: 19px;
  margin-left: 8px;
  cursor: pointer;
}

.blog-card:last-child {
  border-bottom: none;
}
.blog-card-bl:last-child {
  border-bottom: none;
}
.load-wrapper {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 128px;
  margin-top: 32px;
}

.load-btn {
  font-family: "Open Sans", sans-serif;
  width: 200px;
  border: 1px solid #444444;
  text-align: center;
  padding: 8px;
  color: #444444;
  font-weight: bold;
  cursor: pointer;
}

.load-btn:hover {
  background-color: #da3950;
  color: white;
  border: 1px solid transparent;
}
@media only screen and (max-width: 950px) {
  .blogHeader{display:block;height:30% !important;object-fit:cover;margin-top:-15px}
}
@media only screen and (max-width: 768px) {
  .blog-page-bl .shHeader{height:55px;}
  .blogHeader{display:block;height:20% !important;object-fit:cover;margin-top:-15px}
  .blog-card a{width:100%;}
  .blog-card .card-image-bl{width:100%;}
  .only-mobile {
    display: block;
  }
  .only-desktop {
    display: none;
  }
  nav {
    padding: 15px 16px;
  }
  #nav-search {
    margin-right: 0;
  }
  .profile-icon {
    display: none;
  }

  header {
    height: 112px;
    margin-bottom: 16px;
  }
  .splide__arrow {
    display: none;
  }

  .card-details {
    padding: 0;
  }
  .blog-card {
    width: 100%;
  }
  .blog-card-bl {
    width: 100%;
  }
  .slider-view-all {
    position: absolute;
    font-weight:600;
    bottom: 16px;
    right: 16px;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    cursor: pointer;
    font-style: italic;
  }
}
.slider-header-width {
  width: 100%;
  margin-top:6%;
}
.splide__track li {
  width: 341.333px !important;
}
#slider-one {
  width: -webkit-fill-available;
}

.blog-page-bl .react-multiple-carousel__arrow {
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 50%;
  z-index: 1000;
  border: 0;
  background: #fff;
  min-width: 36px;
  min-height: 36px;
  height: 26px;
  opacity: 1;
  cursor: pointer;
  margin-top: -20%;
  margin-right: -44px;
  -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.26);
}
.blog-page-bl  .react-multiple-carousel__arrow:hover {
  background: #fff;
}
.blog-page-bl .react-multiple-carousel__arrow--left {
  margin-left: -4%;
}
.blog-page-bl .react-multiple-carousel__arrow::before {
  color: #000;
  font-weight: bolder;
  font-size: 16px;
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 64px 0 0;
  padding: 0;
  font-family: "Libre Baskerville", serif;
  font-size: 16px;
  line-height: 1.5;
}

.only-mobile {
  display: none;
}
.only-desktop {
  display: block;
}

nav {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 1px solid #e8e8e8;
  padding: 15px 40px;
  background-color: white;
  z-index: 99;
}

.nav-left {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.nav-right {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

#burger-menu {
  width: 28px;
  cursor: pointer;
}

#nav-logo {
  margin-left: 30px;
  width: 188px;
  cursor: pointer;
}

#nav-search {
  margin-right: 60px;
  width: 24px;
  cursor: pointer;
}

.profile-icon {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: #fff;
  background-color: #da3a50;
  border-color: #da3a50;
  border-radius: 50%;
  text-transform: capitalize;
  width: 40px;
  height: 40px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  cursor: pointer;
}

#header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
   -webkit-align-items: center;
           align-items: center; 
  text-align: left;
  margin-left: 16px;
}

#header .header-h1 {
  color: #444444;
  font-size: 24px;
  font-weight: normal;
  margin-top: 25px;
  margin-bottom:20px;
}

#header .sub-title {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight:bold;
}

#filters2 {
  width: 100%;
  max-width: 1024px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  margin: 16px 0;
  padding: 32px 16px;
}

.filter-inputs-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-left: 64px;
}

#search-result-count {
  color: #da3a50;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
}

.filter-label {
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
  margin-right: 16px;
  color: #707070;
}

.filter-select {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
  margin-left: 16px;
}

.filter-select .library-select {
  width: 256px;
  background-color: #f5f5f5;
  border: none;
  padding: 10px 16px;
  border-radius: 8px;
  -webkit-appearance: none;
  appearance: none;
}

.filter-select:after {
  content: "";
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 16px;
  height: 16px;
  /* background-image: url(images/down-arrow.png); */
  background-size: contain;
  pointer-events: none;
}

main {
  margin: 0 auto;
  max-width: 1024px;
  display: -webkit-flex;
  display: flex;
  /* justify-content: space-between; */
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.blog-card {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  /* border-bottom: 1px solid #e8e8e8; */
  padding: 32px 16px;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 33.33%;
}

/* .card-image {
  width: 100%;
  height:200px;
  cursor: pointer;
} */

.card-left2 {
  width: 100%;
}

.card-title-library {
  letter-spacing: 0.5px;
  font-size: 24px;
  margin-bottom: 8px;
  line-height: 1.5;
  color: #444444;
  cursor: pointer;
  height:60px;
  margin-top: 20px;
  /* text-transform: capitalize; */
  font-weight: normal;
  font-family: 'Libre Baskerville';
}

.card-description-library {
  font-size: 14px;
  color: #404040;
  margin-bottom: 8px;
  margin-top: 20px;
  text-transform: none;
  text-align: left;
  height:66px;
  font-weight:600;
  overflow:hidden;
font-family: 'Libre Baskerville';
}

.card-description-library p{
  font-size: 14px;
  color: #404040;
  margin-bottom: 8px;
  text-transform:none;
  text-align: left;
  line-height:1.5;
  font-weight: 600;
}
.card-author-library {
  font-size: 14px;
  color: #444444;
  margin-bottom: 10px;
  font-family: "Open Sans", sans-serif;
  /* font-weight: bold; */
  cursor: pointer;
  text-transform: capitalize;
  height:18px;
  overflow: hidden;
  width: 213px!important;
  float: left;
}

.card-icons-library {
  font-size: 14px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
  font-family: "Open Sans", sans-serif;
}

.card-icon-img-library {
  width: 19px;
  margin-left: 8px;
  cursor: pointer;
}

.blog-card:last-child {
  border-bottom: none;
}

.load-wrapper {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 128px;
  margin-top: 32px;
}

.load-btn {
  font-family: "Open Sans", sans-serif;
  width: 200px;
  border: 1px solid #444444;
  text-align: center;
  padding: 8px;
  color: #444444;
  font-weight: bold;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .card-image2{
    width: 100% !important;
    height: 100%!important;
  }
  .only-mobile {
    display: block;
  }
  .only-desktop {
    display: none;
  }
  nav {
    padding: 15px 16px;
  }
  #nav-search {
    margin-right: 0;
  }
  .profile-icon {
    display: none;
  }

  #search-form {
    width: 300px;
  }

  #filters2 {
    -webkit-flex-direction: column;
            flex-direction: column;
    border-top: none;
    /* margin: 16px 0; */
    padding: 8px 16px!important;
  }

  .filter-select {
    width: 100%;
    -webkit-flex-direction: column;
            flex-direction: column;
    max-width: 256px;
    -webkit-align-items: center;
            align-items: center;
    padding: 8px 0;
  }

  .filter-select select {
    width: 256px;
  }

  #search-result-count {
    margin-bottom: 8px;
  }

  .filter-inputs-wrapper {
    width: 100%;
    margin-left: 0;
  }

  .filter-select:first-child {
    margin: 0;
  }

  .filter-select:after {
    bottom: 18px;
    right: 16px;
  }

  .filter-label {
    padding: 8px 0;
  }

  .card-left2 {
    padding: 0px 16px;
  }
  .blog-card {
    width: 100%;
  }
}

.social-icon a{color:grey;padding: 4px; font-size: 12px;width: 100px;font-family: "Open Sans", sans-serif;
  font-weight: normal;}
[role=tooltip].popup-content{width:120px !important; }
.btn-secondary{
  background-color: none !important;
  border-color: none !important;
}
.main-library{
  margin-top:10px;
  max-width: 82%;
}
.popup-content {
  padding:8px 12px;
}
.bookmarkIcon div{
  height: auto;
  width: auto;
}

.only-mobile {
  display: none;
}
.only-desktop {
  display: block;
}
nav {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 1px solid #e8e8e8;
  padding: 15px 40px;
  background-color: white;
  z-index: 99;
}

.nav-left {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.nav-right {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
#burger-menu {
  width: 28px;
  cursor: pointer;
}

#nav-logo {
  margin-left: 30px;
  width: 188px;
  cursor: pointer;
}

#nav-search {
  margin-right: 60px;
  width: 24px;
  cursor: pointer;
}
.modalClass {  
  text-align: center;  
  margin-top: 100px;  
} 
.logo {
  position: absolute;
  width: 100%;
  color: #fff;
  top: 50%;
  left: 44%;
  margin-top: 13%;
 }
.img1 {
  width: 100%;
  height: 100px;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  vertical-align: middle;
}
.img-hover-zoom {
  overflow: hidden; 
  height: 574px;
  }
.img-hover-zoom img {
  transition: -webkit-transform .5s ease !important;
  transition: transform .5s ease !important;
  transition: transform .5s ease, -webkit-transform .5s ease !important;
}
.img-hover-zoom:hover img {
  -webkit-transform: scale(1.5) !important;
          transform: scale(1.5) !important;
  visibility: visible !important;
}
.centered {
  position:absolute;
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
  text-align: center;
  text-overflow: visible;
  font-weight: bolder;
  width: 100%;
  z-index: 100;
  font-size: 24px;
  color: #fff;
  top: 48%;
  overflow: visible;
}
.react-multi-carousel-list {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  overflow: hidden;
  position: relative;
}
.react-multi-carousel-track {
  list-style: none;
  padding: 0;
  margin: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  position: relative;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  will-change: transform,transition;
}
.react-multi-carousel-item {
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}

.react-multiple-carousel__arrow {
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 50%;
  z-index: 500;
  border: 0;
  background: rgb(65, 61, 61);
  -webkit-tap-highlight-color: transparent;
  min-width: 36px;
  min-height: 36px;
  height: 36px;
  opacity: 1;
  cursor: pointer;
  margin-right: -34px;
  -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.26);
}
.react-multiple-carousel__arrow:hover {
  background: rgba(0, 0, 0, 0.8);
}
.react-multiple-carousel__arrow--left {
  margin-left: -3%;
}
.react-multiple-carousel__arrow::before {
  color: #fff;
  font-weight: bolder;
  font-size: 16px;
}
@media screen and (max-width: 1024px) {
  .logo {
    position: absolute;
    width: 100%;
    color: #fff;
    top: 50%;
    left: 44%;
    margin-top: 13%;
   }

}
@media screen and (max-width: 768px) {
  .img1 {
    display: block;
    object-fit: cover;
  }
  .centered {
    position: absolute;
    font-family: "Open Sans", sans-serif;
    text-align: center;
    font-size: 20px;
    color: #fff; 
    top:50%;
    height: auto;
    width:100%;
  }
  .zoom {
    width: 100%;
  }
  .only-mobile {
    display: block;
  }
  .only-desktop {
    display: none;
  }
  nav {
    padding: 15px 16px;
  }
  #nav-search {
    margin-right: 0;
  }
  .img1 {
    width: 100%;
    height: 200px;
    display: block;
    
  }
  .zoom {
    width: 100%;
  }
}
.logo{left:47%;top:45%}
@font-face {
  font-family: adobeCleanReg;
  src: url(/search/static/media/AdobeCleanRegular.7853f3ae.otf); }
@font-face {
  font-family: adobeCleanLi;
  src: url(/search/static/media/AdobeCleanLight.883716c0.otf); }
  
.topnav {
   background-color:white;
   overflow: hidden;
   
 }
 
 /* Style the links inside the navigation bar */
 .topnav a {
   float: left;
   display:block;
   color:rgb(80, 70, 70);
   -webkit-flex-flow: row;
           flex-flow: row;
   text-align: center;
   -webkit-text-decoration-line: underline;
           text-decoration-line: underline;
   padding: 40px 80px;
   text-decoration: none;
   font-size: 17px;
 }
 
 .topnav a:hover {
   border-bottom: 0.5px solid black;
   text-underline-position: auto;
 }
 
 .topnav a.active {
   border-bottom: 3px solid black;
 }

.topnav-right {
   float: right;
 }
.header{
   color:rgba(255, 0, 0, 0.863);
   border-bottom: 1px solid #E8E8E8;
   overflow:hidden;
}

.imgLink a:hover{
  color: #DA3A50!important;
  text-decoration: none!important;
}

.contentFont {
  font-family: "adobeCleanReg"!important;
  font-size: 16px!important;
  font-weight: 400!important;
  
}

p {
  line-height: 1.7!important;
  color:#444444
}
.menu ul li {
  color:#444444;
}
.about-ab .breadcrumb{display: none;}
.team{color:#444444}
.menu{  
  word-break: break-word;
}
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 64px 0 0;
  padding: 0;
  font-family: "Libre Baskerville", serif;
  font-size: 16px;
  line-height: 1.5;
}

.only-mobile {
  display: none;
}
.only-desktop {
  display: block;
}

nav {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 1px solid #e8e8e8;
  padding: 15px 40px;
  background-color: white;
  z-index: 99;
}

.nav-left {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.nav-right {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

#burger-menu {
  width: 28px;
  cursor: pointer;
}

#nav-logo {
  margin-left: 30px;
  width: 188px;
  cursor: pointer;
}

#nav-search {
  margin-right: 60px;
  width: 24px;
  cursor: pointer;
}

.profile-icon {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: #fff;
  background-color: #da3a50;
  border-color: #da3a50;
  border-radius: 50%;
  text-transform: capitalize;
  width: 40px;
  height: 40px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  cursor: pointer;
}

header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

header .header-h1 {
  color: #444444;
  font-size: 24px;
  font-weight: normal;
  margin-top: 64px;
}
header .header-h1 {
  color: #444444;
  font-size: 24px;
  font-weight: normal;
  margin-top: 64px;
}

header .sub-title {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  margin-bottom: 16px;
}
header .link-h1 {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  margin-bottom: 16px;
}

#filters2 {
  width: 100%;
  max-width: 1024px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  margin: 16px 0;
  padding: 32px 16px;
}

.filter-inputs-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-left: 64px;
}

#search-result-count {
  color: #da3a50;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
}

.filter-label {
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
  margin-right: 16px;
  color: #707070;
}

.filter-select {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
  margin-left: 16px;
}

.filter-select .library-select {
  width: 256px;
  background-color: #f5f5f5;
  border: none;
  padding: 10px 16px;
  border-radius: 8px;
  -webkit-appearance: none;
  appearance: none;
}

.filter-select:after {
  content: "";
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 16px;
  height: 16px;
  /* background-image: url(images/down-arrow.png); */
  background-size: contain;
  pointer-events: none;
}

main {
  margin: 0 auto;
  max-width: 1024px;
  display: -webkit-flex;
  display: flex;
  /* justify-content: space-between; */
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.blog-card {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  /* border-bottom: 1px solid #e8e8e8; */
  padding: 32px 16px;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 33.33%;
}

/* .card-image {
  width: 100%;
  height:200px;
  cursor: pointer;
} */

.card-left {
  width: 100%;
}

.card-title {
  letter-spacing: 0.5px;
  font-size: 20px;
  margin-bottom: 8px;
  line-height: 1.5;
  color: #444444;
  cursor: pointer;
  margin-top: 20px;
  text-transform: capitalize;
  height: 60px;
  overflow:hidden;
}

.card-description {
  font-size: 12px;
  color: #404040;
  margin-bottom: 8px;
  text-transform: capitalize;
  text-align: justify;
  height:55px;
  overflow:hidden;
}

.card-author {
  font-size: 10px;
  color: #444444;
  margin-bottom: 8px;
  font-family: "Open Sans", sans-serif;
  /* font-weight: bold; */
  cursor: pointer;
  text-transform: capitalize;
  height:15px;
  overflow: hidden;

}

.card-icons {
  font-size: 12px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
  font-family: "Open Sans", sans-serif;
}

.card-icon-img {
  width: 19px;
  margin-left: 8px;
  cursor: pointer;
}

.blog-card:last-child {
  border-bottom: none;
}

.load-wrapper {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 128px;
  margin-top: 32px;
}

.load-btn {
  font-family: "Open Sans", sans-serif;
  width: 200px;
  border: 1px solid #444444;
  text-align: center;
  padding: 8px;
  color: #444444;
  font-weight: bold;
  cursor: pointer;
}
.media-container {
  position: absolute;
  width: 90%;
  overflow:hidden;
  background-color: #000000;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
}
.media-caps{
  width: 90%;
  height: 70%;
  overflow:hidden;
  position: absolute;
}
.shadow{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
}

.media-image {
  opacity: 1;
  display: block;
  width: 100%;
  transition: .5s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.media-middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 55%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.media-container:hover .media-image {
  opacity: 0.3;
}

.media-container:hover .media-middle {
  opacity: 1;
}

.media-text {
  background-color: #04AA6D;
  color: white;
  font-size: 16px;
  padding: 16px 32px;
}


@media only screen and (max-width: 768px) {
  .only-mobile {
    display: block;
  }
  .only-desktop {
    display: none;
  }
  nav {
    padding: 15px 16px;
  }
  #nav-search {
    margin-right: 0;
  }
  .profile-icon {
    display: none;
  }
  .check{
    top:0px !important;
  }
  .media-container {
    position: absolute;
    width: 60%;
    height: 30%;
    overflow:hidden;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
  }
  .media-image {
    opacity: 0.3;
    display: block;
    width: 100%;
    height: 100%;
    transition: .5s ease;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
  }
  .media-image1 {
    opacity: 1;
    display: block;
    width: 100%;
    height: 100%;
    transition: .5s ease;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
  }
  .media-middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 55%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    opacity: 1;
  }
  
  .media-text {
    background-color: #04AA6D;
    color: white;
    font-size: 16px;
    padding: 16px 32px;
  }
  #search-form {
    width: 300px;
  }

  #filters2 {
    -webkit-flex-direction: column;
            flex-direction: column;
    border-top: none;
  }

  .filter-select {
    width: 100%;
    -webkit-flex-direction: column;
            flex-direction: column;
    max-width: 256px;
    -webkit-align-items: center;
            align-items: center;
    padding: 8px 0;
  }

  .filter-select select {
    width: 256px;
  }

  #search-result-count {
    margin-bottom: 8px;
  }

  .filter-inputs-wrapper {
    width: 100%;
    margin-left: 0;
  }

  .filter-select:first-child {
    margin: 0;
  }

  .filter-select:after {
    bottom: 18px;
    right: 16px;
  }

  .filter-label {
    padding: 8px 0;
  }

  .card-left {
    padding: 0;
  }
  .blog-card {
    width: 100%;
  }
}

.social-icon a{color:grey;padding: 2px; font-size: 12px;    width: 100px;}
[role=tooltip].popup-content{width:110px !important;}
.btn-secondary{
  background-color: none !important;
  border-color: none !important;
}
.filterImport option{
  width: auto;
}
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 64px 0 0;
  padding: 0;
  font-family: "Libre Baskerville", serif;
  font-size: 16px;
  line-height: 1.5;
}

.only-mobile {
  display: none;
}
.only-desktop {
  display: block;
}

nav {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 1px solid #e8e8e8;
  padding: 15px 40px;
  background-color: white;
  z-index: 99;
}

.nav-left {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.nav-right {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

#burger-menu {
  width: 28px;
  cursor: pointer;
}

#nav-logo {
  margin-left: 30px;
  width: 188px;
  cursor: pointer;
}

#nav-search {
  margin-right: 60px;
  width: 24px;
  cursor: pointer;
}

.profile-icon {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: #fff;
  background-color: #da3a50;
  border-color: #da3a50;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  cursor: pointer;
}

#header-search {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

#header-search h1 {
  color: #444444;
  font-size: 18px;
  font-weight: normal;
  margin-top: 64px;
}

#search-form {
  width: 360px;
  position: relative;
}

#search-form input {
  width: 100%;
  border: 1px solid #e8e8e8;
  padding: 10px 16px;
  margin: 10px 0 0px 0 !important;
  border-radius: 7px;
  font-family: "Open Sans", sans-serif;
}

#search-form-icon {
  position: absolute;
  top: 25px;
  right: 12px;
  font-size: 20px  !important;
}

#filters {
  width: 100%;
  max-width: 1024px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  margin: 16px 0;
  padding: 32px 16px;
}

.filter-inputs-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

#search-result-count {
  color: #da3a50;
  font-family: "Open Sans", sans-serif;
  font-size: 12px  !important;
}

.filter-label {
  font-size: 12px  !important;
  font-family: "Open Sans", sans-serif;
  margin-right: 16px ;
  color: #707070;
}

.filter-select {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
  margin-left: 16px;
}

.filter-select select {
  width: 256px;
  background-color: #f5f5f5;
  border: none;
  padding: 10px 16px;
  border-radius: 8px;
  -webkit-appearance: none;
  appearance: none;
  font-size: 12px;
    font-family: auto;
}
.filter-select .import-select {
  width: 156px;
  background-color: #f5f5f5;
  border: none;
  padding: 10px 16px;
  border-radius: 8px;
  -webkit-appearance: none;
  appearance: none;
  font-size: 12px;
    font-family: auto;
}
.filter-select:after {
  content: "";
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 16px;
  height: 16px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAqCAYAAADFw8lbAAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAKqADAAQAAAABAAAAKgAAAAD30ocxAAADb0lEQVRYCc2ZS3LaQBCGLWAf+wQBLhCyy84DBetwg9gnMD5BnBMEToC32XnPw+IEIRcAsssS9jzy/yo1NRJ6zIiXuko1rXl0f+rp0YzAuTlCWq3W/W63UzDB6xZXDZcuU9wscbmO47iDwWCiN9rojk1n9gVcbbvdPkFtwznhjAUPRei3QqHQAzQfwliMQQkIRz9hWRlbT+7IKD+bAqeCKqVuS6XSd/jsJPvN3Npdr9c/XNdltGMlEdSPYh+ja7EWTtMwRXQfk6IbC+rn4rttHmblZv4id+txsJGgl4aUh0uCPQC9FmQabEE6sOTCwVP1LzXdum/R6ZsMZJE6lgHQYrH4grpzLxz6TZOaz7Lvt596f4X/3rfkQEF0P8vi2kcU4ebLPFeiM3kRzWM0JWISVS+i2Ls70pC3Uthk6r/mDVDj8dicZrOpUPmuNSSpKzR+SOpwprY6I6oMjK9wcKgMh8Nb5gz6E/gYeYa9O0zro6ERZQraxelmQaN8XQBWQc0ESzg8MO0tx+PxK1Z2j3ZTpEbQ1KmEsbJuKCssIQmn2zLUPxI0dSdCBL81Go0H3agtbBQkX4uw/aTbjdG9iMa0BatxBOtnhGWK1MORlMNP0Ev8HSNqLBlgV8xn5KSrOxFItAUOHnqfsG4FysEWsB6k7NXiOAuk5xcL5Y8YMS0NYE8KScYCwr80BdT7JcFyuk8VSfqEvUWxWq2WoStc1gID7XK5/Hc+n09l8Gw2+8dL7llmnW7Nxi/mqKtVWKtRkdWNnACS5lzvmIf9ntOf+uLXAcJ63HsS9cd+ya64dXurHsn6FnZsex+O7IkieSNs5zg4M185Q8r2YaP6Yx14nyMeKDtg+l0U99RzJBNMuyKPN/VUQN5hmSfRmYoCxldKpVK5Q+MXqbtmidzsIZqvwrCPKCs2m80LdwFpvFZJhtFoFJjhACgPs1i9DwDkiedaskLA2mHnAVA22p4zwwaPvPfOCPI1odvar3q9kjrfg5gCF+pRGwFtGUrkQUbGHkRUGiSyl8hZ+sAiPjjICAvL2IhKJ/6qxh+sYMjkk0GGGZeA7IUXTtTgVFAZ5KdCF/en2hQmePhO+Dgo/sKlMagM9PfwDpxwZdrm7woR5N83XVNA8WsNKgNZ+r+yKKgKAPxx4hPrRfzcW+B+issNfztJP5PyP/0JzMj/KV0IAAAAAElFTkSuQmCC);
  background-size: contain;
  pointer-events: none;
}

.searchList main {
  margin: 0 auto;
  max-width: 700px;
}

.blog-card2 {
  width:100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 1px solid #e8e8e8;
  padding: 32px 16px;
}

.blog-card2 .card-image-search {
  width: 300px;
  height:200px;
  float:right;
  cursor: pointer;
}

.blog-card2 .card-type-search {
  color: #da3a50;
  font-family: "Open Sans", sans-serif;
  font-size: 12px !important;
  margin-bottom: 4px;
  text-transform: capitalize;
}
.search-hover:hover{
  color:inherit;
}
.blog-card2 .card-left-search {
  padding-right: 32px;
  width:368px;
}

.blog-card2 .card-title-search {
  letter-spacing: 0.5px;
  font-size: 20px  !important;
  margin-bottom: 8px;
  line-height: 1.5;
  color: #444444;
  cursor: pointer;
  margin-top:0px;
}

.blog-card2 .card-category-search {
  font-family: "Open Sans", sans-serif;
  font-size: 12px  !important;
  color: #9f9f9f;
  margin-bottom: 8px;
}

.blog-card2 .card-description-search {
  font-size: 12px  !important;
  color: #404040;
  margin-bottom: 8px;
}
.blog-card2 .card-description-search p{
  font-size: 12px  !important;
  color: #404040;
  margin-bottom: 8px;
}

.blog-card2 .card-author-search {
  font-size: 12px  !important;
  color: #404040;
  margin-bottom: 8px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  cursor: pointer;
}

.blog-card2 .card-icons-search {
  font-size: 12px  !important;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
  font-family: "Open Sans", sans-serif;
}

.blog-card2 .card-icon-img-search {
  width: 16px;
  margin-left: 8px;
  cursor: pointer;
}

.blog-card2 .blog-card:last-child {
  border-bottom: none;
}

.load-wrapper {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 128px;
  margin-top: 32px;
}

.load-btn {
  font-family: "Open Sans", sans-serif;
  width: 200px;
  border: 1px solid #444444;
  text-align: center;
  padding: 8px;
  color: #444444;
  font-weight: bold;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .image-size{
    width:0px;
    display:none;
  }
  .only-mobile {
    display: block;
  }
  .only-desktop {
    display: none;
  }
  nav {
    padding: 15px 16px;
  }
  #nav-search {
    margin-right: 0;
  }
  .profile-icon {
    display: none;
  }

  #search-form {
    width: 300px;
  }

  #filters {
    -webkit-flex-direction: column;
            flex-direction: column;
    border-top: none;
  }

  .filter-select {
    width: 100%;
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .filter-select select {
    width: calc(100vw / 2 - 30px);
  }

  #search-result-count {
    margin-bottom: 8px;
    margin-right: 16px!important;
  }

  .filter-inputs-wrapper {
    width: 100%;
  }

  .filter-select:first-child {
    margin: 0;
  }

  .filter-select {
    /* align-items: flex-start; */
    padding: 8px 0;
  }

  .filter-select:after {
    bottom: 18px;
    right: 16px;
  }
  .filter-select .import-select {
    width: 120px;
    background-color: #f5f5f5;
    border: none;
    padding: 10px 16px;
    border-radius: 8px;
    -webkit-appearance: none;
    appearance: none;
    font-size: 12px;
      font-family: auto;
  }
  .filter-label {
    padding: 8px 0;
  }

  .card-left-search {
    padding-right: 0px !important;
  }

  .card-image {
    display: none;
  }
}

.btn-secondary{
  background-color: #fff !important;
  border-color: #fff !important;
  height:45px;
  margin-top: -7px;
}
.dropdown-item2 {
  font-size: 14px;
  text-align: right;
  color: #444;
  padding: 5px 35px;
  font-family: open sans,sans-serif;
}
.dropdown-menu.show{top:-16px !important;}
.dropdown-item.active, .dropdown-item:active{
  background-color: grey !important;
}
.load-btn:hover {
  background-color: #da3950;
  color: white;
  border: 1px solid transparent;
}
body {
    margin: 0%;
    font-family: "Libre Baskerville", serif;
    font-weight: normal;
    line-height: 1.5;
    color: #444;
    background-color: #fff;
  }
  a {
    display: inline-block;
  }
  
  a:hover {
    text-decoration: none;
    color: inherit;
  }
 .image{
   display: inline;
   display: initial;
   width:30px;
   height:32px;
  } 
  .design{
      margin-right: 20%;
      margin-left: 20%;
      margin-top: 8%;
      line-height: 2 !important;
  }
  .equip{
      font-size:24px;
  }
  u{
    color: #da3a50;;
    -webkit-text-decoration-line: underline; 
    text-decoration-line: underline;
  }
  .method{
    font-size:24px;
    text-align: center;
  }
  .val{
    font-size: 18px;
  }
  .txt{
      margin-left: 20%;
      margin-right: 23% ;
  }
  .value{
    font-size: 18px;
    margin-left: 2.5%;
    margin-right: -20% ;
  }
  .pop{
    margin-left: 15%;
      margin-right: 16% ;
      font-size:18px;
  }
  ul {
  list-style: none;
  }
  .drop li { 
    padding-left: 2.5em;
    position: relative; 
  }
  
  .drop li::before {
    content: '';
      height: .4em;
      width: .4em;
      background:#000; 
      position: absolute;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
      top: .75em;
      left: 5;
  }

  @media screen and (max-width: 768px) {
    .design{
      margin-right: 3%;
      margin-left: 6%;
      margin-top: 10%;
      line-height: 1.6 !important;
  }
  .image{
    width:20px;
    height:20px;
   }
  .equip{
      font-size:18px;
  }
  .method{
    font-size:20px;
    text-align: center;
  }
  .val{
    font-size: 14px;
  }
  .value{
    font-size: 14px;
    margin-left: 2.5%;
    margin-right: 1% ;
  }
  .txt{
      margin-left: 10%;
      margin-right:7% ;
  }
  .pop{
    margin-left: 0%;
      margin-right: 11% ;
      font-size: 13px;
  }
  ul {
    list-style: none;
    }
    .drop li { 
      padding-left: 2.5em;
      position: relative; 
    }
    
    .drop li::before {
      content: '';
        height: .4em;
        width: .4em;
        background:#000; 
        position: absolute;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
        top: .55em;
        left: 10;
    }

  }

body {
  margin: 0;
  font-family: "Libre Baskerville", serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  color: #444;
  background-color: #fff;
    /* Set "my-sec-counter" to 0 */
    counter-reset: my-sec-counter;
}

a {
  display: inline-block;
}

a:hover {
  text-decoration: none;
  color: inherit;
}

img {

  

}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

input:focus,
button:focus,
.btn:focus,
.btn.focus,
.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: none;
  outline: none;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
}

/*** Start Checkboxes ***/

/* Customize the label (the shCheckCon) */
.shCheckCon {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.shCheckCon input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.shCheckCon:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.shCheckCon input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.shCheckCon input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.shCheckCon .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

/*** Start Checkboxes ***/

.shHeader {
  width: 100%;
  height: auto;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 3;
  background-color: #fff;
}

.shTopHeader {
  padding: 15px 40px;
  height: 71px;
  border-bottom: 1px solid #e8e8e8;
  transition: all 0.5s ease-in-out;
}

.shHeader.smHeader .shTopHeader {
  padding: 0px 40px;
  height: 47px;
  transition: all 0.5s ease-in-out;
}

.shMenu {
  width: 28px;
  opacity: 1;
  -webkit-transition: opacity 1s ease-in;
  -moz-transition: opacity 1s ease-in;
  -o-transition: opacity 1s ease-in;
}

.shHeader.smHeader .shMenu {
  -webkit-transition: opacity 0.1s ease-in;
  -moz-transition: opacity 0.1s ease-in;
  -o-transition: opacity 0.1s ease-in;
  opacity: 0;
}
.sahaLogo {

    margin-left: 30px;
    width: 45px;
    transition: all 0.5s ease-in-out;
  }
  .sLogo {
    margin-left: 115px;
    margin-top: -45px;
    width: 110px;
  }


.shHeader.smHeader .shLogo {
  margin-left: -28px;
  margin-top: 2px;
  transition: all 0.5s ease-in-out;
}

.shSearch {
  margin-right: 60px;
  width: 24px;
  opacity: 1;
  -webkit-transition: opacity 1s ease-in;
  -moz-transition: opacity 1s ease-in;
  -o-transition: opacity 1s ease-in;
}

.shHeader.smHeader .shSearch {
  -webkit-transition: opacity 0.1s ease-in;
  -moz-transition: opacity 0.1s ease-in;
  -o-transition: opacity 0.1s ease-in;
  opacity: 0;
}

.topLinks {
  
  width: auto;
  -webkit-transition: opacity 0.1s ease-in;
  -moz-transition: opacity 0.1s ease-in;
  -o-transition: opacity 0.1s ease-in;
  opacity: 0;
  display: none;
}

.shHeader.smHeader .topLinks {
  opacity: 1;
  display: block;
  -webkit-transition: opacity 1s ease-in;
  -moz-transition: opacity 1s ease-in;
  -o-transition: opacity 1s ease-in;
}

.topLinks li {
  float: left;
  margin: 0 0 0px 70px;
}

.topLinks li a {
  font: 600 14px "Open Sans", Semi bold;
  color: #444;
  padding: 14px 15px;
  text-transform: uppercase;
  text-decoration: none;
  font-size:16px;
  margin-top:2%;
}

.topLinks li.active a,
.topLinks li a:hover {
  background: #fcecef;
  color: #da3a50;
  text-decoration: none;
}

.bookmarkIcon {
  float: right;
  width: 16px;
  -webkit-transition: opacity 0.1s ease-in;
  -moz-transition: opacity 0.1s ease-in;
  -o-transition: opacity 0.1s ease-in;
  opacity: 0;
  margin-top: 11px;
}

.shHeader.smHeader .bookmarkIcon {
  opacity: 1;
  -webkit-transition: opacity 1s ease-in;
  -moz-transition: opacity 1s ease-in;
  -o-transition: opacity 1s ease-in;
}

.shProfile {
  display: inline-block;
  font-family: "Open Sans", sans-serif;
  margin-right: -16px;
  opacity: 1;
  -webkit-transition: opacity 1s ease-in;
  -moz-transition: opacity 1s ease-in;
  -o-transition: opacity 1s ease-in;
}

.shHeader.smHeader .shProfile {
  -webkit-transition: opacity 0.1s ease-in;
  -moz-transition: opacity 0.1s ease-in;
  -o-transition: opacity 0.1s ease-in;
  opacity: 0;
}

.shProfile .dropdown-menu {
  border: 0;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  top: 14px !important;
}

.shProfile .dropdown-item {
  font-size: 14px;
  text-align: right;
  color: #444;
  padding: 5px 35px;
}

.shProfile .dropdown-item.dropdown-sign-out {
  color: #da3a50;
  padding: 20px 35px;
}

.shProfile .dropdown-toggle::after,
.shLang .dropdown-toggle::after {
  display: none;
}

.shProfile .btn-secondary {
  color: #fff !important;
  background-color: #da3a50 !important;
  border-color: #da3a50 !important;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  box-shadow: none !important;
}

.shBanner .container {
  position: relative;
}

.shBnImg > img.banner-image {
  display: block;
  height: 100%;
  /* margin: 0 auto; */
  width: auto;
}
.shBnImg > iframe.banner-image {
  display: block;
  height: 100%;
  border: none;
  /* margin: 0 auto; */
  width: 100%;
}
.shBnIfr > iframe.banner-image {
  display: block;
  height: 100%;
  border: none;
  /* margin: 0 auto; */
  width: 100%;
}

.shBnOverlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.5) 0,
    rgba(0, 0, 0, 0.0001) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1);
  background-repeat: repeat-x;
}

.shBnImg {
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
}
.shBnIfr {
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
}

.shBnText {
  position: absolute;
  left: 60px;
  bottom: 40px;
  color: #fff;
}
.shBnTimeline {
  top: auto;
  width: 50%;
  margin-left: 130px;
  color: #000;
  z-index: 1;
  margin-top: 80px;
}
/* .sectioning{
    margin-top: 300px;
} */
.Timeline-Page{
    max-width: 100%;
      overflow-x: hidden;   
  }
.shBnTimeline p {
  font-weight: 100 !important;
}
.shBnTimeline #subDomain_p a {
  font-weight: 600 !important;
  color: #444444;
}
.shBnTimeline p {
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  color: #6b6b6b;
  margin: 0 0 12px;
}

.shBnText p {
  font-size: 16px;
  margin-bottom: 10px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  padding-left: 0px;
  color: whitesmoke;
}

.shBnText h2 {
  font-size: 36px;
  margin-bottom: 10px;
}

.shBnText .shDateSec {
  position: relative;
}

.shBnText .shDateSec h3 {
  font-size: 16px;
  /* margin-right: 60px; */
  margin: 7.5px 4px 0 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}

.shSocialList li {
  display: inline-block;
  margin-right: 15px;
}

.shSocialList li.shLoc {
  margin-right: -30px;
}
.shSocialList li.shTag {
  margin-left: 60px;
}

.balign {
  margin-left: 60px;
}

.shSocialList li a {
  width: 24px;
}

.shSocialSec {
  margin-top: 40px;
}

.shSocialSec .shSocialList {
  float: left;
}

.shSocialSec .shLang {
  float: left;
  margin-left: 80px;
  font-family: "Open Sans", sans-serif;
}

.shSocialSec .shLang .dropdown-menu {
  border: 0;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.shSocialSec .shLang .dropdown-item {
  color: #444;
  font-size: 14px;
}

.shLang .btn-secondary {
  color: #fff !important;
  background-color: transparent !important;
  border-color: transparent !important;
  font-size: 12px;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}

.shLang .btn-secondary img {
  width: 12px;
  margin-top: -2px;
  display: inline-block;
}

.articlePlaceMain,
.trailPlaceMain {
  margin-top: 70px;
}

.mainPlaceholder .articlePageTheme {
  padding-top: 50px!important;
}

/*.articlePage {
    width: 717px;
}*/
.mainPlaceholder .articleMainTxt p{
  padding-bottom: 40px;
  line-height: 36px;
  color: #444;
  font-size: 18px!important;
  /* letter-spacing: 1px; */
  margin-bottom: 0px;
}
.museumPageTheme .museumMainTxt p {
  padding-bottom: 0px;
  line-height: 2 !important;
  color: #444 !important;
  font-size: 18px !important;
  letter-spacing: 0px;
  margin: 0 0 0px;
}
/* .museumMainTxt div{
    padding-bottom: 0px;
    line-height: 2;
    color: #444;
    font-size: 18px;
    letter-spacing: 0px;
    margin:0 0 0px;
} */

.articleMainTxt .paraItalic p {
  font-style: italic;
  padding-bottom: 0px;
}

.pageImg {
  margin-bottom: 40px;
  position: relative;
}

.pageImg .pageImgItem {
  display: inline-block;
}

.pageImg .pageImgItem p {
  font-size: 12px;
  padding-bottom: 0;
  font-family: "Open Sans", sans-serif;
  text-align: left;
  padding-left: 40px;
}

.moreMainSec {
  float: right;
  width: 88%;
}

.shArtLogo {
  background: #fff;
  margin: 20px 0px;
  width: 100%;
  text-align: center;
}
.shArtLogo.shArtLogoSec {
  width: 75%;
  /* height: 200px; */
  display: inline-block;
}
.shArtLogo.shArtLogoSec img {
}
.moreTopicSec {
  background: #f5f5f5;
  padding: 35px 25px;
  margin-bottom: 40px;
  color: #444444;
}

.moreTopicSec h2 {
  font-family: 'Open sans';
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 20px;
  color: #444444;
}

.moreList li {
  border-bottom: 1px solid #d6d6d6;
  margin-bottom: 20px;
}

.moreList li:last-child {
  border-bottom: 0;
  margin-bottom: 0;
}

.moreList li a {
  color: #444444;
}

.moreList li a h3 {
  font-size: 18px;
  word-break: break-word;
  color: #444444;
  line-height: 1.5;
}

.moreList li a:hover h3 {
  text-decoration: underline;
  color: #444444;
}
.notTabMain a {
  cursor: auto;
}
.moreList li a p {
  font-size: 12px;
  font-family: sans-serif;
  font-weight: 600;
  color: #444444;
  line-height: 1.5;
}
.Timeline-Page .moreList li a p {
  font-size: 12px;
  font-family: sans-serif;
  font-weight: 700;
  color: #444444;
  line-height: 1.5;
}

.moreExtra .moreExtraItem {
  margin-bottom: 40px;
  min-height: 200px;
  position: relative;
}

.moreExtra .moreExtraItem .moreExtraItemTxt {
  padding: 40px 35px 20px 25px;
  position: absolute;
  z-index: 1;
  top: 0;
}

.overLay {
  background: rgb(0 0 0 / 0.3);
  height: 100%;
  width: 100%;
  position: absolute;
}

.moreExtra .moreExtraItem .moreExtraItemTxt h5 {
  padding-right: 50px;
  margin-bottom: 30px;
  color: #fff;
  font-size: 24px;
  font-family: "Open Sans", sans-serif;
}

.btn-con {
  font-size: 10px;
  border-radius: 0;
  padding: 5px 20px;
  font-family: "Open Sans", sans-serif;
  background-color: #fff;
}

.suppotedSec {
  font-size: 12px;
  color: #444;
  opacity: 0.7;
  font-style: italic;
  margin-top: -1rem;
}

/* .tantraSec {
    margin: 40px 0;
} */
div.banner-details div.right-des {
  letter-spacing: normal;
  height:52px;
  overflow-y: auto;
}
.tantraSec a {
  background: #f5f5f5;
  color: #9f9f9f;
  font-size: 12px;
  padding: 2px 18px;
  margin-bottom: 4%;
  border-radius: 4px;
  margin-right: 8px;
  font-family: "Open Sans", sans-serif;
}

.tantraSec a:hover{
  
  color: #dd4d61;
 background-color: #f8e2e5;
}

.articleNotesTab .nav-tabs {
  border-bottom: 0;
}

.articleNotesTab .nav-tabs .nav-item {
  width: 33.33%;
  border-right: 1px solid #fff;
}

.articleNotesTab .nav-tabs .nav-item:last-child {
  border-color: #e8e8e8;
  border-top-right-radius: 0.25rem;
}

.articleNotesTab .nav-tabs .nav-link {
  color: #444;
  background: #e8e8e8;
  font-size: 16px;
  padding: 5px 0;
  text-align: center;
  font-family: "Open Sans", sans-serif;
}

.articleNotesTab .nav-tabs .nav-link.active,
.articleNotesTab .nav-tabs .nav-item.show .nav-link {
  color: #444;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.articleNotesTab .tab-content {
  padding: 60px;
  background-color: #f5f5f5;
  height: auto;
}

.notTabMain p {
  font-size: 14px;
}

.artNotesTabSocial {
  padding: 20px 0;
}

.artNotesTabSocial .shSocialList {
  float: left;
}

.artNotesTabSocial .someRight {
  float: right;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  margin-top: 5px;
}

.someRight {
  color: #444;
}

/* .someRight.someRightdis {
    pointer-events: none;
} */

.someRight img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  display: inline-block;
}

.resize {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  margin-top: 3px;
  margin-bottom: 5px;
  margin-left: 190px;
}
.resize img {
  width: 12px;
  height: 12px;
  margin-right: 10px;
  margin-top: 6px;
}
.resize1 {
  float: right;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 5px;
}
.resize1 img {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.readMore {
  padding: 40px 0;
  font-family: sans-serif;
  font-weight: 600;
  border-top: 1px solid rgb(214 214 214 / 0.8);
}

.readMore h6 {
  color: #444;
  font-family: 'open sans' sans-serif;
  font-size: 16px;
  font-weight: 700;
}

.stFtThumb {
  padding-bottom: 80px;
}

.stThumbItem {
  color: #444;
  margin-bottom: 40px;
}

#navigate-menu {
  display: none;
}

#navigate-menu.active {
  display: block;
  margin-top: 30px;
}

.stThumbItem .stThumbItemImg {
  height: 227px;
  background-size: cover !important;
}

.stThumbItem .stThumbItemImg.visual_01 {
  background: url(/search/static/media/visual_01.3e00dd25.png) 0 0 no-repeat;
}

.stThumbItem .stThumbItemImg.visual_02 {
  background: url(/search/static/media/visual_02.a8dfe4e9.png) 0 0 no-repeat;
}

.stThumbItem .stThumbItemImg.visual_03 {
  background: url(/search/static/media/visual_03.8989fdd1.png) 0 0 no-repeat;
}

.stThumbItem .stThumbItemImg.visual_04 {
  background: url(/search/static/media/visual_04.08838f88.png) 0 0 no-repeat;
}

.stThumbItem .stThumbItemImg.visual_05 {
  background: url(/search/static/media/visual_05.830659b2.png) 0 0 no-repeat;
}

.stThumbItem .stThumbItemImg.visual_06 {
  background: url(/search/static/media/visual_06.5a7f381f.png) 0 0 no-repeat;
}

.stThumbItem p {
  font-size: 14px;
  margin-bottom: 10px;
}

.stThumbItem .stThumbCat {
  color: #da3a50;
  font-size: 12px;
  margin-top: 16px;
  font-family: "Open Sans", sans-serif;
}
.stThumbDesc {
  margin-top: 12px;
  margin-bottom: 12px !important;
}

.stThumbItem h2 {
  font-size: 24px;
  height:64px;
  line-height: 36px;
  margin-bottom: 0px;
}

.stThumbItem .stThumbOw {
  font-family: sans-serif;
  font-weight: 600;
  float: left;
}

.stThumbItem .stThumbSculp {
  color: #9f9f9f;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  margin-top: 4px;
}

.btn-load {
  background-color: #fff;
  border: 1px solid #3a3a3a;
  font-size: 16px;
  font-weight: 700;
  padding: 13px 33px;
  color: #444;
  font-family: "Open Sans", sans-serif;
  border-radius: 0;
}

.stFooter {
  background-color: #f5f5f5;
  padding-top: 40px;
}

.stFootLogo img {
  width: 264px;
  margin-left: 60px;
  margin-bottom: 20px;
}

.stFootBtm {
  padding-bottom: 23px;
  text-align: center;
}

.stFootBtm a {
  font-size: 12px;
  color: #444;
  margin: 0 20px;
  font-family: "Open Sans", sans-serif;
}

.stFtTopItem {
  font-family: "Open Sans", sans-serif;
  margin-bottom: 34px;
}

.stFtTopItem h5 {
  font-size: 16px;
  color: #444;
  font-weight: 700;
  margin-bottom: 12px;
}

.stFtTopList li {
  display: block;
  margin-bottom: 8px;
}

.stFtTopList li a {
  font-size: 14px;
  color: #444;
}

.stFtSubs h4,
.stFtSubs p {
  margin-bottom: 30px;
  font-family: "Open Sans", sans-serif;
}

.stFtSubs h4 {
  font-size: 24px;
}

.stFtSubs p {
  font-size: 14px;
}

.stFtSubsInput .form-control {
  float: left;
  width: calc(100% - 68px);
  height: 48px;
  background-color: #ebebeb;
  border-color: #ebebeb;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
}

.stFtSubsInput .btn-subs {
  float: right;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #da3a50;
}

.playItem {
  position: absolute;
  width: 100%;
  bottom: 80px;
  z-index: 1;
}

.playItem a {
  width: 96px;
}

.playItem h6 {
  color: #fff;
  font-size: 36px;
  font-weight: 400;
  margin-top: 40px;
  padding: 0 170px;
  line-height: 47px;
}

.playItem .btn-con {
  margin-top: 40px;
}

.searchPlaceMain,
.libraryPlaceMain {
  margin-top: 70px;
}

.shSearhTypeView {
  padding: 70px 0 20px;
}

.shSearchInputSec h2 {
  font-size: 18px;
  margin-bottom: 20px;
}

.shSearchInputSec h2.myLib {
  font-size: 24px;
}

.shSearchInputSec p {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.shSearchInputItem {
  width: 444px;
  height: 40px;
  border: 1px solid #d6d6d6;
  border-radius: 8px;
  position: relative;
  margin: 0 auto;
}

.shSearchInputItem input {
  width: 100%;
  height: 100%;
  padding: 0 50px 0 20px;
  border: 0;
  border-radius: 8px;
  font-family: "Open Sans", sans-serif;
}

.shSearchInputItem .btn-search {
  width: 50px;
  height: 40px;
  position: absolute;
  right: 0;
  padding: 3px 12px 7px;
  opacity: 0.6;
  background-color: transparent;
}

.shSearchResFoundBlk {
  padding: 20px 0;
  border-top: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
  font-family: "Open Sans", sans-serif;
}

.shResultCount {
  float: left;
  font-size: 12px;
  color: #da3a50;
  margin-top: 12px;
}

.shSearhResFoundFilter {
  float: left;
  margin-left: 100px;
}

.shSearhResFoundFilterItem {
  margin-right: 80px;
  float: left;
}

.shSearhResFoundFilterItem label {
  float: left;
  font-size: 12px;
  margin-top: 12px;
}

.shSearhResFoundFilterItem .shSearhResFoundFilterItemSel {
  float: left;
  margin-left: 30px;
}

.shSearhResFoundFilterItem .shSearhResFoundFilterItemSel .dropdown-toggle {
  width: 274px;
  height: 42px;
  background: #f5f5f5;
  border: 0;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 12px;
  text-align: left;
  color: #444;
  position: relative;
}

.shSearhResFoundFilterItem .shSearhResFoundFilterItemSel .dropdown-toggle img {
  width: 21px;
  position: absolute;
  right: 20px;
  display: inline-block;
}

.shSearhResFoundFilterItem
  .shSearhResFoundFilterItemSel
  .dropdown-toggle::after {
  display: none;
}

.shSearhResFoundFilterItem .shSearhResFoundFilterItemSel .dropdown-menu {
  width: 100%;
  background-color: #f5f5f5;
  font-size: 14px;
  margin-top: -5px;
  border: 0;
  border-top: 1px solid rgb(112 112 112 / 0.5);
  border-radius: 0;
}

.shSearchResListItem {
  width: 814px;
  margin: 0 auto;
}

.shSearchResListSec {
  padding: 60px 0;
}

.shSearchResList li {
  padding-bottom: 30px;
  border-bottom: 1px solid #d6d6d6;
  margin-bottom: 30px;
}

.shSearchResList li:last-child {
  border-bottom: 0;
}

.shSearchResList li .stThumbItem {
  margin-bottom: 0;
}

.shSearchResList li .stThumbItem .stThumbItemImg {
  float: right;
  width: 260px;
}

.shSearchResList li .stThumbItem .stThumbItemDesc {
  float: left;
  width: 460px;
}

.stThumbItemCtrl {
  text-align: right;
  margin-top: -28px;
}

.stThumbItemCtrl a {
  width: 18px;
  height: 18px;
  margin-left: 10px;
}

.shSearchResListSec.shSearchLibSec .stThumbItem h2 {
  margin-top: 20px;
}

#author_entity {
  cursor: pointer;
  margin-right: 64px;
}

#author_entity:hover {
  color: #da3a50;
}

h3[id^="author_entity_"] {
    cursor: pointer;
    margin-right: 15px;
    font-size: 16px;
    float: left;
}

h3[id^="author_entity_"]:hover {
    color: #da3a50;
}

.shAuthBio {
  padding: 20px;
  border-radius: 4px;
  background-color: #fff;
  width: 500px;
  position: absolute;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 30px;
  display: none;
}

.shAuthBio.active {
  display: block;
}

.shToolCl {
  /* width: 24px; */
  /* height: 24px; */
  position: absolute;
  top: 5px;
  right: 5px;
  text-align: center;
  z-index: 1000;
}

.shToolCl img {
  width: 12.5px;
}

.shAuthBio .shAuthImg {
  width: 100px;
  float: left;
}
.social-icon {
  background-color: #fff;
  border-radius: 5%;
}

.shAuthBio .shAuthDesc {
  padding-left: 120px;
  color: #444;
  line-height: 24px;
  font-size: 13px;
  font-family: "Libre Baskerville", serif;
  font-weight: 400;
}
.shAuthDesc2 {
  width: 500px;
  background-color: #fff;
  height: auto;
  margin-top: -10px;
  padding: 3px 10px 10px 10px;
  color: #444;
  line-height: 24px;
  font-size: 13px;
  font-family: "Libre Baskerville", serif;
  font-weight: 400;
}
/* .popup-content{background-color: inherit !important;border:none;box-shadow:initial !important;} */
.artCarouselSec {
  position: relative;
  margin-left: -23%;
}

.artCarouselSec .owl-carousel .owl-item .artCarouselImg {
  height: 376px;
  background-size: cover !important;
}

.artCarouselSec .owl-carousel .owl-item .artCarouselImg.artCarouselImg_01 {
  background: url(/search/static/media/devarani.6e8628a5.png) 0 0 no-repeat;
}

.artCarouselSec .owl-carousel .owl-item .artCarouselImg.artCarouselImg_02 {
  background: url(/search/static/media/ananda.819d6630.png) 0 0 no-repeat;
}

.artCarouselSec .owl-carousel .owl-item .artCarouselImg.artCarouselImg_03 {
  background: url(/search/static/media/myanmar.5f575294.png) 0 0 no-repeat;
}

.artCarouselSec .owl-carousel .owl-item .artCarouselImg.artCarouselImg_04 {
  background: url(/search/static/media/padag.128a03ae.png) 0 0 no-repeat;
}

.artCarouselSec .owl-theme .owl-dots {
  display: none;
}

.artCarouselSec .owl-theme .owl-nav {
  position: absolute;
  width: 100%;
  top: 50%;
  margin-top: -70px;
  z-index: 1;
  padding: 0 20px;
}

.artCarouselSec .owl-theme .owl-nav button {
  position: relative;
  width: 40px;
  height: 40px;
  background: rgb(255 255 255 / 0.7) !important;
  border-radius: 50%;
  margin: 0;
}

.artCarouselSec .owl-theme .owl-nav button:focus {
  outline: none;
}

.artCarouselSec .owl-theme .owl-nav button.owl-prev {
  float: left;
}

.artCarouselSec .owl-theme .owl-nav button.owl-next {
  float: right;
}

.artCarouselSec .owl-theme .owl-nav button span {
  font-size: 48px;
  color: #000;
  position: relative;
  top: -20px;
}

.shSubHeader {
  height: 30px;
  padding-right: 40px;
  border-bottom: 1px solid #e8e8e8;
  font-family: "Open Sans", sans-serif;
}

.shAllMaps {
  width: 248px;
  color: #da3a50;
  display: block;
  line-height: 30px;
  float: left;
  padding-left: 40px;
}

.shAllMaps i {
  width: 12px;
  height: 12px;
  display: block;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAGKADAAQAAAABAAAAGAAAAADB/VeXAAABFElEQVRIDe2VvQ3CMBCFXxBCgoaMAE1oU0ZUYQOzAUzECtkAbwAVUEILRVaABgokzJ2lIIRy+TMNEm6s+O7e53OeZeDXh9ekgTRU/r2LJTyEVD8JNnov6bSkgLRuxXtYkXhMOb5n7CyloxbgJQ67cxjg0L4hEdUpUPmI8sQ7V8TDvT47A5qKM7i0AxfxUoCreCHgG+IMEF1EPtcUZ59bt1T5oZz7OUQAJVpxLmgZJGVu+RTOvmUAXySDCycaD4tjpGZZUZ250EXHsQoJsiav9a3oA/Ngp5OvAVjIFVLYQbZTF0glgEsnlQFNIbUAeRBjMB1tNd+Z3CHbNDcdsI/Lm4XpPfCFVLflNFKDU6RiN5V/NZ3AE8jyg+dUNsfBAAAAAElFTkSuQmCC) 0 0 no-repeat;
  background-size: cover;
  float: left;
  margin: 9px 10px 0 0;
}

.shAllMaps:hover {
  color: #fff;
  background-color: #da3a50;
}

.shAllMaps:hover i {
  width: 12px;
  height: 12px;
  display: block;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAGKADAAQAAAABAAAAGAAAAADB/VeXAAAAjUlEQVRIDWNgGJHg////AkC8H4jfA7EBVQMBaCDI8PNADAMFVLMAaCK64RdAYlSxYNRwrME4dIMF5B2g6w8AMQxQL7XAwgpo8geY6UCaemkdyQIDNEsSYHJUo4EWjFpCfGgO6+AKwBcOTPgksckxMjJeAIo7APFHqDx1imuoYXAKGCcKQOwAFxhlkBsCAADGTXxyNrp3AAAAAElFTkSuQmCC) 0 0 no-repeat;
  background-size: cover;
  float: left;
  margin: 9px 10px 0 0;
}

.shSubHdRt {
  padding-left: 248px;
}

.shSubNav a {
  font-size: 12px;
  color: #444;
  line-height: 30px;
  padding: 0 33px;
  float: left;
}

.shSubNav a:hover {
  color: #da3a50;
  background-color: rgb(218 58 80 / 0.2);
}

.shSubNav a.shSubLoc {
  font-size: 14px;
  color: #da3a50;
  /* cursor: text; */
  text-transform: uppercase;
}

.shSubNav a.shSubLoc:hover {
  color: #da3a50;
  background-color: transparent;
}

.shBreadcrumb .breadcrumb {
  font-size: 12px;
  padding: 0;
  margin: 0;
  background-color: transparent;
  text-transform: uppercase;
}

.shBreadcrumb .breadcrumb .breadcrumb-item a {
  color: #da3a50;
  line-height: 30px;
  font-size: 14px;
}

.shBreadcrumb .breadcrumb .breadcrumb-item.active {
  color: rgb(104 104 104 / 0.5);
}

.shBreadcrumb .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  color: rgb(104 104 104 / 0.7);
  line-height: 30px;
}

.themePlaceMain {
  font-family: "Open Sans", sans-serif;
  height: calc(100% - 86px);
  position: fixed;
  top: 86px;
  margin-top: 15px;
}

.shMapSec {
  width: 100vw;
  height: 100%;
  background-color: #efefef;
}

.shSidemenu {
  width: 248px;
  height: 100%;
  padding-bottom: 10px;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #f7f7f7;
  z-index: 2;
  box-shadow: 0 4px 4px rgb(0 0 0 / 10%);
}

.shSideMenuTrig {
  width: 20px;
  height: 60px;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  right: -20px;
  background-color: #f7f7f7;
  border-radius: 0 10px 10px 0;
  text-align: center;
  padding-top: 17px;
  box-shadow: 6px 0px 4px 2px rgb(0 0 0 / 10%);
}

.shSideMenuTrig img {
  width: 16px;
  margin-left: -8px;
  display: none;
}

.shSideMenuTrig img.active {
  margin: 5px 0 0 0;
  display: block;
}

.shSidemenuScrl {
  padding-top: 30px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.shSidemenuList li {
  display: block;
  margin-bottom: 5px;
}

.shSidemenuList li a {
  color: #505050;
  font-size: 14px;
  display: block;
  padding: 7px 0 7px 20px;
  position: relative;
  line-height: 0.65rem;
  margin-right: 10px;
}

.shSidemenuList li a i.inbuilt {
  width: 22px;
  height: 22px;
  background: #505050;
  /* margin-right: 10px; */
  display: inline-block;
  border-radius: 50%;
  position: relative;
}

.shSidemenuList li a i.inbuilt.extra {
  margin-right: 10px;
}
.sub-theme-item,
.inbuilt.sub-theme-item {
  display: none !important;
}

.sub-theme-item.show {
  display: block !important;
}

a.theme-item {
  padding: 20px 0 0 !important;
  position: relative !important;
  left: -5px;
}

.shSidemenuList li a i.inbuilt img {
  position: absolute;
}

.bx {
  font-size: 26px !important;
}

a.all-theme-select i {
  display: none;
}

a.all-theme-select i.show {
  display: block;
}

.shSidemenuList li a span {
  position: relative;
  top: -5px;
  line-height: 1rem;
}

.shSidemenuList li a div {
  position: relative;
  top: -5px;
  line-height: 1rem;
}

.shSidemenuList li.active a .ic_acc,
.shMapSel .dropdown.show .btn-secondary .ic_acc {
  background: url(/search/static/media/ic_arrow_up.05dc1ce5.svg) 0 0 no-repeat;
}

.shSidemenuList li a .ic_acc,
.shMapSel .btn-secondary .ic_acc {
  width: 12px;
  height: 12px;
  display: inline-block;
  background: url(/search/static/media/ic_arrow_down.50769fd7.svg) 0 0 no-repeat;
  position: relative;
  top: -2px;
  margin-left: 10px;
}

.shSidemenuList li.active .shSidemenuList {
  display: block;
}

.shSidemenuList li .shSidemenuList {
  margin-left: 15px;
  display: none;
}

.shSidemenu.shSidemenuCol {
  width: 48px;
}

.shSidemenuCol .shSidemenuList li a span,
.shSidemenuCol .shSidemenuList li a .ic_acc {
  display: none;
}

.shSidemenuCol .shSidemenuList li a {
  padding: 7px 0 7px 13px;
}

.shSidemenuCol .shSidemenuList li .shSidemenuList {
  margin-left: 0;
}

.shMapItem {
  width: 100%;
  padding-left: 248px;
  position: relative;
}

.shMapSel {
  position: fixed;
  left: 278px;
  margin-top: 35px;
}

.shMapSearchSec {
  right: 0;
  position: fixed;
  z-index: 3;
}

.shMapSearchTrig {
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 50%;
  padding: 8px;
  margin-right: 46px;
  margin-top: 30px;
  box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 10%);
}

.shMapSearchBox {
  background: #fff;
  width: 277px;
  border-radius: 6px 0 0 6px;
  margin-top: 14px;
  padding: 20px 43px 14px 23px;
  box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 10%);
  display: none;
}

.shMapSearchBox .shSearchInputItem {
  border: 0;
  border-bottom: 1px solid #d6d6d6;
  border-radius: 0;
  width: 100%;
  height: 26px;
}

.shMapSearchBox .shSearchInputItem input {
  padding: 0 40px 0 0;
}
.shMapSearchBox .shSearchInputItem input {
  margin: 0px;
}

.shMapSearchBox .shSearchInputItem .btn-search {
  width: 32px;
  height: 32px;
  right: 8px;
  top: -8px;
  padding: 3px;
  opacity: 1;
}

.shMapSearchRes {
  height: calc(100vh - 200px);
  overflow-y: auto;
  margin-right: -43px;
  padding-right: 43px;
}

.shMapSearchResList li {
  margin-top: 24px;
}

.shMapSearchResList li a h2 {
  font-size: 18px;
  color: #444;
  margin-bottom: 4px;
  font-family: "EB Garamond", serif;
}

.shMapSearchResList li a label {
  font-size: 12px;
  color: #da3a50;
  margin-bottom: 4px;
  display: block;
}

.shMapSearchResList li a p {
  font-size: 12px;
  color: #444;
  font-family: "Libre Baskerville", serif;
}

.shMapSel .shSidemenuScrl {
  padding-top: 0;
}

.shMapSel .shSidemenuList li a {
  padding: 3px 0 3px 20px;
}

.shMapSel .shSidemenuList li a i {
  width: 12px;
  height: 12px;
  background: #9f9f9f;
  margin-right: 10px;
}

.shMapSel .shSidemenuList li a span {
  position: relative;
  top: -1px;
}

.shMapSel .shSidemenuList li a span:hover {
  color: #da4250;
}
.shMapSel .shSidemenuList li a div {
  position: relative;
  top: -1px;
}

.shMapSel .shSidemenuList li a div:hover {
  color: #da4250;
}

.shMapSel .btn-secondary,
.shMapSel .show > .btn-secondary.dropdown-toggle {
  color: #444;
  background-color: #fff;
  border-color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  padding: 6px 20px;
  box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 10%);
  height: auto;
}

.shMapSel .dropdown-toggle::after {
  margin-left: 5px;
  display: none;
}

.shMapSel .dropdown-menu {
  position: absolute;
  -webkit-transform: translate3d(0px, 32px, 0px);
          transform: translate3d(0px, 32px, 0px);
  top: 0px;
  left: 0px;
  will-change: transform;
  width: 220px;
  height: auto;
  max-height:300px !important;
  overflow-y: auto;
  border: 0;
  box-shadow: 0px 6px 4px 2px rgb(0 0 0 / 10%);
  margin-top: -5px;
}

.shAllMenu {
  font-family: "Open Sans", sans-serif;
  width: 100%;
  height: calc(100vh - 70px);
  max-height: 86%;
  position: fixed;
  top: 70px;
  z-index: 2;
  background-color: #f7f7f7;
  padding-top: 60px;
  overflow: scroll;
}

.shAllMenu .stFtTopItem {
  margin-top: 60px;
}

.shAllMenu .stFtTopItem h5 {
  color: #da3a50;
  margin-bottom: 20px;
}

.shPoiSec {
  width: 284px;
  position: fixed;
  top: 20%;
  left: 20%;
  z-index: 2;
  background: #fff;
  border-radius: 4px;
}

.shPoiHead {
  padding: 0px;
  position: relative;
}

.shPoiHead h2 {
  font-family: "Libre Baskerville", serif;
  font-size: 18px;
  margin-bottom: 5px;
  padding: 5px 70px 0px 10px;
  color: #444444;
}

.shPoiBody {
  padding: 8px;
  font-family: "Open Sans", sans-serif;
}

.shPoiBody p {
  font-size: 14px;
  /* padding-right: 50px; */
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: 0;
  line-height: 1.25rem;
}

.shPoiBody p .pop-summary {
  padding: 0;
}

.shPoiBreadcrumb {
  margin: 2px 0;
  font-size: 12px;
  padding: 0 8px;
}

.shPoiBreadcrumb .shBreadcrumb .breadcrumb {
  text-transform: none;
  text-transform: initial;
}

.shPoiBreadcrumb .shBreadcrumb .breadcrumb .breadcrumb-item a {
  line-height: normal;
  line-height: initial;
}

.shPoiBreadcrumb .shBreadcrumb .breadcrumb .breadcrumb-item.active {
  color: #da3a50;
}

.shPoiBreadcrumb .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  color: #444;
  line-height: normal;
  line-height: initial;
}

.shPoiFoot {
  padding: 0 10px 15px 8px;
}

.shPoiFoot button {
  float: left;
}

.shPoiFoot .shPoiFtRt {
  float: right;
  margin-top: 4px;
}

.shPoiFoot .shPoiFtRt a {
  width: 24px;
  margin-left: 7px;
}
a.btn-more,
.btn-more {
  color: #fff;
  background-color: #da3a50;
  border-color: #da3a50;
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
  padding: 4px 25px;
}

.shMultiPoiSec {
  padding-top: 10px;
  height: 170px;
  overflow-y: auto;
  padding-right: 10px;
}

.shMultiPoiSec .shSidemenuList li {
  position: relative;
}

.shSidemenuList li .shPoiFtRt {
  position: absolute;
  right: 0;
  top: 10px;
}

.shSidemenuList li .shPoiFtRt a {
  width: 24px;
  display: inline-block;
  padding: 0;
  margin-left: 5px;
}

.aboutPlaceMain {
  margin-top: 100px;
}

.aboutNav {
  text-align: center;
  font-family: "Open Sans", sans-serif;
  padding: 70px 0 60px;
}

.shSubNav.aboutNav a {
  font-size: 14px;
  font-weight: 600;
  float: none;
}

.shSubNav.aboutNav a:hover {
  background-color: transparent;
}

.shSubNav.aboutNav a.active {
  color: #da3a50;
}

.shSubHdRt.shSubHdRtAbt {
  padding-left: 110px;
}

.aboutMain {
  padding-top: 60px;
  color: #444;
  width: 720px;
  margin: 0 auto;
}

.aboutMain p {
  font-size: 18px;
  line-height: 36px;
  margin-bottom: 60px;
}

.aboutMain h2 {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 36px;
  text-align: center;
}

.WorkList li {
  list-style: disc;
  margin-bottom: 60px;
  padding-left: 35px;
  margin-left: 20px;
  font-size: 18px;
}

.allTeamSec {
  font-family: "Open Sans", sans-serif;
}

.allTeamItem {
  margin-bottom: 70px;
}

.allTeamItem a {
  display: block;
  font-size: 16px;
  color: #444;
}

.allTeamItem h4 {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 4px;
}

.teamMemPic {
  height: 300px;
  background-size: cover !important;
  margin-bottom: 16px;
}

.teamMemPic.team_01 {
  background: url(/search/static/media/team_01.4e1bd66f.png) 0 0 no-repeat;
}

.teamMemPic.team_02 {
  background: url(/search/static/media/team_02.2214021b.png) 0 0 no-repeat;
}

.teamMemPic.team_03 {
  background: url(/search/static/media/team_03.746caa31.png) 0 0 no-repeat;
}

.teamMemPic.team_04 {
  background: url(/search/static/media/team_04.10f10223.png) 0 0 no-repeat;
}

.contactSec {
  width: 720px;
  margin: 30px auto 0;
}

.contactItemWithHead {
  color: #444;
  margin-bottom: 70px;
  text-align: center;
}

.contactItemWithHead h3 {
  font-size: 36px;
  margin-bottom: 20px;
}

.contactItemWithHead p {
  font-size: 18px;
}

.contactList {
  font-family: "Open Sans", sans-serif;
  margin-bottom: 80px;
}

.contactList li {
  font-size: 16px;
  margin-bottom: 36px;
}

.contactList li a {
  color: #da3a50;
}

.teamMemDetail {
  position: fixed;
  z-index: 1;
  top: 100px;
}

.teamMemDetail .allTeamItem {
  margin-bottom: 40px;
  font-family: "Open Sans", sans-serif;
}

.overlayTeam {
  width: 300px;
  height: 100vh;
  background: rgb(0 0 0 / 20%);
  position: absolute;
}

.teamMemDetailMain {
  width: calc(100% - 300px);
  background: #fff;
  margin-left: 300px;
  padding: 50px 100px 80px 90px;
  position: relative;
  height: calc(100vh - 100px);
  overflow: auto;
}

.closeBox {
  width: 24px;
  position: absolute;
  right: 100px;
}

.teamMemDesc {
  font-size: 18px;
  line-height: 36px;
}

.shMapSel .btn-secondary .ic_acc,
.shMapSel .dropdown.show .btn-secondary .ic_acc {
  top: 2px;
  margin-left: 5px;
}

.shMapSel.contract {
  left: 68px;
}

.introPlaceMain .artCarouselSec {
  margin-left: auto;
}

.introBtnLogo {
  position: fixed;
  bottom: 40px;
  width: 100%;
  z-index: 2;
  text-align: center;
}

.introBtnLogo a {
  width: 240px;
  display: inline-block;
}

/* svg {
    display: none;
}

svg.show {
    display: block;
} */

.introBlk {
  display: block;
  position: relative;
  text-align: center;
}

.introBlk .introBgImg {
  width: 33.33vw;
  height: 100vh;
  background-size: cover !important;
}

.introBlk .introBgImg.intro_01 {
  background: url(/search/static/media/intro_01.8106c76f.png) 0 0 no-repeat;
}

.introBlk .introBgImg.intro_02 {
  background: url(/search/static/media/intro_02.cc0e3948.png) 0 0 no-repeat;
}

.introBlk .introBgImg.intro_03 {
  background: url(/search/static/media/intro_03.0c7c6435.png) 0 0 no-repeat;
}

.introBlk .introBgImg.intro_04 {
  background: url(/search/static/media/intro_04.8274fc81.png) 0 0 no-repeat;
}

.introBlk .introBgImg.intro_05 {
  background: url(/search/static/media/intro_05.29f1e8e1.png) 0 0 no-repeat;
}

.introBlk p {
  position: absolute;
  color: #fff;
  top: 50%;
  margin-top: -40px;
  left: 50%;
  margin-left: -60px;
  margin-bottom: 0;
  width: 150px;
  text-align: center;
  font-weight: 700;
  color: #fff;
  font-size: 24px;
  font-family: "Open Sans", sans-serif;
}

.introPlaceMain .artCarouselSec .owl-carousel .owl-item img {
  height: 100vh;
}

.introPlaceMain .artCarouselSec .owl-theme .owl-nav {
  position: fixed;
  margin-top: -40px;
}

.introPlaceMain .artCarouselSec .owl-theme .owl-nav button {
  position: fixed;
  background: rgb(0 0 0 / 70%) !important;
}

.introPlaceMain .artCarouselSec .owl-theme .owl-nav button span {
  color: #fff;
}

.introPlaceMain .artCarouselSec .owl-theme .owl-nav button.owl-prev {
  left: 20px;
}

.introPlaceMain .artCarouselSec .owl-theme .owl-nav button.owl-next {
  right: 20px;
}

.trailPageTheme {
  padding-top: 45px;
}

.trailPlaceMain .shBnText {
  position: relative;
  left: auto;
  bottom: auto;
  color: #444;
  z-index: 1;
}

.trailPlaceMain .shBnText h2 {
  font-size: 30px;
  line-height: 36px;
}

.trailPlaceMain .shBnText .shTrailDesc {
  font-size: 18px;
  line-height: 36px;
  font-style: italic;
  font-family: "Libre Baskerville", serif;
}

.trailPlaceMain .shBnText .shBnCat {
  color: #da3a50;
}

.shTrailBnMap {
  position: relative;
}

.shExporeBtn {
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  color: #da3a50;
}

.shExporeBtn img {
  margin-left: 5px;
}

/* .shTrailBnMap .shBnImg {
  height: 480px;
} */

.shTrailBnText {
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    10%,
    rgba(255, 255, 255, 1)
  );
  position: absolute;
  width: 50%;
  top: 0;
  right: 0;
  height: 100%;
  padding: 110px 135px 0 150px;
}

.shBnCtrl {
  margin-top: 10px;
  font-family: "Open Sans", sans-serif;
}

.shBnCtrl a {
  color: #444;
  font-size: 14px;
  margin-right: 20px;
}

.shBnCtrl a img {
  margin-right: 10px;
}

.shBnOverview {
  position: absolute;
  top: 4px;
  left: 40px;
  z-index: 1;
}

.shBnOverview a {
  color: #da3a50;
  padding: 0 16px;
  font-size: 12px;
  margin-right: 20px;
  font-family: "Open Sans", sans-serif;
  background: #fff;
  border-radius: 4px;
}

.landingMapSec {
  width: 100%;
  height: 360px;
  background-color: #efefef;
  margin-top: -40px;
  margin-bottom: 60px;
}

.shSearchSec {
  position: relative;
}
.shSearchInputItem input {
  margin: 0px;
}
.shSearchSec .shSearchInputItem {
  border: 0;
  border-bottom: 1px solid #d6d6d6;
  border-radius: 0;
  width: 310px;
  position: absolute;
  right: 91px;
  margin-top: 1px;
}

.shSearchSec .shSearchInputItem input {
  padding-left: 0;
}

.shSearchSec .shSearchInputItem .btn-search {
  opacity: 1;
}

.locMapPopup,
.loginPopup {
  width: 100%;
  height: 100vh;
  background: rgb(0 0 0 / 30%);
  position: fixed;
  top: 0;
  z-index: 2;
}

.locMapPopup.hidden {
  display: none;
}

.locMapItem {
  width: 860px;
  height: 480px;
  background: #efefef;
  margin: 8% auto;
  border: 2px solid #fff;
  border-radius: 6px;
  position: relative;
}

.locMapItem > a {
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 50%;
  position: absolute;
  top: 20px;
  right: 20px;
  background: #fff;
  z-index: 999;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 10%);
}

.locMapItem .locMapMain {
  width: 100%;
  height: 100%;
}

.expMapSec {
  width: 100%;
  height: calc(100vh - 70px);
  background: #efefef;
  position: fixed;
  top: 70px;
  z-index: 2;
}

.shSocialList li .shShare {
  font-family: "Open Sans", sans-serif;
}

.shSocialList li .shShare .dropdown > a {
  padding: 0;
  background: transparent;
  border: 0;
}

.shSocialList li .shShare .dropdown.show > a:focus {
  box-shadow: none;
}

.shSocialList li .shShare a img {
  width: 24px;
}

.shSocialList li .shShare .dropdown-toggle::after {
  display: none;
}

.shSocialList li .shShare .dropdown .dropdown-menu {
  margin-left: 36px;
  margin-top: -68px;
  border: 0;
  box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 10%);
}

.shSocialList li .shShare .dropdown .dropdown-menu:after {
  display: inline-block;
  width: 0;
  height: 0;
  content: "";
  border-top: 10px solid transparent;
  border-right: 10px solid #fff;
  border-bottom: 10px solid transparent;
  border-left: 0;
  position: absolute;
  left: -10px;
  top: 50%;
  margin-top: -10px;
}

.shSocialList li .shShare .dropdown .dropdown-menu h5 {
  color: #686868;
  font-size: 12px;
  padding: 0.25rem 1.5rem;
  margin-bottom: 0;
}

.shSocialList li .shShare .dropdown .dropdown-menu a {
  width: auto;
  font-size: 12px;
}

.shSocialList li .shShare .dropdown .dropdown-menu a > img {
  width: 12px;
  margin-right: 10px;
  margin-top: -1px;
}

.shChapterSec {
  margin-top: 40px;
  margin-bottom: 35px;
}

.shChapterItem {
  width: 100%;
}

.shChapterItem li {
  width: 20%;
  padding-left: 15px;
  padding-right: 15px;
  float: left;
}

.shChapterItem li a {
  display: block;
}

.shChapterItem li a .progress {
  height: 1px;
  background-color: #9f9f9f;
  margin-bottom: 10px;
}

.shChapterItem li a .progress .progress-bar {
  background-color: #da3a50;
}

.shChapterItem li a h5,
.shChapterItem li a h6 {
  font-size: 12px;
  font-weight: 400;
  color: #444;
  margin-bottom: 5px;
}

.shChapterItem li a h5 {
  font-family: "Open Sans", sans-serif;
}

.loginPopup {
  top: 0;
  z-index: 3;
  background: rgb(0 0 0 / 40%);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.loginPopupMain {
  width: 900px;
  font-family: "Open Sans", sans-serif;
  background: #fff;
  box-shadow: 8px 16px 32px rgb(0 0 0 / 10%);
}

.loginLt {
  float: left;
  width: 50%;
  padding-top: 70px;
  text-align: center;
}

.btn-donate {
  background-color: #da3a50;
  padding: 12px 110px;
  font-size: 16px;
  border-radius: 30px;
  color: #fff;
}

.loginRt {
  float: left;
  width: 50%;
  background-color: #e2e2e2;
  padding: 60px 100px 60px 70px;
  position: relative;
}

.loginRt .loginPopupClose {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 40px;
  right: 40px;
}

.loginRt h2 {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 16px;
  color: #2a2a2a;
}

.newUser {
  font-size: 16px;
  font-weight: 700;
  color: #2a2a2a;
}

.createAcc {
  color: #da3a50;
  font-weight: 400;
  margin-left: 10px;
}

.createAcc:hover {
  color: #da3a50;
}

.loginField {
  margin-top: 30px;
}

.loginField .form-control {
  font-size: 16px;
  padding: 13px 16px;
  border: 0;
  border-radius: 0;
  color: #444;
}

.loginField .btn-donate {
  color: #da3a50;
  background-color: #fff;
}

.viaSocialLabel {
  position: relative;
  margin: 30px 0;
  width: 100%;
}

.viaSocialLabel:after {
  content: "";
  width: 100%;
  height: 2px;
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 10px;
}

.viaSocialSec label {
  font-size: 13px;
  color: #2a2a2a;
  background-color: #e2e2e2;
  position: relative;
  z-index: 1;
  margin-bottom: 0;
  padding: 0 10px;
}

.viaSocialLinkGrp {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  padding: 0 40px;
}

.viaSocialLinkGrp a {
  width: 48px;
  height: 48px;
  background-color: #fff;
  border-radius: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.viaSocialLinkGrp a img {
  width: 24px;
}

.loginCheckBox .shCheckCon {
  color: #686868;
  font-weight: 600;
  font-size: 16px;
}

.loginCheckBox .shCheckCon input:checked ~ .checkmark,
.loginCheckBox .shCheckCon:hover input ~ .checkmark {
  background-color: #fff;
}

.loginCheckBox .checkmark {
  height: 24px;
  width: 24px;
  background-color: #fff;
}

.loginCheckBox .shCheckCon .checkmark:after {
  border-color: #686868;
}

.loginCheckBox .shCheckCon .checkmark:after {
  top: 6px;
  border-width: 0 2px 2px 0;
}

.shTrailBnSec2 {
  position: relative;
}

.shTrailBnSec2 .pageImg .pageImgItem p {
  padding-left: 0;
  margin-bottom: 0;
}

.shTrailBnSec2 .shTrailBnText {
  padding: 20px 90px 0;
  overflow-y: auto;
}

.trailPlaceMain .shTrailBnSec2 .shBnText .shTrailDesc {
  font-size: 14px;
  line-height: 24px;
  font-style: normal;
}

.shTrailBnSec2 .pageImg {
  margin-bottom: 20px;
}

.shTrailBnSec2 .pageImg .pageImgItem img {
  height: 280px;
}

/* .locMapPopup  */
.leaflet-div-icon {
  background: transparent !important;
  border: 1px solid transparent !important;
}

.mapMarker {
  position: absolute;
  width: 26px;
  height: 26px;
  border: 1px solid #fff;
  border-radius: 4px;
}

/*.mapMarker:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 8px solid #000;
    left: 8px;
    bottom: -8px;
    z-index: 1;
}*/
.mapMarker:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 10px solid #fff;
  left: 6px;
  bottom: -10px;
}

.map-container {
  height: 350px;
  margin-bottom: 75px;
  width: 100%;
}

.inner-map-container {
  height: 400px;
  position: relative;
}

.leaflet-top.leaflet-right {
  display: none;
}

.category-filter {
  position: relative;
  top: -10px;
  z-index: 2;
}

#category {
  height: 30px;
  width: 100px;
}

#map {
  width: 100%;
  height: 100%;
}

.landing-map-wrapper {
  margin: 0;
  padding: 0;
  width: 100%;
  height: calc(100% - 15px);
}

/* .inner-map-container #map {
    top: -75px;
} */
.inner-map-container .shMapSel {
  position: absolute;
  left: 10px;
  font-family: "Open Sans", sans-serif;
}
#ham-menu {
  display: none;
}

#ham-menu.active {
  display: block;
}

.fullBoxMain > div {
  z-index: 99;
  position: relative;
}
.shBnCat {
  font-size: 16px !important;
  margin-bottom: 12px;
  font-family: "Open Sans", sans-serif;
  color: #fff;
}
.shBnCat:hover {
  text-decoration: underline;
  color: #fff;
}

.react-fullscreen-slideshow-modal-content .react-fullscreen-slideshow-slides img {
  width: auto !important;
  height: 100% !important
}
.shAllMenu .stFtTopItem h5 img {
  float: right;
  margin-top: 2px;
  width: 12px;
  display: none;
}
.shMenuAccountList {
  padding: 20px 0;
  border-top: 5px solid #f5dcdd;
  display: none;
}
.shMenuAccountList li {
  display: block;
}
.shMenuAccountList li a {
  display: block;
  font-size: 12px;
  color: #444;
  padding: 5px 30px;
}
.shMenuAccountList li.shMenuSignOut {
  margin-top: 10px;
}
.shMenuAccountList li.shMenuSignOut a {
  color: #da3a50;
  font-size: 12px;
}
.shGalSec {
  font-family: "Open Sans", sans-serif;
  margin-top: 40px;
}
.shTotalImg {
  color: #fff;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}
.shViewGal {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  margin-left: 50px;
}
.shTotalImg img {
  margin-right: 5px;
}
.shTotalImg img,
.shViewGal img {
  width: 24px;
  display: inline-block;
}
.shTotalImg:hover,
.shViewGal:hover {
  color: #fff;
}
/* .react-fullscreen-slideshow-view-all-thumbnails {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap; */
/* } */

/* .react-fullscreen-slideshow-modal-wrapper {
    z-index: 3;
} */

.createNewTrig {
  margin-bottom: 60px;
  font-size: 16px;
  font-weight: 700;
  color: #444;
  font-family: "Open Sans", sans-serif;
}
.createNewTrig i {
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
  margin-top: -4px;
  background: url(/search/static/media/ic_create.3f28df8e.svg) 0 0 no-repeat;
}
.createNewTrig:hover {
  color: #da3a50;
}
.createNewTrig:hover i {
  background-position: -24px 0;
}

#expand-bar .shSideMenuTrig a {
  display: none;
}

/* #react-fullscreen-slideshow-caption > p {
    color: #fff;
    font-weight: 400;
    padding-bottom: 0;
    margin-bottom: 60px;
} */

.shBnCat:hover,
#author_entity h3.float-left:hover {
  color: #da3a50;
}

.leaflet-container a.leaflet-popup-close-button {
  z-index: 99 !important;
}

.leaflet-popup-content {
  /* width: 100% !important; */
  width: auto !important;
  margin: 0;
}

.leaflet-popup-content-wrapper {
  border-radius: 2.5px;
}

.content-wrapper {
  width: 100%;
}

a i.inbuilt.filter-item.selected {
  background: #da3a50;
}

#attr > a {
  float: right;
}

/* #expand-bar .shSideMenuTrig a img.active {
    display: block;
} */

.leaflet-popup.leaflet-zoom-animated {
  width: 280px;
}

.shVidBanner .shBnText {
  left: 0;
  /* bottom: -350px; */
  color: #444;
  max-width: 66.666667%;
  padding-right: 15px;
  padding-left: 0px;
  position: inherit;
  margin-top: 4%;
}
.videoPageTheme .moreMainSec {
  margin-top: -35%;
}
/* .videoPageTheme .articlePage {
    margin-top: 300px;
} */
.video-content .articlePageTheme {
  padding-top: 0px !important;
}

#player {
  padding-top: 25px;
}

#player .youtube-chapter-wrapper .youtube-wrapper {
  -webkit-flex: 3 1 100%;
          flex: 3 1 100%;
}

#player .youtube-video {
  width: 100%;
  height: 584px;
}

#player .youtube-chapter-wrapper .youtube-wrapper iframe {
  width: 100%;
  height: 100%;
}

#player .youtube-chapter-wrapper .chapters-wrapper {
  border: 0;
  width: 100%;
  -webkit-flex: 1 1 100%;
          flex: 1 1 100%;
  overflow-x: overlay;
  margin-top: 20px;
  margin-left: 25px;
}

#player .youtube-chapter-wrapper .chapters-wrapper .chapter-point-wrapper {
  border-top: 1px solid rgb(159 159 159/50%);
  cursor: pointer;
  float: left;
  width: 18%;
  margin-right: 22px;
  opacity: 0.7;
  height: 100px;
}

#player
  .youtube-chapter-wrapper
  .chapters-wrapper
  .chapter-point-wrapper
  .chapter-point {
  padding: 10px 0;
}

#player
  .youtube-chapter-wrapper
  .chapters-wrapper
  .chapter-point-wrapper:nth-child(2n) {
  background-color: transparent;
}

#player
  .youtube-chapter-wrapper
  .chapters-wrapper
  .chapter-point-wrapper:last-child {
  border-bottom: 0;
}

#player
  .youtube-chapter-wrapper
  .chapters-wrapper
  .chapter-point-wrapper
  .chapter-point
  h5 {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
}

#player
  .youtube-chapter-wrapper
  .chapters-wrapper
  .chapter-point-wrapper
  .chapter-point
  h6 {
  font-size: 14px;
}

#player
  .youtube-chapter-wrapper
  .chapters-wrapper
  .chapter-point-wrapper:hover {
  background-color: transparent;
}

#player
  .youtube-chapter-wrapper
  .chapters-wrapper
  .chapter-point-wrapper.current,
#player
  .youtube-chapter-wrapper
  .chapters-wrapper
  .chapter-point-wrapper.current:hover {
  background-color: transparent;
  border-top: 1px solid #da3a50;
  opacity: 1;
}

#playerMobile .youtube-chapter-wrapper .youtube-wrapper {
  -webkit-flex: 3 1 100%;
          flex: 3 1 100%;
}

#playerMobile .youtube-video {
  width: 100%;
  height: 584px;
}

#playerMobile .youtube-chapter-wrapper .youtube-wrapper iframe {
  width: 100%;
  height: 100%;
}

#playerMobile .youtube-chapter-wrapper .chapters-wrapper {
  border: 0;
  width: 100%;
  -webkit-flex: 1 1 100%;
          flex: 1 1 100%;
  overflow-x: overlay;
  margin-top: 20px;
}

#playerMobile
  .youtube-chapter-wrapper
  .chapters-wrapper
  .chapter-point-wrapper {
  border-top: 1px solid rgb(159 159 159/50%);
  cursor: pointer;
  float: left;
  width: 18%;
  margin-right: 22px;
  opacity: 0.7;
  height: 100px;
}

#playerMobile
  .youtube-chapter-wrapper
  .chapters-wrapper
  .chapter-point-wrapper
  .chapter-point {
  padding: 10px 0;
}

#playerMobile
  .youtube-chapter-wrapper
  .chapters-wrapper
  .chapter-point-wrapper:nth-child(2n) {
  background-color: transparent;
}

#playerMobile
  .youtube-chapter-wrapper
  .chapters-wrapper
  .chapter-point-wrapper:last-child {
  border-bottom: 0;
}

#playerMobile
  .youtube-chapter-wrapper
  .chapters-wrapper
  .chapter-point-wrapper
  .chapter-point
  h5 {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
}

#playerMobile
  .youtube-chapter-wrapper
  .chapters-wrapper
  .chapter-point-wrapper
  .chapter-point
  h6 {
  font-size: 14px;
}

#playerMobile
  .youtube-chapter-wrapper
  .chapters-wrapper
  .chapter-point-wrapper:hover {
  background-color: transparent;
}

#playerMobile
  .youtube-chapter-wrapper
  .chapters-wrapper
  .chapter-point-wrapper.current,
#player
  .youtube-chapter-wrapper
  .chapters-wrapper
  .chapter-point-wrapper.current:hover {
  background-color: transparent;
  border-top: 1px solid #da3a50;
  opacity: 1;
}

.text-wrapper {
  display: none;
}

.react-fullscreen-slideshow-tile-button {
  height: 30px;
  width: 30px;
}
.react-fullscreen-slideshow-view-all-thumbnails img {
  width: auto;
  display: inline-block;
  max-height: 20vh;
}


.react-fullscreen-slideshow-modal-wrapper {
  z-index: 2;
}

.sLogo1{display:none}

#embeddedMedia {
  display: none;
}

.react-fullscreen-slideshow-slides {
  -webkit-flex: 0 0 60% !important;
          flex: 0 0 60% !important;
}

.layer-item-wrap {
  display: none;
}

/* .museumMainTxt div {
    padding-bottom: 0!important;
    line-height: 2!important;
    letter-spacing: 0!important;
    margin: 0!important;
} */

.react-fullscreen-slideshow-close {
  display: block !important;
  right: 25px;
  top: 90px;
  left: auto !important;
  z-index: 1;
}

.react-fullscreen-slideshow-close-text {
  display: none !important;
}

.react-fullscreen-slideshow-tile-row {
  width: 20px !important;
}

.react-fullscreen-slideshow-close-view-all {
  top: 40px !important;
}

.react-fullscreen-slideshow-bottom-text {
  margin: 4px 0 0 10px;
  font-size: 14px;
  font-family: sans-serif;
}

.flickity-prev-next-button {
  margin-top: -20px;
}

/* .flickity-prev-next-button.previous {
    left: 70px !important;
} */

/* .flickity-prev-next-button.next {
    right: 70px !important;
} */

#react-fullscreen-slideshow-caption {
  position: absolute;
  margin: 60px 50px 0 10px;
  max-height: 170px;
  overflow-y: auto;
  bottom: 70px;
}

#react-fullscreen-slideshow-caption > p {
  color: #fff;
  font-weight: 400;
  padding-bottom: 0;
  margin-bottom: 0;
  margin-top: 105px;
  font-size: 20px;
  text-align: left;
}
.react-fullscreen-slideshow-numbertext {
  font-family: "Open Sans", sans-serif;
}

.popup-content {
  position: absolute;
  z-index: 999;
  pointer-events: auto;
  top: 520.6px;
  left: 321.6px;
}

.tooltip-outer {
  height: 8px;
  width: 16px;
  position: absolute;
  background: transparent;
  color: rgb(255, 255, 255);
  z-index: -1;
  -webkit-transform: rotate(-90deg) translateY(-150%) translateX(25%);
          transform: rotate(-90deg) translateY(-150%) translateX(25%);
  top: 50%;
  left: 0%;
}

.react-fullscreen-slideshow-modal-top {
  border: none;
}
.highlightEmail {
  color: #da3a50;
}

.notTabMain {
  font-size: 14px;
  word-break: break-word;
}
.articleContainer .mobileSocialView {
  font-size: 26px;
  margin-top: -16px;
  margin-right: 6px;
  margin-bottom: 2px;
}
.socialNew3 .mobileSocialView {
-webkit-filter:invert(25%)!important;
        filter:invert(25%)!important;
font-size:26px;
}
#playerMobile{display:none;}
.shVidBannerText .shSocialList li{
-webkit-filter:invert(75%);
        filter:invert(75%);
}
.carousel{height:460px;margin: 0 auto 40px;}
.Museum-Page #author_entity h3{font-size:16px;float:left;}
.Museum-Page .flickity-button
.museumLanguage{font-size: 13.33px;font-weight: 700;font-family: Arial;}
.dropdownLang{min-width:8rem;cursor: pointer;}
.Museum-Page .timeline-summary p{padding-bottom: 0px !important;}
.shBnTimeline .timeline-summary p{color:#6b6b6b !important;font-family:'Libre Baskerville'; font-weight:100;margin-bottom:12px; padding-bottom:0px;}

.inActive{display:none;}
#author_entity div {
background-color: inherit !important;
}
.shDateSec .popup-content{
width:50% !important;
}
.projSection{text-align: center;}
.Museum-Page .flickity-button{background: hsla(0,0%,100%,.30) !important;}
/* .Museum-Page .flickity-button:hover{background:#fff !important;} */
/* .Museum-Page .flickity-button:hover{background:#fff !important;} */

.Museum-Page .flickity-prev-next-button{
width: 36px;
height: 36px;}
.newTrail{font-family: sans-serif;}
.Timeline-Page .mobileSocialView{
font-size:26px;
}
.Timeline-Page .shAuthBio{
top:75%;
}
.socialNew2 .mobileSocialView{
font-size:27.5px;
margin-bottom: 8px;
}
.shBnTimeline p b{
font-weight: 600;
font-family: sans-serif;
font-size:14px;
color:#444444;
}
.signMobile{
display:none;
}
.museumReadmore{
padding-left: 15px;
padding-right: 15px;
margin-left: 62.5;
margin-right: 62.5;
}

.wrapper-pop{height:180px;overflow-y: auto;}
.article-book{
  -webkit-filter: none !important;
          filter: none !important;
}
.article-book #donate{
  margin-top: -32px !important;
}
.article-book .signinlogo{
  margin-top: 114px !important;
}
@media screen and (max-width: 360px) {

   .sahaLogo, .slogo{
    margin-right:0 !important;
  }
  
    .socialMuseum{
        padding:1% 20%;
    }

    .artNotesTabSocial .someRight{
        float:left !important;
        padding:1% 8%;
    }
    /* .sahaLogo {
        width: 60%;
        margin:1% 8% !important;
    } */
    /* .shHeader.smHeader .topLinks{
        display:none;
    } */
      .sahaLogo {
        width: 60%;
        margin: 1% 9% !important;
        
      }
      
      .shHeader.smHeader .topLinks {
        display: none;
      }
}
@media screen and (max-width: 280px) {
   
    .shHeader.smHeader .topLinks{
        display:none;
    }
    .shHeader .shSearchSec{
      display:block;
  }
    .shMenu{
      margin-left: -20% !important;
      margin-top: 40% !important;
    }
    .sahaLogo { 
      margin-right:10% !important;
      margin-top: -10% !important;
    }
    .sLogo{
      margin-top:-25% !important;
     float:right ;
     margin-right: -140% !important;
    }
  .shSearch{
    margin-left: 50px!important;
    margin-top: -8px !important;
    display:none;
  }
  .shHeader2 .smHeader .topLinks{
    display:none;
}
.shHeader2 .shSearchSec{
  display:none;
}
.shHeader2 .shMenu{
  margin-left: -20% !important;
  margin-top: 28% !important;
}
.shHeader2 .sahaLogo { 
  margin-right:10% !important;
  margin-top: -2% !important;
}
.shHeader2 .sLogo{
  margin-top:-36% !important;
 float:right ;
 margin-right: -54% !important;
}
.shHeader2 .shSearch{
margin-left: 50px!important;
margin-top: -8px !important;
}
  }

@media screen and (max-width: 1150px) {
.shHeader.smHeader .topLinks {
display: none;
}
.topLinks li {
margin: 0 0 0 0px;
}
#title_heading_entity {
font-size: 30px !important;
margin-bottom: 10px;
/* color: #444; */
}
}
@media screen and (max-width: 960px) {
.shHeader.smHeader .topLinks {
display: none;
}
/* .react-fullscreen-slideshow-caption-container {
position: fixed;
} */
#react-fullscreen-slideshow-caption {
/* padding-top: 20px; */
max-width: 100%;
}
}
@media screen and (max-width: 768px) {
  .shSearchSec .shProfile{display:block !important}
  .roundLogo{display:none}
  .sLogo{display:none}
  .sLogo1{display:block}
  .sLogo1 .slogo{display:block}
  .Navigate-mobile{
    color: #444;
    margin-bottom: 0;
    padding: 2% 20px;
    font-size: 12px;
    border-bottom: 1px solid rgb(218 58 80/15%);
  }
 .react-fullscreen-slideshow-modal-bottom .react-fullscreen-slideshow-bottom-left-pane{padding:5px 5px 5px 20px !important}

.react-fullscreen-slideshow-modal-content .react-fullscreen-slideshow-slides img{width: auto !important}
.flickity-button-icon{fill:white;}
.flickity-button{background-color: #bcbcbc !important;}
.react-fullscreen-slideshow-modal-bottom{position:inherit !important;}
.react-fullscreen-slideshow-gallery-icon-bar .share-button{width:53px !important;padding: 5px 6px !important;}
.react-fullscreen-slideshow-caption-container #react-fullscreen-slideshow-caption{bottom:auto !important; max-height: 170px !important}
.react-fullscreen-slideshow-modal-content .react-fullscreen-slideshow-caption-container{bottom:auto !important}
#react-fullscreen-slideshow-caption > p{font-size: 16px;}
.flickity-enabled{background-color: #f5f5f5;width:119% !important;margin:0px 0px 0px -30px !important;}
.banner-details{padding: 3px 10px !important;}
.react-fullscreen-slideshow-slides{height:55%}
.mobileBann{
display: none;
}
.smHeader .sahaLogo, .smHeader .slogo{
display: none;
}
.articlePageTheme.museumPageTheme {
padding-top: 0px !important;
margin-top: 0px !important;
}

.leaflet-popup.leaflet-zoom-animated{
width:210px;
}
.mobile-card-at{padding:0px}
.shHeader.smHeader .topLinks{
display:block;
}
.projSection{text-align: left !important;}

.Museum-Page #author_entity h3{font-size:14px;}

.shAuthDesc2{width:230px !important}
.shAuthDesc{height:200px;overflow-y: scroll;padding-left:20px !important}
.articleContainer .shAuthBio{width:320px !important;}
.Museum-Page .shAuthBio{width:100% !important;}
.dropdown-menu.show{
top: 30px !important;
}
.shLang .btn-secondary img{
-webkit-filter: invert(30%);
        filter: invert(30%);
width:10px !important;
margin-top:0px !important;
}
.shArtLogo img{
background: #fff;
margin: 20px 0px;
height: 267px !important;
width: 100%;
text-align: center;
}
.sectioning{
margin-top: 300px;
} 
.Timeline-Page .mobileSocialView{
font-size:20px;
margin-top: 1%;
}
.shArtLogo.shArtLogoSec img{
width:100%!important;
height: 200px !important;
display: inline-block;
margin:0% 15%;
}
/* .shArtLogo.shArtLogoSec img {
width:auto;
height: 200px;
display: inline-block;
} */
.carousel {
height: 275px !important;
margin: 0 auto 0px;
}

.videoPageTheme .moreMainSec {
margin-top: 0% !important;
}
.shVidBannerText .shSocialList li {
-webkit-filter: invert(25%) !important;
        filter: invert(25%) !important;
}
.articleMainTxt .paraItalic p {
padding-right: 0px !important;
}
#playerMobile {
display: block !important;
}
#player {
display: none;
}

.articleContainer .mobileSocialView {
font-size: 20px;
margin-top: -9px!important;
}
.socialNew3 .mobileSocialView {
/* filter:invert(25%)!important; */
font-size: 20px;
}

.articleContainer {
margin-top: 20%;
padding: 0px;
}

.shSocialList img {
 -webkit-filter: invert(75%);
         filter: invert(75%);
}

.shSocialList li img {
width: 18px;
}
.highlightPhone {
color: #da3a50;
}
/* .shArtLogo{
height:100%;
margin-top:7%;
padding:0px 32px;
} */
.moreMainSec {
width: 100% !important;
}

    .shHeader {
        z-index: 3;
    }

    .shTopHeader {
        padding: 8px 20px;
        height: 56px;
    }

    .shMenu {
        width: 24px;
        float: left;
        margin-top: 8px;
    }

    .sahaLogo {
    
      margin-left: 40px;
        width: 40px; 
        
        
   }
   .sLogo{
      
       margin-top: -50;
       width: 130px;
   }


    .shSearch {
        margin-right: 10%;
        margin-top: 8px;
    }

    .shProfile {
        display: block !important;
    }

    .logoSecp {
        width: calc(50% - 40px);
        
    }

    .shBanner {
        margin-top: 56px;
    }

    .shBnText {
        position: relative;
        left: 0;
        bottom: 0;
        color: #444;
        padding: 0px 15px;
    }
    .shBnTimeline{
        position: relative;
        color: #fff;
        z-index: 1;
        margin-left: -80px;
        width:85% !important;
    }
    .shBnTimeline p {
        font-size: 15px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 100;
        color: #444;
    }
    .shBnTimeline h2 {
        font-size: 20px;
        margin-bottom: 10px;
        color: #444;
    }
    
    .shBnText p,
    .shBnText .shDateSec h3 {
        font-size: 12px;
    }

    .shBnText h2 {
        font-size: 20px;
    }

    .shLang .btn-secondary {
        color: #444 !important;
    }

    .shSocialSec .shLang {
        margin-left: 0;
        margin-top: 10px;
    }

    .shSocialSec {
        margin-top: 4px;
    }

    .shBnText .shDateSec p {
        float: right !important;
    }

    .shSocialSec .shSocialList {
        width: 100%;
    }

    .shSocialList li.shTag {
        float: right;
        margin-right: 5px;
    }

    .shBnCat {
        color: #DA3A50;
    }

    .shBnCat > a {
        color: #fff;
    }

    .shBnOverlay {
        display: none;
    }

    .shBnImg, .shBnImg > img.banner-image {
        height: 250px;
    }
    .shBnImg > iframe.banner-image {
        display: block;
        height: auto;
        border:none;
        width: 100%;
    }
    .shBnIfr, .shBnIfr > img.banner-image {
      height: 100% !important;
  }
  .shBnIfr > iframe.banner-image {
      display: block;
   
      border:none;
      width: 100%;
  }
  .balign {
    margin-left: 0px;
  }

    .articlePage,
    .moreMainSec {
        width: auto;
    }

    .articleMainTxt p.paraItalic {
        padding-right: 0;
        margin-bottom: 20px;
        padding-bottom: 0;
        font-size: 15px;
    }

    .articleMainTxt p {
        padding-bottom: 30px!important;
        line-height: 24px;
        font-size: 18px!important;
        margin-bottom: 0;
    }

    .pageImg {
        margin-bottom: 24px;
    }

    .artCarouselSec {
        margin-left: 0;
    }

    .playItem {
        bottom: 40px;
    }

    .playItem a {
        width: 48px;
    }

    .playItem h6 {
        font-size: 16px;
        padding: 0 60px;
        margin-top: 10px;
        line-height: 24px;
    }

    .playItem .btn-con {
        margin-top: 0;
        background-color: #fff;
    }

    .articleNotesTab .nav-tabs .nav-link {
        font-size: 12px;
    }

    .articleNotesTab .tab-content {
        padding: 20px;
        height: auto;
        font-size:14px;
    }

    .artNotesTabSocial .someRight {
        font-size: 12px;
    }
    .artNotesTabSocial .someRight span{
        margin:3px 0px;
    }

    .articlePlaceMain {
        margin-top: 56px;
    }

    .articlePageTheme {
        padding: 20px 15px 0px 15px;
    }

    .articleMainTxt .pageImg p {
        padding-bottom: 0;
        padding-left: 0;
        line-height: 16px;
        margin-top: 10px;
    }

    .tantraSec {
        margin: 0px 0 30px 0;
        text-decoration: none;
    }

    .notTabMain p {
        font-size: 12px;
        word-break: break-word;
    }
    .notTabMain a{
        font-size: 12px;
        word-break: break-word;
    }

    .artNotesTabSocial {
        padding: 20px 0 20px 0;
    }

    .moreTopicSec {
        margin-bottom: 0px;
    }

    .moreExtra {
        border-top: 1px solid #D6D6D6;
        padding-top: 48px;
        text-align: center;
    }

    .moreExtra .moreExtraItem {
        width: 260px;
        display: inline-block;
    }

    .moreExtra .moreExtraItem .moreExtraItemTxt {
        text-align: left;
    }

    .readMore {
        padding: 40px 0 23px 0;
    }

    .readMore a {
        font-weight: 700;
    }

    .stThumbItem h2 {
        font-size: 16px;
        margin-bottom: 0;
    }

    .stThumbItem .stThumbSculp {
        font-size: 12px;
    }

    .stThumbItem .stThumbOw {
        margin-top: 12px;
        font-size: 14px;
    }

    .stThumbItem p {
        margin-bottom: 5px;
    }

    .btn-load {
        font-size: 12px;
    }

    .stFootBtm {
        padding-bottom: 40px;
        /* padding-left: 50px; */
        position: relative;
        text-align: left;
        margin-top: 30px;
    }

    .stFootBtm a {
        font-size: 14px;
        margin: 0 0 10px 0;
        width: 48%;
    }

    .stFootBtm a:first-child {
        /* position: absolute;
        padding:1% 16%; */
        left: 0;
        font-size: 12px;
        /* width: 100%; */
        text-align: center;
        margin-top: 2%;
    }

    .stFtTopList {
        display: block;
        background-color: rgb(218 58 80 / 15%);
        padding: 10px
    }

    .stFtTopItem {
        text-align: center;
        margin-bottom: 0;
    }

    .stFtTopItem h5 {
        font-size: 14px;
        margin-bottom: 0;
        padding: 10px;
        border-bottom: 1px solid rgb(218 58 80 / 15%);
    }

    .stFtTopList li {
        margin-bottom: 10px;
    }

    .stFtTopList li a {
        font-size: 12px;
    }

    .stFooter {
        padding-top: 0;
    }

    .stFootLogo {
        display: none;
    }

    .stFtThumb .col-md-4,
    .stFootTop .col-md-4,
    .stFootTop .col-md-8,
    .stFootTop .col-md-6,
    .shSearchResFound .col-md-12,
    .shSearchLibSec .col-md-4 {
        padding-right: 0;
        padding-left: 0;
    }

    .stThumbItemDesc {
        padding: 0 40px;
    }

    .stThumbItemCtrl {
        margin-top: -26px;
    }

    .artCarouselSec .owl-carousel .owl-item .artCarouselImg {
        height: 260px;
        width: 100vh;
    }

    .artCarouselSec .owl-theme .owl-nav {
        top: 60%;
    }

    .stThumbItem .stThumbCat {
        margin-bottom: 0;
    }

    .landingMapSec {
        margin-top: 0;
        margin-bottom: 40px;
    }

    .shAllMaps {
        width: auto;
        padding-left: 20px;
        font-size: 12px;
    }

    .shSubHdRt {
        padding-left: 90px;
    }

    .shSubNav a.shSubLoc {
        font-size: 12px;
    }

    .shSubNav a {
        padding: 0 10px;
    }

    .shBreadcrumb .breadcrumb .breadcrumb-item.active {
        display: none;
    }

    .shSubHeader {
        padding-right: 20px;
    }

    .shMapSearchBox {
        width: 210px;
    }

    .shMapSel {
        left: 68px;
    }

    .shMapSearchTrig {
        margin-right: 20px;
    }

    .shMapSearchResList li a p {
        margin-bottom: 0;
    }

    .shSubHdRt.shSubHdRtAbt {
        padding-left: 20px;
    }

    .aboutPlaceMain {
        margin-top: 86px;
    }

    .aboutNav {
        text-align: center;
        font-family: 'Open Sans', sans-serif;
        padding: 40px 0 30px;
    }

    .shSubNav.aboutNav a {
        font-size: 12px;
        padding: 0 20px;
    }

    .aboutMain p {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 30px;
    }

    .aboutMain h2 {
        font-size: 20px;
        margin-bottom: 16px;
    }

    .WorkList li {
        margin-bottom: 30px;
        font-size: 14px;
    }

    .aboutMain {
        width: auto;
        padding-top: 30px;
    }

    .aboutBanner {
        margin-left: -15px;
        margin-right: -15px;
    }

    .teamMemDesc {
        font-size: 14px;
        line-height: 24px;
    }

    .allTeamItem h4,
    .allTeamItem p {
        font-size: 12px;
    }

    .teamMemDetail .allTeamItem {
        margin-bottom: 30px;
    }

    .closeBox {
        right: 20px;
    }

    .teamMemDetailMain {
        width: 100%;
        height: calc(100vh - 86px);
        margin-left: 0;
        padding: 20px;
    }

    .teamMemDetail {
        top: 86px;
    }

    .overlayTeam {
        display: none;
    }

    .contactSec {
        width: auto;
        margin: 0 auto;
    }

    .contactItemWithHead {
        margin-bottom: 30px;
    }

    .contactItemWithHead h3 {
        font-size: 24px;
        margin-bottom: 20px;
    }

    .contactItemWithHead p {
        font-size: 14px;
    }

    .shSearchResList li .stThumbItem .stThumbItemImg {
        display: none;
    }

    .shSearchResListItem {
        width: auto;
    }

    .shSearchResList li .stThumbItem .stThumbItemDesc {
        width: auto;
        padding: 0;
    }

    .shSearchResList li {
        padding-bottom: 0;
        border-bottom: 0;
    }

    .shSearhResFoundFilter {
        float: left;
        margin-left: 0;
        width: 100%;
    }

    .shSearchInputItem {
        width: auto;
    }

    .shSearhTypeView {
        padding-top: 40px;
        padding-bottom: 4px;
    }

    .shResultCount {
        width: 100%;
        text-align: center;
    }

    .shSearhResFoundFilterItem {
        margin-right: 0;
        width: 48%;
    }

    .shSearhResFoundFilterItem .shSearhResFoundFilterItemSel {
        margin-left: 0;
        width: 100%;
    }

    .shSearhResFoundFilterItem .shSearhResFoundFilterItemSel .dropdown-toggle {
        width: 100%;
    }

    .shSearhResFoundFilter .shSearhResFoundFilterItem:last-child {
        float: right;
    }

    .shSearchResFoundBlk {
        padding-top: 0;
        border-top: 0;
    }

    .shSearchResListSec {
        padding-top: 30px;
    }

    .searchPlaceMain,
    .libraryPlaceMain {
        margin-top: 56px;
    }

    .libraryPlaceMain .shSearhResFoundFilter {
        text-align: center;
    }

    .libraryPlaceMain .shSearhResFoundFilter .shSearhResFoundFilterItem:last-child {
        display: inline-block;
        float: none;
        width: 70%;
    }

    .libraryPlaceMain .shSearhResFoundFilterItem label {
        display: block;
        text-align: center;
        float: none;
    }

    .libraryPlaceMain .shSearchInputSec p {
        margin-bottom: 0;
    }

    .shSearchResListSec.shSearchLibSec .stThumbItem h2 {
        margin-top: 0;
    }

    .allTeamSec .col-md-3 {
        width: 50%;
    }

    .teamMemPic {
        height: 185px;
        width: 100%;
    }

    .allTeamItem {
        margin-bottom: 30px;
    }

    .teamMemDetailMain .teamMemPic {
        width: 160px;
    }

    .loginPopupMain {
        width: 100%;
    }

    .loginLt {
        display: none;
    }

    .loginRt {
        width: 100%;
        height: 100vh;
        padding: 60px 40px;
    }
    .locMapItem {
        width: auto;
        height: 85vh;
        margin: 8% 10px;
    }
    .shAllMenu {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 56px);
    top: 56px;
    z-index: 3;
  }

  .shTopHeader {
    padding: 8px 20px;
    height: 56px;
  }

  .shMenu {
    width: 24px;
    float: left;
    margin-top: 8px;
  }

  .sahaLogo {
    width: 30px;
    margin-top: 1.5%;
  }
  .sLogo {
    margin-left: 27%;
    margin-top: -12;
    width: 130px;
  }

  .shSearch {
    margin-right: 10%;
    margin-top: 8px;
  }

  .shProfile {
    display: none;
  }

  .logoSecp {
    width: calc(50% - 40px);
  }

  .shBanner {
    margin-top: 56px;
  }

  .shBnText {
    position: relative;
    left: 0;
    bottom: 0;
    color: #444;
    padding: 15px 15px 0px 10px;
  }
  .shBnTimeline {
    position: relative;
    color: #fff;
    z-index: 1;
    margin-left: -100px;
    width: 85% !important;
  }
  .shBnTimeline p {
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    font-weight: 100;
    color: #444;
  }
  .shBnTimeline h2 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #444;
  }
  .shBnText p,
  .shBnText .shDateSec h3 {
    font-size: 12px;
  }

  .shBnText h2 {
    font-size: 20px;
  }

  .shLang .btn-secondary {
    color: #444 !important;
    font-size: 12px!important;
  }

  .shSocialSec .shLang {
    margin-left: 0;
    margin-top: 10px;
  }

  .shSocialSec {
    margin-top: 4px;
  }

  .shBnText .shDateSec p {
    float: right !important;
  }

  .shSocialSec .shSocialList {
    width: 65%;
  }

  .shSocialList li.shTag {
    float: right;
    margin-right: 12px;
  }

  .shBnCat {
    color: #da3a50;
  }

  .shBnCat > a {
    color: #fff;
  }

  .shBnOverlay {
    display: none;
  }

  .shBnImg,
  .shBnImg > img.banner-image {
    height: 250px;
  }
  .shBnImg > iframe.banner-image {
    display: block;
    height: auto;
    border: none;
    width: 100%;
  }

  .articlePage,
  .moreMainSec {
    width: auto;
  }

  .articleMainTxt p.paraItalic {
    padding-right: 0;
    margin-bottom: 20px;
    padding-bottom: 0;
    font-size: 15px;
  }

  .articlePage .articleMainTxt p {
    padding-bottom: 30px !important;
    line-height: 24px;
    font-size: 14px !important;
    margin-bottom: 0;
  }

  .pageImg {
    margin-bottom: 24px;
  }

  .artCarouselSec {
    margin-left: 0;
  }

  .playItem {
    bottom: 40px;
  }

  .playItem a {
    width: 48px;
  }

  .playItem h6 {
    font-size: 16px;
    padding: 0 60px;
    margin-top: 10px;
    line-height: 24px;
  }

  .playItem .btn-con {
    margin-top: 0;
    background-color: #fff;
  }

  .articleNotesTab .nav-tabs .nav-link {
    font-size: 12px;
  }

  .articleNotesTab .tab-content {
    padding: 20px;
    height: auto;
    font-size: 14px;
  }

  .artNotesTabSocial .someRight {
    font-size: 12px;
  }
  .artNotesTabSocial .someRight span {
    margin: 3px 0px;
  }

  .articlePlaceMain {
    margin-top: 56px;
  }

  .articlePageTheme {
    padding: 20px 15px 0px 15px;
  }

  .articleMainTxt .pageImg p {
    padding-bottom: 0;
    padding-left: 0;
    line-height: 16px;
    margin-top: 10px;
  }

  .tantraSec {
    margin: 0px 0 30px 0;
  }

  .notTabMain p {
    font-size: 12px;
    word-break: break-word;
  }
  .notTabMain a {
    font-size: 12px;
    word-break: break-word;
  }

  .artNotesTabSocial {
    padding: 24px 0 40px 0;
  }

  

  .moreExtra {
    border-top: 1px solid #d6d6d6;
    padding-top: 48px;
    text-align: center;
  }

  .moreExtra .moreExtraItem {
    width: 260px;
    display: inline-block;
  }

  .moreExtra .moreExtraItem .moreExtraItemTxt {
    text-align: left;
  }

  .readMore {
    padding: 40px 0 23px 0;
  }

  .readMore a {
    font-weight: 700;
  }

  .stThumbItem h2 {
    font-size: 16px;
    margin-bottom: 0;
  }

  .stThumbItem .stThumbSculp {
    font-size: 12px;
  }

  .stThumbItem .stThumbOw {
    margin-top: 12px;
    font-size: 14px;
  }

  .stThumbItem p {
    margin-bottom: 5px;
  }

  .btn-load {
    font-size: 12px;
  }

  .stFootBtm {
    padding-bottom: 40px;
    /* padding-left: 50px; */
    position: relative;
    text-align: left;
    margin-top: 30px;
  }

  .stFootBtm a {
    font-size: 14px;
    margin: 0 0 10px 0;
    width: 48%;
  }

  .stFootBtm a:first-child {
    /* position: absolute;
        padding:1% 16%; */
    left: 0;
    font-size: 14px;
    /* width: 100%; */
    text-align: center;
    margin-top: 2%;
  }

  .stFtTopList {
    display: block;
    background-color: rgb(218 58 80 / 15%);
    padding: 10px;
  }

  .stFtTopItem {
    text-align: center;
    margin-bottom: 0;
  }

  .stFtTopItem h5 {
    font-size: 14px;
    margin-bottom: 0;
    padding: 10px;
    border-bottom: 1px solid rgb(218 58 80 / 15%);
  }

  .stFtTopList li {
    margin-bottom: 10px;
  }

  .stFtTopList li a {
    font-size: 12px;
  }

  .stFooter {
    padding-top: 0;
  }

  .stFootLogo {
    display: none;
  }

  .stFtThumb .col-md-4,
  .stFootTop .col-md-4,
  .stFootTop .col-md-8,
  .stFootTop .col-md-6,
  .shSearchResFound .col-md-12,
  .shSearchLibSec .col-md-4 {
    padding-right: 0;
    padding-left: 0;
  }

  .stThumbItemDesc {
    padding: 0 40px;
  }

  .stThumbItemCtrl {
    margin-top: -26px;
  }

  .artCarouselSec .owl-carousel .owl-item .artCarouselImg {
    height: 260px;
    width: 100vh;
  }

  .artCarouselSec .owl-theme .owl-nav {
    top: 60%;
  }

  .stThumbItem .stThumbCat {
    margin-bottom: 0;
  }

  .landingMapSec {
    margin-top: 0;
    margin-bottom: 40px;
  }

  .shAllMaps {
    width: auto;
    padding-left: 20px;
    font-size: 12px;
  }

  .shSubHdRt {
    padding-left: 90px;
  }

  .shSubNav a.shSubLoc {
    font-size: 12px;
  }

  .shSubNav a {
    padding: 0 10px;
  }

  .shBreadcrumb .breadcrumb .breadcrumb-item.active {
    display: none;
  }

  .shSubHeader {
    padding-right: 20px;
  }

  .shMapSearchBox {
    width: 210px;
  }

  .shMapSel {
    left: 68px;
  }

  .shMapSearchTrig {
    margin-right: 20px;
  }

  .shMapSearchResList li a p {
    margin-bottom: 0;
  }

  .shSubHdRt.shSubHdRtAbt {
    padding-left: 20px;
  }

  .aboutPlaceMain {
    margin-top: 86px;
  }

  .aboutNav {
    text-align: center;
    font-family: "Open Sans", sans-serif;
    padding: 40px 0 30px;
  }

  .shSubNav.aboutNav a {
    font-size: 12px;
    padding: 0 20px;
  }

  .aboutMain p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 30px;
  }

  .aboutMain h2 {
    font-size: 20px;
    margin-bottom: 16px;
  }

  .WorkList li {
    margin-bottom: 30px;
    font-size: 14px;
  }

  .aboutMain {
    width: auto;
    padding-top: 30px;
  }

  .aboutBanner {
    margin-left: -15px;
    margin-right: -15px;
  }

  .teamMemDesc {
    font-size: 14px;
    line-height: 24px;
  }

  .allTeamItem h4,
  .allTeamItem p {
    font-size: 12px;
  }

  .teamMemDetail .allTeamItem {
    margin-bottom: 30px;
  }

  .closeBox {
    right: 20px;
  }

  .teamMemDetailMain {
    width: 100%;
    height: calc(100vh - 86px);
    margin-left: 0;
    padding: 20px;
  }

  .teamMemDetail {
    top: 86px;
  }

  .overlayTeam {
    display: none;
  }

  .contactSec {
    width: auto;
    margin: 0 auto;
  }

  .contactItemWithHead {
    margin-bottom: 30px;
  }

  .contactItemWithHead h3 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .contactItemWithHead p {
    font-size: 14px;
  }

  .shSearchResList li .stThumbItem .stThumbItemImg {
    display: none;
  }

  .shSearchResListItem {
    width: auto;
  }

  .shSearchResList li .stThumbItem .stThumbItemDesc {
    width: auto;
    padding: 0;
  }

  .shSearchResList li {
    padding-bottom: 0;
    border-bottom: 0;
  }

  .shSearhResFoundFilter {
    float: left;
    margin-left: 0;
    width: 100%;
  }

  .shSearchInputItem {
    width: auto;
  }

  .shSearhTypeView {
    padding-top: 40px;
    padding-bottom: 4px;
  }

  .shResultCount {
    width: 100%;
    text-align: center;
  }

  .shSearhResFoundFilterItem {
    margin-right: 0;
    width: 48%;
  }

  .shSearhResFoundFilterItem .shSearhResFoundFilterItemSel {
    margin-left: 0;
    width: 100%;
  }

  .shSearhResFoundFilterItem .shSearhResFoundFilterItemSel .dropdown-toggle {
    width: 100%;
  }

  .shSearhResFoundFilter .shSearhResFoundFilterItem:last-child {
    float: right;
  }

  .shSearchResFoundBlk {
    padding-top: 0;
    border-top: 0;
  }

  .shSearchResListSec {
    padding-top: 30px;
  }

  .searchPlaceMain,
  .libraryPlaceMain {
    margin-top: 56px;
  }

  .libraryPlaceMain .shSearhResFoundFilter {
    text-align: center;
  }

  .libraryPlaceMain
    .shSearhResFoundFilter
    .shSearhResFoundFilterItem:last-child {
    display: inline-block;
    float: none;
    width: 70%;
  }

  .libraryPlaceMain .shSearhResFoundFilterItem label {
    display: block;
    text-align: center;
    float: none;
  }

  .libraryPlaceMain .shSearchInputSec p {
    margin-bottom: 0;
  }

  .shSearchResListSec.shSearchLibSec .stThumbItem h2 {
    margin-top: 0;
  }

  .allTeamSec .col-md-3 {
    width: 50%;
  }

  .teamMemPic {
    height: 185px;
    width: 100%;
  }

  .allTeamItem {
    margin-bottom: 30px;
  }

  .teamMemDetailMain .teamMemPic {
    width: 160px;
  }

  .loginPopupMain {
    width: 100%;
  }

  .loginLt {
    display: none;
  }

  .loginRt {
    width: 100%;
    height: 100vh;
    padding: 60px 40px;
  }
  .locMapItem {
    width: auto;
    height: 85vh;
    margin: 8% 10px;
  }
  .shAllMenu {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 56px);
    top: 10%;
    z-index: 3;
    padding-top: 0;
    background-color: #fff;
  }
  .shAllMenu .stFtTopItem {
    text-align: left;
    margin-top: 0;
  }
  .shAllMenu .container {
    padding-right: 0;
    padding-left: 0;
  }
  .shAllMenu .stFtTopItem h5 {
    color: #444;
    margin-bottom: 0;
    padding-left: 30px;
    font-size: 12px;
    padding-right: 20px;
  }
  .shAllMenu .stFtTopItem h5.active img {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
    transition: 0.3s ease;
  }
  .shAllMenu .stFtTopItem h5 img,
  .shMenuAccountList {
    display: none;
  }
  .shAllMenu .stFtTopList {
    padding-left: 48px;
    height:150px;
    overflow-y: auto;
  }
  .shGalSec {
    position: absolute;
    margin-top: 28%;
    text-align: center;
    width: 100%;
    left: 0;
  }
  .shGalSec a {
    display: block;
  }
  .shViewGal {
    margin-left: 0;
    display: block;
    margin-top: 10px;
  }
  .shChapterItem {
    white-space: nowrap;
    overflow-x: auto;
  }

  .shChapterItem li {
    width: 50%;
    float: none;
    display: inline-block;
  }
  .createNewTrig {
    margin-bottom: 30px;
  }

  .shVidBanner .shBnText {
    left: 0;
    bottom: 0;
    color: #444;
    max-width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
  .videoPageTheme .articlePage {
    margin-top: 0;
  }
  .moreMainSec {
    width: 100%;
  }

  #player .youtube-chapter-wrapper .chapters-wrapper ul {
    white-space: nowrap;
    overflow-x: auto;
  }

  #player .youtube-chapter-wrapper .chapters-wrapper .chapter-point-wrapper {
    width: auto;
    float: none;
    display: inline-block;
  }

  #player .youtube-video {
    height: auto;
  }
  #playerMobile .youtube-chapter-wrapper .chapters-wrapper ul {
    white-space: nowrap;
    overflow-x: auto;
    margin-left: 10px;
    margin-right: 10px;
  }

  #playerMobile
    .youtube-chapter-wrapper
    .chapters-wrapper
    .chapter-point-wrapper {
    width: auto;
    float: none;
    display: inline-block;
    overflow: scroll;
    scrollbar-width: none;
  }
  #playerMobile
    .youtube-chapter-wrapper
    .chapters-wrapper
    .chapter-point-wrapper::-webkit-scrollbar {
    display: none;
  }

  #playerMobile .youtube-video {
    height: auto;
  }

  .flickity-prev-next-button.previous {
    left: 1px !important;
    width: 25px;
    height: 25px;
    background-color: #b4b4b4 !important;

  }

  .flickity-prev-next-button.next {
    right: 1px !important;
    width: 25px;
    height: 25px;
    background-color: #b4b4b4 !important;
  }

  .react-fullscreen-slideshow-modal-content .react-fullscreen-slideshow-slides {
    -webkit-flex: 0 0 100% !important;
            flex: 0 0 100% !important;
  }

  .react-fullscreen-slideshow-close {
    top: 1px !important;
  }

  .react-fullscreen-slideshow-prev-mobile,
  .react-fullscreen-slideshow-next-mobile {
    top: 35% !important;
  }

  .react-fullscreen-slideshow-modal-bottom .react-fullscreen-slideshow-bottom-left-pane {
    left: 0;
    right: 0;
    padding-left: 20px;
    -webkit-justify-content: left;
            justify-content: left;
    width:50% !important;
  }

  .react-fullscreen-slideshow-caption-container {
    position: relative !important;
    margin: 0px 15px !important;
  }
  .react-fullscreen-slideshow-caption-container .react-fullscreen-slideshow-gallery-icon-bar {
    position: absolute;
    bottom: 50% !important;
    left: 2% !important;
    display: block !important;
  }
  .react-fullscreen-slideshow-modal-content {
    height: 60% !important;
  }

  #react-fullscreen-slideshow-caption {
    max-width: 100%;
    bottom: 100px;
    padding: 0 10px;
    margin: 60px 50px 0 10px !important;
  }

  .react-fullscreen-slideshow-modal-content {
    display: block;
  }
  .share-button{
    width:40px !important;
    padding: 5px 8px !important;
  }
  .react-fullscreen-slideshow-next-mobile{
    right:1% !important;
    opacity:0.75 !important;
    border-radius: 35px;
    padding:0.5vw;
    background-color: #121212;
    width:32px  !important;
    height:32px !important;
    font-size:14px !important
  }
  .react-fullscreen-slideshow-prev-mobile{
    left:1%;
    opacity:0.75 !important;
    border-radius: 35px;
    padding:0.5vw;
    background-color: #121212;
    width:32px !important;
    height:32px !important;
    font-size:14px !important

  }
}
/* .medium-insert-embeds{display:none;} */
.staticSocial li {
  -webkit-filter: invert(1);
          filter: invert(1);
}
.shHeader {
  height: 70px;
}

.flickity-page-dots {
  display: none;
}
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a9a9a9;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #444444;
}
.theme-t .articleMainTxt .museumMainTxt p {
  padding-bottom: 40px;
  line-height: 2 !important;
  color: #444;
  font-size: 18px;
  /* letter-spacing: 1px; */
  margin-bottom: 0px;
  margin-top: 1%;
}
div.banner-details div.left-des {
  width: auto;
  font-size: 12px;
}
div.banner-details {
  color: #1b1b1b;
}
.hide {
  display: none;
}
.share-button{
  padding:10px;
}
.fullBoxMain .react-fullscreen-slideshow-modal-content .react-fullscreen-slideshow-slides{
  -webkit-flex: 0 0 77%;
          flex: 0 0 77%;
}
.react-fullscreen-slideshow-modal-bottom{
  width:0;
  border-top: none !important;
}
.react-fullscreen-slideshow-bottom-left-pane{top:90%; padding:5px 5px 5px 0px !important}
.react-fullscreen-slideshow-bottom-right-pane{top:91%}
.react-fullscreen-slideshow-slides img{
  width: 100% !important;
  height:auto !important;
}
.react-fullscreen-slideshow-gallery-icon-bar .share-button{
  width:60px ;
  padding: 5px 12px 5px 12px !important;
}
.react-fullscreen-slideshow-modal-bottom .react-fullscreen-slideshow-bottom-left-pane{
  right: 15%;
  width:8%;
  left:5px;
  background-color: #121212;
  z-index: 10000;
}
.react-fullscreen-slideshow-modal-bottom .react-fullscreen-slideshow-bottom-text{
  font-size: 18px !important;
}
.react-fullscreen-slideshow-numbertext{
  font-size:18px !important;
  color:#fff !important;
}
.react-fullscreen-slideshow-caption-container{
  bottom:10% !important
}
.react-fullscreen-slideshow-caption-container .react-fullscreen-slideshow-gallery-icon-bar{
  bottom:1% ;
}
.react-fullscreen-slideshow-bottom-left-pane:before{
  content: "\f06e" !important;
}
.react-fullscreen-slideshow-modal-content .react-fullscreen-slideshow-prev-mobile, .react-fullscreen-slideshow-next-mobile{
  position: absolute;
  display: block !important;
}
.react-fullscreen-slideshow-modal-content .react-fullscreen-slideshow-next-mobile{
  right:26%;
  opacity:1;
  border-radius: 35px;
  padding:0.5vw;
  background-color: #121212;
  width:64px;
  height:64px
}
.react-fullscreen-slideshow-modal-content .react-fullscreen-slideshow-prev-mobile{
  left:1%;
  opacity:1;
  border-radius: 35px;
  padding:0.5vw;
  background-color: #121212;
  width:64px;
  height:64px
}
.react-fullscreen-slideshow-prev{
  display:none
}
.react-fullscreen-slideshow-next{
  display:none
}
.react-fullscreen-slideshow-modal-content{height: 100%;}
div.carousel-cell div.embed-image img{
  height:480px !important;
}
/* .banner-details{height:80px} */
.articlePage .museumMainTxt .carousel{height:500px !important; width: 100%!important;}
div.carousel-cell div.embed-image{height:480px;}

.landing-page-lp .leaflet-popup-content{
  margin:0px 0px 0px 0px !important;
}
.landing-page-lp .leaflet-popup-content-wrapper{
  border-radius:0px !important;
}
.landing-page-lp .leaflet-popup-content p{
  margin: 0px 0 !important;
}


.theme-t .leaflet-popup-content{
  margin:0px 0px;
}
.theme-t .leaflet-popup-content-wrapper{
  border-radius:0px;
}
.wrapper-pop .shPoiBody p{
  font-size:14px !important;
  padding-bottom: 0px !important;
}
.react-fullscreen-slideshow-modal-wrapper {
  z-index: 3 !important;
}
.theme-t .wrapper-pop{
  height:135px;
  overflow-y: auto;
}
.theme-t .leaflet-popup-content p{
  margin:0px 0px;
}
.theme-t .leaflet-popup.leaflet-zoom-animated{
  width:240px !important;
}

.theme-t .inner-map-container{
  height:560px !important;
}
.theme-t .shPoiBreadcrumb p{
  padding-bottom: 0px;
}
.disableDrop{background-color: #d7d7d7 !important;}
.landing-page-lp .btn-secondary{border-color: #d7d7d7 !important;}

.stFtTopItem ul {
  display: block; 
}
.stFtTopItem.active ul {
  display: block !important;
  height:200px;
  overflow-y: auto;
}
.articleNew .shBnImg{
  height:100%;
  width:100%;
  background-size: 100% 100%;
}
.articleMainTxt .carousel{
  margin:0 auto 0px !important;
}
.medium-insert-active{display: block;}
.theme-fullscreen{
  position: absolute;
  z-index: 2;
  right:0.5%;
  top:90%;
  background-color: #fff;
  border: 1px solid #efefef;
  border-radius: 50%;
  padding: 0.5%;
  box-shadow:0 2px 4px rgb(0 0 0 / 20%);
}

/* Popup style */
.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.box {
  position: relative;
  width: 70%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
}

.close-icon {
  content: 'x';
  cursor: pointer;
  position: fixed;
  right: calc(15% - 30px);
  top: calc(100vh - 85vh - 33px);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}
.react-fullscreen-slideshow-view-all-modal{
  z-index: 3 !important;
}
.theme-t-full .inner-map-container{
  height:100% !important;
}
.theme-t-full .shMapSel{
  margin-top:5%;
  margin-left:2%;
}
.articleNotesTab a{
  color:#da3a50;
  display: block;
}
.articlePage a{
  color:#da3a50;
}

.team .imgLink{
    font-size: 16px;
    font-weight: 600;
    font-family: sans-serif;
    margin-top: 4%;
}
.team span{
  font-size: 16px;
    font-family: sans-serif;
}
.Museum-Page header{
height:100% !important;
background-size: 100% 100%;
}
.share-button [aria-label="pinterest"]{display:none;}
.navigateVideo .navigateButton{
  background-color: rgb(218 58 80 / 0.2);
  color:#da3a50;
}
.navigateVideo .timer{
  display:none;
}
.articleMainTxt .medium-insert-images p{
  text-align: center;
  padding-bottom:0px !important;
  font-size: 14px !important;
}
.articleMainTxt ul li p{
  padding-bottom:10px !important;
}
.articleMainTxt ul li ul li{
  list-style-type: disc;
}
.notes:after{
            counter-increment: my-sec-counter;
            content: "[" counter(my-sec-counter) "]";
            color:#267fec !important;
        }
        .lineNote{line-height: 2.5 !important;
        display: none;
      }

.museumPageTheme .museumMainTxt p{
  padding-bottom: 0px !important;
}
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 71px 0 0;
  padding: 0;
  font-family: "Libre Baskerville", serif;
  font-size: 14px;
  line-height: 1.5;
  color: #444444;
}

article {
  /* border-bottom: #d6d6d6 1px solid; */
  position: relative;
}

p {
  margin: 0 0 16px;
  line-height: 1.3;
  font-size: 14px;
}

a {
  color: inherit;
}

img {
  width: 100%;
  display: block;
}

.signinlogo{
  width: 64%;
  display: block;
  margin-top: 114px;
}
.signinlogo1{
  width: 70%;
  display: block;
  margin-top: 114px;
}
.mapSignin .signinlogo1{
  width: 70%;
  display: block;
  margin-top: 27px;
}
.only-mobile {
  display: none;
}
.only-desktop {
  display: block;
}

.highlights {
  color: #da3a50;
}

nav {
  position: fixed;
  width: 100%;
  height: 71px;
  top: 0;
  left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 1px solid #e8e8e8;
  padding: 15px 40px;
  background-color: white;
  z-index: 99;
}

.nav-left {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.nav-right {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

#burger-menu {
  width: 22px;
  cursor: pointer;
}

#nav-logo {
  margin-left: 30px;
  width: 188px;
  cursor: pointer;
}

#nav-search {
  margin-right: 60px;
  width: 24px;
  cursor: pointer;
}

.profile-icon {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: #fff;
  background-color: #da3a50;
  border-color: #da3a50;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  cursor: pointer;
}

header {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  /* background-image: url(images/header-image.png); */
  background-size: cover;
  background-position: center;
  height: calc(100vh - 71px);
  margin-bottom: 64px;
}

.header-overlay {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #ffffff;
  background-color: hsla(0, 0%, 0%, 0.5);
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.75) 100%
  );
}

.header-wrapper {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 32px;
}

header .title {
  font-size: 32px;
  font-weight: normal;
  margin-bottom: 10px;
}

header .sub-title {
  font-weight: normal;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 12px;
}

.header-details {
  margin-bottom: 16px;
  font-family: "Open Sans", sans-serif;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
}

.header-details .author {
  margin-right: 64px;
  font-weight: bold;
}

.header-bottom {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  margin: 16px 0 40px;
}

.header-bottom .location {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-right: 64px;
  font-family: "Open Sans", sans-serif;
}

.header-bottom .location .loc-icon {
  width: 16px;
  margin-right: 16px;
}

.social {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-right: 64px;
}

.social .social-icon {
  width: 24px;
  margin-right: 16px;
  cursor: pointer;
}
.bookmark-icon {
  width: 16px;
  margin-right: 16px;
  cursor: pointer;
  margin-right: 64px;
}

.language {
  position: relative;
  font-family: Arial;
  margin-left: 7%;
}
.languag{font-family: 'open sans',sans-serif;font-weight: bolder;}

.language select {
  /* width: 256px; */
  color: white;
  background-color: transparent;
  border: none;
  padding: 10px 32px 10px 16px;
  border-radius: 8px;
  -webkit-appearance: none;
  appearance: none;
  font-weight: bold;
  cursor: pointer;
}

.language:after {
  content: "";
  position: absolute;
  bottom: 16px;
  right: 1px;
  width: 16px;
  height: 16px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAGKADAAQAAAABAAAAGAAAAADB/VeXAAABaklEQVRIDbWWgXGDMAxF406QbkAmaEagE9QjeISM0BGyQegGdIN0gtIJmg2aDcj7HOYMNS5Qort/kmX5f0tALmaTsLquM7ZfQA4ysAeyClzAGbwbYxRPNxGDE5hqJwqzSQoUWvAD5prO2KQIBW4ua6TeRUWkHilemup3AotmvmQsYxcQ11adPLTtvOKbRLv+rxPXUSRGt8d/a3EH26kDewdiT5lLIPerwH8QP4K3IDcWquYZfEUK7IYRVZEn1cxPB9grIvs+VXhSEkefDPynCMbMBYdjIiH5HpLoW5gSkPCYyCRyEUggNiLteRuKTCaHoJJA6ZkSvhPZtEbt6FgCnlIChyCRCjsRiqaQi8vN/dAONHAFesu24C/bNQUoFZJb2YpOHeIMXFcUEFe/QxJ2RQHb3T4MEHAriLiQ81fcdrJkXDoTv/lQhUI9kzkPXrX9mbekZkgeriXEWrfKQQaegEy/nBdQgnPqb8sNeXQLTUNrCL8AAAAASUVORK5CYII=);
  background-size: contain;
  pointer-events: none;
}
.language select option{
  color:#444444;
}
.languageMuseum {
  margin-top: 0px !important;
  font-family: Arial !important;
}
.bookmarkAlign1{
  margin-left: -100px;
}
.bookmarkAlign2{
  margin-left: 280px;
}
/* main {
  margin-bottom: 128px;
} */

.main-content {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  max-width: 1024px;
  margin: 0 auto;
}

.left-side {
  width: 70%;
  padding: 0 32px;
}

.right-side {
  min-width: 260px;
  width: 30%;
  padding: 0 32px;
}

.more-topics {
  padding: 25px;
  background-color: #f5f5f5;
  margin-bottom: 32px;
}

.more-topics > .title {
  font-size: 14px;
  margin: 16px 0 -16px;
  font-family: "Open Sans", sans-serif;
}

.more-topic-card {
  border-bottom: 1px solid #d6d6d6;
  padding-bottom: 16px;
  margin-bottom: 32px;
  cursor: pointer;
}

.more-topic-card:last-child {
  border-bottom: none;
  margin-bottom: 0;
}

.more-topic-card > .title {
  margin: 32px 0 16px;
  font-size: 18px;
  font-family: "Libre Baskerville", serif;
}

.more-topic-card .author {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
}

.more-topic-images {
  margin: 40px 0;
  cursor: pointer;
}

.more-section {
  background-color: #f5f5f5;
  padding: 64px;
  margin: 0px 0px !important;
  position: relative;
}

.more-section .title {
  margin: 0 0 32px;
}

.more-section .fact-row {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 32px;
}

.more-section .fact-row:last-child {
  margin-bottom: 0;
}

.more-section .fact-row-left {
  font-size: 14px;
  width: 128px;
  font-style: italic;
}

.more-section .fact-row-right {
  padding-left: 16px;
  width: 100%;
  word-break: break-word;
}

.more-section .service-icon {
  width: 20px;
  margin-right: 16px;
}

.more-section .service-icons {
  /* display: flex;
  justify-content: flex-start; */
  -webkit-align-items: center;
          align-items: center;
}

#more-icon-title {
  background-color: white;
  padding: 2px 4px;
  font-size: 10px;
  font-style: italic;
  border: 1px solid black;
  border-radius: 4px;
  display: none;
}

.arrow-bottom,
.arrow-bottom::before {
  position: absolute;
  width: 8px;
  height: 8px;
  /* margin-left: -10px; */
  background-color: #da3a50;
  background: inherit;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
}

.arrow-bottom {
  visibility: hidden;
}

.arrow-bottom::before {
  visibility: visible;
  content: "";
  -webkit-transform: rotate(45deg) translate(9px, 10px);
          transform: rotate(45deg) translate(9px, 10px);
}

.last-updated {
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 8px;
}

.supported-by {
  font-size: 12px;
  font-style: italic;
  color: #707070;
  margin: 45px 0 32px;
}
.article-supported-by {
  font-size: 12px;
  font-style: italic;
  color: #707070;
}

.tags {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  margin: 16px 0 32px;
}

.tags .tag-pill {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  background-color: #f5f5f5;
  color: #9f9f9f;
  padding: 4px 28px;
  margin-right: 8px;
  border-radius: 4px;
  cursor: pointer;
}

.tags .tag-pill:hover {
  color: #dd4d61;
  background-color: #f8e2e5;
}

.article-end {
  margin: 32px 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  font-family: "Open Sans", sans-serif;
}

.rights-section {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
}

.rights-icon {
  width: 24px;
  margin-right: 10px;
}

#share-open,
#share-open-mobile {
  display: none;
  padding: 8px 16px;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  color: #444444;
  background-color: white;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  z-index: 9;
}

#share-open .title,
#share-open-mobile .title {
  font-size: 12px;
  color: #686868;
  margin: 8px 0;
}

#share-open .share-more,
#share-open-mobile .share-more {
  font-size: 12px;
  padding: 8px 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
}

#share-open .share-more:last-child,
#share-open-mobile .share-more:last-child {
  padding-bottom: 8px;
}

#share-open .share-more:hover,
#share-open-mobile .share-more:hover {
  color: #da3b50;
}

#share-open .share-icon,
#share-open-mobile .share-icon {
  width: 16px;
  margin-right: 8px;
}

.arrow,
.arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  margin-left: -10px;
  background-color: #da3a50;
  background: inherit;
}

/* .arrow {
  visibility: hidden;
} */

.arrow::before {
  visibility: visible;
  content: "";
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

#slider-wrapper {
  position: relative;
  width: calc(100%);
}

#slider-wrapper li {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.slider-image {
  padding-right: 32px;
  margin-bottom: 32px;
  /* width: 50%; */
}

.splide__arrow {
  background: #fff;
}

.splide__pagination__page.is-active {
  background-color: #444444;
}

.read-more-text {
  /* max-width: 1024px; */
  /* margin: 35px auto 16px; */
  /* padding: 0 32px; */
  font-family:sans-serif;
  font-weight: bold;
  font-size:14px;

/* transform: translate(8.5%,0%); */

  padding: 40px 0;
-webkit-transform: translate(2.8%,0%);
        transform: translate(2.8%,0%);
  border-top: 1px solid rgb(214 214 214 / .8);
}

.read-more-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  max-width: 1024px;
  margin: 0 auto;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 0 16px;
}

.blog-card {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  /* border-bottom: 1px solid #e8e8e8; */
  padding: 16px;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 33.33%;
  height:450px;
}

.card-image {
  width: 100%;
  cursor: pointer;
}

.card-left {
  width: 100%;
  padding-right: 32px;
  position: relative;
}

.card-type {
  font-family: "Open Sans", sans-serif;
  margin-top: 20px;
  font-size: 12px;
  color: #da3a50;
  margin-bottom: 4px;
  cursor: pointer;
}

.card-title {
  letter-spacing: 0.5px;
  font-size: 20px;
  margin-bottom: 4px;
  line-height: 1.5;
  height:60px;
  color: #444444;
  cursor: pointer;
}

.card-category {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  color: #9f9f9f;
  margin-bottom: 4px;
  cursor: pointer;
}

.card-description {
  font-size: 14px;
  color: #404040;
  margin-bottom: 8px;
}

.card-author {
  font-size: 10px;
  color: #444444;
  margin-bottom: 8px;
  font-family: "Open Sans", sans-serif;
  /* font-weight: bold; */
  cursor: pointer;
}

.card-icons {
  font-size: 12px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
  font-family: "Open Sans", sans-serif;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 8px;
}

.bookmarkList .card-icon-img {
  width: 22px;
  height:24px;
  margin-left: 3px;
  cursor: pointer;
}
.card-icon-img {
  width: 24px;
  margin-left: 8px;
  cursor: pointer;
}
.bookmarkList .card-icon-img-red {
  width: 22px !important;
  height:23px;
  margin-left: 3px;
  cursor: pointer;
  -webkit-filter: none;
          filter: none;
}

.void .signinlogo{
  margin-top: 61px;
  }
 
.card-icon-img-red {
  width: 24px;
  margin-left: 8px;
  cursor: pointer;
}

.blog-card:last-child {
  border-bottom: none;
}

.bottom-border {
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% - 64px);
  height: 1px;
  background-color: #d6d6d6;
  margin: 0 32px;
}
.tl-media-item {
  max-height: 280px !important;
  height: 335px;
  width: 400px;
}
.tl-slide-content {
  padding-left: 100px;
  padding-right: 100px;
  width: 100% !important;
}
.timeline-summary p {
  padding-bottom: 40px;
  line-height: 2x !important;
  color: #444 !important;
  font-size: 18px !important;
  letter-spacing: 0px;
  font-style: italic;
  margin: 0 0 0px;
}
.timeline-summary {
  padding-bottom: 40px!important;
  line-height: 2 !important;
  color: #444 !important;
  font-size: 18px !important;
  letter-spacing: 0px;
  font-style: italic;
  margin: 0 0 0px;
}
.timelineText {
  padding-top: 170px;
}
#author_entity {
  float: left;
}
.shBnTimeline #title_heading_entity {
  font-size: 30px !important;
  margin-bottom: 10px;
  color: #000;
  }
.socialNew2 li {
  width: 24px;
  margin-right: 16px;
  cursor: pointer;
}
.socialNew3 li {
  width: 24px;
  margin-right: 16px;
  cursor: pointer;
}
.socialNew li {
  width: 24px;
  margin-right: 16px;
  cursor: pointer;
}

.socialNew2 img {
  -webkit-filter: invert(70%);
          filter: invert(70%);
}
.socialNew3 img {
  -webkit-filter: invert(75%);
          filter: invert(75%);
}
.socialMuseum {
  float: left;
  display: -webkit-flex;
  display: flex;
  margin-top:1%;
}
.tantraSec {
  margin: 0px 0 30px 0;
}

.supported-by {
  margin: 45px 0 0px;
}
.article-supported-by {
  margin: 0px 0 0px;
}

.supported-by p {
  margin: 45px 0 0px;
}
.article-supported-by p {
  margin: 0px 0 0px;
}
.museumRelation {
  max-width: 960px !important;
  padding-left: 0px;
  padding-right: 0px;
}
.MuseumText {
  max-width: 980px !important;
}

.museumRelation .stThumbItemCtrl a {
  width: 19px;
  height: 19px;
}
.museumRelation .stThumbItem .stThumbOw {
  font-size: 14px;
  font-weight: bolder;
  margin-top: 0px;
  width: 240px;
  float: left;
}
.museumRelation .stThumbItem p {
  line-height: 1.5;
  margin-bottom: 4px;
}
.mobileSocialView {
  font-size: 20px;
  -webkit-filter:invert(1);
          filter:invert(1);
}
.mobileSocialView1{
  font-size: 26px;
  margin-top: -16px;
}
.socialNew2 .mobileSocialView {
  -webkit-filter:invert(30%);
          filter:invert(30%);
}
.bannerSocialView .mobileSocialView{
  font-size:26px;
  vertical-align: inherit;
}
.socialDropdown{
  margin-bottom: 0px;
  float: left;
  margin-left: 12%;
  font-family: "Open Sans", sans-serif;
}
#entity_date{font-size: 16px;color: whitesmoke;}
.textAlign1{
  left: 27px;
}
.textAlign2{
  left: 50px;
  margin-top: -30%;
}
.textAlign3{
  left: 50px;
  margin-top: -40%;
}
.create1{
 text-align: center;
}
.create2{
  text-align: center;
  -webkit-transform: translate(-30% ,-15%);
          transform: translate(-30% ,-15%);
}
@media only screen and (max-width: 330px) {

  .socialNew li{
    margin-right: 5px !important;
  }
  .header-bottom .location{
    margin-right: 5px !important;

  }
  .bookmarkAlign1{
    margin-left: 0px;
  }
  .bookmarkAlign2{
    margin-left: 0px;
  }
  .textAlign1{
    left: 0px;
  }
  .textAlign2{
    left: 0px;
    margin-top: 0%;
  }
  .textAlign3{
    left: 0px;
    margin-top: 0%;
  }
  .create1{
    text-align: center;
  }
  .create2{
    text-align: center;
  }
  .search_mobile {
    margin-top: 4px !important;
    margin-left: -70px !important;
    width: 80px !important;
  }
}

@media only screen and (max-width: 768px) {
  .shSocialSecMuseum{
    margin-top:0px !important;
  }
  .socialNew2 li{
    margin-right:10px;
  }
  .create1{
    text-align: center;
    -webkit-transform: translate(-15% ,1%);
            transform: translate(-15% ,1%);
  }
  .create2{
    text-align: center;
    -webkit-transform: translate(7% ,-135%);
            transform: translate(7% ,-135%);
  }
  .bookmarkAlign1{
    margin-left: 0px;
  }
  .bookmarkAlign2{
    margin-left: 0px;
  }
  .textAlign1{
    left: 0px;
  }
  .textAlign2{
    left: 0px;
    margin-top: 0%;
  }
  .textAlign3{
    left: 0px;
    margin-top: 0%;
  }
  .resize{margin-left:46px !important}
  .header-details .shDateSec{width:100%}

  #entity_date{font-size: 12px !important;
    -webkit-justify-content:left !important;
            justify-content:left !important;
    color: black !important;
    /* transform: translate(150%,45%);   */
  }

  .mobileSocialView {
    -webkit-filter:invert(30%) !important;
            filter:invert(30%) !important;
  }
  .mobileSocialView1{
    font-size: 20px;
    margin-top: -10px;
  }
  #mapper_entity{font-weight:bolder;}
  .copyFooter{ margin: 1% -8% !important;}
  .read-more-text{
    font-size:12px;
  }
  .bookmarkList .card-icon-img{
    width:22px !important;
    margin-top:5px;
    position: relative;
    bottom: 2px;
    height: 22px;
  }
  .bannerSocialView .mobileSocialView {
    font-size: 20px;
    -webkit-filter: invert(30%);
            filter: invert(30%);
  }

  .stThumbItemCtrl li {
    list-style: none;
  }
  /* .stThumbItemCtrl img{filter:invert(70%)} */
  .museumRelation .stThumbItemCtrl a {
    width: 16px;
    height: 16px;
  }
  .museumRelation .stThumbItem h2 {
    font-size: 24px;
    letter-spacing: 0.5px;
    line-height: 1.5;
  }
  .museumRelation .stThumbItem .stThumbCat {
    margin-bottom: 4px;
  }
  .museumRelation .stThumbItem .stThumbOw {
    font-size: 14px;
    font-weight: 700;
    margin-top: 0px;
  }
  .languageMuseum {
    font-size: 10px !important;
  }
  .shHeader.smHeader .bookmarkIcon {
    opacity: 0;
    -webkit-transition: opacity 1s ease-in;
    -moz-transition: opacity 1s ease-in;
    -o-transition: opacity 1s ease-in;
  }
  .shHeader.smHeader .shSearch {
    opacity: 1;
    -webkit-transition: opacity 1s ease-in;
    -moz-transition: opacity 1s ease-in;
    -o-transition: opacity 1s ease-in;
  }
  .SocialShare {
    font-size: 12px !important;
    color: #686868 !important;
    margin: 8px 0;
    font-weight: normal;
  }
  .socialMuseum {
    float: left;
    display: -webkit-flex;
    display: flex;
    margin-top:1%;
  }
  .tantraSec a {
    padding: 4px 28px !important;
    margin-bottom:8%;
  }
  .supported-by p {
    margin: 45px 0 0px !important;
  }
  .supported-by {
    margin: 45px 0 0px !important;
  }
  .article-supported-by p {
    margin: 0px 0 0px !important;
  }
  .article-supported-by {
    margin: 0px 0 0px !important;
  }
  .MuseumText p {
    letter-spacing: inherit !important;
    padding-right: 0px;
  }
  .MuseumText .museumMainTxt p {
    padding-right: 0px !important;
    font-size: 18px;
    line-height: 2 !important;
  }
  .MuseumText {
    max-width: 100% !important;
  }
  .museumPageTheme {
    padding: 0px 18px !important;
  }
  .shHeader.smHeader .shMenu {
    width: 28px;
    opacity: 1 !important;
    -webkit-transition: opacity 1s ease-in;
    -moz-transition: opacity 1s ease-in;
    -o-transition: opacity 1s ease-in;
  }
  .shMenu {
    margin-top: 38px;
    
  }
  .topLinks {
    display: none;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .bookmarkList img {
    -webkit-filter: invert(70%);
            filter: invert(70%);
    width: 16px !important;
  }
  .bookmarkList {
    position: absolute;
    right: 0;
    padding-right: 24px;
  }
  #author_entity h3{
    font-weight: 600;
    margin-right: 10px !important;
    font-size:14px!important;
  }
  .social {
    margin-right: 0px !important;
    margin-left: 4%;
  }
  .socialNew li {
    width: 18px;
    margin-right: 20px;
    cursor: pointer;
  }
  .socialNew img {
    -webkit-filter: invert(70%);
            filter: invert(70%);
  }
  .location_text {
    color: #444 !important;
  }
  #date_entity {
    margin: 12px 0px 16px !important;
    line-height: 0;
    font-size: inherit;
    right: 0;
    padding-right: 32px;
    position: absolute;
  }
  .search-conMobile {
    right: 55px !important;
  }
  .search_mobile {
    margin-top: 4px !important;
    margin-left: -70px !important;
    width: 80px !important;
  }

  .only-mobile {
    display: block;
  }
  .only-desktop {
    display: none;
  }
  nav {
    padding: 15px 32px;
  }
  #nav-logo {
    width: 164px;
  }
  #nav-search {
    margin-right: 0;
  }
  .profile-icon {
    display: none;
  }

  header {
    display: none;
  }

  .header-mobile {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 10px 25px 16px;
  }

  .header-bottom {
    width: 100%;
    -webkit-justify-content: initial;
            justify-content: initial;
    margin-bottom: 0px;
  }

  .header-bottom .location {
    font-size: 12px;
    margin-right: 16px;
  }

  .header-bottom .location .loc-icon {
    width: 10px;
    margin-right: 8px;
  }

  /* .header-bottom .social {
    margin-left: -32px;
  } */

  .header-bottom .social .social-icon {
    width: 16px;
    margin-right: 12px;
  }

  .bookmark-icon {
    width: 12px;
    margin-right: 0;
  }

  .language select {
    color: #444444;
    padding: 10px 32px 10px 0;
    font-size: 10px;
  }

  .language:after {
    width: 12px;
    height: 12px;
    /* background-image: url(images/down-arrow-dark.png); */
  }

  .header-image-moblie {
    margin-bottom: 16px;
  }

  .header-mobile .title {
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 4px;
  }

  .header-mobile .sub-title {
    font-size: 12px;
    font-weight: normal;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 4px;
  }

  .header-mobile .header-details {
    width: 100%;
    font-size: 12px;
    margin-bottom: 0;
    font-family: "Open Sans", sans-serif;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
  }

  .header-mobile .header-details .author {
    margin-right: 32px;
    font-weight: bold;
    width: 60%;
  }

  .right-side {
    width: 100%;
    padding: 0 32px;
    margin-top: 32px;
  }

  .more-topic-images {
    padding: 0 32px;
  }

  .more-section {
    padding: 32px 16px;
    /* margin: 32px 0px; */
  }

  .more-section .fact-row {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .more-section .fact-row-left {
    font-size: 12px;
    /* margin-bottom: 8px; */
    width: 100%;
  }

  .more-section .fact-row-right {
    padding-left: 0;
    font-size: 14px;
  }

  .main-content {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
  }

  .left-side {
    width: 100%;
    padding: 0 32px;
  }

  .rights-section {
    font-size: 12px;
  }

  .social .social-icon {
    width: 16px;
    margin-right: 12px;
  }

  .slider-image {
    padding: 0;
    margin-bottom: 0;
    /* width: 50%; */
  }

  .splide__arrow {
    top: 42%;
    background-color: #cacaca;
    width: 2.5em;
    height: 2.5em;
    /* display: none; */
  }
  .splide__arrow path {
    fill: white;
  }

  .splide__arrow--prev {
    left: 0.5em;
  }

  .splide__arrow--next {
    right: 0.5em;
  }

  .blog-card {
    width: 100%;
    padding: 16px 0;
    display: contents;
  }

  .card-left {
    padding: 0 32px;
  }
  .card-icons {
    right: 32px;
  }
  .card-icon-img {
    width: 16px;
  }
  .read-more-wrapper {
    padding: 0;
  }
  .read-more-text {
    margin-bottom: 8px;
    padding: 40px 0px 24px;
    /* margin-left: -5px; */
  }
  .bottom-border {
    width: calc(100% - 64px);
    margin: 0 32px;
  }

  #slider-wrapper {
    padding: 32px;
    background-color: #f5f5f5;
    margin-bottom: 45px;
  }

  .more-section .service-icon {
    margin-top: 10px;
  }
  .location_banner {
    max-width: 20px !important;
    -webkit-filter:invert(25%) !important;
            filter:invert(25%) !important;
    float: left;
    margin-top: -6px;
    margin-left: -5%;
    margin-right: 40px!important;
  }
  .muLang .btn-secondary{height:0px;}
  .location img {
    -webkit-filter: invert(30%);
            filter: invert(30%);
  }
  .location_text {
    color: #444;
    font-weight: 100 !important;
    padding: 0px 0px 0px 12px !important;
    margin-top: -9%;
    float: left;
  }
}
.location_banner {
  max-width: 24px;
  float: left;
  margin-right: 60px;
  /* margin-top: -4px; */
}
.location_text {
  color: #fff;
  font-weight: 200;
  padding: 0px 0px 8px 20px;
  float: left;
  font-family: "Open Sans", sans-serif;
}
.entry p {
  font-size: 14px !important;
}
.social-icon p {
  font-size: 12px;
}
.lineBottom a {
  color: #da3a50 !important;
}
.more-section p {
  font-size: 14px !important;
  line-height: 1.5 !important;
  padding-bottom: 0px !important;
  margin: 0px !important;
  letter-spacing: inherit !important;
}
.supported-by p {
  font-size: 12px !important;
  font-style: italic;
  color: #707070 !important;
  margin: 45px 0 0px;
  line-height: 1.5;
  letter-spacing: inherit;
}
.articleMainTxt .article-supported-by p {
  font-size: 12px !important;
  font-style: italic;
  color: #707070 !important;
  line-height: 1.5;
  letter-spacing: inherit;
}
.museumContainer {
  max-width: 100%;
  padding: 0px !important;
}
.museum-banner-text {
  position: absolute;
  left: 190px;
  bottom: 40px;
  color: #fff;
  z-index: 1;
}
/* .museumMainTxt div{
  padding-bottom: 40px;
  line-height: 36px;
  color: #444;
  font-size: 18px;
  letter-spacing: 1px;
} */
.museumPageTheme {
  padding: 0px 180px;
}
.shBnTimeline #author_entity h3 {
  margin-top: 8px;
  font-size: 16px;
  font-weight: 700;
  font-family:sans-serif;
  color: #444444;
}
.shSocialSec .muLang {
  padding-top: 15px;
}
.shSocialSecMuseum {
  margin-top: 12px;
}
.headerBook img {
  -webkit-filter: invert(100%);
          filter: invert(100%);
}
.headerBook {
  -webkit-filter: invert(50%);
          filter: invert(50%);
}
.signInForm {
  padding: 20px 60px;
  background-color: #e2e2e2;
  margin-bottom: 15px;
}
.signInForm1 {
  padding: 40px 50px;
  background-color: #e2e2e2;
}
.signInForm3 {
  padding: 40px 265px;
  background-color: #e2e2e2;
}
.BoldTexts {
  font-weight: 600;
  font-size: 15px;
}
.Colortexts {
  font-weight: 100 !important;
  color: #da3a50;
}
.keeplogin input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin-right: 15px;
  vertical-align: -5px;
}
.keeplogin {
  font-size: 16px;
  font-weight: 600;
  color: #686868;
  margin: 25px 0px;
}
.or-signin-with {
  width: 100%;
  position: relative;
  margin-top: 35px;
}
.or-signin-with .text {
  position: absolute;
  font-size: 14px;
  font-weight: 700;
  background: #e2e2e2;
  padding: 5px 15px;
  left: 50%;
  margin-left: -63px;
  top: -14px;
}
.or-signin-with .line {
  width: 100%;
  background: #e2e2e2;
  height: 2px;
}
.social-login {
  width: 100%;
  margin-top: 35px;
  text-align: Center;
}
.social-login a {
  display: inline-block;
  /* background: #fff; */
  color: #000;
  font-size: 18px;
  width: 50px;
  height: 50px;
  margin-left: 15px;
  border-radius: 50px;
  padding: 17px;
}
.sign-in-area .field .left-field {
  width: 133px;
  margin-right: 5px;
  float: left;
}

.sign-in-area .field .right-field {
  width: 133px;
  margin-left: 5px;
  float: right;
}
.fullBoxMain > div {
  z-index: 99;
  position: relative;
}
* {
  box-sizing: border-box;
}
input[type="text"],
input[type="password"],
input[type="date"] {
  width: 280px;
  font-size: 14px;
  padding: 10px;
  margin: 15px 0 0px 0;
  display: inline-block;
  border: none;
  background: #fff;
}
#org_or_ind {
  width: 100%;
  font-size: 14px;
  padding: 15px;
  margin: 5px 0 22px 0;
  display: inline-block;
  border: none;
  background: #f1f1f1;
}
label {
  font-size: 15px;
  text-align: left !important;
}
input[type="text"]:focus,
input[type="password"]:focus {
  /* background-color: #ddd; */
  outline: none;
}
hr {
  border: 1px solid #f1f1f1;
  margin-bottom: 25px;
}

.buttonStyl {
  background-color: #da3a50;
  color: white;
  padding: 10px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 280px;
  border-radius: 6px !important;
  font-size: 15px !important;
}
.shTag .buttonStyll {
  background-color: #da3a50;
  color: white;
  padding: 10px;
  margin: 35px 0;
  border: none;
  cursor: pointer;
  width: 280px;
  border-radius: 6px !important;
  font-size: 15px !important;
  margin-left: -122px;
}
.buttonStyl:hover {
  opacity: 1;
}
.cancel {
  padding: 14px 20px;
  background-color: #ff3d2f;
}
.formContainer {
  padding: 16px;
}
.formContainer p {
  font-size: 28px;
}
.shProfile .dropdown-item.dropdown-sign-out {
  padding: 4px 35px;
}
.shBnText  #title_heading_entity {
  font-size: 30px !important;
}
.medium-insert-embed div {
  max-width: 100% !important;
}
.location_text {
  color: #fff;
  padding: 0px 0px 6px 16px;
  float: left;
}
.entry p {
  font-size: 14px !important;
}
.social-icon p {
  font-size: 12px;
}
.shBnTimeline {
  left: 140px !important;
}
/* .topLinks a{text-transform: capitalize;} */
.shSocialList a {
  cursor: pointer;
}
.shBnTimeline #date_entity {
  margin: 3px 70px 16px;
  line-height: 0;
  font-size: inherit;
}
/* .bookmarkList {
  margin-bottom: 6px;
} */

/* .location_banner{max-width:24px !important;float:left;}
.location_text{color:#fff;font-weight: 600;padding:3px 0px 0px 20px;float:left}
.entry p{font-size:14px !important; margin-top: -7px;}
.social-icon p{font-size: 12px;} */

.ClickableEvent a:hover {
  color: #da3a50;
}
.social-icon svg {
  display: block;
  height: 19px;
  width: 19px;
  color: #444444;
}
.social-icon p {
  color: #444444;
}
a {
  cursor: pointer;
}
.stFootBtm svg {
  display: block;
}
/* .bookmarkIcon .card-icons-bl img{
  filter: invert(100%);
} */
/* .service-icons span{
  background-image: url(/src/components/images/facilities-icons.png);
} */
.service-icons span.photography.yes {
  background-position: 0px 4px;
  display: inline-block;
}
.service-icons span.videography.yes {
  background-position: -48px 1px;
  display: inline-block;
}
.service-icons span.cafeteria.yes {
  background-position: -100px 2px;
  display: inline-block;
}
.service-icons span.museumshop.yes {
  background-position: -153px 2px;
  display: inline-block;
}
.service-icons span.wheelchair.yes {
  background-position: -201px 2px;
  display: inline-block;
}
.service-icons span.restroom.yes {
  background-position: -249px 2px;
  display: inline-block;
}
.service-icons span.water.yes {
  background-position: -294px 4px;
  display: inline-block;
}
.service-icons span.lockerroom.yes {
  background-position: -334px 4px;
  display: inline-block;
}
.service-icons span.escalator.yes {
  background-position: -380px 4px;
  display: inline-block;
}
.service-icons span.seating.yes {
  background-position: -426px 3px;
  display: inline-block;
}
.service-icons span.membership.yes {
  background-position: -469px 3px;
  display: inline-block;
}
.service-icons span.elderly.yes {
  background-position: -514px 3px;
  display: inline-block;
}
.service-icons span.parking.yes {
  background-position: 0px -37px;
  display: inline-block;
}
.service-icons span.infoinbraile.yes {
  background-position: -52px -37px;
  display: inline-block;
}
.service-icons span.garden.yes {
  background-position: -97px -40px;
  display: inline-block;
}
.service-icons span.workshops.yes {
  background-position: -150px -36px;
  display: inline-block;
}
.service-icons span.audioguide.yes {
  background-position: -200px -34px;
  display: inline-block;
}
.service-icons span.guidedtour.yes {
  background-position: -248px -33px;
  display: inline-block;
}
.service-icons span.library.yes {
  background-position: -293px -35px;
  display: inline-block;
}
.service-icons span.itfacilities.yes {
  background-position: -333px -34px;
  display: inline-block;
}
.service-icons span.conference.yes {
  background-position: -379px -35px;
  display: inline-block;
}
.service-icons span.conservation.yes {
  background-position: -426px -35px;
  display: inline-block;
}
.service-icons span.research.yes {
  background-position: -466px -36px;
  display: inline-block;
}
.service-icons span.auditorium.yes {
  background-position: -513px -36px;
  display: inline-block;
}

.service-icons span {
  background-image: url(/search/static/media/MOI_Icons.9d7eb654.png);
  background-repeat: no-repeat;
  background-size: 540px auto;
  display: none;
  height: 40px;
  width: 40px;
  margin: 2px 10px;
  cursor: pointer;
}
.medium-insert-buttons {
  display: none;
}
.museumLanguage .btn-secondary {
  font-size: 14px !important;
  padding: 9px !important;
}
.museumLanguage {
  margin-left: 64px;
}
.bookmarkIcon .card-icon-img {
  -webkit-filter: invert(60%);
          filter: invert(60%);
}

/* .bookmarkIcon .card-icons-bl img{
  filter: initial;
} */
.bookmarkIcon div {
  position: static !important;
  position: initial !important;
}
.MuseumText .paraItalic p {
  padding-right: 0px;
}
.museumMoreSec .moreTopicSec h2 {
  font-weight: 600;
  margin: 16px 0 20px;
  font-size:16px;
}
.Timeline-Page .museumMoreSec .moreTopicSec h2 {
  font-weight: 700;
  margin: 16px 0 20px;
}
.museumMoreSec .moreList li a:hover h3 {
  text-decoration: none;
}
.smHeader .shProfile {
  display: none;
}
.header-details .shDateSec {
  position: relative;
}

.whatsappHover:hover {
  color: inherit !important;
}
.shTag .articlesign{
  width:200%;
}
.Museum-Page #entity_date{
  margin:0px !important;
}
.landingPgSign #BoldTexts{
margin-right:60px !important; 
}
.mapSignin .landingPgSign #BoldTexts{
  margin-right:104px !important;
  width: 100% !important; 
  text-align: left;
  }
.landingPgSign #keeplogin{
  margin-left: -100PX !important;
  width: 100% !important; 
  }
  .landingPgSign #field{
    margin-top: 40px !important; 
    }
    .landingPgSign #field2{
      margin-top: 20px !important; 
      }
    .landingPgSign #donate{
      margin-left: -270px !important; 
      }
      .landingPgSign #donate1{
        margin-left: -270px !important; 
        }
        .landingPgSign #BoldTexts1{
          margin-left:-30px !important;
          width: 100%; 
          }
          .signin-text{
            font-size: 30px !important;
          }
