@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Open+Sans:wght@400;600&display=swap");

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  margin: 71px 0 0;
  padding: 0;
  font-family: "Libre Baskerville", serif;
  font-size: 14px;
  line-height: 1.5;
  color: #444444;
}

article {
  /* border-bottom: #d6d6d6 1px solid; */
  position: relative;
}

p {
  margin: 0 0 16px;
  line-height: 1.3;
  font-size: 14px;
}

a {
  color: inherit;
}

img {
  width: 100%;
  display: block;
}

.signinlogo{
  width: 64%;
  display: block;
  margin-top: 114px;
}
.signinlogo1{
  width: 70%;
  display: block;
  margin-top: 114px;
}
.mapSignin .signinlogo1{
  width: 70%;
  display: block;
  margin-top: 27px;
}
.only-mobile {
  display: none;
}
.only-desktop {
  display: block;
}

.highlights {
  color: #da3a50;
}

nav {
  position: fixed;
  width: 100%;
  height: 71px;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  padding: 15px 40px;
  background-color: white;
  z-index: 99;
}

.nav-left {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-right {
  display: flex;
  justify-content: center;
  align-items: center;
}

#burger-menu {
  width: 22px;
  cursor: pointer;
}

#nav-logo {
  margin-left: 30px;
  width: 188px;
  cursor: pointer;
}

#nav-search {
  margin-right: 60px;
  width: 24px;
  cursor: pointer;
}

.profile-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #da3a50;
  border-color: #da3a50;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  cursor: pointer;
}

header {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-image: url(images/header-image.png); */
  background-size: cover;
  background-position: center;
  height: calc(100vh - 71px);
  margin-bottom: 64px;
}

.header-overlay {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #ffffff;
  background-color: hsla(0, 0%, 0%, 0.5);
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.75) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.75) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.75) 100%
  );
}

.header-wrapper {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 32px;
}

header .title {
  font-size: 32px;
  font-weight: normal;
  margin-bottom: 10px;
}

header .sub-title {
  font-weight: normal;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 12px;
}

.header-details {
  margin-bottom: 16px;
  font-family: "Open Sans", sans-serif;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.header-details .author {
  margin-right: 64px;
  font-weight: bold;
}

.header-bottom {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 16px 0 40px;
}

.header-bottom .location {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 64px;
  font-family: "Open Sans", sans-serif;
}

.header-bottom .location .loc-icon {
  width: 16px;
  margin-right: 16px;
}

.social {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 64px;
}

.social .social-icon {
  width: 24px;
  margin-right: 16px;
  cursor: pointer;
}
.bookmark-icon {
  width: 16px;
  margin-right: 16px;
  cursor: pointer;
  margin-right: 64px;
}

.language {
  position: relative;
  font-family: Arial;
  margin-left: 7%;
}
.languag{font-family: 'open sans',sans-serif;font-weight: bolder;}

.language select {
  /* width: 256px; */
  color: white;
  background-color: transparent;
  border: none;
  padding: 10px 32px 10px 16px;
  border-radius: 8px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  font-weight: bold;
  cursor: pointer;
}

.language:after {
  content: "";
  position: absolute;
  bottom: 16px;
  right: 1px;
  width: 16px;
  height: 16px;
  background-image: url(/src/components/images/down-arrow-white.png);
  background-size: contain;
  pointer-events: none;
}
.language select option{
  color:#444444;
}
.languageMuseum {
  margin-top: 0px !important;
  font-family: Arial !important;
}
.bookmarkAlign1{
  margin-left: -100px;
}
.bookmarkAlign2{
  margin-left: 280px;
}
/* main {
  margin-bottom: 128px;
} */

.main-content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  max-width: 1024px;
  margin: 0 auto;
}

.left-side {
  width: 70%;
  padding: 0 32px;
}

.right-side {
  min-width: 260px;
  width: 30%;
  padding: 0 32px;
}

.more-topics {
  padding: 25px;
  background-color: #f5f5f5;
  margin-bottom: 32px;
}

.more-topics > .title {
  font-size: 14px;
  margin: 16px 0 -16px;
  font-family: "Open Sans", sans-serif;
}

.more-topic-card {
  border-bottom: 1px solid #d6d6d6;
  padding-bottom: 16px;
  margin-bottom: 32px;
  cursor: pointer;
}

.more-topic-card:last-child {
  border-bottom: none;
  margin-bottom: 0;
}

.more-topic-card > .title {
  margin: 32px 0 16px;
  font-size: 18px;
  font-family: "Libre Baskerville", serif;
}

.more-topic-card .author {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
}

.more-topic-images {
  margin: 40px 0;
  cursor: pointer;
}

.more-section {
  background-color: #f5f5f5;
  padding: 64px;
  margin: 0px 0px !important;
  position: relative;
}

.more-section .title {
  margin: 0 0 32px;
}

.more-section .fact-row {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 32px;
}

.more-section .fact-row:last-child {
  margin-bottom: 0;
}

.more-section .fact-row-left {
  font-size: 14px;
  width: 128px;
  font-style: italic;
}

.more-section .fact-row-right {
  padding-left: 16px;
  width: 100%;
  word-break: break-word;
}

.more-section .service-icon {
  width: 20px;
  margin-right: 16px;
}

.more-section .service-icons {
  /* display: flex;
  justify-content: flex-start; */
  align-items: center;
}

#more-icon-title {
  background-color: white;
  padding: 2px 4px;
  font-size: 10px;
  font-style: italic;
  border: 1px solid black;
  border-radius: 4px;
  display: none;
}

.arrow-bottom,
.arrow-bottom::before {
  position: absolute;
  width: 8px;
  height: 8px;
  /* margin-left: -10px; */
  background-color: #da3a50;
  background: inherit;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
}

.arrow-bottom {
  visibility: hidden;
}

.arrow-bottom::before {
  visibility: visible;
  content: "";
  transform: rotate(45deg) translate(9px, 10px);
}

.last-updated {
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 8px;
}

.supported-by {
  font-size: 12px;
  font-style: italic;
  color: #707070;
  margin: 45px 0 32px;
}
.article-supported-by {
  font-size: 12px;
  font-style: italic;
  color: #707070;
}

.tags {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 16px 0 32px;
}

.tags .tag-pill {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  background-color: #f5f5f5;
  color: #9f9f9f;
  padding: 4px 28px;
  margin-right: 8px;
  border-radius: 4px;
  cursor: pointer;
}

.tags .tag-pill:hover {
  color: #dd4d61;
  background-color: #f8e2e5;
}

.article-end {
  margin: 32px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Open Sans", sans-serif;
}

.rights-section {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.rights-icon {
  width: 24px;
  margin-right: 10px;
}

#share-open,
#share-open-mobile {
  display: none;
  padding: 8px 16px;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  color: #444444;
  background-color: white;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  z-index: 9;
}

#share-open .title,
#share-open-mobile .title {
  font-size: 12px;
  color: #686868;
  margin: 8px 0;
}

#share-open .share-more,
#share-open-mobile .share-more {
  font-size: 12px;
  padding: 8px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

#share-open .share-more:last-child,
#share-open-mobile .share-more:last-child {
  padding-bottom: 8px;
}

#share-open .share-more:hover,
#share-open-mobile .share-more:hover {
  color: #da3b50;
}

#share-open .share-icon,
#share-open-mobile .share-icon {
  width: 16px;
  margin-right: 8px;
}

.arrow,
.arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  margin-left: -10px;
  background-color: #da3a50;
  background: inherit;
}

/* .arrow {
  visibility: hidden;
} */

.arrow::before {
  visibility: visible;
  content: "";
  transform: rotate(45deg);
}

#slider-wrapper {
  position: relative;
  width: calc(100%);
}

#slider-wrapper li {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-image {
  padding-right: 32px;
  margin-bottom: 32px;
  /* width: 50%; */
}

.splide__arrow {
  background: #fff;
}

.splide__pagination__page.is-active {
  background-color: #444444;
}

.read-more-text {
  /* max-width: 1024px; */
  /* margin: 35px auto 16px; */
  /* padding: 0 32px; */
  font-family:sans-serif;
  font-weight: bold;
  font-size:14px;

/* transform: translate(8.5%,0%); */

  padding: 40px 0;
transform: translate(2.8%,0%);
  border-top: 1px solid rgb(214 214 214 / .8);
}

.read-more-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 1024px;
  margin: 0 auto;
  flex-wrap: wrap;
  padding: 0 16px;
}

.blog-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: 1px solid #e8e8e8; */
  padding: 16px;
  flex-direction: column;
  width: 33.33%;
  height:450px;
}

.card-image {
  width: 100%;
  cursor: pointer;
}

.card-left {
  width: 100%;
  padding-right: 32px;
  position: relative;
}

.card-type {
  font-family: "Open Sans", sans-serif;
  margin-top: 20px;
  font-size: 12px;
  color: #da3a50;
  margin-bottom: 4px;
  cursor: pointer;
}

.card-title {
  letter-spacing: 0.5px;
  font-size: 20px;
  margin-bottom: 4px;
  line-height: 1.5;
  height:60px;
  color: #444444;
  cursor: pointer;
}

.card-category {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  color: #9f9f9f;
  margin-bottom: 4px;
  cursor: pointer;
}

.card-description {
  font-size: 14px;
  color: #404040;
  margin-bottom: 8px;
}

.card-author {
  font-size: 10px;
  color: #444444;
  margin-bottom: 8px;
  font-family: "Open Sans", sans-serif;
  /* font-weight: bold; */
  cursor: pointer;
}

.card-icons {
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 8px;
}

.bookmarkList .card-icon-img {
  width: 22px;
  height:24px;
  margin-left: 3px;
  cursor: pointer;
}
.card-icon-img {
  width: 24px;
  margin-left: 8px;
  cursor: pointer;
}
.bookmarkList .card-icon-img-red {
  width: 22px !important;
  height:23px;
  margin-left: 3px;
  cursor: pointer;
  filter: none;
}

.void .signinlogo{
  margin-top: 61px;
  }
 
.card-icon-img-red {
  width: 24px;
  margin-left: 8px;
  cursor: pointer;
}

.blog-card:last-child {
  border-bottom: none;
}

.bottom-border {
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% - 64px);
  height: 1px;
  background-color: #d6d6d6;
  margin: 0 32px;
}
.tl-media-item {
  max-height: 280px !important;
  height: 335px;
  width: 400px;
}
.tl-slide-content {
  padding-left: 100px;
  padding-right: 100px;
  width: 100% !important;
}
.timeline-summary p {
  padding-bottom: 40px;
  line-height: 2x !important;
  color: #444 !important;
  font-size: 18px !important;
  letter-spacing: 0px;
  font-style: italic;
  margin: 0 0 0px;
}
.timeline-summary {
  padding-bottom: 40px!important;
  line-height: 2 !important;
  color: #444 !important;
  font-size: 18px !important;
  letter-spacing: 0px;
  font-style: italic;
  margin: 0 0 0px;
}
.timelineText {
  padding-top: 170px;
}
#author_entity {
  float: left;
}
.shBnTimeline #title_heading_entity {
  font-size: 30px !important;
  margin-bottom: 10px;
  color: #000;
  }
.socialNew2 li {
  width: 24px;
  margin-right: 16px;
  cursor: pointer;
}
.socialNew3 li {
  width: 24px;
  margin-right: 16px;
  cursor: pointer;
}
.socialNew li {
  width: 24px;
  margin-right: 16px;
  cursor: pointer;
}

.socialNew2 img {
  filter: invert(70%);
}
.socialNew3 img {
  filter: invert(75%);
}
.socialMuseum {
  float: left;
  display: flex;
  margin-top:1%;
}
.tantraSec {
  margin: 0px 0 30px 0;
}

.supported-by {
  margin: 45px 0 0px;
}
.article-supported-by {
  margin: 0px 0 0px;
}

.supported-by p {
  margin: 45px 0 0px;
}
.article-supported-by p {
  margin: 0px 0 0px;
}
.museumRelation {
  max-width: 960px !important;
  padding-left: 0px;
  padding-right: 0px;
}
.MuseumText {
  max-width: 980px !important;
}

.museumRelation .stThumbItemCtrl a {
  width: 19px;
  height: 19px;
}
.museumRelation .stThumbItem .stThumbOw {
  font-size: 14px;
  font-weight: bolder;
  margin-top: 0px;
  width: 240px;
  float: left;
}
.museumRelation .stThumbItem p {
  line-height: 1.5;
  margin-bottom: 4px;
}
.mobileSocialView {
  font-size: 20px;
  filter:invert(1);
}
.mobileSocialView1{
  font-size: 26px;
  margin-top: -16px;
}
.socialNew2 .mobileSocialView {
  filter:invert(30%);
}
.bannerSocialView .mobileSocialView{
  font-size:26px;
  vertical-align: inherit;
}
.socialDropdown{
  margin-bottom: 0px;
  float: left;
  margin-left: 12%;
  font-family: "Open Sans", sans-serif;
}
#entity_date{font-size: 16px;color: whitesmoke;}
.textAlign1{
  left: 27px;
}
.textAlign2{
  left: 50px;
  margin-top: -30%;
}
.textAlign3{
  left: 50px;
  margin-top: -40%;
}
.create1{
 text-align: center;
}
.create2{
  text-align: center;
  transform: translate(-30% ,-15%);
}
@media only screen and (max-width: 330px) {

  .socialNew li{
    margin-right: 5px !important;
  }
  .header-bottom .location{
    margin-right: 5px !important;

  }
  .bookmarkAlign1{
    margin-left: 0px;
  }
  .bookmarkAlign2{
    margin-left: 0px;
  }
  .textAlign1{
    left: 0px;
  }
  .textAlign2{
    left: 0px;
    margin-top: 0%;
  }
  .textAlign3{
    left: 0px;
    margin-top: 0%;
  }
  .create1{
    text-align: center;
  }
  .create2{
    text-align: center;
  }
  .search_mobile {
    margin-top: 4px !important;
    margin-left: -70px !important;
    width: 80px !important;
  }
}

@media only screen and (max-width: 768px) {
  .shSocialSecMuseum{
    margin-top:0px !important;
  }
  .socialNew2 li{
    margin-right:10px;
  }
  .create1{
    text-align: center;
    transform: translate(-15% ,1%);
  }
  .create2{
    text-align: center;
    transform: translate(7% ,-135%);
  }
  .bookmarkAlign1{
    margin-left: 0px;
  }
  .bookmarkAlign2{
    margin-left: 0px;
  }
  .textAlign1{
    left: 0px;
  }
  .textAlign2{
    left: 0px;
    margin-top: 0%;
  }
  .textAlign3{
    left: 0px;
    margin-top: 0%;
  }
  .resize{margin-left:46px !important}
  .header-details .shDateSec{width:100%}

  #entity_date{font-size: 12px !important;
    justify-content:left !important;
    color: black !important;
    /* transform: translate(150%,45%);   */
  }

  .mobileSocialView {
    filter:invert(30%) !important;
  }
  .mobileSocialView1{
    font-size: 20px;
    margin-top: -10px;
  }
  #mapper_entity{font-weight:bolder;}
  .copyFooter{ margin: 1% -8% !important;}
  .read-more-text{
    font-size:12px;
  }
  .bookmarkList .card-icon-img{
    width:22px !important;
    margin-top:5px;
    position: relative;
    bottom: 2px;
    height: 22px;
  }
  .bannerSocialView .mobileSocialView {
    font-size: 20px;
    filter: invert(30%);
  }

  .stThumbItemCtrl li {
    list-style: none;
  }
  /* .stThumbItemCtrl img{filter:invert(70%)} */
  .museumRelation .stThumbItemCtrl a {
    width: 16px;
    height: 16px;
  }
  .museumRelation .stThumbItem h2 {
    font-size: 24px;
    letter-spacing: 0.5px;
    line-height: 1.5;
  }
  .museumRelation .stThumbItem .stThumbCat {
    margin-bottom: 4px;
  }
  .museumRelation .stThumbItem .stThumbOw {
    font-size: 14px;
    font-weight: 700;
    margin-top: 0px;
  }
  .languageMuseum {
    font-size: 10px !important;
  }
  .shHeader.smHeader .bookmarkIcon {
    opacity: 0;
    -webkit-transition: opacity 1s ease-in;
    -moz-transition: opacity 1s ease-in;
    -o-transition: opacity 1s ease-in;
  }
  .shHeader.smHeader .shSearch {
    opacity: 1;
    -webkit-transition: opacity 1s ease-in;
    -moz-transition: opacity 1s ease-in;
    -o-transition: opacity 1s ease-in;
  }
  .SocialShare {
    font-size: 12px !important;
    color: #686868 !important;
    margin: 8px 0;
    font-weight: normal;
  }
  .socialMuseum {
    float: left;
    display: flex;
    margin-top:1%;
  }
  .tantraSec a {
    padding: 4px 28px !important;
    margin-bottom:8%;
  }
  .supported-by p {
    margin: 45px 0 0px !important;
  }
  .supported-by {
    margin: 45px 0 0px !important;
  }
  .article-supported-by p {
    margin: 0px 0 0px !important;
  }
  .article-supported-by {
    margin: 0px 0 0px !important;
  }
  .MuseumText p {
    letter-spacing: inherit !important;
    padding-right: 0px;
  }
  .MuseumText .museumMainTxt p {
    padding-right: 0px !important;
    font-size: 18px;
    line-height: 2 !important;
  }
  .MuseumText {
    max-width: 100% !important;
  }
  .museumPageTheme {
    padding: 0px 18px !important;
  }
  .shHeader.smHeader .shMenu {
    width: 28px;
    opacity: 1 !important;
    -webkit-transition: opacity 1s ease-in;
    -moz-transition: opacity 1s ease-in;
    -o-transition: opacity 1s ease-in;
  }
  .shMenu {
    margin-top: 38px;
    
  }
  .topLinks {
    display: none;
    justify-content: center;
  }
  .bookmarkList img {
    filter: invert(70%);
    width: 16px !important;
  }
  .bookmarkList {
    position: absolute;
    right: 0;
    padding-right: 24px;
  }
  #author_entity h3{
    font-weight: 600;
    margin-right: 10px !important;
    font-size:14px!important;
  }
  .social {
    margin-right: 0px !important;
    margin-left: 4%;
  }
  .socialNew li {
    width: 18px;
    margin-right: 20px;
    cursor: pointer;
  }
  .socialNew img {
    filter: invert(70%);
  }
  .location_text {
    color: #444 !important;
  }
  #date_entity {
    margin: 12px 0px 16px !important;
    line-height: 0;
    font-size: inherit;
    right: 0;
    padding-right: 32px;
    position: absolute;
  }
  .search-conMobile {
    right: 55px !important;
  }
  .search_mobile {
    margin-top: 4px !important;
    margin-left: -70px !important;
    width: 80px !important;
  }

  .only-mobile {
    display: block;
  }
  .only-desktop {
    display: none;
  }
  nav {
    padding: 15px 32px;
  }
  #nav-logo {
    width: 164px;
  }
  #nav-search {
    margin-right: 0;
  }
  .profile-icon {
    display: none;
  }

  header {
    display: none;
  }

  .header-mobile {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 10px 25px 16px;
  }

  .header-bottom {
    width: 100%;
    justify-content: initial;
    margin-bottom: 0px;
  }

  .header-bottom .location {
    font-size: 12px;
    margin-right: 16px;
  }

  .header-bottom .location .loc-icon {
    width: 10px;
    margin-right: 8px;
  }

  /* .header-bottom .social {
    margin-left: -32px;
  } */

  .header-bottom .social .social-icon {
    width: 16px;
    margin-right: 12px;
  }

  .bookmark-icon {
    width: 12px;
    margin-right: 0;
  }

  .language select {
    color: #444444;
    padding: 10px 32px 10px 0;
    font-size: 10px;
  }

  .language:after {
    width: 12px;
    height: 12px;
    /* background-image: url(images/down-arrow-dark.png); */
  }

  .header-image-moblie {
    margin-bottom: 16px;
  }

  .header-mobile .title {
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 4px;
  }

  .header-mobile .sub-title {
    font-size: 12px;
    font-weight: normal;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 4px;
  }

  .header-mobile .header-details {
    width: 100%;
    font-size: 12px;
    margin-bottom: 0;
    font-family: "Open Sans", sans-serif;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-mobile .header-details .author {
    margin-right: 32px;
    font-weight: bold;
    width: 60%;
  }

  .right-side {
    width: 100%;
    padding: 0 32px;
    margin-top: 32px;
  }

  .more-topic-images {
    padding: 0 32px;
  }

  .more-section {
    padding: 32px 16px;
    /* margin: 32px 0px; */
  }

  .more-section .fact-row {
    flex-direction: column;
  }

  .more-section .fact-row-left {
    font-size: 12px;
    /* margin-bottom: 8px; */
    width: 100%;
  }

  .more-section .fact-row-right {
    padding-left: 0;
    font-size: 14px;
  }

  .main-content {
    flex-direction: column;
    align-items: center;
  }

  .left-side {
    width: 100%;
    padding: 0 32px;
  }

  .rights-section {
    font-size: 12px;
  }

  .social .social-icon {
    width: 16px;
    margin-right: 12px;
  }

  .slider-image {
    padding: 0;
    margin-bottom: 0;
    /* width: 50%; */
  }

  .splide__arrow {
    top: 42%;
    background-color: #cacaca;
    width: 2.5em;
    height: 2.5em;
    /* display: none; */
  }
  .splide__arrow path {
    fill: white;
  }

  .splide__arrow--prev {
    left: 0.5em;
  }

  .splide__arrow--next {
    right: 0.5em;
  }

  .blog-card {
    width: 100%;
    padding: 16px 0;
    display: contents;
  }

  .card-left {
    padding: 0 32px;
  }
  .card-icons {
    right: 32px;
  }
  .card-icon-img {
    width: 16px;
  }
  .read-more-wrapper {
    padding: 0;
  }
  .read-more-text {
    margin-bottom: 8px;
    padding: 40px 0px 24px;
    /* margin-left: -5px; */
  }
  .bottom-border {
    width: calc(100% - 64px);
    margin: 0 32px;
  }

  #slider-wrapper {
    padding: 32px;
    background-color: #f5f5f5;
    margin-bottom: 45px;
  }

  .more-section .service-icon {
    margin-top: 10px;
  }
  .location_banner {
    max-width: 20px !important;
    filter:invert(25%) !important;
    float: left;
    margin-top: -6px;
    margin-left: -5%;
    margin-right: 40px!important;
  }
  .muLang .btn-secondary{height:0px;}
  .location img {
    filter: invert(30%);
  }
  .location_text {
    color: #444;
    font-weight: 100 !important;
    padding: 0px 0px 0px 12px !important;
    margin-top: -9%;
    float: left;
  }
}
.location_banner {
  max-width: 24px;
  float: left;
  margin-right: 60px;
  /* margin-top: -4px; */
}
.location_text {
  color: #fff;
  font-weight: 200;
  padding: 0px 0px 8px 20px;
  float: left;
  font-family: "Open Sans", sans-serif;
}
.entry p {
  font-size: 14px !important;
}
.social-icon p {
  font-size: 12px;
}
.lineBottom a {
  color: #da3a50 !important;
}
.more-section p {
  font-size: 14px !important;
  line-height: 1.5 !important;
  padding-bottom: 0px !important;
  margin: 0px !important;
  letter-spacing: inherit !important;
}
.supported-by p {
  font-size: 12px !important;
  font-style: italic;
  color: #707070 !important;
  margin: 45px 0 0px;
  line-height: 1.5;
  letter-spacing: inherit;
}
.articleMainTxt .article-supported-by p {
  font-size: 12px !important;
  font-style: italic;
  color: #707070 !important;
  line-height: 1.5;
  letter-spacing: inherit;
}
.museumContainer {
  max-width: 100%;
  padding: 0px !important;
}
.museum-banner-text {
  position: absolute;
  left: 190px;
  bottom: 40px;
  color: #fff;
  z-index: 1;
}
/* .museumMainTxt div{
  padding-bottom: 40px;
  line-height: 36px;
  color: #444;
  font-size: 18px;
  letter-spacing: 1px;
} */
.museumPageTheme {
  padding: 0px 180px;
}
.shBnTimeline #author_entity h3 {
  margin-top: 8px;
  font-size: 16px;
  font-weight: 700;
  font-family:sans-serif;
  color: #444444;
}
.shSocialSec .muLang {
  padding-top: 15px;
}
.shSocialSecMuseum {
  margin-top: 12px;
}
.headerBook img {
  filter: invert(100%);
}
.headerBook {
  filter: invert(50%);
}
.signInForm {
  padding: 20px 60px;
  background-color: #e2e2e2;
  margin-bottom: 15px;
}
.signInForm1 {
  padding: 40px 50px;
  background-color: #e2e2e2;
}
.signInForm3 {
  padding: 40px 265px;
  background-color: #e2e2e2;
}
.BoldTexts {
  font-weight: 600;
  font-size: 15px;
}
.Colortexts {
  font-weight: 100 !important;
  color: #da3a50;
}
.keeplogin input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin-right: 15px;
  vertical-align: -5px;
}
.keeplogin {
  font-size: 16px;
  font-weight: 600;
  color: #686868;
  margin: 25px 0px;
}
.or-signin-with {
  width: 100%;
  position: relative;
  margin-top: 35px;
}
.or-signin-with .text {
  position: absolute;
  font-size: 14px;
  font-weight: 700;
  background: #e2e2e2;
  padding: 5px 15px;
  left: 50%;
  margin-left: -63px;
  top: -14px;
}
.or-signin-with .line {
  width: 100%;
  background: #e2e2e2;
  height: 2px;
}
.social-login {
  width: 100%;
  margin-top: 35px;
  text-align: Center;
}
.social-login a {
  display: inline-block;
  /* background: #fff; */
  color: #000;
  font-size: 18px;
  width: 50px;
  height: 50px;
  margin-left: 15px;
  border-radius: 50px;
  padding: 17px;
}
.sign-in-area .field .left-field {
  width: 133px;
  margin-right: 5px;
  float: left;
}

.sign-in-area .field .right-field {
  width: 133px;
  margin-left: 5px;
  float: right;
}
.fullBoxMain > div {
  z-index: 99;
  position: relative;
}
* {
  box-sizing: border-box;
}
input[type="text"],
input[type="password"],
input[type="date"] {
  width: 280px;
  font-size: 14px;
  padding: 10px;
  margin: 15px 0 0px 0;
  display: inline-block;
  border: none;
  background: #fff;
}
#org_or_ind {
  width: 100%;
  font-size: 14px;
  padding: 15px;
  margin: 5px 0 22px 0;
  display: inline-block;
  border: none;
  background: #f1f1f1;
}
label {
  font-size: 15px;
  text-align: left !important;
}
input[type="text"]:focus,
input[type="password"]:focus {
  /* background-color: #ddd; */
  outline: none;
}
hr {
  border: 1px solid #f1f1f1;
  margin-bottom: 25px;
}

.buttonStyl {
  background-color: #da3a50;
  color: white;
  padding: 10px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 280px;
  border-radius: 6px !important;
  font-size: 15px !important;
}
.shTag .buttonStyll {
  background-color: #da3a50;
  color: white;
  padding: 10px;
  margin: 35px 0;
  border: none;
  cursor: pointer;
  width: 280px;
  border-radius: 6px !important;
  font-size: 15px !important;
  margin-left: -122px;
}
.buttonStyl:hover {
  opacity: 1;
}
.cancel {
  padding: 14px 20px;
  background-color: #ff3d2f;
}
.formContainer {
  padding: 16px;
}
.formContainer p {
  font-size: 28px;
}
.shProfile .dropdown-item.dropdown-sign-out {
  padding: 4px 35px;
}
.shBnText  #title_heading_entity {
  font-size: 30px !important;
}
.medium-insert-embed div {
  max-width: 100% !important;
}
.location_text {
  color: #fff;
  padding: 0px 0px 6px 16px;
  float: left;
}
.entry p {
  font-size: 14px !important;
}
.social-icon p {
  font-size: 12px;
}
.shBnTimeline {
  left: 140px !important;
}
/* .topLinks a{text-transform: capitalize;} */
.shSocialList a {
  cursor: pointer;
}
.shBnTimeline #date_entity {
  margin: 3px 70px 16px;
  line-height: 0;
  font-size: inherit;
}
/* .bookmarkList {
  margin-bottom: 6px;
} */

/* .location_banner{max-width:24px !important;float:left;}
.location_text{color:#fff;font-weight: 600;padding:3px 0px 0px 20px;float:left}
.entry p{font-size:14px !important; margin-top: -7px;}
.social-icon p{font-size: 12px;} */

.ClickableEvent a:hover {
  color: #da3a50;
}
.social-icon svg {
  display: block;
  height: 19px;
  width: 19px;
  color: #444444;
}
.social-icon p {
  color: #444444;
}
a {
  cursor: pointer;
}
.stFootBtm svg {
  display: block;
}
/* .bookmarkIcon .card-icons-bl img{
  filter: invert(100%);
} */
/* .service-icons span{
  background-image: url(/src/components/images/facilities-icons.png);
} */
.service-icons span.photography.yes {
  background-position: 0px 4px;
  display: inline-block;
}
.service-icons span.videography.yes {
  background-position: -48px 1px;
  display: inline-block;
}
.service-icons span.cafeteria.yes {
  background-position: -100px 2px;
  display: inline-block;
}
.service-icons span.museumshop.yes {
  background-position: -153px 2px;
  display: inline-block;
}
.service-icons span.wheelchair.yes {
  background-position: -201px 2px;
  display: inline-block;
}
.service-icons span.restroom.yes {
  background-position: -249px 2px;
  display: inline-block;
}
.service-icons span.water.yes {
  background-position: -294px 4px;
  display: inline-block;
}
.service-icons span.lockerroom.yes {
  background-position: -334px 4px;
  display: inline-block;
}
.service-icons span.escalator.yes {
  background-position: -380px 4px;
  display: inline-block;
}
.service-icons span.seating.yes {
  background-position: -426px 3px;
  display: inline-block;
}
.service-icons span.membership.yes {
  background-position: -469px 3px;
  display: inline-block;
}
.service-icons span.elderly.yes {
  background-position: -514px 3px;
  display: inline-block;
}
.service-icons span.parking.yes {
  background-position: 0px -37px;
  display: inline-block;
}
.service-icons span.infoinbraile.yes {
  background-position: -52px -37px;
  display: inline-block;
}
.service-icons span.garden.yes {
  background-position: -97px -40px;
  display: inline-block;
}
.service-icons span.workshops.yes {
  background-position: -150px -36px;
  display: inline-block;
}
.service-icons span.audioguide.yes {
  background-position: -200px -34px;
  display: inline-block;
}
.service-icons span.guidedtour.yes {
  background-position: -248px -33px;
  display: inline-block;
}
.service-icons span.library.yes {
  background-position: -293px -35px;
  display: inline-block;
}
.service-icons span.itfacilities.yes {
  background-position: -333px -34px;
  display: inline-block;
}
.service-icons span.conference.yes {
  background-position: -379px -35px;
  display: inline-block;
}
.service-icons span.conservation.yes {
  background-position: -426px -35px;
  display: inline-block;
}
.service-icons span.research.yes {
  background-position: -466px -36px;
  display: inline-block;
}
.service-icons span.auditorium.yes {
  background-position: -513px -36px;
  display: inline-block;
}

.service-icons span {
  background-image: url(/src/components/images/MOI_Icons.png);
  background-repeat: no-repeat;
  background-size: 540px auto;
  display: none;
  height: 40px;
  width: 40px;
  margin: 2px 10px;
  cursor: pointer;
}
.medium-insert-buttons {
  display: none;
}
.museumLanguage .btn-secondary {
  font-size: 14px !important;
  padding: 9px !important;
}
.museumLanguage {
  margin-left: 64px;
}
.bookmarkIcon .card-icon-img {
  filter: invert(60%);
}

/* .bookmarkIcon .card-icons-bl img{
  filter: initial;
} */
.bookmarkIcon div {
  position: initial !important;
}
.MuseumText .paraItalic p {
  padding-right: 0px;
}
.museumMoreSec .moreTopicSec h2 {
  font-weight: 600;
  margin: 16px 0 20px;
  font-size:16px;
}
.Timeline-Page .museumMoreSec .moreTopicSec h2 {
  font-weight: 700;
  margin: 16px 0 20px;
}
.museumMoreSec .moreList li a:hover h3 {
  text-decoration: none;
}
.smHeader .shProfile {
  display: none;
}
.header-details .shDateSec {
  position: relative;
}

.whatsappHover:hover {
  color: inherit !important;
}
.shTag .articlesign{
  width:200%;
}
.Museum-Page #entity_date{
  margin:0px !important;
}
.landingPgSign #BoldTexts{
margin-right:60px !important; 
}
.mapSignin .landingPgSign #BoldTexts{
  margin-right:104px !important;
  width: 100% !important; 
  text-align: left;
  }
.landingPgSign #keeplogin{
  margin-left: -100PX !important;
  width: 100% !important; 
  }
  .landingPgSign #field{
    margin-top: 40px !important; 
    }
    .landingPgSign #field2{
      margin-top: 20px !important; 
      }
    .landingPgSign #donate{
      margin-left: -270px !important; 
      }
      .landingPgSign #donate1{
        margin-left: -270px !important; 
        }
        .landingPgSign #BoldTexts1{
          margin-left:-30px !important;
          width: 100%; 
          }
          .signin-text{
            font-size: 30px !important;
          }