@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville&family=Open+Sans:wght@400;600&display=swap");

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  margin: 64px 0 0;
  padding: 0;
  font-family: "Libre Baskerville", serif;
  font-size: 16px;
  line-height: 1.5;
}

.only-mobile {
  display: none;
}
.only-desktop {
  display: block;
}

nav {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  padding: 15px 40px;
  background-color: white;
  z-index: 99;
}

.nav-left {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-right {
  display: flex;
  justify-content: center;
  align-items: center;
}

#burger-menu {
  width: 28px;
  cursor: pointer;
}

#nav-logo {
  margin-left: 30px;
  width: 188px;
  cursor: pointer;
}

#nav-search {
  margin-right: 60px;
  width: 24px;
  cursor: pointer;
}

.profile-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #da3a50;
  border-color: #da3a50;
  border-radius: 50%;
  text-transform: capitalize;
  width: 40px;
  height: 40px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  cursor: pointer;
}

header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

header .header-h1 {
  color: #444444;
  font-size: 24px;
  font-weight: normal;
  margin-top: 64px;
}
header .header-h1 {
  color: #444444;
  font-size: 24px;
  font-weight: normal;
  margin-top: 64px;
}

header .sub-title {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  margin-bottom: 16px;
}
header .link-h1 {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  margin-bottom: 16px;
}

#filters2 {
  width: 100%;
  max-width: 1024px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  margin: 16px 0;
  padding: 32px 16px;
}

.filter-inputs-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 64px;
}

#search-result-count {
  color: #da3a50;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
}

.filter-label {
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
  margin-right: 16px;
  color: #707070;
}

.filter-select {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-left: 16px;
}

.filter-select .library-select {
  width: 256px;
  background-color: #f5f5f5;
  border: none;
  padding: 10px 16px;
  border-radius: 8px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.filter-select:after {
  content: "";
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 16px;
  height: 16px;
  /* background-image: url(images/down-arrow.png); */
  background-size: contain;
  pointer-events: none;
}

main {
  margin: 0 auto;
  max-width: 1024px;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  flex-wrap: wrap;
}

.blog-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: 1px solid #e8e8e8; */
  padding: 32px 16px;
  flex-direction: column;
  width: 33.33%;
}

/* .card-image {
  width: 100%;
  height:200px;
  cursor: pointer;
} */

.card-left {
  width: 100%;
}

.card-title {
  letter-spacing: 0.5px;
  font-size: 20px;
  margin-bottom: 8px;
  line-height: 1.5;
  color: #444444;
  cursor: pointer;
  margin-top: 20px;
  text-transform: capitalize;
  height: 60px;
  overflow:hidden;
}

.card-description {
  font-size: 12px;
  color: #404040;
  margin-bottom: 8px;
  text-transform: capitalize;
  text-align: justify;
  height:55px;
  overflow:hidden;
}

.card-author {
  font-size: 10px;
  color: #444444;
  margin-bottom: 8px;
  font-family: "Open Sans", sans-serif;
  /* font-weight: bold; */
  cursor: pointer;
  text-transform: capitalize;
  height:15px;
  overflow: hidden;

}

.card-icons {
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: "Open Sans", sans-serif;
}

.card-icon-img {
  width: 19px;
  margin-left: 8px;
  cursor: pointer;
}

.blog-card:last-child {
  border-bottom: none;
}

.load-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 128px;
  margin-top: 32px;
}

.load-btn {
  font-family: "Open Sans", sans-serif;
  width: 200px;
  border: 1px solid #444444;
  text-align: center;
  padding: 8px;
  color: #444444;
  font-weight: bold;
  cursor: pointer;
}
.media-container {
  position: absolute;
  width: 90%;
  overflow:hidden;
  background-color: #000000;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
}
.media-caps{
  width: 90%;
  height: 70%;
  overflow:hidden;
  position: absolute;
}
.shadow{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
}

.media-image {
  opacity: 1;
  display: block;
  width: 100%;
  transition: .5s ease;
  backface-visibility: hidden;
}

.media-middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.media-container:hover .media-image {
  opacity: 0.3;
}

.media-container:hover .media-middle {
  opacity: 1;
}

.media-text {
  background-color: #04AA6D;
  color: white;
  font-size: 16px;
  padding: 16px 32px;
}


@media only screen and (max-width: 768px) {
  .only-mobile {
    display: block;
  }
  .only-desktop {
    display: none;
  }
  nav {
    padding: 15px 16px;
  }
  #nav-search {
    margin-right: 0;
  }
  .profile-icon {
    display: none;
  }
  .check{
    top:0px !important;
  }
  .media-container {
    position: absolute;
    width: 60%;
    height: 30%;
    overflow:hidden;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
  }
  .media-image {
    opacity: 0.3;
    display: block;
    width: 100%;
    height: 100%;
    transition: .5s ease;
    backface-visibility: hidden;
  }
  .media-image1 {
    opacity: 1;
    display: block;
    width: 100%;
    height: 100%;
    transition: .5s ease;
    backface-visibility: hidden;
  }
  .media-middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    opacity: 1;
  }
  
  .media-text {
    background-color: #04AA6D;
    color: white;
    font-size: 16px;
    padding: 16px 32px;
  }
  #search-form {
    width: 300px;
  }

  #filters2 {
    flex-direction: column;
    border-top: none;
  }

  .filter-select {
    width: 100%;
    flex-direction: column;
    max-width: 256px;
    align-items: center;
    padding: 8px 0;
  }

  .filter-select select {
    width: 256px;
  }

  #search-result-count {
    margin-bottom: 8px;
  }

  .filter-inputs-wrapper {
    width: 100%;
    margin-left: 0;
  }

  .filter-select:first-child {
    margin: 0;
  }

  .filter-select:after {
    bottom: 18px;
    right: 16px;
  }

  .filter-label {
    padding: 8px 0;
  }

  .card-left {
    padding: 0;
  }
  .blog-card {
    width: 100%;
  }
}

.social-icon a{color:grey;padding: 2px; font-size: 12px;    width: 100px;}
[role=tooltip].popup-content{width:110px !important;}
.btn-secondary{
  background-color: none !important;
  border-color: none !important;
}
.filterImport option{
  width: auto;
}