@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville&family=Open+Sans:wght@400;600&display=swap");

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  margin: 64px 0 0;
  padding: 0;
  font-family: "Libre Baskerville", serif;
  font-size: 16px;
  line-height: 1.5;
}

.only-mobile {
  display: none;
}
.only-desktop {
  display: block;
}

nav {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  padding: 15px 40px;
  background-color: white;
  z-index: 99;
}

.nav-left {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-right {
  display: flex;
  justify-content: center;
  align-items: center;
}

#burger-menu {
  width: 28px;
  cursor: pointer;
}

#nav-logo {
  margin-left: 30px;
  width: 188px;
  cursor: pointer;
}

#nav-search {
  margin-right: 60px;
  width: 24px;
  cursor: pointer;
}

.profile-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #da3a50;
  border-color: #da3a50;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  cursor: pointer;
}

.blogHeader {
  width: 100%;
  height: 40% !important;
  background-color: #da3950;
  background-image: url(/src/components/blog/images/header.png);
  background-size: 100% 100%;
  background-position: center center;
  margin-bottom: 64px;
}

main {
  margin: 0 auto;
  max-width: 1110px;
  /* display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; */
}
.mainBlog{
  max-width: 1200px !important;

}

.slider-header {
  padding: 16px;
  position: relative;
  color: #444444;
}
.slider-title {
  font-size: 24px;
}

.slider-subtitle p {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  padding-right: 64px;
  margin-bottom: -7px;
}
.slider-subtitle {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  padding-right: 64px;
  padding: 4px 64px 4px 0px;
  font-weight:600;
}

.slider-view-all {
  position: absolute;
  bottom: 16px;
  font-weight:600;
  padding:0 15px 0 0;
  right: 16px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  cursor: pointer;
}

.splide__arrow {
  background-color: white;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.16);
  top: 25%;
  opacity: 1;
}

.splide__arrow--next {
  right: 0;
}

.splide__arrow--prev {
  left: 0;
}

.splide__pagination__page.is-active {
  background: #000;
}

.blog-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: 1px solid #e8e8e8; */
  padding: 0 16px 64px;
  flex-direction: column;
  width: 100%;
  font-family: "adobeCleanReg"!important;
}
.blog-card-bl {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: 1px solid #e8e8e8; */
  padding: 0 16px 64px;
  flex-direction: column;
  width: 100%;
}

.card-image-bl {
  width: 100%;
  /*height: 206px;*/
  cursor: pointer;
}

.card-details-bl {
  width: 100%;
  padding-right: 0;
  font-family: "adobeCleanReg"!important;
}

.card-title-bl {
  letter-spacing: 0.5px;
  font-size: 24px;
  margin-bottom: 8px;
  line-height: 1.5;
  height:60px;
  color: #444444;
  cursor: pointer;
  margin-top: 20px;
  font-weight: normal;
  font-family: 'Libre Baskerville';
}

.card-description-bl {
  font-size: 14px;
  color: #404040;
  font-weight:600!important;
  margin-bottom: 8px;
  margin-top: 7%;
  font-family: 'Libre Baskerville';
}
.card-description-bl p {
  font-size: 14px;
  color: #444444;
  margin-bottom: 8px;
  font-weight:600;
  height: 64px;
  overflow: hidden;
}

.card-icons-bl {
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: "Open Sans", sans-serif;
}

.card-icons-bl .card-icon-img {
  width: 19px;
  margin-left: 8px;
  cursor: pointer;
}

.blog-card:last-child {
  border-bottom: none;
}
.blog-card-bl:last-child {
  border-bottom: none;
}
.load-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 128px;
  margin-top: 32px;
}

.load-btn {
  font-family: "Open Sans", sans-serif;
  width: 200px;
  border: 1px solid #444444;
  text-align: center;
  padding: 8px;
  color: #444444;
  font-weight: bold;
  cursor: pointer;
}

.load-btn:hover {
  background-color: #da3950;
  color: white;
  border: 1px solid transparent;
}
@media only screen and (max-width: 950px) {
  .blogHeader{display:block;height:30% !important;object-fit:cover;margin-top:-15px}
}
@media only screen and (max-width: 768px) {
  .blog-page-bl .shHeader{height:55px;}
  .blogHeader{display:block;height:20% !important;object-fit:cover;margin-top:-15px}
  .blog-card a{width:100%;}
  .blog-card .card-image-bl{width:100%;}
  .only-mobile {
    display: block;
  }
  .only-desktop {
    display: none;
  }
  nav {
    padding: 15px 16px;
  }
  #nav-search {
    margin-right: 0;
  }
  .profile-icon {
    display: none;
  }

  header {
    height: 112px;
    margin-bottom: 16px;
  }
  .splide__arrow {
    display: none;
  }

  .card-details {
    padding: 0;
  }
  .blog-card {
    width: 100%;
  }
  .blog-card-bl {
    width: 100%;
  }
  .slider-view-all {
    position: absolute;
    font-weight:600;
    bottom: 16px;
    right: 16px;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    cursor: pointer;
    font-style: italic;
  }
}
.slider-header-width {
  width: 100%;
  margin-top:6%;
}
.splide__track li {
  width: 341.333px !important;
}
#slider-one {
  width: -webkit-fill-available;
}

.blog-page-bl .react-multiple-carousel__arrow {
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 50%;
  z-index: 1000;
  border: 0;
  background: #fff;
  min-width: 36px;
  min-height: 36px;
  height: 26px;
  opacity: 1;
  cursor: pointer;
  margin-top: -20%;
  margin-right: -44px;
  -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.26);
}
.blog-page-bl  .react-multiple-carousel__arrow:hover {
  background: #fff;
}
.blog-page-bl .react-multiple-carousel__arrow--left {
  margin-left: -4%;
}
.blog-page-bl .react-multiple-carousel__arrow::before {
  color: #000;
  font-weight: bolder;
  font-size: 16px;
}
