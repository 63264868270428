@font-face {
  font-family: adobeCleanReg;
  src: url("../../urls/adobeClean/AdobeCleanRegular.otf"); }
@font-face {
  font-family: adobeCleanLi;
  src: url("../../urls/adobeClean/AdobeCleanLight.otf"); }
  
.topnav {
   background-color:white;
   overflow: hidden;
   
 }
 
 /* Style the links inside the navigation bar */
 .topnav a {
   float: left;
   display:block;
   color:rgb(80, 70, 70);
   flex-flow: row;
   text-align: center;
   text-decoration-line: underline;
   padding: 40px 80px;
   text-decoration: none;
   font-size: 17px;
 }
 
 .topnav a:hover {
   border-bottom: 0.5px solid black;
   text-underline-position: auto;
 }
 
 .topnav a.active {
   border-bottom: 3px solid black;
 }

.topnav-right {
   float: right;
 }
.header{
   color:rgba(255, 0, 0, 0.863);
   border-bottom: 1px solid #E8E8E8;
   overflow:hidden;
}

.imgLink a:hover{
  color: #DA3A50!important;
  text-decoration: none!important;
}

.contentFont {
  font-family: "adobeCleanReg"!important;
  font-size: 16px!important;
  font-weight: 400!important;
  
}

p {
  line-height: 1.7!important;
  color:#444444
}
.menu ul li {
  color:#444444;
}
.about-ab .breadcrumb{display: none;}
.team{color:#444444}
.menu{  
  word-break: break-word;
}