/* Flickity fullscreen v1.0.1
------------------------- */

.flickity-enabled.is-fullscreen {
    Z-INDEX: 1000 !important;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: hsla(0, 0%, 0%, 0.9);
    padding-bottom: 35px;
    z-index: 1;
  }
  

  .flickity-enabled.is-fullscreen 
  
  .flickity-enabled.is-fullscreen .flickity-page-dots {
    bottom: 10px;
  }
  
  .flickity-enabled.is-fullscreen .flickity-page-dots .dot {
    background: white;
  }
  
  /* prevent page scrolling when flickity is fullscreen */
  html.is-flickity-fullscreen {
    overflow: hidden;
  }

  html.is-flickity-fullscreen .magnifying-glass {
    display: block;
  }

  .magnifying-glass {
    display: none;
  }

  .carousel.is-fullscreen .carousel-cell .magnifier .magnifying-glass {
    display: block !important;
  }

  .carousel.flickity-enabled.is-draggable {
      /* margin: 0 0 50px 0; */
  }


  
  /* ---- flickity-fullscreen-button ---- */
  
  .flickity-fullscreen-button {
    display: block;
    right: 10px;
    top: 10px;
    width: 24px;
    height: 24px;
    border-radius: 4px;
  }
  
  /* right-to-left */
  .flickity-rtl .flickity-fullscreen-button {
    right: auto;
    left: 10px;
  }
  
  .flickity-fullscreen-button-exit { display: none; }
  
  .flickity-enabled.is-fullscreen .flickity-fullscreen-button-exit { display: block; }
  .flickity-enabled.is-fullscreen .flickity-fullscreen-button-view { display: none; }
  
  .flickity-fullscreen-button .flickity-button-icon {
    position: absolute;
    width: 16px;
    height: 16px;
    left: 4px;
    top: 4px;
  }

  .carousel {
    margin: 0 auto 60px;
    text-align: center;
    height: 524px;
    
  }
  
  /* cell inherit height from carousel */
  .carousel-cell {
    position: relative;
    height: 100%;
    width:100%;
    /* center images in cells with flexbox */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .carousel.is-fullscreen{
    margin: 0;
  }
  .carousel.is-fullscreen .CarouselCaption{
    color: #fff;
  }
  .carousel.is-fullscreen .carousel-cell {
    height: 100%;
    width: 100%;
  }

  .carousel.is-fullscreen .carousel-cell .caption {
    display: block !important;
  }
  
  .carousel-cell img {
    display: block;
  }

  .carousel-cell .caption {
    display: none;
    position: absolute; /* Position the background text */
    bottom: 0; /* At the bottom. Use top:0 to append it to the top */
    background: rgba(0, 0, 0, 0.475); /* Fallback color */
    color: #f1f1f1; /* Grey text */
    width: 100%; /* Full width */
    padding: 20px; /* Some padding */
  }
  @media(max-width:910px) {
    .flickity-page-dots {
      display: none;
    }
    

  }
  @media(max-width:420px) {
    .carousel{
      height: 320px;
    }
  }
  