@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville&family=Open+Sans:wght@400;600&display=swap");

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  margin: 64px 0 0;
  padding: 0;
  font-family: "Libre Baskerville", serif;
  font-size: 16px;
  line-height: 1.5;
}

.only-mobile {
  display: none;
}
.only-desktop {
  display: block;
}

nav {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  padding: 15px 40px;
  background-color: white;
  z-index: 99;
}

.nav-left {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-right {
  display: flex;
  justify-content: center;
  align-items: center;
}

#burger-menu {
  width: 28px;
  cursor: pointer;
}

#nav-logo {
  margin-left: 30px;
  width: 188px;
  cursor: pointer;
}

#nav-search {
  margin-right: 60px;
  width: 24px;
  cursor: pointer;
}

.profile-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #da3a50;
  border-color: #da3a50;
  border-radius: 50%;
  text-transform: capitalize;
  width: 40px;
  height: 40px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  cursor: pointer;
}

#header {
  display: flex;
  flex-direction: column;
  justify-content: center;
   align-items: center; 
  text-align: left;
  margin-left: 16px;
}

#header .header-h1 {
  color: #444444;
  font-size: 24px;
  font-weight: normal;
  margin-top: 25px;
  margin-bottom:20px;
}

#header .sub-title {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight:bold;
}

#filters2 {
  width: 100%;
  max-width: 1024px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  margin: 16px 0;
  padding: 32px 16px;
}

.filter-inputs-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 64px;
}

#search-result-count {
  color: #da3a50;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
}

.filter-label {
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
  margin-right: 16px;
  color: #707070;
}

.filter-select {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-left: 16px;
}

.filter-select .library-select {
  width: 256px;
  background-color: #f5f5f5;
  border: none;
  padding: 10px 16px;
  border-radius: 8px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.filter-select:after {
  content: "";
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 16px;
  height: 16px;
  /* background-image: url(images/down-arrow.png); */
  background-size: contain;
  pointer-events: none;
}

main {
  margin: 0 auto;
  max-width: 1024px;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  flex-wrap: wrap;
}

.blog-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: 1px solid #e8e8e8; */
  padding: 32px 16px;
  flex-direction: column;
  width: 33.33%;
}

/* .card-image {
  width: 100%;
  height:200px;
  cursor: pointer;
} */

.card-left2 {
  width: 100%;
}

.card-title-library {
  letter-spacing: 0.5px;
  font-size: 24px;
  margin-bottom: 8px;
  line-height: 1.5;
  color: #444444;
  cursor: pointer;
  height:60px;
  margin-top: 20px;
  /* text-transform: capitalize; */
  font-weight: normal;
  font-family: 'Libre Baskerville';
}

.card-description-library {
  font-size: 14px;
  color: #404040;
  margin-bottom: 8px;
  margin-top: 20px;
  text-transform: none;
  text-align: left;
  height:66px;
  font-weight:600;
  overflow:hidden;
font-family: 'Libre Baskerville';
}

.card-description-library p{
  font-size: 14px;
  color: #404040;
  margin-bottom: 8px;
  text-transform:none;
  text-align: left;
  line-height:1.5;
  font-weight: 600;
}
.card-author-library {
  font-size: 14px;
  color: #444444;
  margin-bottom: 10px;
  font-family: "Open Sans", sans-serif;
  /* font-weight: bold; */
  cursor: pointer;
  text-transform: capitalize;
  height:18px;
  overflow: hidden;
  width: 213px!important;
  float: left;
}

.card-icons-library {
  font-size: 14px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: "Open Sans", sans-serif;
}

.card-icon-img-library {
  width: 19px;
  margin-left: 8px;
  cursor: pointer;
}

.blog-card:last-child {
  border-bottom: none;
}

.load-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 128px;
  margin-top: 32px;
}

.load-btn {
  font-family: "Open Sans", sans-serif;
  width: 200px;
  border: 1px solid #444444;
  text-align: center;
  padding: 8px;
  color: #444444;
  font-weight: bold;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .card-image2{
    width: 100% !important;
    height: 100%!important;
  }
  .only-mobile {
    display: block;
  }
  .only-desktop {
    display: none;
  }
  nav {
    padding: 15px 16px;
  }
  #nav-search {
    margin-right: 0;
  }
  .profile-icon {
    display: none;
  }

  #search-form {
    width: 300px;
  }

  #filters2 {
    flex-direction: column;
    border-top: none;
    /* margin: 16px 0; */
    padding: 8px 16px!important;
  }

  .filter-select {
    width: 100%;
    flex-direction: column;
    max-width: 256px;
    align-items: center;
    padding: 8px 0;
  }

  .filter-select select {
    width: 256px;
  }

  #search-result-count {
    margin-bottom: 8px;
  }

  .filter-inputs-wrapper {
    width: 100%;
    margin-left: 0;
  }

  .filter-select:first-child {
    margin: 0;
  }

  .filter-select:after {
    bottom: 18px;
    right: 16px;
  }

  .filter-label {
    padding: 8px 0;
  }

  .card-left2 {
    padding: 0px 16px;
  }
  .blog-card {
    width: 100%;
  }
}

.social-icon a{color:grey;padding: 4px; font-size: 12px;width: 100px;font-family: "Open Sans", sans-serif;
  font-weight: normal;}
[role=tooltip].popup-content{width:120px !important; }
.btn-secondary{
  background-color: none !important;
  border-color: none !important;
}
.main-library{
  margin-top:10px;
  max-width: 82%;
}
.popup-content {
  padding:8px 12px;
}
.bookmarkIcon div{
  height: auto;
  width: auto;
}
